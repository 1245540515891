<template>
  <div class="column">
    <label>{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
    <label class ="custom-file-upload">
      <oib-icon-label
      style="font-size: 0.8em; margin: 0"
      icon="upload"
      :title="file ? file.name : 'Upload bestand'"
      direction="row"
      :active="true"
      @click="click_input"
    />
    </label>
    <input type="file" @input="x => on_input(x)" ref="input"/>
    <slot name="hint"></slot>
    <oib-icon-label
      style="color: red; font-size: 0.8em; height: 50px;"
      icon="error"
      :title="error_msg"
      direction="row"
      :active="false"
    />
  </div>
</template>

<script>
import OibIconLabel from "../../components/oib_icon_label.vue"

export default {
  name: "OibInputFile",
  components: {
    OibIconLabel
  },
  props: {
    init_value: {
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    error_msg: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      file: null
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.input) {
        this.$refs.input.value = this.init_value
      }      
    }, 100)
  },
  watch: {
    init_value() {
      this.$refs.input.value = this.init_value
    }
  },
  methods: {
    click_input() {
      this.$refs.input.click()
    },
    on_input(event) { 
      this.file = event.target.files[0]
      this.$emit("input", event);
    }
  }
}
</script>

<style scoped>
input, .custom-file-upload {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

input[type="file"] {
    display: none;
}

</style>