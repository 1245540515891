<template id="Login">
  <oib-page class="test-background">
    <div 
    :style="hide_login_screen ? 'opacity: 0': ''"
    class="wrapper column center"
    style="max-width: 350px"
    >
      <div class="column center" style="display: flex; flex-direction: column; height: 85vh; min-height: 600px; width: 100%;">
        <h1>Log in</h1>

        <div style="display: flex; flex-direction: column; margin-top: 30px; width: 100%; justify-content: center; box-sizing: border-box;">
          <label >Gebruikersnaam</label>
          <input
            type="text"
            v-model="username"
          />
          <label>Wachtwoord</label>
          <input
            type="password"
            v-model="password"
            @keyup.enter="login"
            @keyup.esc="login"
          />
        </div>
        <oib-button shape="rounded" @click="login">Inloggen</oib-button>
        <p style="text-align: center; font-weight: 700; font-size: 0.8em">OF</p>
        <oib-button-login-qr-code style="margin-bottom: 10px;"/>
        <google style="margin-bottom: 10px" />
        <microsoft />
        <div class="error-box">
        <p><a
        class="error-message instructions"
        style="margin-top: 50px;"
        v-if="$store.getters.get_login_error_instructions"
        @click="go_to_route('LoginInstructions')"
        >{{$store.getters.get_login_error_message}} <br>Wat nu? ➔</a></p>
        </div>
      </div>
      <p style="font-size: 0.7em; text-align: center"><a style="color: grey;" href="https://onderwijsinbeeld.nl/privacy-verklaring">Privacy verklaring</a></p>
      <p style="color: grey; display: block; position: fixed; bottom: 0px; right: 20px; font-size: 0.7em;"> {{app_version}} </p>
      </div>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import Google from "../components/oib_button_login_google.vue";
import Microsoft from "../components/oib_button_login_microsoft.vue";
import OibButton from "../components/OibButton.vue";
import OibButtonLoginQrCode from "../components/oib_button_login_qr_code.vue"

import {get_cookie} from "../cookie.js"
import {post_accounts_internal_authenticate} from "../store/api/user.js"
import { go_to_route } from "../store/navigation";

function signOut() {
  try {
    const auth2 = window.gapi.auth2.getAuthInstance();
    auth2.signOut();
  } catch {
    // attempt again within 100 ms
    setTimeout(() => signOut(), 200);
  }
}

export default {
  name: "Login",
  components: {
    OibPage,
    Google,
    Microsoft,
    OibButton,
    OibButtonLoginQrCode,
  },
  data() {
    return {
      username: "",
      password: "",
      hide_login_screen: false
    }
  },
  created() {
    this.hide_login_screen = get_cookie('ACCOUNT_TYPE')
    setTimeout(() => this.hide_login_screen = false, 300)
  },
  computed: {
    jwt() {
      return this.$store.getters.get_jwt;
    },
    app_version() {
      const environment = process.env.VUE_APP_VERSION
      return environment.split('-')[0]
    }
  },
  watch: {
    jwt(newValue) {
      if (newValue != ""){

        this.$router.push({
          name: "Laden"
        });
      }
    },
  },
  methods: {
    // Import
    go_to_route,

    // Local
    disable_scroll() {
      e.preventDefault(); e.stopPropagation();
      window.scrollTo(0,0);
    },
    async login() {
      const response = await post_accounts_internal_authenticate(this.username, this.password)
      
      // Store user data in store if user was authenticated
      if (response.status == 200) {
        const json = await response.json()
        this.$store.commit("set_jwt", json['jwt'])
        this.$store.commit("set_user", json['user'])
        this.$store.commit("set_organisation_id", json['organisation_id'])
        this.$store.commit("set_organisation_id_active", json['organisation_id'])

        this.$store.commit("set_login_error_message", "")
        this.$store.commit("set_login_error_instructions", "")
      }
      else if (response.status == 401) {
        this.$store.commit("set_login_error_message", "Dit account is onbekend.")
        this.$store.commit(
          "set_login_error_instructions",
          "<p>Wachtwoord vergeten?</p>\
           \
           <p>Vraag de Onderwijs in Beeld software beheerder op jouw basisschool om hulp.</p>\
           \
           <p>Ben jezelf de beheerder op jouw school?</p>\
           \
           <p>Bel dan naar 06 4054 5142 voor een directe verbinding met onze IT-afdeling.</p>"
        )
      }
    }
  },
  beforeRouteUpdate() {
    signOut();
  }
};
</script>

<style scoped>
.google {
  background-color: #4285f4;
  height: 48px;
  font-weight: "500";
  color: #ffffff;
  font-size: 1.2em;
  border-radius: 3px;
}

a {
  color: var(--highlight-color);
}

input {
  background-color: var(--primary-color-sub);
  padding: 5px 15px;
  border-radius: 2px;
  height: 40px;
  margin-bottom: 30px;
  border: none;
  color: var(--contrast-color-p2);
  box-sizing: border-box;
}

input[type="text"],
input[type="password"],
textarea,
select {
  outline: none;
}

label {
  font-weight: 700;
  margin-bottom: 5px;
}

.error-box {
  width: 100%;
  height: 50px;
  text-align: center;
}

.error-message {
  color: red;
  font-size: 0.9em;
}

.instructions:hover {
  cursor: pointer;
}
</style>