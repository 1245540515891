<template>
  <div>
    <oib-form-load-library v-if="!submitting" @submit="x => submit(x)"/>
    <oib-page-loading v-if="submitting" :progress="progress">
      <p>Boeken uploaden ...</p>
    </oib-page-loading>
  </div>
</template>

<script>
import OibFormLoadLibrary from "../components/oib_form_load_library.vue";
import OibPageLoading from "../components/oib_page_loading.vue"

import { post_libraries_books_copies_batch } from "../store/api/library"

export default {
  name: "BibliotheekUploaden",
  components: {
    OibFormLoadLibrary,
    OibPageLoading
  },
  data() {
    return {
      submitting: false,
      progress: 0,
    }
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      this.submitting = true

      const worksheet = body['worksheet']
      const label_dict = body['label_dict']

      // Generate library name to id dict
      let library_name_to_id = {}
      const library_list = this.$store.getters.get_library_list
      library_list.forEach(library => {
        library_name_to_id[library.library_name] = library.library_id
      })

      // Initialize copy dict
      let copy_dict = {}
      for (let i = 0; i < worksheet.length; i++) {
        const row = worksheet[i]
        const library_name = label_dict[row['label_picked']]
        const library_id = library_name_to_id[library_name]
        copy_dict[row['isbn']] = {
          isbn13: row['isbn'],
          library_id: library_id,
          count: 0
        }
      }

      // Compute copy count for each isbn13
      for (let i = 0; i < worksheet.length; i++) {
        const row = worksheet[i]
        copy_dict[row['isbn']]['count'] += row['aantal']
      }

      // Compute library copy dict
      let library_copy_dict = {}
      const keys = Object.keys(copy_dict)
      for (let key of keys) {
        const copy = copy_dict[key]
        if (!(copy.library_id in library_copy_dict)) {
          library_copy_dict[copy.library_id] = []
        }
        for (let i = 0; i < copy.count; i++) {
          library_copy_dict[copy.library_id].push(parseInt(copy.isbn13))
        } 
      }

      // Create chunk lists
      const jwt = this.$store.getters.get_jwt
      const library_id_keys = Object.keys(library_copy_dict)
      let library_chunk_list = []
      for (let library_id of library_id_keys) {
        const chunk_list = this.get_chunks_list(library_copy_dict[library_id])
        for (let isbn13_list of chunk_list) {
          library_chunk_list.push({'library_id': library_id, 'isbn13_list': isbn13_list})
        }        
      }

      // Post to server
      for (let i = 0; i < library_chunk_list.length; i++) {
        const chunk = library_chunk_list[i]
        await post_libraries_books_copies_batch(jwt, chunk.library_id, chunk.isbn13_list)
        this.progress = 100 / library_chunk_list.length * (i + 1)
      }

      // Route to library
      setTimeout(() => this.$router.back(), 400)
    },
    get_chunks_list(array) {
      let chunk_list = []
      const chunkSize = 50;
      for (let i = 0; i < array.length; i += chunkSize) {
          chunk_list.push(array.slice(i, i + chunkSize))
      }
      return chunk_list
    }
  }
};
</script>
