<template>
  <oib-button-icon
  icon="arrow_back"
  style="font-size: 24px;"
  :has_backdrop="show_backdrop"
  @click="back"
  />
</template>


<script>
import OibButtonIcon from '../components/OibButtonIcon.vue'

export default {
  name: "OibButtonBack",
  props: ['show_backdrop'],
  components: {
    OibButtonIcon
  },
  methods: {
    back() {
      this.$emit("back")
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.back-button {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}
</style>
