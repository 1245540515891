<template>
  <div class="log-book-row" v-if="log" @click="on_click">
    <div class="log-book-row-main">
      <img v-if="log_parsed.img_url" class="img-frame" :src="log_parsed.img_url" :style="log_parsed.img_url ? 'opacity: 1' : 'opacity: 0'" />
      <div v-if="!log_parsed.img_url && ![30, 31, 32, 33, 34, 35].includes(log.log_type)" class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: ${get_random_color()}`"/>
      <div v-if="!log_parsed.img_url && (log.log_type == 30 || log.log_type == 31)" class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: ${avi_color[log_parsed.avi_level]}; flex-direction: column`">
        <p style="font-size: 10px; color: white; font-weight: 600; margin: 0; height: 15px;">AVI</p>
        <p style="font-size: 12px; color: white; font-weight: 700; margin: 0; height: 15px;">{{log_parsed.avi_level}}</p>
      </div>
      <div v-if="!log_parsed.img_url && (log.log_type == 32 || log.log_type == 33)" class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: ${avi_color[log_parsed.avi_level]}; flex-direction: column`">
        <p style="font-size: 10px; color: white; font-weight: 600; margin: 0; height: 15px;">DMT</p>
        <p style="font-size: 12px; color: white; font-weight: 700; margin: 0; height: 15px;">{{log_parsed.avi_level}}</p>
      </div>
      <div v-if="!log_parsed.img_url && (log.log_type == 34 || log.log_type == 35)" class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: ${avi_color[log_parsed.avi_level]}; flex-direction: column`">
        <p style="font-size: 10px; color: white; font-weight: 600; margin: 0; height: 15px;">IEP</p>
        <p style="font-size: 12px; color: white; font-weight: 700; margin: 0; height: 15px;">{{log_parsed.avi_level}}</p>
      </div>
      <div class="log-book-row-details">
        <h3 class="h4">{{ log_parsed.title }}</h3>
        <p>{{ log_parsed.message }}</p>
      </div>
    </div>
    <div v-if="log.message.pages_read">
      <p style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 60px; font-size: 10px;">
        <span style="width: 20px;" class="material-icons" :style="log_parsed.icon_style">{{log_parsed.icon_sub}}</span>
        <span style="width: 35px; text-align: right">{{log.message.pages_read}}/{{log_parsed.pages_total}}</span>
      </p>
    </div>
    <div v-if="log_parsed.avi_level">
      <p style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; width: 60px; font-size: 10px;">
        <span style="width: 20px;" class="material-icons" :style="log_parsed.icon_style">{{log_parsed.icon_sub}}</span>
        <span style="width: 50px; text-align: right">{{avi_dict[log_parsed.score_name]}}</span>
      </p>
    </div>
    <oib-button-icon class="icon" :icon="log_parsed.icon ? log_parsed.icon : ''" style="font-size: 24px" :active="false"/>
  </div>
</template>

<script>
import OibButtonIcon from "./OibButtonIcon.vue";

import {get_book_cover} from "../store/api/library.js"
import {on_click_isbn13_router} from "../store/click"

export default {
  name: "OibListItemLogbook",
  props: ["user_id", "log"],
  components: {
    OibButtonIcon,
  },
  data() {
    return {
      log_parsed: {},
      log_type_parser: {
        0: this.parse_new_account,
        2: this.parse_qr_code_connected,
        11: this.parse_pages_read,
        12: this.parse_quit_book,
        13: this.parse_book_finished,
        14: this.parse_cancelled_book,
        20: this.parse_borrow_book,
        21: this.parse_return_book,
        30: this.parse_avi_card,
        31: this.parse_avi_card_decisive,
        32: this.parse_dmt_card,
        33: this.parse_dmt_card_decisive,
        34: this.parse_iep_card,
        35: this.parse_iep_card_decisive,
      },
      avi_color: {
        'M3': '#E5B14F',
        'E3': '#BD2D22',
        'M4': '#82251E',
        'E4': '#BC1184',
        'M5': '#5F0CA2',
        'E5': '#392E96',
        'M6': '#3673B4',
        'E6': '#58AEDC',
        'M7': '#357344',
        'E7': '#5C9923',
        'PLUS': '#B1D034'
      },
      avi_dict: {
        'B': 'Beheersing',
        'I': 'Instructie',
        'F': 'Frustratie'
      },
      iep_to_dle: {
        '3A': 'M3',
        '3B': 'E3',
        '4A': 'M4',
        '4B': 'E4',
        '5A': 'M5',
        '5B': 'E5',
        '6A': 'M6',
        '6B': 'E6',
      }
    }
  },
  mounted() {
    const parser = this.log_type_parser[this.log['log_type']]
    this.log_parsed = parser(this.log)
  },
  beforeUpdate() {
    const parser = this.log_type_parser[this.log['log_type']]
    this.log_parsed = parser(this.log)
  },
  methods: {
    parse_new_account(log) {
      return {
        'title': 'Account geboorte',
        'message': log['datetime_logged'],
        'icon': 'person_add',
      }
    },
    parse_qr_code_connected(log) {
      return {
        'title': 'Boekenlegger gekoppeld',
        'message': log['datetime_logged'],
        'icon': 'qr_code',
      }
    },
    parse_pages_read(log) {
      return {
        'title': 'Boek voortgang',
        'message': log['datetime_logged'],
        'img_url': get_book_cover(log['message']['isbn13'], "s"),
        'icon': 'book',
        'icon_sub': 'arrow_drop_up',
        'icon_style': 'transform: translateX(-4px);',
        'pages_read': log['message']['pages_read'],
        'pages_total': log['message']['pages_total']
      }
    },
    parse_quit_book(log) {
      return {
        'title': 'Gestopt met boek',
        'message': log['datetime_logged'],
        'img_url': get_book_cover(log['message']['isbn13'], "s"),
        'icon': 'playlist_remove',
        'icon_sub': 'clear',
        'icon_style': 'color: red; font-size: 16px;',
        'pages_read': log['message']['pages_read'],
        'pages_total': log['message']['pages_total']
      }
    },
    parse_cancelled_book(log) {
      return {
        'title': 'Toch iets anders lezen',
        'message': log['datetime_logged'],
        'img_url': get_book_cover(log['message']['isbn13'], "s"),
        'icon': 'playlist_remove',
        'icon_sub': 'swap_horiz',
        'icon_style': 'font-size: 16px;',
        'pages_read': log['message']['pages_read'],
        'pages_total': log['message']['pages_total']
      }
    },
    parse_book_finished(log) {
      return {
        'title': 'Boek uit',
        'message': log['datetime_logged'],
        'img_url': get_book_cover(log['message']['isbn13'], "s"),
        'icon': 'playlist_add_check',
        'icon_sub': 'check',
        'icon_style': 'color: limegreen; font-size: 16px;',
        'pages_read': log['message']['pages_read'],
        'pages_total': log['message']['pages_total']
      }
    },
    parse_borrow_book(log) {
      return {
        'title': 'Boek geleend',
        'message': `${log['datetime_logged']} • ${this.get_library_name(log)}`,
        'img_url': get_book_cover(log['message']['isbn13'], "s"),
        'icon': 'skip_next'
      }
    },
    parse_return_book(log) {
      return {
        'title': 'Boek ingeleverd',
        'message': `${log['datetime_logged']} • ${this.get_library_name(log)}`,
        'img_url': get_book_cover(log['message']['isbn13'], "s"),
        'icon': 'skip_previous'
      }
    },
    parse_avi_card(log) {
      return {
        'title': 'AVI-kaart gelezen',
        'message': `${log['datetime_logged']} • ${log['message']['avi_card']}`,
        'img_url': null,
        'icon': 'record_voice_over',
        'avi_level': log['message']['avi_card'].slice(9, -1),
        'score_name': log['message']['score_name']
      }
    },
    parse_avi_card_decisive(log) {
      return {
        'title': 'AVI-toets resultaat',
        'message': `${log['datetime_logged']} • ${log['message']['avi_card']}`,
        'img_url': null,
        'icon': 'record_voice_over',
        'avi_level': log['message']['avi_card'].slice(9, -1),
        'score_name': log['message']['score_name']
      }
    },
    parse_dmt_card(log) {
      return {
        'title': 'DMT-kaart gelezen',
        'message': `${log['datetime_logged']} • ${log['message']['avi_card']}`,
        'img_url': null,
        'icon': 'record_voice_over',
        'avi_level': log['message']['avi_card'].split('_')[2],
        'score_name': log['message']['score_name']
      }
    },
    parse_dmt_card_decisive(log) {
      return {
        'title': 'DMT-toets resultaat',
        'message': `${log['datetime_logged']} • ${log['message']['avi_card']}`,
        'img_url': null,
        'icon': 'record_voice_over',
        'avi_level': log['message']['avi_card'].split('_')[2],
        'score_name': log['message']['score_name']
      }
    },
    parse_iep_card(log) {
      return {
        'title': 'IEP-toets poging',
        'message': `${log['datetime_logged']} • ${log['message']['avi_card']}`,
        'img_url': null,
        'icon': 'record_voice_over',
        'avi_level': this.iep_to_dle[log['message']['avi_card'].split('_')[3]],
        'score_name': log['message']['score_name']
      }
    },
    parse_iep_card_decisive(log) {
      return {
        'title': 'IEP-toets resultaat',
        'message': `${log['datetime_logged']} • ${log['message']['avi_card']}`,
        'img_url': null,
        'icon': 'record_voice_over',
        'avi_level': this.iep_to_dle[log['message']['avi_card'].split('_')[3]],
        'score_name': log['message']['score_name']
      }
    },
    get_library_name(log) {
      const library_id = log['message']['library_id']
      const library_dict = this.$store.getters.get_library_dict
      return library_id in library_dict ? library_dict[library_id].library_name : 'Verwijderd'
    },
    async on_click() {
      if (!this.log.message.isbn13) {
        return
      }

      const isbn13 = this.log['message']['isbn13']
      const book = await this.$store.dispatch("load_book", isbn13)

      let library_id_list = []
      if ('library_id' in this.log['message']) {
        library_id_list = [this.log['message']['library_id']]
      } else {
        library_id_list = Object.keys(this.$store.getters.get_library_dict)
      }

      on_click_isbn13_router(
        this.user_id,
        book['book_edition_id'],
        isbn13,
        isbn13,
        library_id_list,
        false
      )
    },
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    get_random_color() {
      const color_1 = `rgb(${this.randomIntFromInterval(10, 150)}, ${this.randomIntFromInterval(180, 180)}, 205)`
      const color_2 = `rgb(${this.randomIntFromInterval(10, 150)}, ${this.randomIntFromInterval(180, 180)}, 205)`
      const color = `linear-gradient(${this.randomIntFromInterval(0, 45)}deg, ${color_1} 0%, ${color_2} 100%);`
      return color
    }
  } 
};
</script>

<style scoped>
.log-book-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
}

.log-book-row:hover {
  cursor: pointer;
}

.log-book-row-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.log-book-row-main .img-frame {
  width: 40px;
  height: 50px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 2px;
}

.log-book-row-details p {
  margin: 0;
  font-size: 10px;
  color: #888888;
}

.icon {
  color: var(--contrast-color-p4);
}
</style>
