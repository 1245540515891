<template>
  <button class="book-row fade" v-on:click="goToBook">
    <div class="book-row-content wrapper">
      <div class="book-row-content-main">
        <slot>
          <img class="avatar" :src="imgUrl" />
        </slot>
        <div class="book-row-details">
          <h3 class="h4">{{ title }}</h3>
          <p class="subh4">
            {{ subtext }}
          </p>
        </div>
      </div>
      <span class="material-icons next">{{icon}}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "OibListItem",
  props: {
    title: {
      type: String,
      required: true
    },
    subtext: {
      type: String
    },
    imgUrl: {

    },
    icon: {
      type: String,
      default: "chevron_right"
    }
  }
};
</script>

<style scoped>
.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  padding: 0px;
}

.book-row:hover {
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05); 
  box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05);
}

.book-row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.next {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #636465;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.subh4 span {
  font-size: 1.3em;
  padding-right: 4px;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

</style>