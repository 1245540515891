<template>
  <div>
    <slot></slot>
    <div ref="bottom"/>
  </div>
</template>

<script>
// TODO add delay
export default {
  name: "OibPaginator",
  data() {
    return {
      delay: 400,
      last_trigger_time: new Date()
    }
  },
  methods: {
    on_scroll() {
      // Have we reached the bottom?
      const now = new Date()
      const time_diff = now - this.last_trigger_time
      if (this.has_reached_bottom(this.$refs.bottom) && time_diff - this.delay > 0) {
        this.$emit('on_bottom_reached', this.page)
        this.last_trigger_time = now
      }
    },
    has_reached_bottom(el) {
      const rect = el.getBoundingClientRect();
      return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    },
  },
  mounted() {
    // Add events
    window.addEventListener('scroll', this.on_scroll);
    window.addEventListener('wheel', this.on_scroll);
  },
  unmounted() {
    // Add events
    window.removeEventListener('scroll', this.on_scroll);
    window.removeEventListener('wheel', this.on_scroll);
  },
}
</script>

<style>

</style>