<template>
  <div>
    <div class="oib-content" :class="$mq + ' ' + padding_top">
      <div style="z-index: 1; min-height: inherit; overflow-x: clip">
        <slot></slot>
      </div>
    </div>
    <teleport v-if="show_overlay" to="body"><div class="overlay"/></teleport>
  </div>
</template>

<script>
export default {
  name: "OibContent",
  props: {
    padding_top: {},
    show_overlay: {
      default: false
    }
  }
}
</script> 

<style scoped>
.oib-content {
  position: relative;
  color: inherit;
  overflow-y: auto;
  height: inherit;
  z-index: 1;
}

.oib-content.mobile {
  padding-top: 50px;
}

.oib-content.mobile.padding_top {
  padding-top: 70px;
  padding-bottom: 100px;
}

.oib-content.desktop {
  padding-left: 200px;
  padding-top: 50px;
  min-height: calc(100vh - 50px);
}

.oib-content.desktop.padding_top {
  padding-top: 105px;
  padding-bottom: 100px;
}

.oib-content.none {
  padding-top: 0px;
  padding-bottom: 100px;
}


.overlay {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(0,174,239,0.14329481792717091) 100%);
  z-index: 0;
}

</style>
