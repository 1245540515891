<template>
  <div>
    <h2>{{header}}</h2>
    <p class="description">{{description}}</p>
  </div>
</template>

<script>
export default {
  name: "OibFormHeader",
  props: ['header', 'description']
}
</script>

<style scoped>
.description {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 0.9em;
}
</style>