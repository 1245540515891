<template>
  <div>
    <oib-header title="Log in met boekenlegger" style="z-index: 100"/>
    <div class="fullscreen" ref="wrapper">
      <barcode-reader-advanced @decode="onDecode" format="qr_code"/>
      <div v-if="validationSuccess" class="validation-success">
        Ingelogt
      </div>

      <div v-if="validationFailure" class="validation-failure">
        Inloggen mislukt
      </div>

      <div v-if="validationPending" class="validation-pending">
        QR-code controleren ...
      </div>
    </div>
  </div>
</template>

<script>
import BarcodeReaderAdvanced from "../components/barcode_reader_advanced3.vue";
import OibHeader from "../components/OibHeader.vue"

import { go_to_route } from "../store/navigation";
import { post_accounts_internal_token_authenticate } from '../store/user.js'


export default {
  name: "LoginQrCode",

  components: { 
    OibHeader,
    BarcodeReaderAdvanced
  },

  data () {
    return {
      isValid: undefined,
      camera: 'auto',
      decoding: false
    }
  },
  computed: {
    validationPending () {
      return this.isValid === undefined
        && this.decoding === true
    },
    validationSuccess () {
      return this.isValid === true
    },

    validationFailure () {
      return this.isValid === false
    }
  },
  methods: {

    onInit (promise) {
      promise
        .catch(console.error)
        .then(this.resetValidationState)
    },

    resetValidationState () {
      this.isValid = undefined
    },
    async onDecode (result) {

      this.decoding = true

      // Connect id_token to user
      const response = await post_accounts_internal_token_authenticate(result.barcode)

      // Display server response context
      this.isValid = response.status == 200;

      // Send user back to previous screen
      if (this.isValid) {

        // Store logged in user in the store
        // TODO: create shared function with login view
        const json = await response.json();
        this.$store.commit("set_jwt", json['jwt'])
        this.$store.commit("set_user", json['user'])
        this.$store.commit("set_organisation_id", json['organisation_id'])
        this.$store.commit("set_organisation_id_active", json['organisation_id'])

        // Pass user to loading screen
        go_to_route("Laden")

      } else {
        await this.timeout(1250)
        go_to_route("Login")
      }
    },

    turnCameraOn () {
      this.camera = 'auto'
    },

    turnCameraOff () {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    }
  },
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: var(--highlight-color);
}
.validation-failure {
  color: red;
}

.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
</style>