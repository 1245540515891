<template>
  <oib-page>
    <oib-nav-student title="" :show_backdrop="true">
      <template v-slot:right v-if="can_move">
        <oib-button-icon
          :icon="action == 'lenen' ? 'low_priority' : 'login'"
          @click="toggle_action"
          style="font-size: 24px;"
          :has_backdrop="true"
        />
      </template>
    </oib-nav-student>

    <oib-book-background :isbn13="isbn13" v-if="assist_type != null"/>
    
    <oib-content :class="$mq" padding_top="padding_top" v-if="assist_type != null">
      <div class="wrapper column">
        <div class="column">
          <div style="height: 125px; min-height: 15vh; max-height: 20vh"></div>
          <div class="column" style="width: 100%; justify-content: space-between; max-width: 400px;">
            <p class="tiny-text">Boek advies</p>
            <h1 style="margin-bottom: 0; margin-top: 0">Wil je dit boek echt lezen?</h1>
            <p v-if="assist_type == 'DUPLICATE'" style="font-size: 1em; color: white;">Je hebt dit boek al gelezen.</p>
            <p v-if="assist_type == 'SERIES_ORDER'" style="font-size: 1em; color: white;">Je hebt het vorige boek in de serie nog niet gelezen.</p>
            <p v-if="assist_type == 'SERIES_FIRST_ONLY'" style="font-size: 1em; color: white;">Je hebt het eerste boek in de serie nog niet gelezen.</p>
            <div class="row">
              <oib-button priority="p1" style="padding: 10px 20px; margin-right: 20px; font-weight: 700;" :color="book_color" @click="handle_accept_advice">Niet uitlenen</oib-button>
              <oib-button priority="p3" style="padding: 10px 20px;" @click="handle_ignore_advice">Toch uitlenen</oib-button>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper" style="margin-top: 100px;">
        <h2 v-if="assist_type == 'DUPLICATE'">Meer zoals dit</h2>
        <h2 v-if="assist_type == 'SERIES_ORDER' || assist_type == 'SERIES_FIRST_ONLY'">Boeken in deze serie</h2>
      </div>
      <oib-book-grid :query="query" style="width: 100%;" read_count_var="release_number"/>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue"
import OibContent from "../components/OibContent.vue"
import OibNavStudent from "../components/OibNavStudent.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibButton from "../components/OibButton.vue"
import OibBookGrid from "../components/oib_book_grid.vue"
import OibBookBackground from "../components/oib_book_background.vue"

import {get_books} from "../store/api/library.js"
import {post_users_books_assist} from "../store/api/library_assistent.js"
import {pick_book} from "../store/library.js"
import {get_datetime_str} from "../store/utils.js"
import {get_book_cover} from "../store/api/library"
import { go_to_route } from "../store/navigation"
import { has_role } from "../store/utils";

function get_intersection_set (a, b) {
  return new Set([...a].filter(x => b.has(x)))
}

function gtag () {
  window.dataLayer.push(arguments)
}

export default {
  name: "BoekBiebKiezen",
  props: {
    user_id: {
      required: true
    },
    library_id: {
      required: true
    },
    isbn13: {
      required: true
    },
    book_edition_id: {
      required: true
    },
    route_count: {
      required: true
    }
  },
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButtonIcon,
    OibButton,
    OibBookGrid,
    OibBookBackground
  },
  computed: {
    book_product() {
      return this.$store.getters.getBook(this.isbn13);
    },
    book_color () {
      if (!this.book_product) {
        return 'var(--highlight-color)'
      }

      return this.book_product['color'] ? this.color_luminance(`#${this.book_product['color']}`, -0.05) : 'var(--highlight-color)';
    }
  },
  watch:{
    $route (){
      if (this.assist_type != null && this.assist_pending) {
        this.handle_advice(1)
      }        
    }
  },
  data() {
    return {
      cover_url: null,
      isbn13_list: [],
      isbn13_0: null,
      isbn13_1: null,
      max_section_length: 0,
      can_borrow: false,
      can_move: false,
      log_list: [],
      library_img_url: "",
      is_librarian: false,
      can_view_logs: false,
      library_id_old: null,
      isbn13_scanned_in_library: false,
      isbn13_scanned_available: false,
      series_id: null,
      series_title: null,
      release_number: null,
      release_number_max: null,
      release_number_target: null,
      read_order_type: null,
      copies_total_1: null,
      copies_in_use_1: null,
      copies_total_2: null,
      copies_in_use_2: null,
      assist_type: null,
      assist_pending: false,
      query: null
    }
  },
  async mounted() {

    // Ignore on refresh
    const user = this.$store.getters.getUser
    if(!user) {
      return
    }

    this.cover_url = get_book_cover(this.isbn13, 'l')

    // Get isbn13 given a book_edition_id
    const jwt = this.$store.getters.get_jwt
    let json = await get_books(jwt, 'products', {
      'select': 'isbn13,series_id,series_title,release_number,read_order_type',
      'book_edition_id': this.book_edition_id,
      'limit': 100,
      'page': 0
    })
    json['books'].forEach(x => {
      this.isbn13_list.push(x['isbn13'])
      this.series_id = x['series_id']
      this.series_title = x['series_title']
      this.release_number = x['release_number']
      this.read_order_type = x['read_order_type']
    })

    if (this.library_id != 0 && has_role(['STUDENT'], this.user_id)) {
      // Check if user has read book product in edition before
      const isbn13_set_read = this.$store.getters.get_progress_state_dict[this.user_id]

      // Handle borrow intention
      // Check if user has already read this book in the past
      if (get_intersection_set(isbn13_set_read[2], new Set(this.isbn13_list)).size > 0) {
        this.assist_type = 'DUPLICATE'
        this.assist_pending = true
        this.show_duplicate_warning()
        return
      }

      // Check if user has read the previous book in the series
      if (this.series_id != null && this.release_number != null && this.release_number > 1 && this.read_order_type != 'ANY') {
        const jwt = this.$store.getters.get_jwt
        let json = await get_books(jwt, 'products', {
          'select': 'isbn13,release_number',
          'series_id': this.series_id,
          'user_id': this.user_id,
          'progress_state': 2,
          'limit': 100,
          'page': 0
        })
        let isbn13_release_number_dict = {}
        this.release_number_max = 0
        json['books'].forEach(x => {
          isbn13_release_number_dict[x['isbn13']] = x['release_number']
          if (x['release_number'] > this.release_number_max) {
            this.release_number_max = x['release_number']
          }
        })

        let isbn13_read_set = get_intersection_set(isbn13_set_read[2], new Set(Object.keys(isbn13_release_number_dict)))
        if (this.read_order_type == 'FIRST_ONLY') {
          if (isbn13_read_set.size == 0) {
            this.release_number_target = 1
            this.assist_type = 'SERIES_FIRST_ONLY'
            this.assist_pending = true
            this.show_series_order_warning()
            return
          }
        } else if (this.read_order_type == 'ORDER') {
          if (this.release_number_max < this.release_number - 1) {
            this.release_number_target = this.release_number_max + 1
            this.assist_type = 'SERIES_ORDER'
            this.assist_pending = true
            this.show_series_order_warning()
            return
          }
        }
      }
    }

    this.borrow_book()
  },
  methods: {
    handle_advice(is_accepted) {
      post_users_books_assist(
        this.$store.getters.get_jwt,
        this.user_id,
        this.isbn13,
        this.$store.getters.getUser['user_id'],
        is_accepted,
        this.assist_type,
        get_datetime_str()
      )
      this.assist_pending = false
    },
    handle_accept_advice() {
      this.handle_advice(1)
      this.gtag_event('book_advice_' + this.assist_type + '_accept')
      this.$router.go(-this.route_count)
    },
    handle_ignore_advice() {
      this.handle_advice(0)
      this.gtag_event('book_advice_' + this.assist_type + '_ignore')
      this.borrow_book()
    },
    gtag_event(event_name) {
      gtag('event', event_name, {
        'app_name': 'Onderwijs in Beeld - app',
        'screen_name': 'BoekBiebKiezen'
      });
    },
    async borrow_book() {
      const result = await pick_book(this.user_id, this.isbn13)
      if (result == "No copy available") {
      } else if (this.$store.getters.getUser['user_id'] == this.user_id) {
        go_to_route("BibliotheekLezen")
      } else {
        // Overzicht leerling
        this.$router.go(-this.route_count)
      }
    },
    get_library_id_list() {
      let library_id_list = []
      const library_id_set = this.$store.getters.get_user_library_dict[this.user_id]
      if (library_id_set && library_id_set.size > 0) {
        library_id_set.forEach(library_id => library_id_list.push(library_id))
      }
      return library_id_list
    },
    show_duplicate_warning() {
      // Display more books like this
      this.query = {
        grouping: 'editions',
        query: {
          select: 'isbn13,book_edition_id,book_title,author_name,copies_in_use,copies_total,borrow_count_sum,age_min,age_max',
          isbn13_recommendation: this.isbn13,
          library_id: this.get_library_id_list().join(',')
        }
      }

      this.gtag_event('book_advice_' + this.assist_type)
    },
    show_series_order_warning() {
      // Display all books in series
      this.query = {
        grouping: 'editions',
        query: {
          'select': 'isbn13,book_edition_id,book_title,release_number,library_id,copies_total,copies_in_use,author_name',
          series_id: this.series_id,
          library_id: this.get_library_id_list().join(','),
          sort: 'release_number,ASC'
        }
      }

      this.gtag_event('book_advice_' + this.assist_type)
    },
    color_luminance(hex, lum) {

      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, '');
      if (hex.length < 6) {
        hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#", c, i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
      }

      return rgb;
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}

.library-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
  height: 30vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 40px;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34);
}

.tiny-text {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 0.8em;
}
</style>
