<template>
  <div>
    <oib-page>
      <oib-nav-student :allowLogout="false" :show_back="false" :show_backdrop="true"/>
      <oib-content padding_top='padding_top' style="padding-bottom: 100px; ">
        <section class="wrapper column">
          <h1 class="h1" style="margin-top: 0">Taken</h1>
          <!-- <p style="height: 0; font-size: 50px; line-height: 0; margin: 0; font-weight: 700; color: var(--contrast-color-p4); transform: translate(-50px, 0); z-index: 0;">01</p> -->
          <div class="row" style="justify-content: space-between; max-width: 757px; flex-wrap: wrap;">
            <div class="column task-item" style="width: 100%;">
              <h4 class="h4" style="z-index: 1">Accounts</h4>
              <oib-checklist-tasks
                :required="true"
                :options="task_list_accounts"
              />

              <!-- <p style="height: 0; font-size: 50px; line-height: 0; margin: 0; font-weight: 700; color: var(--contrast-color-p4); transform: translate(-50px, 0); z-index: 0;">02</p> -->
              <h4 class="h4" style="z-index: 1">De schoolbibliotheek op orde</h4>
              <oib-checklist-tasks
                :required="true"
                :options="task_list_library"
              />

              <!-- <p style="height: 0; font-size: 50px; line-height: 0; margin: 0; font-weight: 700; color: var(--contrast-color-p4); transform: translate(-50px, 0); z-index: 0;">03</p> -->
              <h4 class="h4" style="z-index: 1">Iedere leerling het juiste boek</h4>
              <oib-checklist-tasks
                :required="true"
                :options="task_list_reading"
              />
            </div>
            <div class="column task-item">
              <div
                v-if="task_list_maintenance.length > 0"
                class="column task-item"
                style="width: 100%; margin-bottom: 20px"
              >
                <h4 class="h4" style="z-index: 1">Onderhoud</h4>
                <div 
                v-for="task in task_list_maintenance"
                :key="task.title"
                class="row task-item"
                style="margin-top: 15px; background-color: var(--primary-color-sub); align-items: center; justify-content: space-between; border-radius: 4px; width: 100%;"
                >
                  <div class="row" style="width: 100%; height: 100%; ">
                    <div class="column" style="background-color: var(--highlight-color); padding: 0 10px; width: 24px; height: 100%; border-radius: 4px; margin-bottom: 0; justify-content: center;">
                      <p class="material-icons" style="color: white; font-size: 24px; margin: 0">{{task.icon}}</p>
                    </div>
                    <div class="column" style="padding: 10px 20px">
                      <p style="color: white; margin-bottom: 2px;">{{task.title}}</p>
                      <p style="margin-bottom: 4px">{{task.description}}</p>
                    </div>
                  </div>
                  <oib-button
                    style="height: 30px; width: 60px; margin-right: 20px;"
                    priority="p1"
                    @click="task.onClick"
                    >Go ➜
                  </oib-button>
                </div>
              </div>
              <div
                v-if="task_list_improvements.length > 0"
                class="column task-item"
                style="width: 100%; margin-bottom: 20px"
              >
                <h4 class="h4" style="z-index: 1">Verbeterpunten</h4>

                <div 
                v-for="task in task_list_improvements"
                :key="task.title"
                class="row task-item"
                style="margin-top: 15px; background-color: var(--primary-color-sub); align-items: center; justify-content: space-between; border-radius: 4px; width: 100%;"
                >
                  <div class="row" style="width: 100%; height: 100%; ">
                    <div class="column" style="background-color: var(--highlight-color); padding: 0 10px; width: 24px; height: 100%; border-radius: 4px; margin-bottom: 0; justify-content: center;">
                      <p class="material-icons" style="color: white; font-size: 24px; margin: 0">{{task.icon}}</p>
                    </div>
                    <div class="column" style="padding: 10px 20px">
                      <p style="color: white; margin-bottom: 2px;">{{task.title}}</p>
                      <p style="margin-bottom: 4px">{{task.description}}</p>
                      <div style="width: 150px; height: 20px; border: 1px solid var(--highlight-color); border-radius: 4px;">
                        <div style="background-color: var(--highlight-color); height: 100%; border-radius: 4px;" :style="`width: ${task.current_perc / task.target_perc * 100}%`"/>
                        <p style="font-size: 0.8em; text-align: center; color: white; font-weight: 700; transform: translate(0, -20px);">{{ task.current_perc != null ? task.current_perc : '???' }} / {{task.target_perc}}</p>
                      </div>
                    </div>
                  </div>
                  <oib-button
                    style="height: 30px; width: 60px; margin-right: 20px;"
                    priority="p1"
                    @click="scroll_to_id(task.icon)"
                    >Go ➜
                  </oib-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="column" style="margin-bottom: 40px; margin-top: 30px;">
          <div class="wrapper column" id="shopping_cart">
            <div class="row section-header">
              <h2>Verbeterplan</h2>
              <p>Punten voltooid: {{ score_status }}</p>
            </div>
            <div style="height: 1px; width: 100%; max-width: 757px; background-color: var(--contrast-color-p4);"/>
          </div>          
          <oib-swiper style="padding-left: 5%; padding-top: 50px;">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="shopping_cart"
              icon_family="symbol"
              :header="`${book_waste_count == null ? '???' : book_waste_count} Nieuwe boeken nog nooit gelezen`"
              style="width: 270px; margin-right: 50px;"
              >
              <p>Aan de rechterzijde zie je voor hoeveel geld aan nieuwe boeken (uitgegeven na 2018) nog nooit gelezen is. Het betreft enkel de lees-, informatie en stripboeken.</p>
              <p>Veel leerlingen komen in een fuik terecht omdat ze elkaars leesgedrag kopiëren. Sommige boeken zijn altijd uitgeleend terwijl andere nooit gelezen worden.</p>
              <p>Geef de nieuwe boeken in de bieb die nog nooit gelezen zijn eens een betere plek en promoot de onderbelichte boeken in de klas!</p>
              <oib-button
                style="height: 40px; margin-bottom: 10px;"
                priority="p1"
                @click="go_to_search({'sort': 'Minst gelezen', 'min_release_date': 2018, 'max_release_date': 2024})"
                >Nieuwe boeken zichtbaar maken
              </oib-button>

              </oib-section-info>
              <div>
                <div style="width: 435px; height: 440px;">
                  <oib-chart
                    v-if="chart.data"
                    id="o39q45n7v9"
                    :data="chart.data"
                    :options="chart.options"
                    :text="book_waste_perc == null ? 'Onvoldoende leesgedrag data': undefined"
                  />
                </div>
                <h5 style="width: 100%; text-align: right; margin-bottom: 0">Aantal niet gelezen vs wel gelezen nieuwe boeken</h5>
              </div>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
          <oib-swiper style="padding-left: 5%; padding-top: 50px;">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="shopping_cart"
              icon_family="symbol"
              :header="`10% van de boeken wordt ${borrow_count_10 == null ? '???' : borrow_count_10}% van de tijd geleend`"
              style="width: 270px; margin-right: 50px;"
              >
              <p>Het gaat er niet om hoeveel boeken er in de bieb staan, maar hoeveel er daadwerkelijk gelezen worden.</p>
              <p>Veel leerlingen komen in een fuik terecht omdat ze elkaars leesgedrag kopiëren. Sommige boeken zijn altijd uitgeleend terwijl andere nooit gelezen worden.</p>
              <p>Geef de nieuwe boeken in de bieb die nog nooit gelezen zijn eens een betere plek en kijk nog eens goed of die oude boeken toch niet gesaneerd moeten worden.</p>
              <oib-button
                style="height: 40px; margin-bottom: 10px;"
                priority="p1"
                @click="go_to_search({'sort': 'Minst gelezen', 'max_release_date': 2005})"
                >Onaantrekkelijke boeken saneren
              </oib-button>
              </oib-section-info>
              <div>
                <div style="width: 435px; height: 440px;">
                  <oib-chart
                    v-if="chart_borrow_count.data"
                    id="923874"
                    :data="chart_borrow_count.data"
                    :options="chart_borrow_count.options"
                    :text="borrow_count_10 == null ? 'Onvoldoende leesgedrag data': undefined"
                  />
                </div>
                <h5 style="width: 100%; text-align: right; margin-bottom: 0">% Uitleningen per 10% van de boeken</h5>
              </div>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
          <oib-swiper style="padding-left: 5%; padding-top: 70px;" id="tips_and_updates">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="tips_and_updates"
              icon_family="symbol"
              :header="(book_advice_accept_perc != null ? book_advice_accept_perc : '???') + '% van het boekadvies geaccepteerd'"
              style="width: 270px; margin-right: 50px;"
              >
              <p>Het kiezen van een passend boek is een hele uitdaging en leerlingen hebben soms een extra zetje nodig</p>
              <p>We zien bijvoorbeeld dagelijks dat leerlingen een boek kiezen binnen een serie terwijl het voorgaande deel overslagen wordt of dat leerlingen hetzelfde boek voor de derde keer dit jaar lezen terwijl er nog zoveel andere leuke boeken zijn!</p>
              <p>Soms weergeeft de Leesapp daarom tijdens het uitlenen een melding. Dit is geen straf, maar een indicatie dat de leerling hulp nodig heeft bij het kiezen van een boek.</p>
              <p>In de grafiek aan de rechtzijde zie je hoe vaak dit boekadvies wordt gehonoreerd.</p>
              </oib-section-info>
              <div>
                <div style="width: 435px; height: 440px;">
                  <oib-chart
                    v-if="charts[0].data"
                    id="923475928374"
                    :data="charts[0].data"
                    :options="charts[0].options"
                  />
                </div>
                <h5 style="width: 100%; text-align: right;">Aantal boekadvies geaccepteerd/genegeerd</h5>
              </div>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
        </section>
      </oib-content>
    </oib-page>
  </div>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibChecklistTasks from "../components/oib_checklist_tasks.vue"
import OibSwiper from "../components/oib_swiper.vue"
import OibChart from "../components/OibChart.vue";
import OibSectionInfo from "../components/oib_section_info.vue"
import OibButton from "../components/OibButton.vue"

import { parse_query } from '../store/request'
import { get_books } from '../store/api/library'
import { get_datetime_str } from '../store/utils'

export default {
  name: "Taken",
  props: ['isbn13'],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibSwiper,
    OibChart,
    OibSectionInfo,
    OibChecklistTasks,
    OibButton
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  data() {
    return {
      book_waste_count: null,
      load_count: 0,
      chart: {
        type: 'bar'
      },
      chart_borrow_count: {
        type: 'bar'
      },
      charts: [
        {
          title: "AVI niveau",
          subtitle: "",
          data: {
            datasets: [
              {
                label: 'DLE',
                backgroundColor: "rgba(0, 174, 239, 0.1)",
                borderColor: "rgba(0, 255, 239, 1)",
                pointBackgroundColor: "rgba(0, 255, 239, 1)",
                lineTension: 0,
                data: [{
                  x: new Date(2018, 10, 29),
                  y: 0,
                },
                {
                  x: new Date(2019, 1, 20),
                  y: 0,
                },
                {
                  x: new Date(2019, 6, 20),
                  y: 0,
                },
                {
                  x: new Date(2020, 1, 31),
                  y: 0,
                },
                {
                  x: new Date(2020, 6, 19),
                  y: 0,
                },
                {
                  x: new Date(2021, 3, 10),
                  y: 0,
                },
                {
                  x: new Date(2021, 6, 18),
                  y: 0,
                }],
                // this dataset is drawn below
                type: 'line',
                order: 1
              }, {
                label: 'DL',
                backgroundColor: "rgba(0, 0, 0, 0.0)",
                borderColor: "rgba(0, 174, 239, 1)",
                pointBackgroundColor: "rgba(0, 174, 239, 1)",
                lineTension: 0,
                borderDash: [10,10],
                data: [{
                  x: new Date(2018, 10, 29),
                  y: 0,
                },
                {
                  x: new Date(2019, 1, 20),
                  y: 0,
                },
                {
                  x: new Date(2019, 6, 20),
                  y: 0,
                },
                {
                  x: new Date(2020, 1, 31),
                  y: 0,
                },
                {
                  x: new Date(2020, 6, 19),
                  y: 0,
                },
                {
                  x: new Date(2021, 3, 10),
                  y: 0,
                },
                {
                  x: new Date(2021, 6, 18),
                  y: 0,
                }],
                // this dataset is drawn below
                type: 'line',
                order: 1
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            layout: {
              padding: {
                // Any unspecified dimensions are assumed to be 0
                right: 5,
              },
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  display: false,
                  time: {
                    unit: "month",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: -20,
                    max: 20,
                    maxTicksLimit: 10,
                  },
                  gridLines: {
                    zeroLineColor: "transparent",
                    drawOnChartArea: true,
                    drawTicks: true,
                    display: true,
                    drawBorder: true,
                  },
                },
              ],
            },
          },
        },
        {
          title: "Leestijd",
          subtitle: "",
          data: [
            {
              x: new Date(2018, 10, 29),
              y: 10,
            },
            {
              x: new Date(2019, 1, 20),
              y: 15,
            },
            {
              x: new Date(2019, 6, 20),
              y: 20,
            },
            {
              x: new Date(2020, 1, 31),
              y: 35,
            },
            {
              x: new Date(2020, 6, 19),
              y: 45,
            },
            {
              x: new Date(2021, 3, 10),
              y: 50,
            },
            {
              x: new Date(2021, 6, 18),
              y: 50,
            },
          ],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
            },
            layout: {
              padding: {
                // Any unspecified dimensions are assumed to be 0
                right: 5,
              },
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  display: true,
                  time: {
                    unit: "month",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    min: 0,
                    max: 55,
                    maxTicksLimit: 1,
                  },
                  gridLines: {
                    zeroLineColor: "transparent",
                    drawTicks: true,
                    display: true,
                    drawBorder: true,
                  },
                },
              ],
            },
          },
        },
      ],
    }
  },
  watch: {
    load_count() {
      if (this.load_count == 3) {
        this.load_borrow_count_metrics()
      }
    }
  },
  computed: {
    book_advice_accept_perc() {
      const total_count = this.$store.getters.get_task_book_advice_total_count
      return total_count > 20 ? this.$store.getters.get_task_book_advice_accept_perc : null
    },
    task_list_accounts() {
      const task_dict = this.$store.getters.get_task_dict
      return [
        {task: task_dict['LINK_LVS'], title: 'Koppel het leerlingadministratiesysteem', 'page': 'GebruikersLeerkrachten'},
        {task: task_dict['ADD_TEACHER_ACCOUNTS'], title: 'Voeg 5 accounts toe aan leerkrachten', 'page': 'GebruikersLeerkrachten'},
      ]
    },
    task_list_library() {
      const task_dict = this.$store.getters.get_task_dict
      console.log(task_dict)
      return [
        {task: task_dict['ADD_LIBRARY'], title: 'Voeg 3 bibliotheken toe', 'page': 'BibliotheekCollectie'},
        {task: task_dict['ADD_LIBRARY_BOOKS'], title: 'Voeg 100 boeken toe aan de bibliotheek', 'page': 'BibliotheekCollectie'}
      ]
    },
    task_list_reading() {
      const task_dict = this.$store.getters.get_task_dict
      return [
        {task: task_dict['LOAD_STUDENTS'], title: 'Laad de leerlingen in', 'page': 'GebruikersLeerlingen'},
        {task: task_dict['LINK_STUDENT_QR_CODES'], title: 'Koppel 20 boekenleggers aan leerlingen', 'page': 'GebruikersLeerlingen'},
        {task: task_dict['LOAD_READING_TESTS'], title: 'Laad de laatste leestoetsen in (AVI/DMT)', 'page': 'GebruikersLeerlingen'}
      ]
    },
    task_list_maintenance() {
      let task_list = []
      const task_dict = this.$store.getters.get_task_dict
      if (task_dict["RETURN_BOOKS"] !== undefined && task_dict['RETURN_BOOKS'].finish_datetime == null) {
        task_list.push({
          'title': 'Vergeten boeken',
          'description': 'Sommige boeken worden langer dan 2 maanden geleend.',
          'icon': 'warning',
          'onClick': this.go_to_longest_borrowed
        })
      }
      console.log(task_list)
      return task_list
    },
    task_list_improvements() {
     
      let task_list = []

      // READ_NEW_BOOKS
      let lvl_list = [50, 75, 90]
      const task_0 = this.get_task_lvl('READ_NEW_BOOKS', lvl_list)
      if (task_0 && this.book_waste_perc != null) {
        task_0['target_perc'] = lvl_list[task_0.lvl_index]
        task_0['current_perc'] = 100 - this.book_waste_perc
        task_0['title'] = "Onderbelichte boek"
        task_0['description'] = `Lees ${task_0['target_perc']}% van de nieuwe boeken`
        task_0['icon'] = 'shopping_cart'
        task_list.push(task_0)
      }

      // BOOK_ADVICE_ACCEPTANCE
      lvl_list = [25, 50, 60]
      const task_1 = this.get_task_lvl('BOOK_ADVICE_ACCEPTANCE', lvl_list)
      if (task_1 && this.book_advice_accept_perc != null) {
        task_1['target_perc'] = lvl_list[task_1.lvl_index]
        task_1['current_perc'] = this.book_advice_accept_perc
        task_1['title'] = "Boektip advies"
        task_1['description'] = `Accepteer ${task_1['target_perc']}% van de boektips`
        task_1['icon'] = 'tips_and_updates'
        task_list.push(task_1)
      }
      return task_list
    },
    score_status() {
      const task_dict = this.$store.getters.get_task_dict
      const improvements = [
        'READ_NEW_BOOKS_50',
        'READ_NEW_BOOKS_75',
        'READ_NEW_BOOKS_90',
        'BOOK_ADVICE_ACCEPTANCE_25',
        'BOOK_ADVICE_ACCEPTANCE_50',
        'BOOK_ADVICE_ACCEPTANCE_60'
      ]

      let score_count = 0
      improvements.forEach(task_title => {
        if (task_title in task_dict && task_dict[task_title].finish_datetime != null) {
          score_count += 1
        }
      })

      return `${score_count}/${improvements.length}`
    },
    book_waste_perc() {
      const book_waste_data = this.$store.getters.get_task_book_waste_data
      if (!book_waste_data) {
        return null
      }

      const not_read_count = book_waste_data[0]
      const read_count = book_waste_data[1]
      if (read_count == 0) {
        return null
      }

      const total_count = not_read_count + read_count
      return parseInt(not_read_count / total_count * 100)
    },
    borrow_count_10() {
      const data = this.$store.getters.get_task_borrow_count_metric_list
      if (data != null && data.length > 0 && data[0] > 0) {
        return data[0]
      }
      return null
    }
  },
  mounted() {
    this.load_count = 0
    const jwt = this.$store.getters.get_jwt
    const organisation_id = this.$store.getters.get_organisation_id_active
    this.$store.dispatch("load_task_book_advice_acceptance", {
      'jwt': jwt,
      'organisation_id': organisation_id        
    }).then(() => this.load_count += 1)

    this.$store.dispatch("load_task_read_new_books", {
      'jwt': jwt,
      'organisation_id': organisation_id
    }).then(() => this.load_count += 1)

    this.$store.dispatch("load_task_borrow_count", {
      'jwt': jwt,
      'organisation_id': organisation_id
    }).then(() => this.load_count += 1)
    this.load_not_returned_books()
  },
  methods: {
    go_to_search(query) {
      // Parse query
      let query_parsed = parse_query(query)
      if (query_parsed == '') {
        query_parsed = null
      }

      this.$router.push({
        name: "BibliotheekZoekenFilter",
        params: {
          query: query_parsed
        }
      });
    },
    go_to_longest_borrowed() {
      this.go_to_search({'sort': 'Langst geleend'})
    },
    get_task_lvl(task_title, lvl_list) {
      const task_dict = this.$store.getters.get_task_dict

      const days_ago_30 = new Date();
      days_ago_30.setDate(days_ago_30.getDate() - 0);

      let lvl_1 = task_title + '_' + lvl_list[0]
      let lvl_2 = task_title + '_' + lvl_list[1]
      let lvl_3 = task_title + '_' + lvl_list[2]
      let finish_datetime_lvl_1 = this.get_task_finish_datetime(lvl_1)
      let finish_datetime_lvl_2 = this.get_task_finish_datetime(lvl_2)
      let finish_datetime_lvl_3 = this.get_task_finish_datetime(lvl_3)

      if (!finish_datetime_lvl_1) {
        return {'task': task_dict[lvl_1], 'lvl_index': 0}
      } else if (!finish_datetime_lvl_2 && finish_datetime_lvl_1) { // && finish_datetime_lvl_1 <= days_ago_30
        return {'task': task_dict[lvl_2], 'lvl_index': 1}
      } else if (!finish_datetime_lvl_3 && finish_datetime_lvl_2) { // && finish_datetime_lvl_2 <= days_ago_30
        return {'task': task_dict[lvl_3], 'lvl_index': 2}
      }
      return null
    },
    get_task_finish_datetime(task_title) {
      const task_dict = this.$store.getters.get_task_dict
      return task_title in task_dict ? task_dict[task_title].finish_datetime : null
    },
    scroll_to_id(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth'
      });
    },
    async load_not_returned_books() {
      // Parse
      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      const selector_active = selector_list[selector_active_index]
      console.log(selector_active)

      const jwt = this.$store.getters.get_jwt
      const json = await get_books(jwt, 'copies', {
        'library_id': selector_active.query['library_id'],
        'in_use': 1,
        'select': 'library_id,copy_id,isbn13,borrowed_datetime',
        'sort': 'borrowed_datetime,ASC',        
        'limit': 1
      })
      const book_list = json['books']
      let finish_datetime = get_datetime_str()
      if (book_list.length > 0) {
        const book = book_list[0]
        const timeDelta = new Date() - new Date(book['borrowed_datetime'])
        const daysPassed = timeDelta / (1000 * 60 * 60 * 24)
        if (daysPassed > 60) {
          finish_datetime = null
        }
      }

      this.$store.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': this.$store.getters.get_organisation_id_active,
          'task_title': 'RETURN_BOOKS',
          'finish_datetime': finish_datetime
        }
      })
    },
    load_borrow_count_metrics() {

      // Book advice metric chart
      const metric_list_ignore = this.$store.getters.get_task_book_advice_metric_list_ignore

      let metric_list_ignore_negative = []
      metric_list_ignore.forEach(metric => metric_list_ignore_negative.push({
        x: metric.x,
        y: -metric.y
      }))


      this.charts[0].data = {
        datasets: [
          {
            label: 'Aantal geaccepteerd',
            backgroundColor: "rgba(0, 174, 239, 0.1)",
            borderColor: "rgba(0, 174, 239, 1)",
            pointBackgroundColor: "rgba(0, 174, 239, 1)",
            lineTension: 0,
            data: this.$store.getters.get_task_book_advice_metric_list_accept
          },
          {
            label: 'Aantal genegeerd',
            backgroundColor: "rgba(239, 65, 0, 0.1)",
            borderColor: "rgba(239, 65, 0, 1)",
            pointBackgroundColor: "rgba(239, 65, 0, 1)",
            lineTension: 0,
            data: metric_list_ignore_negative
          }
        ]
      }

      // Book waste chart
      let max_y = 100
      const chart_data = this.$store.getters.get_task_book_waste_data
      if (chart_data != null) {
        this.book_waste_count = chart_data[1] > 0 ? chart_data[0] : '???'
        max_y = Math.max(...chart_data)
      } 

      this.chart.options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        elements: {
          line: {
            stepped: true
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                max: max_y,
              },
              gridLines: {
                zeroLineColor: "transparent",
                drawTicks: false,
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      }
      this.chart.data = {
        labels: ['Niet gelezen', 'Wel gelezen'],
        datasets: [{
          label: "Aantal boeken",
          type: 'bar',
          backgroundColor: ["#ef4100", "#00aeef"],
          borderColor: "rgba(0, 174, 239, 1)",
          pointBackgroundColor: "rgba(0, 174, 239, 1)",
          data: chart_data,
          lineTension: 0
        }]
      }

      // Borrow count chart
      let color_list = []
      const chart_data2 = this.$store.getters.get_task_borrow_count_metric_list
      if (chart_data2 != null) {
        
        chart_data2.forEach(data_point => {
          color_list.push(`rgba(0, 174, 239, ${data_point / 100 + 0.4})`)
        })
      }

      const labels = [
        '0%-10%',
        '10%-20%',
        '20%-30%',
        '30%-40%',
        '40%-50%',
        '50%-60%',
        '60%-70%',
        '70%-80%',
        '80%-90%',
        '90%-100%',
      ]

      this.chart_borrow_count.options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        elements: {
          line: {
            stepped: true
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                min: 0,
                max: 100,
              },
              gridLines: {
                zeroLineColor: "transparent",
                drawTicks: false,
                display: false,
                drawBorder: false,
              },
            },
          ],
        },
      }
      this.chart_borrow_count.data = {
        labels: labels,
        datasets: [{
          label: "% van alle uitleningen",
          type: 'bar',
          backgroundColor: color_list,
          borderColor: "rgba(0, 174, 239, 1)",
          pointBackgroundColor: "rgba(0, 174, 239, 1)",
          data: chart_data2,
          lineTension: 0
        }]
      }
    },
  }
};
</script>

<style scoped>

.section-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 757px;
}

.section-header h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.section-header p {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 0.7em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section-header p:hover {
  cursor: pointer;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

@media screen and (min-width:320px) {
  .task-item {
    max-width: 400px;
  }
}

@media screen and (min-width:640px) {
  .task-item {
    max-width: 350px;
  }
}
</style>