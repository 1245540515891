<template>
  <div class="tile fade" :style="`background-image: url(${img_url}); background-size: cover; background-position: bottom;`">
    <div class="tile-overlay">
      <div class="row" style="justify-content: space-between; align-items: center;">
        <div>
          <oib-icon-label
          title_style="color: white; font-family: 'PT Sans', sans-serif; font-weight: 700; font-size: 19px;"
          :icon="icon"
          :icon_family="icon_family"
          :title="header"
          direction="row"
          :active="false"
          />
          <p style="width: 100%; color: white;">{{body}}</p>
        </div>
        <span class="material-icons" style="font-size: 24px">arrow_forward_ios</span>
      </div>
    </div>
  </div>
</template>

<script>
import OibIconLabel from '../components/oib_icon_label.vue'

export default {
  name: "OibTileImg",
  props: ['img_url', 'icon', 'icon_family', 'header', 'body'],
  components: {
    OibIconLabel
  }
};
</script>

<style scoped>
.reset-margin {
  margin-left: 50px;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    display: block;
    overflow-x: auto;
    margin-right: 40px;
}

.styled-table thead tr {
    background-color: var(--highlight-color);
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.style-table td {
  min-width: 100px;
}

.styled-table th {
  min-width: 160px;
  text-align: left;
}

.styled-table tbody tr {
    border-bottom: 1px solid #21222f;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #21222f;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--highlight-color);
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: var(--highlight-color);
}

.styled-table tr td:nth-child(2) { width:100% }

.row {
  display: flex;
  flex-direction: row;
}

.tile {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 280px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34);
}

.tile:hover {
  background-color: rgba(255, 255, 255, 0.03);
  /*-webkit-box-shadow: 5px 5px 20px 5px rgba(0, 174, 239, 0.24); 
  box-shadow: 5px 5px 20px 5px rgba(0, 174, 239,0.24);*/
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.70); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.70);
  cursor: pointer;
}

@media screen and (min-width:320px) {
  .tile {
    width: 100%;
  }
}

@media screen and (min-width:700px) {
  .tile {
    width: 300px;
    margin-right: 40px;
  }
}

.tile-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 30%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%);
}


</style>