<template>
  <div>
     <oib-nav-student :show_backdrop="true">
     <template v-slot:right v-if="has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SYSTEM_ADMIN'])">
        <oib-button-icon
        style="font-size: 24px;"
        icon="edit"
        :has_backdrop="true"
        @click="go_to_route('MenuBoek', {'isbn13': isbn13})" />
      </template>
    </oib-nav-student>
    <oib-page-book :isbn13="isbn13">
      
      <template v-slot:right>
        <div class="column">
          <book-progress :user_id="user_id" :isbn13="isbn13"/>
        </div>
      </template>
      <template v-slot:bottom>
        <oib-section-logbook-book :user_id="user_id" :isbn13="isbn13" style="z-index: 1; background-color: var(--primary-color)"/>
      </template>
    </oib-page-book>
  </div>
</template>

<script>
import OibNavStudent from "../components/OibNavStudent.vue";
import OibPageBook from '../components/oib_page_book.vue'
import BookProgress from '../components/BookProgress.vue';
import OibSectionLogbookBook from '../components/oib_section_logbook_book.vue'
import OibButtonIcon from '../components/OibButtonIcon.vue'

import {has_role} from '../store/utils'
import { go_to_route } from "../store/navigation";

export default {
  name: "BoekVoortgang",
  props: ["user_id", "isbn13"],
  components: {
    OibNavStudent,
    OibPageBook,
    BookProgress,
    OibSectionLogbookBook,
    OibButtonIcon,
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    // import
    has_role,
    go_to_route
  }
};
</script>
