<template>
  <oib-barcode-reader-book :user_id="user_id" @decode="body => on_decode(body)" />
</template>

<script>
import OibBarcodeReaderBook from "../components/oib_barcode_reader_book.vue"
import {on_click_isbn13_router} from "../store/click"

export default {
  name: "OibBoekScan",
  props: ['user_id', 'fast_borrow'],
  components: {
    OibBarcodeReaderBook
  },
  methods: {
    async on_decode(body) {
      const isbn13 = body.isbn13
      let isbn13_scanned = null
      if (!isNaN(body.search_str)) {
        isbn13_scanned = body.isbn13
      }

      const library_id_list = Object.keys(this.$store.getters.get_library_dict)

      on_click_isbn13_router(
        this.user_id,
        body['book_edition_id'],
        isbn13,
        isbn13_scanned,
        library_id_list,
        this.fast_borrow === 'true'
      )
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
}
</script>
