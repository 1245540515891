<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Vormgeving aanpassen" @submit="submit">
    <div>
      <oib-input-text
      label="Naam"
      placeholder="schoolbieb"
      :required="true"
      :error_msg="library_name_error"
       :init_value="library_name"
      @input="set_library_name($event.target.value)"
      />
    </div>
    <div>
      <oib-input-image
      label="Foto"
      description="Een foto of afbeelding om in één oogopslag de bieb te identificeren."
      :required="true"
      :init_value="image_url_init"
      @input="x => set_image(x)"
      />
    </div>
    <div>
      <oib-input-multi-select
      label="Locatie type"
      description="Waar staat deze bibliotheek?"
      :allow_multi_select="false"
      :required="true"
      :tile_width="140"
      :options="location_category_selectors"
      :keys_init="get_location_category_index()"
      :sort_alphabetically="false"
      @select="x => set_location_category(location_category_selectors[parseInt(x[0])])"
      />
      <oib-input-multi-select
      v-if="location_category == 'Schoolgebouw'"
      label="Schoolgebouw"
      description="Selecteer de locatie van deze bibliotheek. Er is slechts een antwoord mogelijk."
      :allow_multi_select="false"
      :required="true"
      :tile_width="140"
      :options="building_dict"
      :keys_init="get_building_id_init()"
      @select="x => set_location(x)"
      />
      <oib-input-multi-select
      v-if="location_category == 'Klaslokaal'"
      label="Klaslokaal"
      description="Selecteer de locatie van deze bibliotheek. Er is slechts een antwoord mogelijk."
      :allow_multi_select="false"
      :required="true"
      :tile_width="140"
      :options="classroom_dict"
      :keys_init="get_classroom_id_init()"
      @select="x => set_location(x)"
      />
      <oib-input-text
      v-if="location_category == 'Anders'"
      label="Naam"
      placeholder="Locatie naam"
      :required="true"
      :init_value="location_name_other_init"
      @input="set_location_name_other($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibInputImage from "../components/oib_form/oib_input_image.vue"
import OibInputMultiSelect from "../components/oib_form/oib_input_multi_select.vue"

import {get_bucket} from "../store/bucket.js"

export default {
  name: "OibFormLibrary",
  props: {
    library_id: {
      default: null
    },
    library_name_init: {
      type: String,
      default: ""
    },
    location_name_init: {
      default: ""
    },
  },
  components: {
    OibForm,
    OibInputText,
    OibInputImage,
    OibInputMultiSelect
  },
  data() {
    return {
      form_step_validation_list: [],
      library_name: "",
      library_name_error: null,
      organisation_id: null,
      location_category: "",
      location_category_selectors: ['Schoolgebouw', 'Klaslokaal', 'Anders'],
      building_id: null,
      classroom_id: null,
      building_list: [],
      building_dict: {},
      classroom_list: [],
      classroom_dict: {},
      image_url_init: null,
      image_file: null,
      library_name_dict: {},
      location_name_other: "",
      location_name_other_init: "",
    }
  },
  created() {

    this.organisation_id = this.$store.getters.get_organisation_id_active

    this.$store.getters.get_library_list.forEach(x => {
      this.library_name_dict[x['library_name'].toLowerCase()] = x['library_id']
    })

    this.building_list = this.$store.getters.get_building_list
    this.$store.getters.get_building_list.forEach(x => {
      this.building_dict[x['building_id']] = x['building_name']
    })

    this.classroom_list = this.$store.getters.get_classroom_list
    this.classroom_list.forEach(x => {
      this.classroom_dict[x['classroom_id']] = x['classroom_name']
    })

    // Init validation list
    this.form_step_validation_list = [false, false, false]

    // Init form
    if (this.library_id != null)  {
      this.library_name = this.library_name_init
      this.image_url_init = `${get_bucket('media')}/libraries/l/${this.library_id}.webp`

      // Location
      const building_id = this.get_building_id_by_name(this.location_name_init)
      const classroom_id = this.get_classroom_id_by_name(this.location_name_init)
      if (building_id) {
        this.set_location_category('Schoolgebouw')
        this.set_location(building_id)
      } else if (classroom_id) {
        this.set_location_category('Klaslokaal')
        this.set_location(classroom_id)
      } else {
        this.set_location_category('Anders')
        this.location_name_other_init = this.location_name_init
        this.set_location_name_other(this.location_name_init)
      }
    }

    this.val_step_1()
    this.val_step_2()
    this.val_step_3()
  },
  methods: {
    get_building_id_by_name(building_name) {
      const building_list = this.$store.getters.get_building_list
      for (let i = 0; i < building_list.length; i++) {
        if (building_list[i]['building_name'] == building_name) {
          return building_list[i]['building_id']
        }
      }
      return null
    },
    get_classroom_id_by_name(classroom_name) {
      const classroom_list = this.$store.getters.get_classroom_list
      for (let i = 0; i < classroom_list.length; i++) {
        if (classroom_list[i]['classroom_name'] == classroom_name) {
          return classroom_list[i]['classroom_id']
        }
      }
      return null
    },
    get_location_category_index() {
      if (this.location_category == 'Schoolgebouw') {
        return new Set([0])
      } else if (this.location_category == 'Klaslokaal') {
        return new Set([1])
      } else if (this.location_category == 'Anders') {
        return new Set([2])
      }
      return null
    },
    get_building_classrooms(building_id) {
      let classroom_list = []
      this.classroom_list.forEach((x) => {
        if (x['building_id'] == building_id) {
          classroom_list.push(x)
        }
      })
      return classroom_list
    },
    get_building_classrooms_names(building_id) {
      let name_dict = {}
      this.get_building_classrooms(building_id).forEach(x => {
        name_dict[x['classroom_id']] = x['classroom_name']
      })
      return name_dict
    },
    set_library_name(value) {
      this.library_name = value
      this.val_step_1()
    },
    set_image(value) {
      this.image_file = value
      this.val_step_2()
    },
    set_location_category(location_category) {

      // Update state
      this.building_id = null
      this.classroom_id = null
      this.location_category = location_category

      if (this.location_category == 'Anders') {
        this.set_location_name_other(this.location_name_other)
      }

      this.val_step_3()
    },
    get_building_id_init() {
      if (this.building_id != null) {
        return new Set([this.building_id])
      }
    },
    get_classroom_id_init() {
      if (this.classroom_id != null) {
        return new Set([this.classroom_id])
      }
    },
    set_location(index) {
      if (this.location_category == 'Schoolgebouw') {
        this.set_building_id(index)
      } else if (this.location_category == 'Klaslokaal') {
        this.set_classroom_id(index)
      } else if (this.location_category == 'Anders') {
        this.set_location_name_other(this.location_name_other)
      } 
    },
    set_location_name_other(location_name) {
      this.location_name_other = location_name
      this.set_location_name(location_name)
    },
    set_location_name(location_name) {
      if (location_name != null) {
        this.location_name = location_name
        this.val_step_3()
      }
    },
    set_building_id(building_id) {
      this.building_id = building_id
      this.set_location_name(this.$store.getters.get_building_dict[building_id].building_name)
    },
    set_classroom_id(classroom_id) {
      this.classroom_id = classroom_id
      this.set_location_name(this.$store.getters.get_classroom_dict[classroom_id].classroom_name)
    },
    async val_step_1() {

      // Were all required inputs filled?
      const data_entered = this.library_name != ""

      // Is the library a duplicate?
      const name_lower = this.library_name.toLowerCase()
      const duplicate_name = name_lower in this.library_name_dict
      const is_duplicate = duplicate_name && this.library_name_dict[name_lower] != this.library_id

      this.library_name_error = is_duplicate ? "Er bestaat al een bieb met deze naam op school." : null

      // Set validation
      this.form_step_validation_list[0] = data_entered && !is_duplicate
    },
    async val_step_2() {
      this.form_step_validation_list[1] = this.image_file != null
    },
    val_step_3() {
      this.form_step_validation_list[2] = this.location_name != null && this.location_name != ''
    },
    async submit() {

      // Publish submit event
      this.$emit("submit", {
        'organisation_id': this.organisation_id,
        'organisation_id_owner': this.organisation_id,
        'library_name': this.library_name,
        'location_name': this.location_name,
        'image_file': this.image_file
      })
    }
  }
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>