<template>
  <oib-form-connect-loginmethod
  :init_account_email="init_account_email"
  :account_type="account_type"
  :error_msg="error_msg"
  @submit="x => submit(x)"/>
</template>

<script>
import OibFormConnectLoginmethod from "../components/oib_form_connect_loginmethod.vue";


import {get_users_accounts_external} from "../store/api/user.js"
import {put_users_accounts_external} from "../store/api/user.js"

export default {
  name: "InlogmethodeKoppelen",
  props: ['user_id', 'account_type'],
  components: {
    OibFormConnectLoginmethod
  },
  data() {
    return {
      init_account_email: "",
      error_msg: ""
    }
  },
  async created() {
    const jwt = this.$store.getters.get_jwt
    const json = await get_users_accounts_external(jwt, {
      'user_id': parseInt(this.user_id),
      'account_type': this.account_type
    })
    
    if (json['account_external_list'].length > 0) {
      this.init_account_email = json['account_external_list'][0].account_email
    }    
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    capitalize_first_letter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt
      const response = await put_users_accounts_external(jwt, this.user_id, body.account_email, this.account_type)
      if (response.status == 409) {
        this.error_msg = "Dit mailadres is al aan een ander " + this.capitalize_first_letter(this.account_type) + " account gekoppeld."
      } else if (response.status == 400) {
        this.error_msg = "Dit mailadres is niet geldig."
      } else if (response.status == 500) {
        this.error_msg = "Er is een probleem met de server."
      } else {
        this.$router.go(-2)
      }
    }
  }
};
</script>
