import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueMq from 'vue3-mq'
import 'animate.css';

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueMq, {
    breakpoints: {
      mobile: 992,
      desktop: Infinity,
    }
  })
app.mount('#app')

window.addEventListener('beforeunload', () => {
  app.destroy();
});
