<template>
    <div
    class="row series-tile"
    style="margin-bottom: 20px; border-radius: 4px; padding: 10px; -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); margin-right: 1vw"
    :style="`background-color: #${color}`"
    @click="go_to_search_category()"
    >
        <h4 style="margin: 0; min-width: 130px; max-width: 70px; text-transform: capitalize;">{{series_title}}</h4>
        <div style="width: 100%">
          <img
          :src="get_book_cover(isbn13_cover_list[0])"
          alt=""
          class="drop-shadow"
          style="width: 44px; height: 66px; transform: translate(-40px, 40px) rotate(-10deg); background-color: white; border-radius: 2px;"
          />
          <img
          :src="get_book_cover(isbn13_cover_list[1])"
          alt=""
          class="drop-shadow"
          style="width: 48px; height: 72px; transform: translate(-8px, -40px) rotate(-3deg); background-color: white; border-radius: 2px;"
          />
          <img
          :src="get_book_cover(isbn13_cover_list[2])"
          alt=""
          class="drop-shadow"
          style="width: 52px; height: 91px; transform: translate(20px, -110px) rotate(10deg); background-color: white; border-radius: 2px;"
          />
        </div>
    </div>
</template>

<script>
import {get_book_cover} from "../store/api/library"

export default {
  name: "OibTileSeries",
  props: {
    color: {
        type: String,
        required: true
    },
    isbn13_cover_list: {
        type: Array,
        required: true
    },
    series_title: {
        type: String,
        required: true
    },
    img: {
        type: String,
        required: true
    },
    series_id: {
      type: Number,
      required: true
    }
  },
  methods: {
    get_book_cover(isbn13) {
      return get_book_cover(isbn13, 'm')
    },
    go_to_search_category() {
      this.$router.push({
        name: "BibliotheekZoekenSeries",
        params: {
          color: this.color,
          series_title: this.series_title,
          series_id: this.series_id
        }
      })
    },
  }
}
</script>

<style scoped>

.series-tile {
  width: 180px;
  height: 80px; 
  transition: scale .15s;
}
.series-tile:hover {
  cursor: pointer;
  scale: 1.08;
}

.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}
</style>