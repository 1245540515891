import { parse_query } from "../request.js"
import { request_put } from "../request.js"
import { request_post } from "../request.js"
import { request_get_json } from "../request.js"


export async function put_schools_lvs(jwt, branch_number, lvs_type, authorization_key, classroom_list) {
  /*
  Connect a lvs to a school.

  :param int branch_number: the unique brin + dependance_code combination of this school.
  :param int lvs_type: PARNASSYS | ESIS.
  :param int authorization_key: the authorization_key to authorize to lvs system for this school.
  :param int classroom_list: a list with classrooms from which students should be loaded.
  */

  const request_path = `/schools/${branch_number}/lvs/${lvs_type}`
  const body = {
    'authorization_key': authorization_key,
    'classroom_list': classroom_list
  }

  return await request_put(jwt, request_path, body)
}

export async function get_schools_lvs_query(jwt,query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/schools/lvs?${query_string}`, undefined)
}

export async function get_schools_lvs_download_results_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/schools/lvs/download-results?${query_string}`, undefined)
}

export async function post_schools_lvs_sync(jwt, branch_number, lvs_type, allow_cache, has_priority) {
  return await request_post(jwt, `/schools/${branch_number}/lvs/${lvs_type}/sync`, {
    'allow_cache': allow_cache,
    'has_priority': has_priority
  })
}

export async function get_schools_lvs_sync_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/schools/lvs/sync?${query_string}`, undefined)
}

export async function put_schools_lvs_resources(jwt, branch_number, lvs_type, resource_type, formdata) {
  /* Upload an image to a directory.

  :param str branch_number: BRIN + dependance_code of school.
  :param str lvs_type: PARNASSYS | ESIS.
  :param str resource_type: NIET_METHODE_TOETSEN
  :param FormData formdata: formdata with file
  */

  const request_path =`/schools/${branch_number}/lvs/${lvs_type}/resources/${resource_type}`

  return await request_put(jwt, request_path, formdata)
}
