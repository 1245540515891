<template>
  <div class="column" style="margin-bottom: 15px;">
    <div style="height: 1px; width: 100%; background-color: var(--primary-color-sub); margin-bottom: 30px"/>
    <div class="wrapper column">
      <div class="row">
        <img
        src="../assets/logo_oib_padding.jpg"
        alt=""
        style="width: 50px; height: 50px; border-radius: 50%;"
        >
        <div class="column" style="margin-left: 10px; justify-content: center;">
          <h4 class="h4" style="margin: 0">De Leesapp</h4>
          <p style="font-size: 10px; margin-top: 0; margin-bottom: 0;">Update • {{ parse_datetime(datetime) }}</p>
        </div>
      </div>
      <div style="max-width: 650px;">
        <h3 class="h3" style="margin-bottom: 0">{{header}}</h3>
        <p style="font-size: 0.8em">
          <slot></slot>
        </p>
      </div>      
    </div>    
  </div>
</template>

<script>
export default {
  name: "OibPost",
  props: {
    header: {
      type: String,
      required: false
    },
    datetime: {
      type: String,
      required: true
    }
  },
  methods: {
    parse_datetime(datetime) {
      const now = new Date()
      const date = new Date(datetime)

      const hourDiff = this.hourDiff(now, date)
      if (hourDiff > 0 && hourDiff < 24) {
        return hourDiff.toString() + ' uur geleden'
      }

      const monthDiff = this.monthDiff(now, date)
      if (monthDiff > 1 && monthDiff < 12) {
        return monthDiff.toString() + ' maanden geleden'
      }

      const dayDiff = this.dayDiff(now, date)
      if (dayDiff > 0 && dayDiff < 60) {
        return dayDiff.toString() + ' dagen geleden'
      }

      return ''
    },
    hourDiff(d1, d2) {
      const diffTime = Math.abs(d2 - d1);
      return Math.ceil(diffTime / (1000 * 60 * 60)); 
    },
    dayDiff(d1, d2) {
      const diffTime = Math.abs(d2 - d1);
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    },
    monthDiff(d2, d1) {
      return d2.getMonth() - d1.getMonth() + (12 * (d2.getFullYear() - d1.getFullYear()))
    }
  }
};
</script>
