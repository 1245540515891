<template>
  <div v-if="selector_name" @click="pick_library" style="margin-right: 10px">
    <h3 style="margin: 0; background-color: var(--highlight-color); padding: 8px; font-size: 12px; border-radius: 2px; width: fit-content; white-space:nowrap;">{{selector_name}}</h3>
  </div>
</template>

<script>
export default {
  name: "OibLibraryPickerButtonInline",
  computed: {
    selector_name() {
      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active = this.$store.getters.get_active_library_selector
      const selector = selector_list[selector_active]
      return selector ? selector.name : ''
    }
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  methods: {
    pick_library() {
      this.$router.push({
        name: 'BibliotheekKiezenGroep',
        params: {
          'user_id': this.user_id,
          'library_route': 'back'
        }
      });
    }
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  font-size: 0.8em;
  line-height: 35px;
  height: 35px;
  background-color: none;
  background-size: cover;
  border-radius: 10px;
}

.custom-select .selected {
  border-radius: 4px;
  color: white;
  padding: 0 1em;
  cursor: pointer;
  user-select: none;
  height: 29px;
  margin: 3px;
  display: flex;
  align-items: center;
  background-color: var(--highlight-color);
}

.custom-select .selected.open {
  border-bottom: 1px solid var(--primary-color);
  border-radius: 16px 16px 0px 0px;
}

.custom-select .items {
  color: var(--primary-color);
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  position: absolute;
  background-color: var(--contrast-color);
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div p {
  color: var(--primary-color);
}

.custom-select .items div:hover {
  background-color: var(--contrast-color-p4);
}

.selectHide {
  display: none;
}
</style>
