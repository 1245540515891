<template>
  <div class="wrapper column" ref="ruler"/>
</template>

<script>
export default {
  name: "OibWrapperRuler",
  mounted() {
    window.addEventListener('resize', this.on_resize);
    this.on_resize()
  },
  unmounted() {
    window.removeEventListener('resize', this.on_resize);
  },
  methods: {
    on_resize() {
      const ruler = this.$refs.ruler
      const style = ruler.currentStyle || window.getComputedStyle(ruler);
      this.$store.commit("set_margin_left", style.marginLeft)
      this.$store.commit("set_width", ruler.clientWidth)
    }
  }
}
</script>