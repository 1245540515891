<template>
  <section v-if="library_access && reading_access">
    <div class="row" style="padding-left: 5%" :style="$mq == 'desktop' ? 'margin-top: -47px;' : ''">
      <div class="tab" @click="to_bibliotheek_collectie">
        <h3>Collectie</h3>
        <div
        class="fade"
        :style="tab_active =='collectie' ? 'opacity: 100' : 'opacity: 0'"
        />
      </div>
      <div style="width: 40px"></div>
      <div class="tab" @click="to_bibliotheek_lezen">
        <h3>Lezen</h3>
        <div
        class="fade"
        :style="tab_active =='lezen' ? 'opacity: 100' : 'opacity: 0'"
        />
      </div>
    </div>
    <div style="border-bottom: 1px solid var(--primary-color-p4); margin-bottom: 20px"></div>
  </section>
</template>

<script>
import { has_role } from '../store/utils';

export default {
  name: "OibTabBibliotheek",
  props: {
    tab_active: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      library_access: false,
      reading_access: false
    }
  },
  created() {
    this.library_access = has_role([
      'SYSTEM_ADMIN',
      'SYSTEM_SUPPORT',
      'LIBRARIAN',
      'LIBRARY_ASSISTENT',
      'BOOKSHOP_OWNER',
      'READING_CONSULTANT'
    ])

    this.reading_access = has_role([
      'LIBRARIAN',
      'TEACHER',
      'STUDENT'
    ])
  },
  methods: {
    to_bibliotheek_collectie() {
      this.$router.push({
        name: "BibliotheekCollectie"
      });
    },
    to_bibliotheek_lezen() {
      this.$router.push({
        name: "BibliotheekLezen"
      });
    }
  }
}
</script>

<style scoped>

.tab:hover {
  cursor: pointer;
}

.tab h3 {
  font-size: 0.9em;
  font-weight: 700;
  margin-top: 0
}

.tab div {
  border-bottom: 2px solid var(--highlight-color);
  border-radius: 1px;
  margin-bottom: 0;
}
</style>