<template>
  <div class="column">
    <label v-if="label">{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
    <p v-if="description" class="description">{{description}}</p>
    <input type="color" ref="input" />
  </div>
</template>

<script>
export default {
  name: "OibInputColor",
  props: {
    init_value: {
      default: ""
    },
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    error_msg: {
      type: String,
      default: ""
    },
    show_error: {
      type: Boolean,
      default: true
    },
    type: {
      default: 'text'
    }
  },
  data() {
    return {
      value: null
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.input) {
        this.$refs.input.value = this.init_value
      }      
    }, 100)
  },
  watch: {
    init_value() {
      if (this.$refs.input && this.init_value) {
        this.$refs.input.value = this.init_value
      }
    }
  },
  methods: {
    on_input(input) {
      this.value = input
      this.$emit("input", input);
    }
  }
}
</script>

<style scoped>
p,
label {
  font:
    1rem 'Fira Sans',
    sans-serif;
}

input {
  margin: 0.4rem;
  height: 60px;
  width: 120px;
}


* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>