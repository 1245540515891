<template>
  <div class="oib-page">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "OibPage"
}
</script>

<style scoped>
.oib-page {
  background-color: var(--primary-color);
  color: var(--contrast-color);
  min-height: 100vh;
}

.oib-page.light {
  background-color: var(--contrast-color);
  color: var(--primary-color);
}
</style>