<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Trefwoorden" @submit="submit" ref="form">

    <div v-if="book_type!='INFORMATIEF'">
      <oib-input-multi-select-descriptive
        description="Hoe zou je de hoofdpersoon beschrijven?"
        :allow_multi_select="false"
        :required="true"
        :options="main_character_initial_state_selector_list"
        :keys_init="[main_character_initial_state_index]"
        @select="x => set_main_character_initial_index(x[0])"
      />
    </div>

    <div v-if="book_type!='INFORMATIEF'">
      <oib-input-multi-select-descriptive
        description="Wat start het verhaal?"
        :allow_multi_select="false"
        :required="true"
        :options="story_triggers_selector_list"
        :keys_init="[story_trigger_index]"
        @select="x => set_story_trigger_index(x[0])"
      />
    </div>

    <div v-if="book_type!='INFORMATIEF'">
      <oib-input-multi-select-descriptive
        description="Wat doet de hoofdpersoon in het verhaal?"
        :allow_multi_select="false"
        :required="true"
        :options="story_transition_selector_list"
        :keys_init="[story_transition_index]"
        @select="x => set_story_transition_index(x[0])"
      />
    </div>

    <div v-if="book_type!='INFORMATIEF'">
      <oib-input-multi-select-descriptive
        description="Wat gebeurt er op het einde van het verhaal?"
        :allow_multi_select="false"
        :required="true"
        :options="story_climax_selector_list"
        :keys_init="[story_climax_index]"
        @select="x => set_story_climax_index(x[0])"
      />
    </div>

    <div v-if="book_type!='INFORMATIEF'">
      <oib-input-multi-select-descriptive
        description="Wat betekent dit voor de hoofdpersoon?"
        :allow_multi_select="false"
        :required="true"
        :options="main_character_final_state_selector_list"
        :keys_init="[main_character_final_state_index]"
        @select="x => set_main_character_final_state_index(x[0])"
      />
    </div>

    <div>
      <p class="description">Welke thema's komen terug in dit boek?</p>
      <oib-search-bar
      @search="x => search_query = x"
      :search_frequency_ms="0"
      shape="block"
      style="margin-bottom: 10px; height: 50px; background-color: rgba(255, 255, 255, 0.9);"
      />
      <oib-input-multi-select-descriptive
        v-if="tag_list.length > 0"
        :allow_multi_select="true"
        :required="true"
        :options="tag_list"
        :keys_init="tag_indices"
        :display_selected="true"
        :filter_query="search_query"
        @select="x => set_tag_indices(x)"
      />
    </div>

    <div>
      <p class="description">Hoe relevant zijn deze thema's?</p>
      <div v-for="tag_index in tag_indices" :key="tag_index">
        <p style="font-size: 0.9em; margin-bottom: 0">{{ tag_list[tag_index].tag }}</p>
        <vue-slider
          :height="8"
          dotSize="20"
          @change="relevance => update_relevance_dict(tag_index, relevance)"
          :min="1"
          :max="5"
          v-model="tag_relevance_dict[tag_index]"
          dotStyle="background-color: white; border-color: white;"
          railStyle="background-color: rgba(255, 255, 255, 0.1)"
          :processStyle="{'background-color': 'var(--contrast-color)'}"
          style="padding: 12.5px 0px; width: 100%;"
        />
      </div>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"
import OibSearchBar from "../components/OibSearchBar.vue";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

import {options_book_products_users_scrape_data} from '../store/api/book_scraper'

export default {
  name: "OibFormBookVoteAudience",
  props: ["isbn13", "scrape_data"],
  components: {
    OibForm,
    OibInputMultiSelectDescriptive,
    OibSearchBar,
    VueSlider
  },
  data() {
    return {
      form_step_validation_list: [],
      book_type: null,
      main_character_initial_state_index: null,
      story_trigger_index: null,
      story_transition_index: null,
      story_climax_index: null,
      search_query: '',
      main_character_final_state_index: null,
      main_character_initial_state_selector_list: [
        {'tag': 'Zwak', 'details': 'Sjakie en de Chocoladefabriek, De grijze jager 1'},
        {'tag': 'Conventioneel', 'details': 'Een leerling zonder bijzondere kenmerken'},
        {'tag': 'Held', 'details': 'Superman, Batman, Dog man'},
        {'tag': 'Kluns', 'details': 'De waanzinnige boomhut, Leven van een loser'},
        {'tag': 'Verborgen talent', 'details': 'Harry Potter deel 1, Mathilda'},
        {'tag': 'Talent', 'details': 'Harry Potter deel 2+'},
        {'tag': 'Slechterik', 'details': 'Scrooge'}
      ],
      story_triggers_selector_list: [
        {'tag': 'Kluns', 'details': 'Een domme actie'},
        {'tag': 'Engel', 'details': 'De introductie van een nieuw karakter dat het leven van de hoofdpersoon verandert'},
        {'tag': 'Spreuk', 'details': 'Een spreuk, wens of vloek'},
        {'tag': 'Keuze', 'details': 'Een doelbewuste keuze'},
        {'tag': 'Gevangen', 'details': 'De hoofdpersoon wordt gevangen genomen'},
        {'tag': 'Vluchten', 'details': 'De hoofdpersoon moet vluchten voor gevaar'},
        {'tag': 'Dreiging', 'details': 'Er wordt een gevaar of dreiging geïntroduceerd'}
      ],
      story_transition_selector_list: [
        {'tag': 'Mysterie', 'details': 'De hoofdpersoon probeert een mysterie op te lossen'},
        {'tag': 'Avontuur', 'details': 'De hoofdpersoon gaat op avontuur'},
        {'tag': 'Ontwikkeling', 'details': 'De hoofdpersoon leert nieuwe vaardigheden'},
        {'tag': 'Perspectief', 'details': 'De hoofdpersoon bekijkt iets vanuit een nieuw perspectief'}
      ],
      story_climax_selector_list: [
        {'tag': 'Verslaat slechterik', 'details': 'De hoofdpersoon verslaat een slechterik (en vindt een schat of redt een geliefde)'},
        {'tag': 'Verslaat instituut', 'details': 'De hoofdpersoon verslaat een domininerende kwade macht'},
        {'tag': 'Keuze', 'details': 'De hoofdpersoon maakt een lastige keuze'},
        {'tag': 'Wint competitie', 'details': 'De hoofdpersoon wint een wedstrijd, toernooi of competitie.'},
        {'tag': 'Ontsnapt', 'details': 'De hoofdpersoon ontsnapt gevaar of gevangenschap.'},
        {'tag': 'Drama', 'details': 'Een treurig en soms open einde.'},
        {'tag': 'Anticlimax', 'details': 'De hoofdpersoon bakt een taart, maar vergat de oven aan te zetten.'},
        {'tag': 'Droom', 'details': 'De hoofdpersoon ontwaakt uit een droom.'}
      ],
      main_character_final_state_selector_list: [
        {'tag': 'Betekenis', 'details': 'De hoofdpersoon vindt liefde of een plek waar hij gewaardeerd wordt'},
        {'tag': 'Welvaart', 'details': 'De hoofdpersoon was eerst arm en wordt nu rijk'},
        {'tag': 'Held', 'details': 'De hoofdpersoon wordt een held.'},
        {'tag': 'Anders zijn', 'details': 'De hoofdpersoon accepteert wie hij is.'},
        {'tag': 'Verleden', 'details': 'De hoofdpersoon accepteert zijn verleden.'},
        {'tag': 'Morele les', 'details': 'De hoofdpersoon leert een belangrijke morele les.'},
        {'tag': 'Normaal', 'details': 'De leven van de hoofdpersoon keert weer terug naar normaal.'}
      ],
      tag_list: [],
      tag_indices: new Set(),
      tag_relevance_dict: {},
    }
  },
  async created() {
    const jwt = this.$store.getters.get_jwt
    const response = await options_book_products_users_scrape_data(jwt)
    const response_json = await response.json();
    let tag_list = []
    response_json.tag_list.forEach(tag => tag_list.push({'tag': tag, 'details': ''}))
    this.tag_list = tag_list

    const book = this.$store.getters.getBook(this.isbn13.toString())
    this.book_type = book.book_type

    if (this.book_type == 'INFORMATIEF') {
      this.form_step_validation_list = [false, false]
    } else {
      this.form_step_validation_list = [false, false, false, false, false, false, false]
    }

    if (this.scrape_data) {
      this.scrape_data.tags.forEach(tag_obj => {
        if (tag_obj.tag.startsWith('Hoofdpersoon start')) {
          for (let i = 0; i < this.main_character_initial_state_selector_list.length; i++) {
            if ('Hoofdpersoon start - ' + this.main_character_initial_state_selector_list[i].tag == tag_obj.tag) {
              this.main_character_initial_state_index = i
              break
            }
          }
        }  
        else if (tag_obj.tag.startsWith('Verhaal catalysator')) {
          for (let i = 0; i < this.story_triggers_selector_list.length; i++) {
            if ('Verhaal catalysator - ' +  this.story_triggers_selector_list[i].tag == tag_obj.tag) {
              this.story_trigger_index = i
              break
            }
          }
        }
        else if (tag_obj.tag.startsWith('Verhaal transitie')) {
          for (let i = 0; i < this.story_transition_selector_list.length; i++) {
            if ('Verhaal transitie - ' + this.story_transition_selector_list[i].tag == tag_obj.tag) {
              this.story_transition_index = i
              break
            }
          }
        }
        else if (tag_obj.tag.startsWith('Verhaal climax')) {
          for (let i = 0; i < this.story_climax_selector_list.length; i++) {
            if ('Verhaal climax - ' + this.story_climax_selector_list[i].tag == tag_obj.tag) {
              this.story_climax_index = i
              break
            }
          }
        }
        else if (tag_obj.tag.startsWith('Hoofdpersoon transformatie')) {
          for (let i = 0; i < this.main_character_final_state_selector_list.length; i++) {
            if ('Hoofdpersoon transformatie - ' + this.main_character_final_state_selector_list[i].tag == tag_obj.tag) {
              this.main_character_final_state_index = i
              break
            }
          }
        } 
        else {
          for (let i = 0; i < this.tag_list.length; i++) {
            console
            if (this.tag_list[i].tag == tag_obj.tag) {
              this.tag_indices.add(i)
              this.update_relevance_dict(i, tag_obj.relevance * 4 + 1)
            }
          }
        }
      })
    }
  },
  methods: {
    go_to_tag_list() {
      this.$refs.form.go_to_step(6)
      
    },
    set_book_type(value) {
      if (value.tag == 'Informatief') {
        setTimeout(this.go_to_tag_list, 500)
      } else {
        setTimeout(this.$refs.form.next_step, 500)
      }
    },
    set_main_character_initial_index(value) {
      this.main_character_initial_state_index = value
      if (this.$refs.form) {
        setTimeout(this.$refs.form.next_step, 500)
      }
    },
    set_story_trigger_index(value) {
      this.story_trigger_index = value
      if (this.$refs.form) {
        setTimeout(this.$refs.form.next_step, 500)
      }
    },
    set_story_transition_index(value) {
      this.story_transition_index = value
      if (this.$refs.form) {
        setTimeout(this.$refs.form.next_step, 500)
      }
    },
    set_story_climax_index(value) {
      this.story_climax_index = value
      if (this.$refs.form) {
        setTimeout(this.$refs.form.next_step, 500)
      }
    },
    set_main_character_final_state_index(value) {
      this.main_character_final_state_index = value
      if (this.$refs.form) {
        setTimeout(this.$refs.form.next_step, 500)
      }
    },
    set_tag_indices(value) {
      this.tag_indices = value
      const step_index = this.book_type == 'INFORMATIEF' ? 0 : 5
      this.form_step_validation_list[step_index] = this.tag_indices.length > 0
    },
    update_relevance_dict(tag_index, relevance) {
      this.tag_relevance_dict[tag_index] = relevance
      let can_submit = true
      for (let index of this.tag_indices) {
        if (this.tag_relevance_dict[index] == 1) {
          can_submit = false
        }
      }
      const step_index = this.book_type == 'INFORMATIEF' ? 1 : 6
      this.form_step_validation_list[step_index] = can_submit
    },
    async submit() {
      let tag_list_result = []
      const keys = Object.keys(this.tag_relevance_dict)
      for (let x of keys) {
        tag_list_result.push({
          'tag': this.tag_list[x].tag,
          'relevance': (this.tag_relevance_dict[x] - 1) / 4
        })
      }

      if (this.book_type != 'INFORMATIEF') {
        const main_character_start = this.main_character_initial_state_selector_list[this.main_character_initial_state_index]
        tag_list_result.push({
          'tag': 'Hoofdpersoon start - ' + main_character_start.tag,
          'relevance': 1
        })
        const story_trigger = this.story_triggers_selector_list[this.story_trigger_index]
        tag_list_result.push({
          'tag': 'Verhaal catalysator - ' + story_trigger.tag,
          'relevance': 1
        })
        const story_transition = this.story_transition_selector_list[this.story_transition_index]
        tag_list_result.push({
          'tag': 'Verhaal transitie - ' + story_transition.tag,
          'relevance': 1
        })
        const story_climax = this.story_climax_selector_list[this.story_climax_index]
        tag_list_result.push({
          'tag': 'Verhaal climax - ' + story_climax.tag,
          'relevance': 1
        })
        const main_character_final_state = this.main_character_final_state_selector_list[this.main_character_final_state_index]
        tag_list_result.push({
          'tag': 'Hoofdpersoon transformatie - ' + main_character_final_state.tag,
          'relevance': 1
        })
      }

      this.$emit("submit", {
        'tags': tag_list_result
      })
    }
  }
};
</script>

<style scoped>

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: white;
}
</style>