<template>
  <div v-if="library_id" class="library-section" @click="on_click">
    <button class="book-row fade" v-on:click="toggle" style="z-index: 2">
      <div class="book-row-content">
        <div class="book-row-content-main" style="width: 60%">
          <slot>
            <img class="avatar" :src="img_url" />
          </slot>
          <div class="book-row-details">
            <h3 class="h4">{{ user_name }} • {{ classroom_name }}</h3>
            <p class="subh4">
              {{isbn13}} • {{ library_name }}
            </p>
          </div>
        </div>
        <div :class="$mq" class="availability">
          
          <oib-icon-label
          style="justify-content: flex-end; margin-top: 0"
          icon_style="font-size: 20px; color: var(--highlight-color);"
          title_style="font-size: 0.8em; font-weight: 400"
          icon="last_page"
          :title="borrowed_datetime"
          direction="row"
          :active="false"
          />
          <oib-icon-label
          style="justify-content: flex-end; margin-bottom: 0"
          :style="returned_datetime ? '' : 'opacity: 0'"
          icon="first_page"
          icon_style="font-size: 20px; color: red"
          :title="returned_datetime"
          title_style="font-size: 0.8em; font-weight: 400"
          direction="row"
          :active="false"
          />

        </div>
      </div>
    </button>
  </div>
</template>

<script>
import OibIconLabel from "./oib_icon_label.vue"

import {load_student} from "../store/user.js"
import {get_book_cover} from "../store/api/library.js"
import {on_click_isbn13_router} from "../store/click.js"

export default {
  name: "OibListItem",
  components: {
    OibIconLabel
  },
  props: ["library_id", "user_id", "isbn13", "user_name", "classroom_name", "borrowed_datetime", "returned_datetime"],
  data() {
    return {
      availability: "?/?",
      copies_available: 0,
      show_more: false,
      library_name: "",
      library_location: "",
      max_section_height: 0,
      img_url: null
    }    
  },
  created() {
    this.library_name = this.get_library_name(this.library_id)
    this.library_location = this.get_library_location(this.library_id)
    this.img_url = get_book_cover(this.isbn13, "s")
  },
  methods: {
    get_book_cover(isbn13) {
      return get_book_cover(isbn13, "s")
    },
    get_library_name(library_id) {
      const library_dict = this.$store.getters.get_library_dict
      const library = library_dict[library_id]
      return library.library_name
    },
    get_library_location(library_id) {
      const library_dict = this.$store.getters.get_library_dict
      return library_dict[library_id].location_name
    },
    async on_click() {
      // Only go to book page if it was not returned
      if (!this.returned_datetime) {
        await load_student(this.user_id)
        const book = await this.$store.dispatch("load_book", this.isbn13)

        on_click_isbn13_router(
          this.user_id,
          book['book_edition_id'],
          this.isbn13,
          this.isbn13,
          [this.library_id],
          false
        )
      }      
    }
  }
};
</script>

<style scoped>
.library-section:hover {
  background-color: rgba(255, 255, 255, 0.01);
  -webkit-box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05); 
  box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05);
}

.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  padding: 0px;
}

.book-row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.next {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #636465;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  color: #acaeb0;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.availability {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  max-width: 150px;
  text-align: right;
}

.availability.desktop {
  padding-right: 20px;
}

@keyframes slideDown{
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp{
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;    
  }
}

@keyframes example {
  0% {transform: translateY(0); opacity: 1;}
  20% {transform: translateY(0); opacity: 0;}
  100% {transform: translateY(-100%); opacity: 0;}
}

</style>