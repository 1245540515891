<template>
  <button class="book-row fade" v-on:click="goToBook">
    <div class="book-row-content wrapper">
      <div class="book-row-content-main" style="width: 100%;">
        <div class="book-row-details" style="width: 100%;">
          <div style="display: flex; flex-direction: row; justify-content: space-between; width: 100%;">
            <h3 class="h4">{{ title }}</h3>
            <p class="subh4" v-if="get_organisation_age(subtext.organisation_creation_datetime) < 90"><span style="color: var(--highlight-color)">*</span>Nieuw</p>
          </div>
          <div class="column">
            <p class="subh4" style="margin-bottom: 10px">
              {{subtext.branch_number}} - {{ subtext.street_name }} {{ subtext.house_number }}, {{subtext.zipcode}} {{subtext.city}}
            </p>
            <div class="subh4">
              <div style="display: flex; flex-direction: row; align-items: center; width: 120px;">
                <span class="material-icons">corporate_fare</span>
                <p style="margin: 0">bibliotheken: {{library_count == null ? 0 : library_count}}</p>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; 200px;">
                <span class="material-icons">school</span>
                <p style="margin: 0">leerlingen: {{student_count}}</p>
              </div>
            </div>
            <div class="subh4">
              <div style="display: flex; flex-direction: row; align-items: center; width: 120px;">
                <span class="material-icons">menu_book</span>
                <p style="margin: 0">boeken: {{copy_count == null ? 0 : copy_count}}</p>
              </div>
              <div style="display: flex; flex-direction: row; align-items: center; 200px;">
                <span class="material-icons">login</span>
                <p style="margin: 0">actieve leerkrachten: {{employee_count_active == null ? 0 : employee_count_active}}/{{employee_count == null ? 0 : employee_count}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="material-icons next">{{icon}}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "OibListItem",
  props: {
    title: {
      type: String,
      required: true
    },
    subtext: {

    },
    imgUrl: {

    },
    icon: {
      type: String,
      default: "chevron_right"
    },
    library_count: {
      type: Number,
      default: 0
    },
    copy_count: {
      type: Number,
      default: 0
    },
    student_count: {
      type: Number,
      default: 0
    },
    employee_count: {
      type: Number,
      default: 0
    },
    employee_count_active: {
      type: Number,
      default: 0
    },
  },
  methods: {
    get_organisation_age(creation_datetime) {
      const today = new Date()
      const creation_date = new Date(creation_datetime)
      const diff_time = today - creation_date
      const diff_days = Math.ceil(diff_time / (1000 * 60 * 60 * 24));
      return diff_days
    }
  }
};
</script>

<style scoped>
.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  padding: 10px 0;
}

.book-row:hover {
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05); 
  box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05);
}

.book-row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.next {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #636465;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.8em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.subh4 span {
  font-size: 1.3em;
  padding-right: 4px;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

</style>