<template>
  <popup duration="4000" ref="popup" :class="$mq" class="shrink-mobile" style="height: 110px; top: 140px;">
    <div class="row" style="justify-content: space-between; width: 100%;">
      <div class="row" style="height: 100%;">
        <img class="avatar" :src="cover_url" />
        <div class="column" style="display: flex; flex-direction: column; align-items: start; width: 100%; height: 100%" ref="popup_details">
          <h2 class="spacer" style="color: var(--primary-color)">{{title}}</h2>
          <p>{{description}}</p>
          <p>{{library_name}}</p>
        </div>
      </div>
      <slot></slot>
    </div>
  </popup>
</template>

<script>
import Popup from "./popup.vue"

import {get_book_cover} from "../store/api/library.js"

export default {
  name: "PopupBook",
  props: ["isbn13", "title", "description", "library_name"],
  components: {
    Popup
  },
  computed: {
    cover_url() {
      return get_book_cover(this.isbn13, 's')
    }
  },
  data() {
    return {
      showstyle: ""
    }
  },
  methods: {
    show() {
      this.$refs.popup.show()
    },
    hide() {
      this.$refs.popup.hide()
    }
  }
}
</script>


<style scoped>

.mobile div div h2{
  width: 90%;
}

.mobile div div {
  width: 90%;
}

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.popup h2 {
  text-overflow: ellipsis;
  overflow: hidden; 
  width: 180px; 
  height: 1.2em; 
  white-space: nowrap;
}

.popup p {
  font-size: 0.8em;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

h2 {
  font-size: 1em;
  margin: 0;
}

.spacer {
  margin-bottom: 5px;
}

p {
  margin: 0;
  color: var(--primary-color)
}
</style>
