<template>
  <oib-page>
    <div style="height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center">
      <oib-loading-animation/>
      <div style="border: 2px solid white; width: 280px; height: 20px; border-radius: 40px; margin-top: 60px;">
        <div class="fade" :style="`background-color: var(--highlight-color); height: 20px; width: ${progress}%; border-radius: 40px;`"/>
      </div>
      <slot></slot>
    </div>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibLoadingAnimation from "../components/oib_loading_animation.vue"

export default {
  name: "OibPageLoading",
  props: {
    progress: {
      type: Number,
      required: true,
    },
    text: {
      type: String,
      default: ""
    }
  },
  components: {
    OibPage,
    OibLoadingAnimation
  }
};
</script>
