import store from './index'
import {get_books_recommendations} from './api/book_recommendation.js'
import {has_role} from '../store/utils.js'

function get_library_id_str(user_id) {

  const user = store.getters.getUser
  if (!user) {
    return []
  }

  let library_id_list = []
  if (user['user_id'] == user_id && has_role(['SYSTEM_ADMIN', 'READING_CONSULTANT', 'BOOKSHOP_OWNER'])) {
    library_id_list = Object.keys(store.getters.get_library_dict)
  } else {
    let library_id_set = store.getters.get_user_library_dict[user_id]
    if (library_id_set && library_id_set.size > 0) {
      library_id_set.forEach(library_id => library_id_list.push(library_id))
    }
  }

  return library_id_list.join(',')
}

async function get_books_recommendations_query(query) {
  
  const json = await get_books_recommendations(store.getters.get_jwt, query)

  // Build tile list
  let tile_list = []
  json['recommendation_list'].forEach(book => {
    // let marker = null
    // if (schoolday_count_median_read != null && book.schoolday_count_median > schoolday_count_median_read + 5) {
    //   marker = 'Uitdaging'
    // } else if (book.borrow_count_sum < 5 && book.release_date > '2020-01-01') {
    //   marker = 'Onderbelicht'
    // }
    tile_list.push({
      'isbn13': book.isbn13,
      'book_edition_id': book.book_edition_id,
      'book_title': book.book_title,
      'sub_text': book.author_name,
      'marker': book.borrow_count < 2 ? 'Onderbelicht' : null,
      'copies_total': book.total_count,
      'copies_in_use': book.in_use_count,
      'color': book.color
    })
  })

  return tile_list
}

export async function load_user_recommendations(user_id) {
  const library_id_str = get_library_id_str(user_id)

  // Quit if query was cached
  const recommendation_list = store.getters.get_user_recommendation_list(user_id, library_id_str)
  if (recommendation_list) {
    return recommendation_list
  }

  // Get recommendations
  let query = {
    'user_id': user_id,
    // 'select': 'isbn13,book_edition_id,book_title,author_name,copies_total,copies_in_use,borrow_count_sum,release_date,schoolday_count_median,color',
    'force_variety': 1,
    'can_borrow': 1,
    'limit': 8
  }
  if (library_id_str.length > 0) {
    query['library_id'] = library_id_str
  }

  const tile_list = await get_books_recommendations_query(query)

  // Store tile_list for this user_id in store
  store.commit('add_user_recommendation_list', {
    'library_id_str': library_id_str,
    'user_id': user_id,
    'recommendation_list': recommendation_list
  })

  return tile_list
}

export async function load_book_recommendations(isbn13) {

  const user = store.getters.getUser
  if (!user) {
    return []
  }
  const user_id = user['user_id']

  const library_id_str = get_library_id_str(user_id)

  // Quit if query was cached
  const recommendation_list = store.getters.get_book_recommendation_list(isbn13, library_id_str)
  if (recommendation_list) {
    return recommendation_list
  }

  // Get recommendations
  let query = {
    'select': 'isbn13,book_edition_id,book_title,author_name',
    'isbn13': isbn13,
    'limit': 10,
    'page': 0
  }
  if (library_id_str.length > 0) {
    query['library_id'] = library_id_str
  }

  const tile_list = await get_books_recommendations_query(query)

  // Store tile_list for this book_edition_id in store
  store.commit('add_book_recommendation_list', {
    'library_id_str': library_id_str,
    'isbn13': isbn13,
    'recommendation_list': recommendation_list
  })

  return tile_list
}
