let roleDict = {}

export function getRoleList() {
    return Object.values(roleDict)
}

export function getRole(roleId) {
    return roleDict[roleId] || null;
}

export function setRole(role) {
    roleDict[role.roleId] = role
}
