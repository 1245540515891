<template>
    <library-picker
    :user_id="user_id"
    :physical_access_only="true"
    @input="library_id => go_to_library_route(library_id)"
    />
</template>

<script>
import LibraryPicker from "../components/library_picker.vue"

export default {
  name: "BibliotheekKiezenRouter",
  props: ['user_id', 'library_route'],
  components: {
    LibraryPicker
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    go_to_library_route(library_id) {
      if (this.library_route == 'back') {
        this.$router.back()
      } else {
        this.$router.replace({
          name: this.library_route,
          params: {
            "library_id": library_id
          }
        });
      }      
    }
  }
};
</script>

<style scoped>
.reset-margin {
  margin-left: 50px;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    display: block;
    overflow-x: auto;
    margin-right: 40px;
}

.styled-table thead tr {
    background-color: var(--highlight-color);
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.style-table td {
  min-width: 100px;
}

.styled-table th {
  min-width: 160px;
  text-align: left;
}

.styled-table tbody tr {
    border-bottom: 1px solid #21222f;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #21222f;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--highlight-color);
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: var(--highlight-color);
}

.styled-table tr td:nth-child(2) { width:100% }

</style>