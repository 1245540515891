import { get_books } from "../api/library.js"

export const DEFAULT_SELECT_BOOK_PRODUCT = 'isbn13,book_edition_id,book_title,author_name,page_count,color,book_type'
export const DEFAULT_SELECT_BOOK_EDITION = 'isbn13,book_edition_id,book_story_id,book_title,color,book_type,dyslexia,avi,age_min,age_max,author_id,author_name,series_id,series_title,release_number,page_count,release_date,tag_name,tag_relevance,nbd_label_age,nbd_label_genre,pim_label_category'
export const DEFAULT_SELECT_BOOK_EDITION_LIBRARY = 'isbn13,book_edition_id,book_story_id,book_title,color,book_type,dyslexia,avi,age_min,age_max,author_id,series_id,release_number,page_count,copies_in_use,copies_total,tag_name,tag_relevance,nbd_label_age,nbd_label_genre,pim_label_category'

export default ({
    state: {

        // Books
        bookDict: {},

        // Conversion
        book_edition_id_to_isbn13_list: {},

        // Tips
        book_similarity_dict: {},

        series_tips_book_edition_id_dict: {},
        series_tips_book_edition_id: "",
        series_tips_series_title: "",
        series_tips_book_title: "",

        author_tips_book_edition_id_dict: {},
        author_tips_book_edition_id: "",
        author_tips_author_name: "",
        author_tips_book_title: ""
    },
    mutations: {
        // books
        addBook(state, book) {
          state.bookDict[book['isbn13']] = book
        },

        // conversion
        add_isbn13_list_to_book_edition_id(state, isbn13_list, book_edition_id) {
            state.book_edition_id_to_isbn13[book_edition_id] = isbn13_list
        },

        set_author_book_edition_id_list(state, payload) {
            /*
            Store the result of a query on an author_id. The result contains a list of book_edition_ids
            and the book that triggered the query.

            :param list id_list: a list of book_edition_ids that were returned by the query.
            :param int book_edition_id: the book_edition_id of the book that triggered the call of the query.
            */

            const book_edition_id = payload['book_edition_id']
            const book_story_id = state.book_editions[book_edition_id]['book_story_id']
            const book_story = state.book_stories[book_story_id]
            const author = state.authors[book_story['author_id']]
            const library_id = state.active_library_id
            state.author_tips_book_edition_id = payload['book_edition_id']
            state.author_tips_book_title = book_story['book_title']
            state.author_tips_author_name = author['author_name']
            if (!(library_id in state.series_tips_book_edition_id_dict)) {
              state.author_tips_book_edition_id_dict[library_id] = {}
            }
            state.author_tips_book_edition_id_dict[library_id][book_edition_id] = payload['book_edition_id_list']
        },
        set_series_book_edition_id_list(state, payload) {
            /* 
            Store the result of a query on a series_id. The result contains a list of book_edition_ids
            and the book that triggered the query.

            :param list id_list: a list of book_edition_ids that were returned by the query.
            :param int book_edition_id: the book_edition_id of the book that triggered the call of the query.
            */

            const book_edition_id = payload['book_edition_id']
            const book_story_id = state.book_editions[book_edition_id]['book_story_id']
            const book_story = state.book_stories[book_story_id]
            const series = state.series[book_story['series_id']]
            const library_id = state.active_library_id
            state.series_tips_book_edition_id = payload['book_edition_id_list']
            state.series_tips_book_title = book_story['book_title']
            state.series_tips_series_title = series['series_title']
            if (!(library_id in state.series_tips_book_edition_id_dict)) {
              state.series_tips_book_edition_id_dict[library_id] = {}
            }
            state.series_tips_book_edition_id_dict[library_id][book_edition_id] = payload['book_edition_id_list']
        },
        update_book_similarity_dict(state, payload) {
          /*
          Store the books tips for a book edition.
          
          :param book_edition_id: the book to which the tips are linked.
          :param id_list: a list of book_edition_id of the book edition tips.
          */

          const library_id = state.active_library_id
          const book_edition_id = payload['book_edition_id']
          if (!(library_id in state.book_similarity_dict)) {
            state.book_similarity_dict[library_id] = {}
          }
          state.book_similarity_dict[library_id][book_edition_id] = payload['book_edition_id_list']
        }
    },
    actions: {

        async load_book({commit, getters, rootGetters}, isbn13) {
          /*
          Load book editions given a query.

          :param str jwt: the json web token that authorizes the request.
          :param dict query: the query that is passed to the request.

          :return list book_editions: a list of book products
          */

          if (!isbn13) {
            return null
          }
          
          // Pull from cache
          let book = getters.getBook(isbn13)

          // Download if not found in cache
          if (!book) {
            const json = await get_books(rootGetters.get_jwt, 'products', {
              'select': DEFAULT_SELECT_BOOK_EDITION,
              'isbn13': isbn13
            })
            book = json['books'][0]

            commit("addBook", book)
          }

          return book
        },

        async load_books({commit, getters, rootGetters}, isbn13) {
          /*
          Load book editions given a query.

          :param str jwt: the json web token that authorizes the request.
          :param dict query: the query that is passed to the request.

          :return list book_editions: a list of book products
          */

          if (isbn13.length == 0) {
            return []
          }

          // Parse
          function isArray(obj){
              return !!obj && obj.constructor === Array;
          }
          
          let isbn13_list = isArray(isbn13) ? isbn13 : [isbn13]

          // Check if all requested isbn13 are in cache
          let in_cache = true
          for (let i = 0; i < isbn13_list.length; i++) {
            if (!getters.getBook(isbn13_list[i])) {
              in_cache = false
              break
            }
          }

          // Pull from cache
          let books = []
          if (in_cache) {
            isbn13_list.forEach(isbn13 => { books.push(getters.getBook(isbn13)) })
          } 
          
          // Download from server
          else {
            const json = await get_books(rootGetters.get_jwt, 'products', {
              'select': DEFAULT_SELECT_BOOK_EDITION,
              'isbn13': isbn13
            })
            books = json['books']

            books.forEach(book => commit("addBook", book))
          }

          return books
        },

        async get_book_editions_id_list(state, payload) {
            /*
            Load book products given a query and store the result in the specified list.
            It is used to store results in the author and series book tips list.

            :param str jwt: the json web token that authorize the request.
            :param dict query: the query that we be passed to the request.
            */

            const books = await state.dispatch("get_books", payload)

            // Generate book_edition_id_list for more book editions
            let book_edition_id_list = []
            books.forEach(book => book_edition_id_list.push(book['book_edition_id']))

            // Commit this book_edition_id_list, so that it can be used in the library
            state.commit(payload['mutation'], {
                'book_edition_id': payload['book_edition_id'],
                'book_edition_id_list': book_edition_id_list,
            })
        },
        async get_book_edition_id_list_author(state, payload) {
            /* Load an book_edition_id_list of books of an author. If any of the optional parameters are not
            provided, cached variables will be used.

            :param str jwt: the json web token that is used to authorize the request.
            :param str book_edition_id: (Optional) Used to specify the book that triggered this request.
            :param int school_id: (Optional) The id of the school that owns the books.
            */

            // Get book_edition_id and check if there is any book that could have triggered this request
            const book_edition_id = 'book_edition_id' in payload ? payload['book_edition_id'] : state.state.author_tips_book_edition_id
            if (book_edition_id == "") {
                return
            }

            // Build query
            const select = 'select' in payload ? payload['select'] : DEFAULT_SELECT_BOOK_EDITION;
            let query = {
                'author_id': payload['author_id'],
                'select': select,
                'limit': 10,
                'page': 0
            }
            if ('library_id' in payload) {
              query['library_id'] = payload['library_id']
            }

            // Run request
            state.dispatch("get_book_editions_id_list", {
                'jwt': payload['jwt'],
                'query': query,
                'book_edition_id': book_edition_id,
                'mutation': 'set_author_book_edition_id_list'
            })
        },
        async load_book_edition_id_list_author(state, payload) {
            /* Load an book_edition_id_list of books of an author. If any of the optional parameters are not
            provided, cached variables will be used.

            :param str jwt: the json web token that is used to authorize the request.
            :param str book_edition_id: (Optional) Used to specify the book that triggered this request.
            :param int school_id: (Optional) The id of the school that owns the books.
            */

            // Get book_edition_id and check if there is any book that could have triggered this request
            //const book_edition_id = 'book_edition_id' in payload ? payload['book_edition_id'] : state.state.author_tips_book_edition_id
            //if (book_edition_id == "") {
            //    return
            //}

            
            // check edition_id in author_tips_book_edition_id_list[library_id | 0] ? skip


            // Build query
            let select = DEFAULT_SELECT_BOOK_EDITION
            if ('select' in payload) {
              select = payload['select']
            }
            else if ('library_id' in payload) {
              select = DEFAULT_SELECT_BOOK_EDITION_LIBRARY
            }

            let query = {
                'author_id': payload['author_id'],
                'select': select,
                'limit': 10,
                'page': 0
            }

            if ('library_id' in payload) {
              query['library_id'] = payload['library_id']
            }

            // Run request
            state.dispatch("get_book_editions_id_list", {
                'jwt': payload['jwt'],
                'query': query,
                'book_edition_id': payload['book_edition_id'],
                'mutation': 'set_author_book_edition_id_list'
            })
        },

        async load_book_edition_id_list_series(state, payload) {
            /* Load an book_edition_id_list of books of a series. If any of the optional parameters are not
            provided, cached variables will be used.

            :param str jwt: the json web token that is used to authorize the request.
            :param str book_edition_id: Used to specify the book that triggered this request.
            :param int school_id: (Optional) The id of the school that owns the books.
            */

            // Get isbn13 and check if there is any book that could have triggered this request            
            let select = DEFAULT_SELECT_BOOK_EDITION
            if ('select' in payload) {
              select = payload['select']
            }
            else if ('library_id' in payload) {
              select = DEFAULT_SELECT_BOOK_EDITION_LIBRARY
            }

            let query = {
                'series_id': payload['series_id'],
                'select': select,
                'limit': 10,
                'page': 0
            }

            if ('library_id' in payload) {
              query['library_id'] = payload['library_id']
            }

            // Run request
            state.dispatch("get_book_editions_id_list", {
                'jwt': payload['jwt'],
                'query': query,
                'book_edition_id': payload['book_edition_id'],
                'mutation': 'set_series_book_edition_id_list'
            })
        },
    },
    modules: {
    },
    getters: {

        // Book product
        getBook: (state) =>  (isbn13) => {
          if (!(isbn13 in state.bookDict)) {
            return null
          }
          return state.bookDict[isbn13]
        },

        // Recommendations - TODO: move
        get_series_tips_book_edition_id_dict: state => state.series_tips_book_edition_id_dict,
        get_series_tips_book_title: state => state.series_tips_book_title,
        get_series_tips_series_title: state => state.series_tips_series_title,
        
        get_author_tips_book_edition_id_dict: state => state.author_tips_book_edition_id_dict,
        get_author_tips_book_title: state => state.author_tips_book_title,
        get_author_tips_author_id: state => state.author_tips_author_id,
        get_author_tips_author_name: state => state.author_tips_author_name,

        get_book_similarity_dict: state => state.book_similarity_dict,
    }
})
