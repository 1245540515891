export function isLetters(str) {
    return str.match(/[a-z]/i) != null;
}

export function isNumber(str) {
    return !isNaN(str)
}

export function isZipcode(str) {
    if (str.length != 6) {
        return "Onbekende postcode"
    }

    const numbers = str.slice(0, 4)
    const letters = str.slice(5, 6)
    if (!isNumber(numbers) || !isLetters(letters)) {
        return "Onbekende postcode"
    }
    return null
}

export function isHouseNumber(str) {
    if (str == null || str.length == 0) {
        return "Verplicht veld"
    }
    if (str != null && str.length > 6) {
        return "Te lang"
    }
    return null
}

export function isProvince(str) {
    const province_list = [
        'DRENTHE',
        'FLEVOLAND',
        'FRIESLAND',
        'GELDERLAND',
        'GRONINGEN',
        'LIMBURG',
        'NOORD_BRABANT',
        'NOORD_HOLLAND',
        'OVERIJSSEL',
        'UTRECHT',
        'ZEELAND',
        'ZUID_HOLLAND'
    ]

    if (str == null || !province_list.includes(str)) {
        return "Onbekende provincie"
    }
    return null
}
