<template>
  <oib-content>
    <div v-if="exercise_data">
      <h2>Terugluisteren</h2>
      <div class="wrapper column" style="margin-top: 50px; margin-bottom: 50px;">
        <audio controls>
          <source :src="exercise_data.url_audio" />
        </audio>
        <div class="row" style="margin-top: 30px; margin-bottom: 70px">
          <oib-icon-inline
            style="padding-right: 30px"
            icon="show_chart"
            text="ToDo"
          />
          <oib-icon-inline
            style="padding-right: 30px"
            icon="speed"
            :text="Math.floor(exercise_data.analysis.words_per_minute)"
          />
          <oib-icon-inline
            icon="timelapse"
            :text="exercise_data.analysis.duration + 's'"
          />
        </div>
      </div>
    </div>
  </oib-content>
</template>

<script>
import OibContent from "./OibContent.vue";
import OibIconInline from "./OibIconInline.vue";

export default {
  name: "Analyse",
  props: ["exercise_data"],
  components: {
    OibContent,
    OibIconInline,
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 0;
}

.book-row:hover {
  background-color: #21222f;
}

.book-row-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.next {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #636465;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.subh4 span {
  font-size: 1.3em;
  padding-right: 4px;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
</style>