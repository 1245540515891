<template>
  <oib-form-connect-partner
    :organisation_id="organisation_id"
    :organisation_name="organisation_name"
    @submit="x => submit(x)"
  />
</template>

<script>
import OibFormConnectPartner from "../components/oib_form_connect_partner.vue";

import {post_schools_partners} from "../store/api/user.js"

export default {
  name: "PartnerKoppelen",
  props: ['organisation_id', 'organisation_name'],
  components: {
    OibFormConnectPartner
  },
  data() {
    return {
      init_page_count: "",
      json: null
    }
  },
  methods: {
    async submit(body) {
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt
      const school_id = this.$store.getters.get_school_id_active
      await post_schools_partners(jwt, school_id, body['organisation_id'])

      this.$router.go(-2)
    }
  }
};
</script>
