<template>
  <oib-form-book-vote-cover :isbn13="isbn13" @submit="x => submit(x)"/>
</template>

<script>
import OibFormBookVoteCover from "../components/oib_form_book_vote_cover.vue";

import { post_book_products_users_scrape } from "../store/api/book_scraper"
import { get_book_products_users_scrape_data } from "../store/api/book_scraper"
import { put_book_products_users_scrape_data } from "../store/api/book_scraper"
import { post_book_products_users_scrape_resource } from "../store/api/book_scraper"

export default {
  name: "BoekStemmenKaft",
  props: ['user_id', 'isbn13'],
  components: {
    OibFormBookVoteCover
  },
  data() {
    return {
      json: null
    }
  },
  async created() {
    const jwt = this.$store.getters.get_jwt
    const response = await get_book_products_users_scrape_data(jwt, this.isbn13, this.user_id)
    if (response.status == 200) {
      this.json = await response.json()
    } else {
      this.json = {
        'book_title': null,
        'book_type': null,
        'author_name': null,
        'series_title': null,
        'release_number': null,
        'book_language': null,        
        'dyslexia': null,
        'avi': null,
        'age_min': null,
        'age_max': null,
        'publisher_name': null,
        'binding_method': null,
        'page_count': null,
        'release_date': null,
        'cover': null
      }
    }
  },
  methods: {
    async submit(body) {

      const user = this.$store.getters.getUser
      if (!user) {
        return
      }

      // Skip if body was undefined
      if (!body || !body['cover']) {
        return
      }

      const jwt = this.$store.getters.get_jwt
      
      // Upload book cover
      let cover_url = null
      if (body['cover']) {
        let formData = new FormData();
        formData.append("resource", body['cover']);
        const response = await post_book_products_users_scrape_resource(jwt, this.isbn13, this.user_id, formData)
        const json = await response.json()
        cover_url = json['url']
      }

      // Vote on book      
      await put_book_products_users_scrape_data(
          jwt,
          this.isbn13,
          this.user_id,
          this.json.book_title,
          this.json.book_type,
          this.json.author_name,
          this.json.series_title,
          this.json.release_number,
          this.json.book_language,
          this.json.dyslexia,
          this.json.avi,
          this.json.age_min,
          this.json.age_max,
          this.json.publisher_name,
          this.json.binding_method,
          this.json.page_count,
          this.json.release_date,
          null,
          [],
          cover_url
      )

      post_book_products_users_scrape(jwt, this.isbn13, this.user_id)

      this.$router.back();
    }
  }
};
</script>
