<template>
  <oib-button-icon
  icon="logout"
  style="font-size: 24px;"
  @click="logout"
  />
</template>

<script>
import OibButtonIcon from '../components/OibButtonIcon.vue'

import {logout} from "../store/user.js"

export default {
  name: "OibButtonLogout",
  components: {
    OibButtonIcon
  },
  methods: {
    logout() {
      logout()
    }
  }
};
</script>

<style scoped>
.back-button {
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}
</style>
