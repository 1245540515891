export default {
    state: {
        progress_dict: {},
        progress_state_dict: {},
        isbn13_active: null,
        book_list_reading: [],
        book_dict_reading: {}
    },
    mutations: {
        reset_progress(state, user_id) {
            state.progress_dict[user_id] = {}
        },
        reset_progress_state(state, user_id) {
            state.progress_state_dict[user_id] = {}
            state.progress_state_dict[user_id][0] = new Set(); // WISHLIST
            state.progress_state_dict[user_id][1] = new Set(); // READING
            state.progress_state_dict[user_id][2] = new Set(); // READ
            state.progress_state_dict[user_id][3] = new Set(); // QUIT
            state.progress_state_dict[user_id][4] = new Set(); // REVIEWED
        },
        set_book_user_progress(state, payload) {
            /*
            Set the progress of a user in a book product:

            :param int user_id: the unique id of the user.
            :param int isbn13: the unique isbn13 of the book product.
            :param list progress_list: the progress of the user in the book.
            */

            const user_id = payload.user_id;
            const isbn13 = payload.isbn13
            const progress_list = payload.progress_list

            if (!(user_id in state.progress_dict)) {
                state.progress_dict[user_id] = {}
            }

            state.progress_dict[user_id][isbn13] = progress_list
        },
        set_book_user_progress_state(state, payload) {
            const user_id = payload.user_id;
            const progress_state = payload.progress_state
            const isbn13_set = payload.isbn13_set

            if (!(user_id in state.progress_state_dict)) {
              state.progress_state_dict[user_id] = {}
            }

            state.progress_state_dict[user_id][progress_state] = isbn13_set
        }
    },
    actions: {

    },
    modules: {

    },
    getters: {
        get_progress_dict: state => state.progress_dict,
        get_progress_state_dict: state => state.progress_state_dict,
        get_isbn13_active: state => state.isbn13_active,
        get_book_list_reading: state => state.book_list_reading
    }
}
