import { parse_query } from "../request.js"
import { request_get_json } from "../request.js"

export async function get_books_search(jwt, query) {
  /* Query the book search API. It returns the most read books given a search string.*/

  const query_string = parse_query(query)
  const json = await request_get_json(jwt, `/books/search?${query_string}`)
  return json
}
