import auth from "./auth"
import { parse_query } from "../request.js"
import { request_get_json } from "../request.js"
import { parse_name } from "../user.js"
import { has_role } from "../utils.js"

export default ({
    state: {
        user: undefined,
        userEmail: "",
        users: {},
        students: {},
        organisation: null,
        school: null,
        id_dec: -1,
        organisation_id: null,
        organisation_id_active: null,
        school_id_active: null,

        building_dict: {},
        classroom_dict:  {},
        classroom_dict_active: {},
        group_dict: {},
        group_user_dict: {},
        user_access_dict: {},
        location_filter: {},
        
        location_selector_list: [],
        location_selector_active: 0,
        student_list_sort_mode: 'user_name,ASC'
    },
    mutations: {
        set_student_list_sort_mode(state, sort_mode) {
          state.student_list_sort_mode = sort_mode
        },
        set_location_selector_list(state, location_selector_list) {
          state.location_selector_list = location_selector_list
        },
        set_location_selector_active(state, selector_id) {
          state.location_selector_active = selector_id
        },
        //syncrous
        setUserEmail(state, payload) {
            state.userEmail = payload;
        },
        set_user(state, user) {

            console.log(user)

            // Parse name
            if (!user.user_name) {
              user.user_name = parse_name(user)
            }

            // Generate additional roles
            // if (user.role.includes('BOOKSHOP_OWNER') || user.role.includes('READING_CONSULTANT')) {
            //   user.role.push('LIBRARY_PARTNER')
            // }

            // if (user.role.includes('SYSTEM_SUPPORT')) {
            //   user.role.push('SYSTEM_ADMIN')
            // }

            state.user = user;
            state.users[user.user_id] = user
            // if (user.role.includes('STUDENT')){
            //     state.students[user.user_id] = user
            // }
        },
        reset_users(state) {
            state.users = {};
            state.students = {};
            state.groups = {}
            state.group_dict = {}
            state.group_user_dict = {}
            state.building_dict = {}
            state.classroom_dict = {}
            state.classroom_dict_active = {}
            state.location_filter = {}
            state.school = null
            state.location_selector_list = []
            state.location_selector_active = 0
        },
        resetCurrentUser(state) {
            state.user = undefined
            state.userEmail = ""
        },
        add_user(state, user){
            // Parse name
            if (!user.user_name) {
              user.user_name = parse_name(user)
            }

            state.users[user.user_id] = user
        },
        set_organisation(state, organisation) {
          state.organisation = organisation;
        },
        set_school(state, school) {
          state.school = school;
        },
        set_organisation_id(state, organisation_id) {
          state.organisation_id = organisation_id
        },
        set_organisation_id_active(state, organisation_id) {
          state.organisation_id_active = organisation_id
        },
        set_school_id_active(state, school_id) {
          state.school_id_active = school_id
        },
        add_building(state, building) {
          state.building_dict[building['building_id']] = building
        },
        add_classroom(state, classroom) {
          state.classroom_dict[classroom['classroom_id']] = classroom
        },
        add_classroom_active(state, classroom) {
          state.classroom_dict_active[classroom['classroom_id']] = classroom
        },
        add_group(state, group) {
          state.group_dict[group.group_id] = group
        },
        add_group_user(state, group_user) {
          const user_id = group_user['user_id']
          if (!(user_id in state.group_user_dict)) {
            state.group_user_dict[user_id] = new Set()
          }
          state.group_user_dict[user_id].add(group_user['group_id'])
        },
        compute_user_access_dict(state, user_id) {
          // Building
          const classroom_dict = state.classroom_dict
          const group_dict = state.group_dict

          let building_id_set = new Set()
          let classroom_id_set = new Set()
          let group_id_set = state.group_user_dict[user_id]
          if (!group_id_set) {
            group_id_set = new Set()
          }

          for (let group_id of group_id_set) {
            const classroom_id = group_dict[group_id]['classroom_id']
            const building_id = classroom_dict[classroom_id]['building_id']
            classroom_id_set.add(classroom_id)
            building_id_set.add(building_id)
          }

          state.user_access_dict[user_id] = {
            'building_id_set': building_id_set,
            'classroom_id_set': classroom_id_set,
            'group_id_set': group_id_set,
          }
        },
        set_location_filter(state, location_filter) {
          state.location_filter = location_filter
        }
    },
    actions: {
        async get_group_users(state, payload) {
          if (payload['query']['user_id'] in state.state.group_user_dict) {
            return
          }

          const user_id = payload['query']['user_id']
          const query_string = parse_query(payload['query'])
          const json = await request_get_json(payload['jwt'], `/groups?${query_string}`, undefined)
          const group_list = json['group_list']

          if (group_list.length > 0) {
            json["group_list"].forEach(group => state.commit("add_group_user", {
              'group_id': group['group_id'],
              'user_id':user_id
            }))
          }
        },
    },
    modules: {
        auth
    },
    getters: {
        get_student_list_sort_mode: state => state.student_list_sort_mode,
        getUser: state => state.user,
        getUsers: state => state.users,
        get_school: state => state.school,
        get_building_dict: state => state.building_dict,
        get_building_list: state => Object.keys(state.building_dict).map(x => state.building_dict[x]),
        get_classroom_dict: state => state.classroom_dict,
        get_classroom_list: state => Object.keys(state.classroom_dict).map(x => state.classroom_dict[x]),
        get_classroom_dict_active: state => state.classroom_dict_active,
        get_group_dict: state => state.group_dict,
        get_group_user_dict: state => state.group_user_dict,
        get_user_access_dict: state => state.user_access_dict,
        get_location_filter: state => state.location_filter,
        get_organisation: state => state.organisation,
        get_organisation_id: state => state.organisation_id,
        get_organisation_id_active: state => state.organisation_id_active,
        get_school_id_active: state => state.school_id_active,
        get_location_selector_list: state => state.location_selector_list,
        get_location_selector_active: state => state.location_selector_active,
        getStudents(state) {
            const user_id_list = Object.keys(state.users)
            let students = []

            for (let i = 0; i < user_id_list.length; i++) {
                const user_id = user_id_list[i]
                if (has_role('STUDENT', user_id)) {
                    students.push(user)
                }
            }
            return students
        },
        getRolesDict() {
            return {
                SYSTEM_ADMIN: 'Systeem-Admin',
                SCHOOL_ADMIN: 'School-Admin',
                SUPERVISOR: 'Supervisor',
                LIBRARIAN: 'Bibliothecaris',
                TEACHER: 'Leraar',
                STUDENT: 'Leerling',
              }
        },
        getFiltersDict() {
            return {
                first_name: 'Voornaam',
                last_name: 'Achternaam',
                sex: 'Geslacht',
                role: 'Rollen',
                birthdate: 'Geboorte',
                group_id: 'Groepen',
                account_type: 'Account Type',
                account_email: 'Account Mail',
                contact_email: 'Contact Mail',
                avi: 'AVI-Score',
              }
        },
    }
})
