<template>
  <oib-page-loading :progress="0">
    <div style="max-width: 90%;">
      <p style="text-align: center;">{{text}}</p>
    </div>
  </oib-page-loading>
</template>

<script>
import {accounts_external_microsoft_authenticate} from "../store/user.js"
import {logout} from "../store/user.js"
import {set_cookie} from "../cookie.js"
import {get_cookie} from "../cookie.js"

import OibPageLoading from "../components/oib_page_loading.vue";

export default {
  name: "LoginMicrosoft",
  components: {
    OibPageLoading
  },
  data() {
    return {
      text: 'Login met Microsoft'
    }
  },
  async mounted() {
    console.log('xxxxxxxxx')
    const queryString = window.location.href;
    const id_token = queryString.split('id_token=')[1].split('&')[0]
    this.login(id_token)
  },
  async updated() {
    const queryString = window.location.href;
    const id_token = queryString.split('id_token=')[1].split('&')[0]
    this.login(id_token)
  },
  methods: {
    async login(id_token) {
      const MS_IN_A_DAY = 1000 * 3600 * 24
      const LAST_PROMPT_MS = get_cookie("MICROSOFT_PROMPT_MS")
      const TIME_PROMPT_MS_PASSED = new Date().getTime() - LAST_PROMPT_MS

      if (LAST_PROMPT_MS && TIME_PROMPT_MS_PASSED < MS_IN_A_DAY) {
        const hours_left = ((MS_IN_A_DAY - TIME_PROMPT_MS_PASSED) / 1000 / 3600).toFixed()
        this.text = `Login met Microsoft. Sleutel is ${hours_left} uur geldig.`
      }

      // Authenticate user with identification token returned by Microsoft
      const response = await accounts_external_microsoft_authenticate(id_token)

      // Store user if user was authenticated succesfully
      if (response.status == 200) {
        const json = await response.json()
        this.$store.commit("set_jwt", json['jwt'])
        this.$store.commit("set_user", json['user'])
        this.$store.commit("set_organisation_id", json['organisation_id'])
        this.$store.commit("set_organisation_id_active", json['organisation_id'])

        this.$store.commit("set_login_error_message", "")
        this.$store.commit("set_login_error_instructions", "")

        set_cookie("ACCOUNT_TYPE", 'MICROSOFT', 365)
        set_cookie("ACCOUNT_EMAIL", json['user']['account_email'], 365)

        this.$router.push({
          name: "Laden"
        });
      }
      else if (response.status == 401) {
        const json = await response.json()
        const account_email = json['account_email']

        if (account_email == null) {
          this.$store.commit("set_login_error_message", "De Microsoft sleutel was ongeldig.")
          this.$store.commit("set_login_error_instructions", "<p>Probeer opnieuw in te loggen.</p>")
        } else {
          this.$store.commit("set_login_error_message", `${account_email} is niet gekoppeld.`)
          this.$store.commit(
            "set_login_error_instructions",
            "<p>Dit Microsoft account is niet gekoppeld aan een Onderwijs in Beeld account.</p>\
              \
              <p>Vraag de Onderwijs in Beeld software beheerder op jouw basisschool om hulp.</p>\
              \
              <p>Ben jezelf de beheerder op jouw school?</p>\
              \
              <p>Bel dan naar 085 0509 768 voor een directe verbinding met onze IT-afdeling.</p>"
          )
        }

        logout()
      }
      // TODO: handle other status codes
    }
  }
};
</script>
