<template>
  <oib-page :class="$mq">
    <oib-content v-if="$mq == 'desktop'" class='book-background' :style="style">
      <div class="wrapper row wide" style="align-items: flex-start">
        <img
        :src="cover_url"
        class="fade"
        :onload="onload"
        :style="cover_style"
        />
        <slot name="right"></slot>
      </div>
      <slot name="bottom"></slot>
    </oib-content>

    <oib-content v-if="$mq == 'mobile'" class="book-background" style="padding-top: 0;">
      <oib-book-header v-if="isbn13" :isbn13="isbn13" />
      <slot name="right"></slot>
      <slot name="bottom"></slot>
    </oib-content>
    <div style="background-color: var(--primary-color); height: 100px; width: 100%; z-index: 2"/>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibBookHeader from "../components/OibBookHeader.vue";

import {get_book_cover} from "../store/api/library.js"

export default {
  name: "Boek",
  props: ['isbn13'],
  components: {
    OibPage,
    OibContent,
    OibBookHeader,
  },
  computed: {
    book_product() {
      return this.$store.getters.getBook(this.isbn13);
    },
    cover_url() {
      return get_book_cover(this.isbn13, 'l')
    },
    style () {
      if (!this.book_product) {
        return ""
      }

      const color = this.book_product['color'] ? `#${this.book_product['color']}` : 'var(--highlight-color)';
      return {
        '--book-color': color
      }
    }
  },
  data() {
    return {
      rating: 4,
      summary: "",
      cover_style: ""
    };
  },
  mounted() {
    this.cover_style = 'opacity: 0'
  },
  methods: {
    onload() {
      this.cover_style = 'opacity: 1'
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.desktop .book-background {
  padding-top: 40px;
  background: rgb(27,23,37);
  background: linear-gradient(0deg, rgba(27,23,37,1) 0%, var(--book-color) 100%);
  background-size: 100% 550px;
  background-repeat: no-repeat;
}

.desktop .book-background img {
  margin-right: 5%;
  width: 35%;
  height: auto;
  border-radius: 10px;
  box-shadow: 15px 14px 31px -13px var(--book-color);
  -webkit-box-shadow: 15px 14px 31px -13px var(--book-color);
  -moz-box-shadow: 15px 14px 31px -13px var(--book-color);
}

.wrapper.wide {
  width: 90%;
  max-width: 1200px;
}

.book-details {
  display: flex;
  flex-direction: column;
}

.book-details {
  text-align: center;
  height: 45px;
  justify-content: space-between;
  width: 100%;
}

.book-details p {
  margin: 0;
}

.row,
.details {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rating {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile .rating span {
  font-size: 24px;
}

.lightgrey {
  color: var(--contrast-color-p3);
}

.mobile .subtext {
  font-size: 14px;
  line-height: 1.5em;
  justify-content: space-between;
}
.desktop .subtext {
  font-size: 16px;
  line-height: 1.5em;
  justify-content: space-between;
}

.subtext >>> h1, .subtext >>> h2 {
  font-size: 18px;
}

h1 {
  margin: 0;
}

.important-info p {
  padding-right: 20px;
}

.sub-title {
  font-size: 20px;
}
</style>
