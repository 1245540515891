import { request_post } from "../request.js"
import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"

export async function post_users_books_assist(jwt, user_id_listen, isbn13, user_id_assist, is_accepted, assist_type, assist_datetime) {
  /*
  Add a user_book_assist data point. How did was the user assisted with its book choice?

  :param str jwt: the json web token
  :param int user_id_listen: the id of the user that was helped.
  :param str isbn13: the isbn13 of the book.
  :param int user_id_assist: the user_id of the assistent
  :param bool is_accepted: whether the advice was accepted
  :param str assist_type: DUPLICATE | SERIES_FIRST_ONLY | SERIES_ORDER | NOT_FINISHED
  :param str assist_datetime: the datetime that the user was assisted
  */
  const request_path = `/users/${user_id_listen}/books/${isbn13}/assist`
  return await request_post(jwt, request_path, {
    'user_id_assist': user_id_assist,
      'is_accepted': is_accepted,
      'assist_type': assist_type,
      'assist_datetime': assist_datetime
  })
}

export async function get_users_books_assist_query(jwt, query) {
  /* Query the scrape metadata
  
  :param str jwt: the json web token

  :optional
  :param int user_id_listen: the id of the user that was helped.
  :param str isbn13: the isbn13 of the book.
  :param int user_id_assist: the user_id of the assistent
  :param bool is_accepted: whether the advice was accepted
  :param str assist_type: DUPLICATE | SERIES_FIRST_ONLY | SERIES_ORDER | NOT_FINISHED
  :param str assist_datetime: the datetime that the user was assisted
  */

  const query_string = parse_query(query)
  const request_path = `/users/books/assist?${query_string}`
  return await request_get_json(jwt, request_path, undefined)
}
