<template>
  <oib-barcode-reader-book @decode="onDecode"/>
</template>

<script>
import OibBarcodeReaderBook from "../components/oib_barcode_reader_book.vue";

import {pick_book} from "../store/library"
import { go_to_route } from "../store/navigation";

export default {
  name: 'BoekScanKiezen',
  components: { 
    OibBarcodeReaderBook,
  },
  methods: {
    async onDecode(result) {

      const user = this.$store.getters.getUser
      const user_id = user['user_id']
      const isbn13 = result['isbn13']

      await this.$store.dispatch("load_book", isbn13)
      const response = await pick_book(user_id, isbn13)

      // Stay in this screen if no copy was available
      if (response == 404 || response == 401) {
        return
      }

      go_to_route('BibliotheekCollectie')
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
}
</script>
