<template>
  <div class="button-icon clickable" :class="direction">
    <span :class="icon_class" style="margin-bottom: 1px">{{
      icon
    }}</span>
    <h4 class="title center-text">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  name: "OibButtonIcon",
  props: ["icon", "title", "direction", "active"],
  computed: {
    icon_class() {
      return this.active ? "material-icons" : "material-icons-outlined";
    }
  }
};
</script>

<style scoped>
.button-icon {
  display: flex;
  align-items: center;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
}

.button-icon.column {
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.button-icon.row {
  flex-direction: row;
  justify-content: left;
}

.button-icon.column .title {
  font-size: inherit;
  font-weight: 700;
  margin-top: 0;
}

.button-icon.row .title{
  font-size: inherit;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0 0 0 15px;
}

.button-icon.column span {
  width: 100%;
  text-align: center;
}
</style>
