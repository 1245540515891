<template>
  <oib-page>
    <oib-nav-student title="Meldingen" :allowLogout="true"/>
    <oib-content>
      <div class="wrapper column center" style="height: 85vh;">
        <div>
          <p>Geen meldingen</p>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"

export default {
  name: "Opdracht",
  components: {
    OibPage,
    OibContent,
    OibNavStudent
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
