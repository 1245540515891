<template>
  <oib-page v-if="library_book_loaded">
    <oib-nav-student title="Bieb kiezen" :show_backdrop="true">
      <!-- <template v-slot:left>
        <oib-button-back :has_backdrop="true" @click="on_back_button_pressed"/>
      </template> -->
      <template v-slot:right v-if="can_move">
        <oib-button-icon
          :icon="action == 'lenen' ? 'drive_file_move' : 'login'"
          @click="toggle_action"
          style="font-size: 24px;"
          :has_backdrop="true"
        />
      </template>
    </oib-nav-student>
    <oib-modal ref="access_hint">
      <div class="wrapper column" style="max-width: 400px; padding-bottom: 30px">
        <oib-button-icon icon="drag_handle" style="font-size: 30px; padding: 0; color: var(--contrast-color-p2)"/>
        <h2 class="h2">Leerling heeft geen toegang</h2>
        <p style="margin: 2px 0">Wil je alle leerlingen uit {{ classroom_name }} toegang geven tot deze bibliotheek?</p>
        <p style="font-size: 0.8em">Navigeer als biebbeheerder naar het bibliotheek menu en klik linksboven op <span class="material-icons" style="font-size: 1em;">settings</span>. Klik vervolgens op "Toegang beheren" om leerlingen toegang tot de bieb te geven.</p>
        <a
        style="font-size: 0.8em"
        href="https://leesapp.onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#toegang_beheren"
        target="_blank"
        >Bekijk de handleiding ➜</a>
      </div>
    </oib-modal>
    <oib-modal ref="access_hint_independence">
      <div class="wrapper column" style="max-width: 400px; padding-bottom: 30px">
        <oib-button-icon icon="drag_handle" style="font-size: 30px; padding: 0; color: var(--contrast-color-p2)"/>
        <h2 class="h2">Je mag niet zelfstandig lenen</h2>
        <p style="margin: 2px 0">Wil je alle leerlingen uit {{ classroom_name }} toestemming geven om wel zelfstandig boeken te lenen?</p>
        <p style="font-size: 0.8em">Navigeer als biebbeheerder naar het bibliotheek menu en klik linksboven op <span class="material-icons" style="font-size: 1em;">settings</span>. Klik vervolgens op "Zelfstandig lenen beheren" om leerlingen de rechten te geven om zelfstandig boeken te lenen.</p>
        <a
        style="font-size: 0.8em"
        href="https://leesapp.onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#zelfstandig_lenen_beheren"
        target="_blank"
        >Bekijk de handleiding ➜</a>
      </div>
    </oib-modal>
    <oib-modal ref="access_hint_library_assistent">
      <div class="wrapper column" style="max-width: 400px; padding-bottom: 30px">
        <oib-button-icon icon="drag_handle" style="font-size: 30px; padding: 0; color: var(--contrast-color-p2)"/>
        <h2 class="h2">Je mag geen boeken lenen</h2>
        <p style="margin: 2px 0">Biebassistenten hebben geen permissie om zelf boeken te lenen. Leerlingen, leerkrachten en biebbeheerders mogen dit wel.</p>
        <p style="font-size: 0.8em">Navigeer als schoolbeheerder naar de "leerkrachten" tab en wijzig de permissies van deze gebruiker.</p>
        <a
        style="font-size: 0.8em"
        href="https://leesapp.onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding/#de_schoolbeheerder"
        target="_blank"
        >Bekijk de handleiding ➜</a>
      </div>
    </oib-modal>
    <oib-modal ref="access_hint_reading_consultant">
      <div class="wrapper column" style="max-width: 400px; padding-bottom: 30px">
        <oib-button-icon icon="drag_handle" style="font-size: 30px; padding: 0; color: var(--contrast-color-p2)"/>
        <h2 class="h2">Je mag geen boeken lenen</h2>
        <p style="margin: 2px 0">Leesconsulenten hebben geen permissie om zelf boeken te lenen. Leerlingen, leerkrachten en biebbeheerders mogen dit wel.</p>
      </div>
    </oib-modal>
    <oib-content class="book-background" :class="$mq" :style="style">

      <div style="margin-top: 75px;">
        
        <div class="wrapper column" style="margin-top: -40px; max-width: 600px">
          <div :style="`background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.0) 50%, rgba(0, 0, 0, 0.73) 100%), url(${library_img_url});`" class="library-image">
            <h1 style="font-size: 28px;">{{library_header}}</h1>
          </div>
          <div v-if="library_id_list.length == 0">
            <h3>Klas toegang geven tot bieb</h3>
            <p style="font-size: 0.8em">Navigeer als biebbeheerder naar het bieb menu en klik linksboven op <span class="material-icons" style="font-size: 1em;">settings</span>. Klik vervolgens op "Toegang beheren" om deze leerling toegang te geven.</p>
          </div>
          <div v-if="library_book_loaded && library_book_list.length == 0">
            <h3>Geen boek gevonden in de bieb</h3>
            <p style="font-size: 0.8em">Dit boek werd niet gevonden omdat het boek nog niet aan de bieb is toegevoegd of omdat de gebruiker geen toegang heeft tot die bieb.</p>
            <p style="font-size: 0.8em">Voeg het boek toe of navigeer als biebbeheerder naar het bieb menu en klik linksboven op <span class="material-icons" style="font-size: 1em;">settings</span>. Klik vervolgens op "Toegang beheren" om deze leerling toegang te geven.</p>
          </div>
          <div v-if="library_book_loaded && library_book_list.length > 0 && isbn13_scanned != 'null' && !isbn13_scanned_in_library" style="margin-bottom: 20px;">
            <h3>Isbn niet gevonden in bieb</h3>
            <p style="font-size: 0.8em">Het isbn dat je hebt gescand staat niet in de bieb omdat het boek nog niet aan de bieb is toegevoegd of omdat de gebruiker geen toegang heeft tot die bieb.</p>
          </div>
          <div
          v-if="library_book_list"
          >
            <oib-list-item-library-book
              v-for="library_book in library_book_list"
              :key="library_book.library_id"
              :img_url="get_image_url(library_book.library_id)"
              :library_id="library_book.library_id"
              :copies_in_use="library_book.copies_in_use"
              :copies_total="library_book.copies_total"
              :book_list="library_book.book_list"
              :active="library_id_select == library_book.library_id"
              :button_text="action == 'lenen' ? 'Lenen' : can_move && action == 'verhuizen' ? 'Verhuizen' : null"
              :can_borrow="can_borrow"
              :isbn13="isbn13_scanned"
              :user_id="user_id"
              :has_access="library_id_access_list.includes(library_book.library_id)"
              @click_foldable="select(library_book.library_id)"
              @button_clicked="isbn13 => button_clicked(isbn13)"
            />
            <p style="text-align: center; font-size: 0.8em">Of kies je eigen boek</p>
            <oib-list-item-library-book
              :img_url="get_image_url(0)"
              :library_id="0"
              :copies_in_use="0"
              :copies_total="1"
              :book_list="book_list"
              :active="library_id_select == 0"
              :button_text="action == 'lenen' ? 'Lenen' : null"
              :can_borrow="can_borrow"
              :isbn13="isbn13_scanned"
              :user_id="user_id"
              :has_access="true"
              @click_foldable="select(0)"
              @button_clicked="isbn13 => button_clicked(isbn13)"
            />
          </div>
        </div>
      </div>
      <div
        v-if="can_view_logs && log_list"
        class="wrapper column"
        style="max-width: 600px; margin-top: 40px;"
      >
        <h2>Logboek</h2>
        <div>
          <oib-list-item-library-user-log
          v-for="log in log_list"
          :key="log"
          :library_id="log.library_id"
          :isbn13="log.isbn13"
          :user_id="log.user_id"
          :user_name="log.user_name ? log.user_name : 'Verwijderd'"
          :classroom_name="log.classroom_name"
          :borrowed_datetime="log.borrowed_datetime"
          :returned_datetime="log.returned_datetime"
          />
        </div>
      </div>
      <div style="margin-bottom: 100px;"/>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue"
import OibContent from "../components/OibContent.vue"
import OibNavStudent from "../components/OibNavStudent.vue"
import OibListItemLibraryBook from "../components/oib_list_item_library_book.vue"
import OibListItemLibraryUserLog from "../components/oib_list_item_library_user_log.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibModal from "../components/oib_modal.vue"

import {get_bucket} from "../store/bucket.js"
import {get_books} from "../store/api/library.js"
import {get_libraries_books_copies} from "../store/api/library.js"
import {get_libraries_books_users_logs} from "../store/api/library.js"
import { has_role } from "../store/utils.js"

export default {
  name: "BoekBiebKiezen",
  props: {
    user_id: {
      required: true
    },
    show: {
      type: Boolean,
      default: false
    },
    physical_access_only: {
      type: Boolean,
      default: false
    },
    book_edition_id: {
      required: true
    },
    isbn13_scanned: {
      required: true
    },
    action: {
      required: true
    },
    fast_borrow: {
      type: Boolean,
      default: false
    }
  },
  emits: ["input"],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibListItemLibraryBook,
    OibListItemLibraryUserLog,
    OibButtonIcon,
    OibModal
  },
  computed: {
    classroom_name() {
      const user_access_dict = this.$store.getters.get_user_access_dict
      if (this.user_id in user_access_dict && user_access_dict[this.user_id].classroom_id_set.size > 0) {
        const classroom_id = [...user_access_dict[this.user_id].classroom_id_set][0]
        const classroom_dict = this.$store.getters.get_classroom_dict
        if (classroom_id in classroom_dict) {
          return classroom_dict[classroom_id]['classroom_name']
        }
      }

      return 'de groep van de leerling'
      
    },
    library_header() {
      if (!this.library_book_loaded) {
        return ""
      }

      if (this.isbn13_scanned != 'null' && !this.isbn13_scanned_in_library) {
        return "Geen kopie gevonden"
      } else if (this.isbn13_scanned != 'null' && !this.isbn13_scanned_available) {
        return "Geen kopie beschikbaar"
      } else if (this.library_id_list.length == 0) {
        return "Geen bieb gevonden"
      } else if (this.library_book_list.length == 0) {
        return "Geen kopie beschikbaar"
      } else {
        return "Bieb kiezen"
      }
    },
    book_product() {
        return this.$store.getters.getBook(this.isbn13_scanned);
    },
    style () {
      if (!this.book_product) {
        return {
          '--book-width': '90px'
        }
      }

      const color = this.book_product['color'] ? `#${this.book_product['color']}` : 'var(--highlight-color)';
      return {
        '--book-color': color,
        '--book-width': '90px'
      }
    }
  },
  data() {
    return {
      cover_url: "https://us.123rf.com/450wm/teploleta/teploleta1801/teploleta180100105/94540385-seamless-vector-pattern-with-doodle-questions-marks-on-a-blackboard-background.jpg?ver=6",
      book_list: [],
      library_id_list: [],
      library_id_access_list: [],
      library_id_select: -1,
      library_dict: {},
      library_book_list: [],
      library_book_json: {},
      library_book_select: null,
      library_book_loaded: false,
      library_product_metric_list: [],
      isbn13_list: [],
      isbn13_clicked: null,
      isbn13_0: null,
      isbn13_1: null,
      max_section_length: 0,
      can_borrow: false,
      can_move: false,
      log_list: [],
      library_img_url: "",
      is_librarian: false,
      can_view_logs: false,
      library_id_old: null,
      isbn13_scanned_in_library: false,
      isbn13_scanned_available: false,
      series_id: null,
      series_title: null,
      release_number: null,
      release_number_max: null,
      release_number_target: null,
      read_order_type: null,
      copies_total_1: null,
      copies_in_use_1: null,
      copies_total_2: null,
      copies_in_use_2: null,
      assist_type: null,
      assist_pending: false
    }
  },
  async created() {

    // Ignore on refresh
    const user = this.$store.getters.getUser
    if(!user) {
      return
    }

    this.library_img_url = this.get_image_url(this.library_id_select)
    this.is_librarian = has_role(
      ['LIBRARY_ASSISTENT', 'LIBRARIAN', 'LIBRARY_PARTNER', 'SYSTEM_SUPPORT', 'SYSTEM_ADMIN'],
      user['role']
    )
    this.can_view_logs = has_role(
      ['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SYSTEM_SUPPORT', 'SYSTEM_ADMIN'],
      user['role']
    )

    this.library_dict = this.get_library_dict()
    this.library_id_select = -1

    this.library_id_list = []
    this.library_id_access_list = []

    // Which libraries should we query?
    if (has_role(['SYSTEM_SUPPORT', 'SYSTEM_ADMIN', 'LIBRARY_PARTNER'])) {
      Object.keys(this.library_dict).forEach(x => {
        if (x != '0') {
          this.library_id_list.push(parseInt(x))
        }
      })
    }
    else if (this.user_id != user.user_id) {
      this.library_id_list = this.get_library_access_list(user.user_id)
    }
    else {
      this.library_id_list = this.get_library_access_list(this.user_id)
    }

    // Which libraries are accesible?
    if (this.user_id == user.user_id && has_role(['SYSTEM_SUPPORT', 'SYSTEM_ADMIN', 'LIBRARY_PARTNER'], user['role'])) {
      this.library_id_access_list = this.library_id_list
    }
    else if (this.user_id != user.user_id) {
      this.library_id_access_list = this.get_library_access_list(this.user_id)
    }
    else {
      this.library_id_access_list = this.get_library_access_list(this.user_id)
    }

    // Get isbn13 given a book_edition_id
    const jwt = this.$store.getters.get_jwt
    let json = await get_books(jwt, 'products', {
      'select': 'isbn13,series_id,series_title,release_number,read_order_type',
      'book_edition_id': this.book_edition_id,
      'limit': 100,
      'page': 0
    })
    json['books'].forEach(x => {
      this.isbn13_list.push(x['isbn13'])
      this.series_id = x['series_id']
      this.series_title = x['series_title']
      this.release_number = x['release_number']
      this.read_order_type = x['read_order_type']
    })

    this.library_product_metric_list = await this.load_library_product_metric_list(this.isbn13_list)

    if (this.can_view_logs) {
      this.load_logs()
    }

    // Adults all have the permission to borrow
    const user_id_self = this.$store.getters.getUser['user_id']
    if (has_role(['TEACHER', 'LIBRARIAN', 'SYSTEM_SUPPORT', 'SYSTEM_ADMIN'])) {
      this.can_borrow = true
    }

    // Library assistent can only borrow for others
    else if (has_role(['LIBRARY_ASSISTENT', 'READING_CONSULTANT']) && this.user_id != user_id_self.toString()) {
      this.can_borrow = true
    } else {
      this.can_borrow = false
    }

    // Students can only borrow on their own if the classroom has the permissions set
    if (this.user_id == user.user_id && has_role(['STUDENT'])) {
      const classroom_settings_dict = this.$store.getters.get_classroom_borrow_independence
      const group_dict = this.$store.getters.get_group_dict
      const group_user_dict = this.$store.getters.get_group_user_dict
      const group_id_list = group_user_dict[this.user_id]
      let classroom_id_set = new Set()
      group_id_list.forEach(group_id => classroom_id_set.add(group_dict[group_id].classroom_id))
      for (let classroom_id of classroom_id_set) {
        if (classroom_id in classroom_settings_dict && classroom_settings_dict[classroom_id]) {
          this.can_borrow = true
          break
        }
      }
    }

    if (this.user_id == this.$store.getters.getUser.user_id && this.is_librarian) {
      this.can_move = true
    }

    // Get copies that user can borrow
    let copies_available_list = []
    for (let i = 0; i < this.library_product_metric_list.length; i++) {
      const metric = this.library_product_metric_list[i]
      const is_scanned = this.isbn13_scanned == metric.isbn13
      const has_access = this.library_id_access_list.includes(metric.library_id)
      const is_available = metric.copies_in_use < metric.copies_total
      if (is_scanned && has_access && is_available) {
        copies_available_list.push(metric)
      }
    }

    // Borrow book immediately if the right conditions are met
    if (this.fast_borrow && this.can_borrow && copies_available_list.length == 1) {
      this.library_id_select = copies_available_list[0].library_id
      this.$store.commit('set_active_library_id', this.library_id_select) // TODO: This is very ugly
      this.button_clicked(copies_available_list[0].isbn13, true)
      return
    }

    // Init school and home library tabs
    this.compute_library_book_list(this.library_product_metric_list)
    this.init_home_library_section(this.isbn13_list)
  },
  methods: {
    get_library_access_list(user_id) {
      const library_id_set = this.$store.getters.get_user_library_dict[user_id]
      if (library_id_set && library_id_set.size > 0) {
        return [...library_id_set]
      } else {
        return []
      }
    },
    toggle_action() {
      if (this.action == 'lenen') {
        this.$router.replace({
          name: "BoekBiebKiezen",
          params: {
            user_id: this.user_id,
            book_edition_id: this.book_edition_id,
            isbn13_scanned: this.isbn13_scanned,
            action: 'verhuizen'
          }
        });
      } else {
        this.$router.replace({
          name: "BoekBiebKiezen",
          params: {
            user_id: this.user_id,
            book_edition_id: this.book_edition_id,
            isbn13: this.isbn13_scanned,
            action: 'lenen'
          }
        });
      }
    },
    async load_library_product_metric_list(isbn13_list) {

      let library_product_metric_list = []
      const isbn13_set = new Set(isbn13_list)

      // Gather book availablity in libraries
      if (this.library_id_list.length > 0) {
        const json = await get_libraries_books_copies(this.$store.getters.get_jwt, {
          'select': 'copy_id,library_id,isbn13,user_id',
          'library_id': this.library_id_list.join(','),
          'isbn13': this.isbn13_list.join(','),
          'limit': 1000000,
          'page': 0
        })

        // Compute local library_product_metrics
        let metric_dict = {}
        for (let i = 0; i < json['copy_list'].length; i++) {
          const copy = json['copy_list'][i]
          const key = copy['library_id'] + '-' + copy['isbn13']
          if (!(key in metric_dict)) {
            metric_dict[key] = {
              'library_id': copy['library_id'],
              'isbn13': copy['isbn13'],
              'copies_total': 1,
              'copies_in_use': copy['user_id'] == null ? 0 : 1
            }
          } else {
            metric_dict[key]['copies_total'] = metric_dict[key]['copies_total'] + 1
            metric_dict[key]['copies_in_use'] = metric_dict[key]['copies_in_use'] + (copy['user_id'] == null ? 0 : 1)
          }
        }

        // Filter by isbn13
        Object.keys(metric_dict).forEach(key => {
          const metric = metric_dict[key]
          if (isbn13_set.has(metric['isbn13'].toString())) {
            library_product_metric_list.push(metric)
            if (metric['isbn13'].toString() == this.isbn13_scanned) {
              this.isbn13_scanned_in_library = true
              if (metric['copies_in_use'] < metric['copies_total']) {
                this.isbn13_scanned_available = true
              }
            }
          }        
        })
      }

      return library_product_metric_list
    },
    async compute_library_book_list(library_product_metric_list) {

      // Init library book dict
      let library_book_list = []
      let library_book_dict = {}
      if (library_product_metric_list.length > 0) {
        for (let i = 0; i < library_product_metric_list.length; i++) {
          const library_book = library_product_metric_list[i]
          library_book_dict[library_book['library_id']] = {
            'library_id': library_book['library_id'],
            'library_name': this.$store.getters.get_library_dict[library_book['library_id']].library_name,
            'copies_total': 0,
            'copies_in_use': 0,
            'book_list': []
          }
        }

        // Increment copy availability
        library_product_metric_list.forEach(x => {
            const copies_total = library_book_dict[x['library_id']]['copies_total'] + x['copies_total']
            const copies_in_use = library_book_dict[x['library_id']]['copies_in_use'] + x['copies_in_use']
            this.copies_total_2 += copies_total
            this.copies_in_use_2 += copies_in_use
            library_book_dict[x['library_id']]['copies_total'] = copies_total
            library_book_dict[x['library_id']]['copies_in_use'] = copies_in_use
            library_book_dict[x['library_id']]['book_list'].push(x)
        })

        // Show libraries with access first
        Object.keys(library_book_dict).forEach(library_id => {
            if (this.library_id_access_list.includes(parseInt(library_id))) {
              library_book_list.push(library_book_dict[library_id])
            }            
        })
        // Show libraries without access last
        Object.keys(library_book_dict).forEach(library_id => {
            if (!this.library_id_access_list.includes(parseInt(library_id))) {
              library_book_list.push(library_book_dict[library_id])
            }            
        })
      }

      // Compute section length
      let max_book_list_length = 0
      library_book_list.forEach(x => max_book_list_length = Math.max(max_book_list_length, x.book_list.length))
      this.max_section_length = library_book_list.length * 80 + max_book_list_length * 60;

      this.library_book_list = library_book_list
      this.library_book_loaded = true
    },
    init_home_library_section(isbn13_list) {
      this.book_list = []
      isbn13_list.forEach(isbn13 => this.book_list.push({
        'isbn13': isbn13,
        'library_id': 0,
        'copies_total': 1,
        'copies_in_use': 0,
      }))
    },
    async load_logs() {
      const jwt = this.$store.getters.get_jwt
      const json = await get_libraries_books_users_logs(jwt, {
        'library_id': this.library_id_list.join(','),
        'isbn13': this.isbn13_list.join(','),
        'limit': 30
      })
      this.log_list = json['log_list']
    },
    async button_clicked(isbn13, auto_click=false) {
      this.isbn13_clicked = isbn13
      if (this.can_move && this.action == 'verhuizen') {
        this.$router.push({
          name: 'BibliotheekBoekVerplaatsen',
          params: {
            'user_id': this.user_id
          },
          query:{
            'library_id': this.$store.getters.get_active_library_id,
            'isbn13': this.isbn13_clicked
          }
        });
      } else if (this.action == 'lenen') {
        if (!this.can_borrow) {
          const user_id_self = this.$store.getters.getUser['user_id']
          if (has_role(['LIBRARY_ASSISTENT']) && this.user_id == user_id_self.toString()) {
            this.$refs.access_hint_library_assistent.show()
          } else if (has_role(['READING_CONSULTANT']) && this.user_id == user_id_self.toString()) {
            this.$refs.access_hint_reading_consultant.show()
          } else {
            this.$refs.access_hint_independence.show()
          }          
        }
        else if (this.library_id_select == 0 || this.library_id_access_list.includes(this.library_id_select)) {
          const params = {
            'user_id': this.user_id,
            'library_id': this.library_id_select,
            'isbn13': this.isbn13_clicked,
            'route_count': auto_click ? 2 : 3
          }
          if (auto_click) {
            this.$router.replace({name: 'BoekLenen', params: params})
          } else {
            this.$router.push({name: 'BoekLenen', params: params})
          }
          
        }
        else {
          this.$refs.access_hint.show()
        }
      }
    },
    get_image_url(library_id) {
      return `${get_bucket('media')}/libraries/l/${library_id}.webp`
    },
    get_library_dict() {
      const library_dict = this.$store.getters.get_library_dict
      const library_id_list = Object.keys(library_dict)
      
      // Set default home
      this.library_dict = {}
      this.library_dict[0] = {
        'library_id': 0,
        'library_name': 'Thuis'
      }

      for (let i = 0; i < library_id_list.length; i++) {
        const library_id = library_id_list[i]
        this.library_dict[library_id] = {
          'library_id': library_id,
          'library_name': library_dict[library_id].library_name
        }
      }

      return this.library_dict
    },
    select(library_id) {
      // this.library_book_select = library_book

      if (this.library_id_select == library_id) {
        // Deactivate library selection - the image should remain
        this.library_id_select = -1
        this.library_img_url = this.get_image_url(this.library_id_select)
      } else {
        this.library_img_url = this.get_image_url(library_id)
        // Wait for animation
        setTimeout(() => {
          this.library_id_select = library_id
          this.$store.commit('set_active_library_id', this.library_id_select)
        }, 200)
      }
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}

.library-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
  height: 30vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 40px;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34);
}

.desktop.book-background {
  padding-top: 40px;
  background: rgb(27,23,37);
  background: linear-gradient(0deg, rgba(27,23,37,1) 0%, var(--book-color) 100%);
  background-size: 100% 550px;
  background-repeat: no-repeat;
}
</style>
