<template>
  <div>
    <oib-header title="Log in met boekenlegger" :show_back="true" style="z-index: 100" />
    <div class="fullscreen" ref="wrapper">
      <barcode-reader-advanced @decode="onDecode" format="qr_code"/>
      <div v-if="validationFailure" class="validation-failure">
        Geen leerling gevonden
      </div>
    </div>
  </div>
</template>

<script>
import BarcodeReaderAdvanced from "../components/barcode_reader_advanced3.vue";
import OibHeader from "../components/OibHeader.vue"

import { get_users_query } from '../store/api/user.js'

export default {
  name: "OverzichtLeerlingenQrCode",
  components: { 
    OibHeader,
    BarcodeReaderAdvanced
  },

  data () {
    return {
      isValid: undefined,
      camera: 'auto',
      decoding: false
    }
  },

  computed: {
    validationPending () {
      return this.isValid === undefined
        && this.decoding === true
    },
    validationSuccess () {
      return this.isValid === true
    },
    validationFailure () {
      return this.isValid === false
    }
  },

  methods: {
    async onDecode (result) {
      this.decoding = true

      // Parse query
      const jwt = this.$store.getters.get_jwt
      const location_filter = this.$store.getters.get_location_filter
      let query = {}
      query['id_token'] = result.barcode

      if ('school_id' in location_filter) query['school_id'] = location_filter['school_id']
      if ('building_id' in location_filter) query['building_id'] = location_filter['building_id']
      if ('classroom_id' in location_filter) query['classroom_id'] = location_filter['classroom_id']
      
      // Get user given id_token
      const response = await get_users_query(jwt, query)

      // Display error if request was not valid
      if (response.status != 200) {
        this.isValid = false
        await this.timeout(1250)
        this.$router.back()
        return
      }

      // Display error if user was not found
      const json = await response.json()
      if (json['user_list'].length != 1) {
        this.isValid = false
        await this.timeout(1250)
        this.$router.back()
        return
      }

      // Go to student overview
      const user_id = json['user_list'][0]['user_id']
      this.$router.push({
        name: "OverzichtLeerling",
        params: {
          user_id: user_id
        },
      });
    },
    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    beforeCreate() {
      if (!this.$store.getters.getActiveSession) {
        this.$router.push({
          name: "Login",
        });
      }
    },
  },
}
</script>

<style scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}
.validation-success {
  color: var(--highlight-color);
}
.validation-failure {
  color: red;
}

.fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}
</style>