export default {
    state: {
        exerciseIsbn13: 0,
        exercises: {}
    },
    mutations: {
        setExerciseIsbn13(state, isbn13) {
            state.exerciseIsbn13 = isbn13;
        },
        attachExercisesToUser(state, payload) {

            const user_id = payload.user_id
            const exercises = payload.exercises

            state.exercises[user_id] = exercises;
            state.exercises[user_id].sort((a, b) => (b.datetime - a.datetime ? 1 : -1));
        }
    },
    actions: {
        async loadExercises(state, payload) {

            const jwt = payload.jwt
            const user_id = payload.user_id

            // Get all books from the school library
            const url = new URL(window.location.href);
            const request =
                url.protocol + "//" + url.hostname + "/users/" + user_id + "/exercises";

            const headers = {
                method: 'GET',
                headers: new Headers({
                    'Authorization': jwt,
                }),
            };

            const response = await fetch(request, headers)
            const response_json = await response.json();

            state.commit("attachExercisesToUser", {
                exercises: response_json.exercises,
                user_id: user_id
            });
        },
    },
    modules: {

    },
    getters: {
        getExerciseIsbn13: state => state.exerciseIsbn13,
        getExercises: state => state.exercises
    }
}
