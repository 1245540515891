<template>
  <div class="column">
    <label>{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
    <p v-if="description" class="description">{{description}}</p>
    <div ref="drop_area" class="drop-area" :class="dimensions" @click="on_click()">
      <div v-if="file == null" style="pointer-events: none; text-align: center;">
        <p class="material-icons-outlined" style="font-size: 48px; margin-bottom: 0;">photo</p>
        <p>Upload foto</p>
      </div>
      <canvas v-if="file != null" ref="canvas" width=100% height=280 style="border-radius: 4px;"></canvas>
    </div>
    <!-- <canvas ref="canvas" width=100% height=280></canvas> -->
    <input type="file" style="visibility: hidden" ref="input_file" @change="on_file_change">
  </div>
</template>

<script>
export default {
  name: "OibInputText",
  props: {
    label: {
      type: String,
      required: true
    },
    init_value: {
      type: String
    },
    description: {
      type: String,
      default: null
    },
    required: {
      default: true
    },
    dimensions: {
      type: String,
      default: "vertical"
    }
  },
  data() {
    return {
      file: null,
      url: null
    }
  },
  watch: {
    init_value: {
      handler() {
        if (this.init_value != null) {        
          fetch(this.init_value).then(res => res.blob()).then(blob => this.set_file(blob))
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Init image if specified
    if (this.init_value != null) {
      fetch(this.init_value).then(res => res.blob()).then(blob => this.set_file(blob))
    }    

    // Init drop_area
    const drop_area = this.$refs.drop_area
    drop_area.addEventListener('dragenter', this.on_drag_enter, false)
    drop_area.addEventListener('dragleave', this.on_drag_leave, false)
    window.addEventListener("dragover", e => e.preventDefault(), false)
    drop_area.addEventListener('drop', this.on_image_dropped, false)
  },
  methods: {
    on_drag_enter(e) {
      e.preventDefault();
      this.$refs.drop_area.classList.add('highlight')
    },
    on_drag_leave(e) {
      e.preventDefault();
      this.$refs.drop_area.classList.remove('highlight')
    },
    on_image_dropped(e) {
      e.preventDefault();
      this.set_file(e.dataTransfer.files[0])
    },
    on_click() {
      this.$refs.input_file.click()
    },
    on_file_change(e) {
      this.set_file(e.target.files[0])
    },
    set_file(file) {
      // Return if blob is not a file
      if (file && file.size < 250) {
        return
      }

      this.file = file;
      this.url = URL.createObjectURL(this.file);
      this.update_canvas()
      this.$emit("input", this.file);      
    },
    update_canvas() {
      // This function renders the image to a canvas. This avoids rendering a 10MB background image.

      const drop_area_rect = this.$refs.drop_area.getBoundingClientRect()
      const preview_image = new Image()
      preview_image.src = this.url
      preview_image.decode().then(() => {

        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d');
        canvas.width = drop_area_rect.width
        canvas.height = drop_area_rect.height;

        if (preview_image.naturalWidth > preview_image.naturalHeight) {
          // Draw image centered to canvas
          ctx.drawImage(
            preview_image,
            (preview_image.naturalWidth - (canvas.width * preview_image.naturalHeight / canvas.height)) / 2,
            0,
            canvas.width * preview_image.naturalHeight / canvas.height,
            preview_image.naturalHeight,
            0,
            0,
            canvas.width,
            canvas.height
          );
        } else {
          // Draw image centered to canvas
          ctx.drawImage(
            preview_image,
            0,
            (preview_image.naturalHeight - (canvas.height * preview_image.naturalWidth / canvas.width)) / 2,
            preview_image.naturalWidth,
            canvas.height * preview_image.naturalWidth / canvas.width,
            0,
            0,
            canvas.width,
            canvas.height
          );
        }
      })
    }
  }
}
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
}

* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.drop-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  background-color: transparent;
  background-size: cover;
  background-size: 100% auto;
  background-position: center;
}

.drop-area.horizontal {
  height: 280px;
  width: 100%;
}

.drop-area.vertical {
  height: 50vh;
  max-height: 300px;
  width: 100%;
}

.drop-area p {
  font-size: 0.8em;
}

.drop-area.highlight {
  border-color: var(--highlight-color)
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>