<template>
  <oib-button-microsoft @click="login_prompt(null)" text="Log in met Microsoft"/>
</template>

<script>
// External libraries
//import { PublicClientApplication } from "@azure/msal-browser";

import * as Msal from "msal";

// Internal libraries
import {get_uri} from "../store/request.js"
import {get_cookie} from "../cookie.js"
import {set_cookie} from "../cookie.js"

import OibButtonMicrosoft from "./oib_button_microsoft.vue"

export default {
  name: "Microsoft",
  components: {
    OibButtonMicrosoft
  },
  data() {
    return {
      msalConfig: {
          auth: {
              clientId: "f873063c-58ee-44f3-a83c-64711a56d41a",
              redirectUri: `${get_uri()}/login/microsoft`,
          },
          cache:{
            cacheLocation: "localStorage"
          }
      }
    };
  },
  mounted() {
    this.login_refresh()
  },
  methods: {
    login_refresh() {
      const MS_IN_A_DAY = 1000 * 3600 * 24
      const ACCOUNT_TYPE = get_cookie('ACCOUNT_TYPE')
      const ACCOUNT_EMAIL = get_cookie('ACCOUNT_EMAIL')
      const LAST_PROMPT_MS = get_cookie("MICROSOFT_PROMPT_MS")

      if (ACCOUNT_TYPE == 'MICROSOFT' && ACCOUNT_EMAIL) {
        if (LAST_PROMPT_MS && new Date().getTime() - LAST_PROMPT_MS < MS_IN_A_DAY) {
          this.login_silent(ACCOUNT_EMAIL)
        } else {
          this.login_prompt()
        }
      }
    },
    login_silent(login_hint) {
      const msalInstance = new Msal.UserAgentApplication(this.msalConfig);
      msalInstance.loginRedirect({
        scopes: ['user.read'],
        loginHint: login_hint
      })
    },
    login_prompt() {
      /*
      If login prompt is succesful, the user will be logged in silently for 24 hours.
      If it fails or the user opts out, the automatic prompt won't be shown again.
      */

      let body = {
        scopes: ['user.read'],
        prompt: 'select_account'
      }

      set_cookie("ACCOUNT_TYPE", '', 365)
      set_cookie("ACCOUNT_EMAIL", '', 365)
      set_cookie("MICROSOFT_PROMPT_MS", new Date().getTime(), 1)
      const msalInstance = new Msal.UserAgentApplication(this.msalConfig);
      msalInstance.loginRedirect(body)
    }
  }
};
</script>
