<template>
  <oib-page-loading :progress="0">
    <p>Inloggen ...</p>
  </oib-page-loading>
</template>

<script>
import {accounts_external_google_authenticate} from "../store/user.js"
import {logout} from "../store/user.js"
import {set_cookie} from "../cookie.js"

import OibPageLoading from "../components/oib_page_loading.vue";

export default {
  name: "GoogleLogin",
  components: {
    OibPageLoading
  },
  methods: {
    async login() {
      const queryString = window.location.href;
      const urlParams = new URLSearchParams(queryString);
      const id_token = urlParams.get('id_token')

      // Try the authenticate user with its Google account.
      const response = await accounts_external_google_authenticate(id_token)

      // Store user data in store if user was authenticated
      if (response.status == 200) {
        const json = await response.json()
        this.$store.commit("set_jwt", json['jwt'])
        this.$store.commit("set_user", json['user'])
        this.$store.commit("set_organisation_id", json['organisation_id'])
        this.$store.commit("set_organisation_id_active", json['organisation_id'])

        this.$store.commit("set_login_error_message", "")
        this.$store.commit("set_login_error_instructions", "")

        set_cookie("ACCOUNT_TYPE", 'GOOGLE', 365)
        set_cookie("ACCOUNT_EMAIL", json['user']['account_email'], 365)

        this.$router.push({
          name: "Laden"
        });
      }
      else if (response.status == 401) {
        const json = await response.json()
        const account_email = json['account_email']

        if (account_email == null) {
          this.$store.commit("set_login_error_message", "De Google sleutel was ongeldig.")
          this.$store.commit("set_login_error_instructions", "<p>Probeer opnieuw in te loggen</p>")
        } else {
          this.$store.commit("set_login_error_message", `${account_email} is niet gekoppeld.`)
          this.$store.commit(
            "set_login_error_instructions",
            "<p>Dit Google account is niet gekoppeld aan een Onderwijs in Beeld account.</p>\
              \
              <p>Vraag de Onderwijs in Beeld software beheerder op jouw basisschool om hulp.</p>\
              \
              <p>Ben jezelf de beheerder op jouw school?</p>\
              \
              <p>Bel dan naar 085 0509 768 voor een directe verbinding met onze IT-afdeling.</p>"
          )
        }

        logout()
      }
      // TODO: handle other status codes
    }
  },
  mounted() {
    this.login()  
  },
  updated() {
    this.login()
  }
};
</script>