<template>
  <div class="icon-inline">
    <span class="material-icons" :style="icon_style">{{ icon }}</span>
    <p :style="text_style">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "OibIconInline",
  props: ["icon", "icon_style", "text", "text_style"],
};
</script>

<style scoped>
.icon-inline {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-inline span {
  margin: 0;
  color: var(--highlight-color);
  padding-right: 5px;
}

.icon-inline p {
  margin: 0;
}
</style>