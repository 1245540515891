<template>
  <oib-form-library-style
  v-if="library_name"
  :library_id="library_id"
  :library_name_init="library_name"
  :location_name_init="location_name"
  @submit="x => submit(x)"
  />
</template>

<script>
import OibFormLibraryStyle from "../components/oib_form_library_style.vue";

import {put_organisations_libraries} from "../store/api/library.js"
import {put_upload_image} from "../store/api/media.js"
import {reload_libraries} from "../store/library.js"

export default {
  name: "BibliotheekAanpassenVormgeving",
  props: ['library_id'],
  components: {
    OibFormLibraryStyle
  },
  data() {
    return {
      library_name: "",
      location_name: "",
      creation_datetime: null
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    // Skip on refresh
    if (!this.$store.getters.getActiveSession) {
      return
    }

    // Library vars init
    const library = this.$store.getters.get_library_dict[this.library_id]
    this.library_name = library['library_name']
    this.location_name = library['location_name']
    this.creation_datetime = library['creation_datetime']
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt

      // Update library
      await put_organisations_libraries(
          jwt,
          body.organisation_id,
          this.library_id,
          body.organisation_id,          
          body.library_name,
          body.location_name,
          this.creation_datetime
      )

      reload_libraries()

      // Upload image      
      let formData = new FormData();
      formData.append("image", body.image_file);      
      put_upload_image(jwt, 'libraries', this.library_id, formData)      

      // Route back
      this.$router.back()
    }
  }
};
</script>
