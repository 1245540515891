<template>
  <!-- Slider main container -->
  <div class="swiper-container">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper" :style="'padding-left: ' + paddingLeft">
      <!-- Slides -->
      <div class="swiper-slide">
        <slot name="left"></slot>
      </div>
      <div
      v-for="(tile, index) in tile_list"
      :key="index"
      class="swiper-slide"
      :style="index == tile_list.length - 1? `padding-right: calc(20px + ${paddingLeft})` : 'padding-right: 20px'"
      >
        <book-tile
          class="slide-element"
          :user_id="user_id"
          :isbn13="tile.isbn13"
          :book_edition_id="tile.book_edition_id"
          :book_title="tile.book_title"
          :author_name="tile.author_name"
          :sub_text="tile.sub_text"
          :marker="tile.marker"
          :marker_width="marker_width"
          :copies_total="tile.copies_total"
          :copies_in_use="tile.copies_in_use"
          :progress_state="tile.progress_state"
        />
        <div v-if="show_view_more" class="book-tile-link" @click="on_view_more">
          <p class="h4">Bekijk meer ➔</p>
        </div>
      </div>
      <div class="swiper-slide" style="width: 10%" />
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper from "swiper";
// import Swiper styles
import "swiper/swiper-bundle.css";

import BookTile from "./BookTile.vue";

export default {
  name: "OibBookSlider",
  props: {
    user_id: {
      required: true
    },
    tile_list: {
      type: Array,
      required: true,
    },
    paddingLeft: {
      type: String,
      default: "",
      required: false,
    },
    show_view_more: {
      type: Boolean,
      default: false
    },
    marker_width: {
      type: Number,
      default: 40
    }
  },
  components: {
    BookTile,
  },
  methods: {
    create_swiper() {
      new Swiper(".swiper-container", {
        // grabCursor: true,
        // direction: 'horizontal',
        // loop: false,
        // slidesPerView: 'auto',
        // // loopedSlides: 3,
        // simulateTouch: false,
        // autoplay: {
        //   enabled: true,
        //   delay: 0,
        //   pauseOnMouseEnter: false,
        //   disableOnInteraction: false
        // },
        // centerInsufficientSlides: true,
        // speed: 500
        grabCursor: true,
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
      });
    },
    on_view_more() {
      this.$emit("view_more")
    }
  },
  mounted() {
    this.create_swiper()
  },
  updated() {
    // Required to ensure proper spacing after clickthrough/update
    this.create_swiper()
  }
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  padding-top: 4px;
  margin-top: -4px;
}

.swiper-slide { 
  display: flex;
  flex-direction: row;
  width: auto;
}

.book-tile-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: var(--book-width);
  height: calc(var(--book-width) / 4 * 5);
  margin-bottom: 67.5px;
  background-color: var(--primary-color-p4);
  border-radius: 4px;
  margin-right: 20px;
}
</style>
