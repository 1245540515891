<template>
  <div 
  v-if="disable_mute || show_hint"
  class="row"
  style="background-color: var(--primary-color-sub); justify-content: space-between; border-radius: 4px; margin-bottom: 25px"
  >
    <div class="row" style="width: 100%; height: 100%; align-items: center; ">
      <div class="row" style="width: 100%; height: 100%; ">
        <div class="column" style="background-color: var(--highlight-color); padding: 0 10px; width: 24px; border-radius: 4px; margin-bottom: 0; justify-content: center;">
          <p class="material-icons" style="color: white; font-size: 24px; margin: 0">tips_and_updates</p>
        </div>
        <div class="column" style="padding: 10px 0px 10px 20px">
          <p style="font-size: 0.85em; color: white; margin-top: 10px; margin-bottom: 2px;">{{title}}</p>
          <p style="font-size: 0.8em; margin: 5px 0;"><slot></slot></p>
        </div>
      </div>
      <oib-button
        v-if="href"
        style="height: 30px; min-width: 80px; margin-right: 20px;"
        priority="p1"
        @click="go_to_link(href)"
        >Video ➜
      </oib-button>
    </div>
    <p
    v-if="!disable_mute"
    class="material-icons highlight-hover fade"
    style="color: white; font-size: 16px; margin: 0; padding: 6px; height: 100%;"
    @click="do_not_show_anymore"
    >close</p>
    <div v-if="disable_mute" style="width: 12px"></div>
  </div>
</template>

<script>
import { get_cookie } from "../cookie.js"
import { set_cookie } from "../cookie.js"

import OibButton from "../components/OibButton.vue"

export default {
  props: {
    cookie_id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    disable_mute: {
      type: Boolean,
      required: false,
      default: false
    },
    href: {
      type: String,
      default: null
    }
  },
  components: {
    OibButton
  },
  data() {
    return {
      show_hint: false,
      cookie_id_full_str: null
    }
  },
  methods: {
    do_not_show_anymore() {
      set_cookie(this.cookie_id_full_str, '', 100000)
      this.show_hint = false
    },
    go_to_link(href) {
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      setTimeout(() => {
        window.open(href, '_blank');
      })
    },
  },
  mounted() {
    const user = this.$store.getters.getUser
    if (!user)  {
      return
    }
    this.cookie_id_full_str = 'hint_' + user['user_id'] + '_' + this.cookie_id
    this.show_hint = get_cookie(this.cookie_id_full_str) == null
  }
}
</script>

<style scoped>
.highlight-hover:hover {
  color: var(--highlight-color);
  cursor: pointer;
}
</style>
