<template>
  <book-search
  :query="query"
  :filter_library="true"
  :sort_modes="sort_modes"  
  />
</template>

<script>
import BookSearch from "../components/book_search.vue";

export default {
  name: "BibliotheekZoekenFilter",
  props: {
    query: {
      required: false,
      default: ''
    }
  },
  components: {
    BookSearch
  },
  data() {
    return {
      sort_modes: [
        'Meest relevant',
        'Meest gelezen',
        'Minst gelezen',
        'Langst geleend',
        'Nieuw geleend',
        'Onlangs toegevoegd',
        'Nieuwste'
      ]
    }
  }  
}
</script>