<template>
  <div style="margin-bottom: 20px;">
    <div class="column">
      <label>{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
      <p v-if="description" class="description">{{description}}</p>
      <oib-grid :elements="option_list" :tile_width="tile_width">
        <template v-slot[props]="props">
          <oib-button
            class="tile-btn"
            :style="`width: ${tile_width}px; border-radius: 10px`"
            :priority="indices.has(props.data.index) ? 'p1' : 'p2'"
            @click="on_select(props.data.index)"
          >{{props.data.body}}</oib-button>
          </template>
      </oib-grid>
      <div v-if="allow_multi_select" class="select-all">
        <p class="description">Selecteer alles</p>
        <oib-checkbox
        style="margin: 0 10px 0 0"
        :set_checked="select_all"
        @click="on_select_all(!select_all)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import OibButton from "../../components/OibButton.vue"
import OibCheckbox from "../../components/OibCheckbox.vue"
import OibGrid from "../../components/oib_grid.vue"

export default {
  name: "OibInputText",
  props: {
    options: {
      type: Object || Map,
      required: true
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    allow_multi_select: {
      type: Boolean,
      default: false
    },
    keys_init: {
      type: Set,
      default: null
    },
    sort_alphabetically: {
      default: true
    }
  },  
  components: {
    OibButton,
    OibCheckbox,
    OibGrid
  },
  data () {
    return {
      option_list: [],
      indices: new Set(),
      tile_width: null,
      keys: null,
      keys_selected: new Set(),
      select_all: false
    }
  },
  watch: {
    keys_init() {
      this.keys_init.forEach((key) => {
        this.indices.add(this.get_index_by_key(key))
        this.keys_selected.add(key)
      })
    }
  },
  mounted() {
    this.keys = Array.from(Object.keys(this.options))
    if (this.sort_alphabetically) {
      this.keys.sort((a, b) => this.options[a].localeCompare(this.options[b]))
    }    

    this.keys.forEach(x => {
      // object and map compatibility
      const value = this.options[x] ? this.options[x] : this.options.get(x)
      this.option_list.push(value)
    });

    const max_str_length = this.get_max_str_length(this.option_list)
    this.tile_width = max_str_length * 6 + (Math.max(30, max_str_length * 2));

    
    // Init tile if specified
    if (this.keys_init != null) {
      this.keys_init.forEach((key) => {
        this.indices.add(this.get_index_by_key(key))
        this.keys_selected.add(key)
      })

      this.publish_event()
    }
  },
  methods: {
    get_index_by_key(key) {
      for (let i = 0; i < this.keys.length; i++) {
        if (key == this.keys[i]) {
          return i
        }
      }
    },
    get_max_str_length(array) {
      let max_str_length = 0
      for(let i = 0; i < array.length; i++) {
        const value = array[i]
        max_str_length = Math.max(max_str_length, value.length)
      }
      return max_str_length
    },
    on_select(index) {
      if (this.allow_multi_select) {
        if (this.indices.has(index)) {
          this.indices.delete(index)
          this.keys_selected.delete(parseInt(this.keys[index]))
        } else {
          this.indices.add(index)
          this.keys_selected.add(parseInt(this.keys[index]))
        }
      } else {
        this.indices.clear()
        this.keys_selected.clear()
        this.indices.add(index)
        this.keys_selected.add(parseInt(this.keys[index]))
      }
      this.publish_event()
    },
    on_select_all(is_checked) {
      this.select_all = is_checked
      if (this.select_all) {
        for (let i = 0; i < this.option_list.length; i++)  {
          this.indices.add(i)
          this.keys_selected.add(parseInt(this.keys[i]))
        }
      } else {
        for (let i = 0; i < this.option_list.length; i++)  {
          this.indices.delete(i)
          this.keys_selected.delete(parseInt(this.keys[i]))
        }
      }
      this.publish_event()
    },
    publish_event() {
      this.$emit("select", Array.from(this.keys_selected))
    }
  }
}
</script>

<style scoped>
.multi-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 500px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.tile-btn {
  width: 100px;
  padding: 10px 5px;
  margin-bottom: 20px;
}

.select-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.select-all p {
  margin: 0 10px 5px 0;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}
</style>