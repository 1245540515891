<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Avi-toets toevoegen" ref="form">
    <div>
      <oib-input-multi-select-descriptive
        description="Hoe wil je een avi-toets toevoegen?"
        :allow_multi_select="false"
        :required="true"
        :sort_alphabetically="false"
        :options="main_character_initial_state_selector_list"
        @select="x => on_click(main_character_initial_state_selector_list[parseInt(x[0])])"
      />
    </div>
    <div></div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"

import { go_to_route } from "../store/navigation";

export default {
  name: "MenuLeerkrachtenToevoegen",
  components: {
    OibForm,
    OibInputMultiSelectDescriptive
  },
  data() {
    return {
      form_step_validation_list: [false, false],
      main_character_final_state_index: null,
      main_character_initial_state_selector_list: [
        {'tag': 'Importeer bestand leerlingvolgsysteem', 'details': 'Exporteer een spreadsheet in het leerlingvolgsysteem en upload het bestand om alle leestoetsen tegelijk in te laden.'},
        {'tag': 'Handmatig', 'details': 'Voeg een avi-toets handmatig toe.'}
      ],
    }
  },
  methods: {
    go_to_lvs_import(){
      go_to_route('LvsNietMethodeToetsen')
    },
    go_to_add_test_manual() {
      go_to_route('BiebhulpToevoegen')
    },
    on_click(option) {
      if (option.tag == 'Importeer bestand leerlingvolgsysteem') {
        setTimeout(go_to_lvs_import, 500)
      } else if (option.tag == 'Handmatig') {
        setTimeout(this.go_to_add_test_manual, 500)
      }
    }
  }
};
</script>
