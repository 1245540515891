import { parse_query } from "../request.js"
import { request_get_json } from "../request.js"

export async function get_organisations_scrape(jwt,query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/organisations/scrape?${query_string}`, undefined)
}

export async function get_organisations_scrape_discover(jwt,query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/organisations/scrape/discover?${query_string}`, undefined)
}
