import { parse_query } from "../request.js"
import { request_put } from "../request.js"
import { request_get_json } from "../request.js"

export async function put_organisations_tasks(jwt, organisation_id, task_title, create_datetime, finish_datetime) {
  /*
  Add a task to an organisation.

  :param int organisation_id: the unique id of the of the organisation.
  :param int task_title: the title of the task.
  :param int create_datetime: the datetime that the task was created.
  :param int finish_datetime: the datetime that the task was finished.
  */

  const request_path = `/organisations/${organisation_id}/tasks`
  const body = {
    'task_title': task_title,
    'create_datetime': create_datetime,
    'finish_datetime': finish_datetime
  }

  return await request_put(jwt, request_path, body)
}

export async function get_organisations_tasks_query(jwt,query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/organisations/tasks?${query_string}`, undefined)
}
