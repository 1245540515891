import store from '../index'
import { get_users_roles } from "../api/user.js"
import { setRole } from "../cache/cacheUser.js"

/**
 * Query all user roles and store the roles in cache.
 * 
 * @param onFinish - a callback function called onFinish
 */
export async function loadRoles() {
    const jwt = store.getters.get_jwt
    await get_users_roles(jwt, {}).then(json => {
        json['role_list'].forEach(role => {
            setRole({
                'roleId': role['role_id'],
                'role': role['role'],
                'roleIdListCreate': role['role_id_list_create'],
                'schoolyearLinked': role['schoolyear_linked'],
                'dataProtectionScopeId': role['data_protection_scope_id']
            })
        });
    })
}
