import store from './index'

//import { get_books } from "./api/library"
import { parse_query } from './request'
import { request_get_json } from './request'
import { get_datetime_str } from './utils'
import { post_books_users_progress } from './api/book_progress'
import { put_books_users_progress } from './api/book_progress'

const READING = 1
const FINISHED = 2
const QUIT = 3

export async function get_books_users_progress_query(query) {
  const jwt = store.getters.get_jwt
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/users/book-products/progress?${query_string}`)
}

export async function get_books_users_progress_query_states(query) {
  const jwt = store.getters.get_jwt
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/users/book-products/progress/states?${query_string}`)
}

export async function load_book_progress(user_id, isbn13) {
  /*
  Load the progress of a user in a book product.

  :param int user_id: the unique id of the user.
  :param int isbn13: the unique isbn13 of the book product.
  */

  const response_json = await get_books_users_progress_query({
    'user_id': user_id,
    'isbn13': isbn13,
    'limit': 1000,
    'page': 0
  })

  store.commit("set_book_user_progress", {
      user_id: user_id,
      isbn13: isbn13,
      progress_list: response_json.progress_list
  })
}

export async function load_book_progress_states(user_id, progress_state) {
  /*
  Load the progress of a user in a book product.

  :param int user_id: the unique id of the user.
  :param int progress_state: the progress_state of the user in the books.
  */

  const response_json = await get_books_users_progress_query_states({
    'user_id': user_id,
    'progress_state': progress_state,
    'limit': 1000,
    'page': 0
  })
  let isbn13_set = new Set()
  response_json.progress_state_list.forEach(x => isbn13_set.add(x['isbn13'].toString()))

  store.commit("set_book_user_progress_state", {
      user_id: user_id,
      progress_state: progress_state,
      isbn13_set: isbn13_set
  })
}


// UPDATE PROGRESS
export async function add_progress(user_id, isbn13, pages_read, pages_total) {
    const datetime_logged = get_datetime_str()
    await post_books_users_progress(
        store.getters.get_jwt,
        user_id,
        isbn13,
        pages_read,
        pages_total,
        READING,
        datetime_logged
    )

    // Add local progress to avoid new query
    let progress_list = []
    const progress_dict = store.getters.get_progress_dict
    if (user_id in progress_dict && isbn13 in progress_dict[user_id]) {
        progress_list = store.getters.get_progress_dict[user_id][isbn13]
    }

    progress_list.push({
        'user_id': user_id,
        'isbn13': isbn13,
        'pages_read': pages_read,
        'pages_total': pages_total,
        'progress_state': READING,
        'datetime_logged': datetime_logged
    })
    
    // Update local progress
    store.commit("set_book_user_progress", {
        'user_id': user_id,
        'isbn13': isbn13,
        'progress_list': progress_list
    })

    // Update local progress state
    const progress_state_dict = store.getters.get_progress_state_dict
    progress_state_dict[user_id][READING].add(isbn13.toString())
    store.commit('set_book_user_progress_state', progress_state_dict)
}

export async function finish_book(user_id, isbn13, pages_total) {

  const datetime_logged = get_datetime_str()
  await post_books_users_progress(
      store.getters.get_jwt,
      user_id,
      isbn13,
      pages_total,
      pages_total,
      FINISHED,
      datetime_logged
  )

  // Update local progress
  const progress_list = store.getters.get_progress_dict[user_id][isbn13]
  progress_list.push({
    'user_id': user_id,
    'isbn13': isbn13,
    'pages_read': pages_total,
    'pages_total': pages_total,
    'progress_state': FINISHED,
    'datetime_logged': datetime_logged
  })
  store.commit("set_book_user_progress", user_id, isbn13, progress_list)

  // Update local progress state
  const progress_state_dict = store.getters.get_progress_state_dict
  progress_state_dict[user_id][READING].delete(isbn13.toString())
  store.commit('set_book_user_progress_state', progress_state_dict)
}

export async function quit_book(user_id, isbn13) {

  const datetime_logged = get_datetime_str()
  const progress_list = store.getters.get_progress_dict[user_id][isbn13].sort(compare_log)

  await post_books_users_progress(
      store.getters.get_jwt,
      user_id,
      isbn13,
      progress_list[0].pages_read,
      progress_list[0].pages_total,
      QUIT,
      datetime_logged
  )

  // Update local progress state
  const progress_state_dict = store.getters.get_progress_state_dict
  progress_state_dict[user_id][READING].delete(isbn13.toString())
  //progress_state_dict[user_id][QUIT].add(isbn13.toString())
  store.commit('set_book_user_progress_state', progress_state_dict)
}

function compare_log(a, b) {
  if (a.datetime_logged < b.datetime_logged) {
    return 1
  }
  if (a.datetime_logged > b.datetime_logged) {
    return -1
  }
  return 0
}

export async function handle_total_pages_change(user_id, isbn13, pages_total_new) {
  const progress_dict = store.getters.get_progress_dict

  // Skip if no progress was found
  if (!(user_id in progress_dict) || !(isbn13 in progress_dict[user_id])) {
    return
  }

  const progress_list_old = store.getters.get_progress_dict[user_id][isbn13]
  
  // Skip if progress list was empty
  if (progress_list_old.lenght == 0) {
    return
  }

  const total_pages_old = progress_list_old[0]['total_pages']
  const pages_read_old = progress_list_old[progress_list_old.length - 1]['pages_read']
  
  // Skip if total pages did not change
  if (total_pages_old == pages_total_new) {
    return
  }

  // Skip if pages read exceed total new pages - next time this will automatically resolve
  if (pages_read_old > pages_total_new) {
    return
  }

  // Generate new progress list
  let progress_list_new = []
  const jwt = store.getters.get_jwt
  for (let progress of progress_list_old) {
    
    await put_books_users_progress(
      jwt,
      progress['progress_id'],
      progress['user_id'],
      progress['isbn13'],
      Math.min(progress['pages_read'], pages_total_new),
      pages_total_new,
      progress['progress_state'],
      progress['datetime_logged']
    )

    progress['total_pages'] = pages_total_new
    progress_list_new.push(progress)
  }

  // Update local store
  store.commit("set_book_user_progress", user_id, isbn13, progress_list_new)
}