<template>
  <div>
    <oib-page>
      <oib-nav-student title="Boek menu"/>
      <oib-content style="padding-bottom: 100px">
        <div v-if="has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])">
          <div class="wrapper column">
            <h2 class="h2">Boek</h2>
          </div>
          <oib-menu-item
            description="Laat ons weten hoeveel paginanummers dit boek heeft. De meeste stemmen gelden!"
            @click="go_to_route('BoekStemmenPaginas', {'isbn13': isbn13, 'user_id': user_id})"
            hover="hover"
            >
            <template v-slot:title>
              <oib-icon-label
              style="color: white;"
              icon="how_to_vote"
              title="Stem op paginanummers"
              direction="row"
              :active="true"
              />
            </template>
          </oib-menu-item>

          <oib-menu-item
            description="Maak een foto van dit boek en upload het bestand."
            @click="go_to_route('BoekStemmenKaft', {'isbn13': isbn13, 'user_id': user_id})"
            hover="hover"
            >
            <template v-slot:title>
              <oib-icon-label
              style="color: white;"
              icon="image"
              title="Kaft toevoegen"
              direction="row"
              :active="false"
              />
            </template>
          </oib-menu-item>

          <oib-menu-item
            description="Update de doelgroep informatie van dit boek zodat we betere boektips kunnen geven."
            @click="go_to_route('BoekStemmenDoelgroep', {'isbn13': isbn13, 'user_id': user_id})"
            hover="hover"
            >
            <template v-slot:title>
              <oib-icon-label
              style="color: white;"
              icon="groups"
              title="Doelgroep verbeteren"
              direction="row"
              :active="true"
              />
            </template>
          </oib-menu-item>

          <oib-menu-item
            v-if="has_role(['LIBRARY_PARTNER', 'SYSTEM_ADMIN'])"
            description="Update de trefwoorden informatie van dit boek zodat we betere boektips kunnen geven."
            @click="go_to_route('BoekStemmenTrefwoorden', {'isbn13': isbn13, 'user_id': user_id})"
            hover="hover"
            >
            <template v-slot:title>
              <oib-icon-label
              style="color: white;"
              icon="palette"
              title="Trefwoorden verbeteren"
              direction="row"
              :active="true"
              />
            </template>
          </oib-menu-item>


          <oib-menu-item
            v-if="has_role(['SYSTEM_ADMIN'])"
            description="Verander de informatie over een boek."
            @click="go_to_route('BoekToevoegen', {'isbn13': isbn13})"
            hover="hover"
            >
            <template v-slot:title>
              <oib-icon-label
              style="color: white;"
              icon="edit"
              title="Boek aanpassen"
              direction="row"
              :active="true"
              />
            </template>
          </oib-menu-item>
        </div>
      </oib-content>
    </oib-page>
  </div>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"

import { has_role } from "../store/utils"
import { go_to_route } from "../store/navigation";

export default {
  name: "Instellingen",
  props: ['isbn13'],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    const user = this.$store.getters.getUser
    this.user_id = user['user_id']
  },
  methods: {
    // Import
    has_role,
    go_to_route
  }
};
</script>

<style scoped>
.checkbox-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
}

.checkbox-option .wrapper-checkbox {
  padding-right: 10px;
}
</style>