<template>
  <div>
    <oib-form-upload-niet-methode-toetsen :init_error_msg="error_msg" @submit="x => submit(x)"/>
    <oib-modal-loading ref="modal_loading" :progress="progress" :text="text"/>
  </div>
</template>

<script>
import OibFormUploadNietMethodeToetsen from "../components/oib_form_upload_niet_methode_toetsen.vue";
import OibModalLoading from "../components/oib_modal_loading.vue";

import { put_schools_lvs_resources } from "../store/api/lvs"
import { post_schools_lvs_sync } from "../store/api/lvs"

export default {
  name: "BibliotheekUploaden",
  components: {
    OibFormUploadNietMethodeToetsen,
    OibModalLoading
  },
  data() {
    return {
      processing: false,
      progress: 0,
      text: '',
      error_msg: ''
    }
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body || this.processing) {
        return
      }

      this.text = 'LVS koppelen ...'
      this.processing = true

      const form_data = new FormData();
      form_data.append("resource", body['file']);

      const jwt = this.$store.getters.get_jwt
      const school = this.$store.getters.get_school
      const branch_number = school['branch_number']
      const lvs_type = body['lvs_type']
      const resource_type = lvs_type == 'PARNASSYS' ? 'NIET_METHODE_TOETSEN' : 'READING_TESTS'

      this.$refs.modal_loading.show()
      this.text = 'Bestand uploaden ...'

      // Upload file
      const response = await put_schools_lvs_resources(jwt, branch_number, lvs_type, resource_type, form_data)
      if (response.status == 400) {
        this.error_msg = "Het bestand kon niet verwerkt worden. Is het wel een Excelbestand (.xls of .xlsx)?"
        this.processing = false
        this.$refs.modal_loading.hide()
        return
      } else if (response.status == 404) {
        this.error_msg = "Er ging iets fout. Neem contact op."
        this.processing = false
        this.$refs.modal_loading.hide()
        return
      } else if (response.status == 422) {
        this.error_msg = "Het bestand had het juiste bestandformaat, maar de inhoud kon niet verwerkt worden."
        this.processing = false
        this.$refs.modal_loading.hide()
        return
      }

      // load tests
      await post_schools_lvs_sync(jwt, branch_number, lvs_type, 1, 0)

      this.progress = 100
      this.text = 'Bestand uploaden voltooid'
      await this.sleep(1000)

      this.$refs.modal_loading.hide()

      this.$router.push({
        name: 'LvsSyncBericht',
      });
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>
