import { parse_query } from "../request.js"
import { request_put } from "../request.js"
import { request_post } from "../request.js"
import { request_get_json } from "../request.js"

export async function post_users_test_results(jwt, user_id, test_part_type, didactic_age, didactic_age_equivalent, is_decisive, test_datetime, score, error_count, skill_level, annotation) {
  /* Create a test results of a user.

    :param int user_id: the unique id of the user.
    :param int test_part_type: the type of test part in config.TestPartType.
    :param int didactic_age: the didactic age of the student.
    :param int didactic_age_equivalent: the didactic age equivalent of what the student scored.
    :param int is_decisive: 0 | 1 is this result decisive?
    :param str test_datetime: the datetime the user took the test.
    :param int score: the score of the user on the test. (optional)
    :param int error_count: the amount of errors the user made on the test. (optional)
    :param str skill_level: the skill level (for example: 'Beheersing')
    :param str annotation: an optional annotation of the test (can be null).
  */

  const request_path = `/users/${user_id}/test-results`

  return await request_post(jwt, request_path, {
    'test_part_type': test_part_type,
    'didactic_age': didactic_age,
    'didactic_age_equivalent': didactic_age_equivalent,
    'is_decisive': is_decisive,
    'test_datetime': test_datetime,
    'score': score,
    'error_count': error_count,
    'skill_level': skill_level,
    'annotation': annotation
  })
}

export async function put_users_test_results(jwt, user_id, test_result_id, test_part_type, didactic_age, didactic_age_equivalent, is_decisive, test_datetime, score, error_count, skill_level, annotation) {
  /* Create a test results of a user.

    :param int user_id: the unique id of the user.
    :param int test_result_id: the unique id of the test result.
    :param int test_part_type: the type of test part in config.TestPartType.
    :param int didactic_age: the didactic age of the student.
    :param int didactic_age_equivalent: the didactic age equivalent of what the student scored.
    :param int is_decisive: 0 | 1 is this result decisive?
    :param str test_datetime: the datetime the user took the test.
    :param int score: the score of the user on the test. (optional)
    :param int error_count: the amount of errors the user made on the test. (optional)
    :param str skill_level: the skill level (for example: 'Beheersing')
    :param str annotation: an optional annotation of the test (can be null).
  */

  const request_path = `/users/${user_id}/test-results/${test_result_id}`
  
  return await request_put(jwt, request_path, {
    'test_part_type': test_part_type,
    'didactic_age': didactic_age,
    'didactic_age_equivalent': didactic_age_equivalent,
    'is_decisive': is_decisive,
    'test_datetime': test_datetime,
    'score': score,
    'error_count': error_count,
    'skill_level': skill_level,
    'annotation': annotation
  })
}

export async function get_users_test_results(jwt,query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/users/test-results?${query_string}`, undefined)
}
