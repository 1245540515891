<template>
  <oib-page>
    <oib-nav-student :title="user_name"/>
    <oib-content>
      <div class="wrapper column center" style="height: 85vh;">
        <h3 style="margin-top: 0">Kies een loginmethode</h3>
        <oib-button-qr-code
        text="Koppel boekenlegger"
        style="margin-bottom: 10px;"
        @click="go_to_route('InstellingenQrCode', {'user_id': user_id})"
        />
        <oib-button-microsoft
        text="Koppel Microsoft"
        style="margin-bottom: 10px"
        @click="go_to_route('InlogmethodeKoppelen', {'user_id': user_id, 'account_type': 'MICROSOFT'})"
        />
        <oib-button-google
        text="Koppel Google"
        @click="go_to_route('InlogmethodeKoppelen', {'user_id': user_id, 'account_type': 'GOOGLE'})"
        />
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibButtonQrCode from "../components/oib_button_qr_code.vue"
import OibButtonMicrosoft from "../components/oib_button_microsoft.vue";
import OibButtonGoogle from "../components/oib_button_google.vue";

import { go_to_route } from "../store/navigation";

export default {
  name: "InlogmethodeToevoegen",
  props: ['user_id'],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButtonQrCode,
    OibButtonMicrosoft,
    OibButtonGoogle
  },
  data() {
    return {
      user_name: ''
    }
  },
  methods: {
    // Import
    go_to_route
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  mounted() {
    if (!this.$store.getters.getUser) {
      return
    }

    const user = this.$store.getters.getUsers[this.user_id]
    this.user_name = user.user_name
  }
};
</script>
