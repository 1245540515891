<template>
  <div class="column" style="margin-bottom: 20px; width: 100%;">
    <label v-if="label">{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
    <p v-if="description" class="description">{{description}}</p>
    <div 
    ref="checklist"
    style="width: 100%; margin-top: 10px; overflow-y: scroll;"
    :style="checklist_style"
    >
      <div
        v-for="option, i in option_list"
        :key="i"
        style="width: 100%; max-width: 500px;"
      >
        <div v-if="option.task" class="row" style="margin-bottom: 4px;">
          <oib-checkbox
          :set_checked="true"
          style="margin-top: 0px;"
          />
          <div class="row" style="margin-left: 20px; width: 100%; justify-content: space-between;">
            <div>
              <p
              style="margin: 0; color: white; font-size: 0.8em;"
              :style="`${option.details ? 'margin-top: 1px' : 'margin-top: 4px'};`">
                {{option.title}}
              </p>
              <p v-if="option.details" style="font-size: 0.8em; margin-top: 0; color: var(--contrast-color-p3)">{{option.details}}</p>
            </div>
          </div>          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OibCheckbox from "../components/OibCheckbox.vue"

import { go_to_route } from "../store/navigation"

export default {
  name: "OibChecklistTasks",
  props: {
    options: {
      type: Object || Map,
      required: true
    },
    label: {
      type: String,
    },
    description: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    allow_multi_select: {
      type: Boolean,
      default: true
    },
    keys_init: {
      default: null
    },
    sort_alphabetically: {
      default: false
    },
    display_selected: {
      default: false
    },
    filter_query: {
      default: ''
    }
  },  
  components: {
    OibCheckbox
  },
  data () {
    return {
      option_list: [],
      indices: new Set(),
      indices_filtered: new Set(),
      tile_width: null,
      keys: null,
      keys_selected: new Set(),
      checklist_style: ''
    }
  },
  watch: {
    keys_init() {
      this.keys_init.forEach((key) => {
        this.indices.add(this.get_index_by_key(key))
        this.keys_selected.add(key)
      })
    },
    filter_query() {
      this.filter_options()
    },
  },
  created() {
    this.keys = Array.from(Object.keys(this.options))
    if (this.sort_alphabetically) {
      this.keys.sort((a, b) => this.options[a].tag.localeCompare(this.options[b].tag))
    }    

    this.keys.forEach(x => {
      // object and map compatibility
      const value = this.options[x] ? this.options[x] : this.options.get(x)
      this.option_list.push(value)
    });

    const max_str_length = this.get_max_str_length(this.option_list)
    this.tile_width = max_str_length * 6 + (Math.max(30, max_str_length * 2));

    
    // Init tile if specified
    if (this.keys_init != null) {
      this.keys_init.forEach((key) => {
        this.indices.add(this.get_index_by_key(key))
        this.keys_selected.add(key)
      })

      this.publish_event()
    }
    this.filter_options()
  },
  methods: {
    filter_options() {
      this.indices_filtered = new Set()

      const filter_query_lower = this.filter_query.toLowerCase()

      if (this.filter_query.length < 2) {
        for (let i = 0; i < this.option_list.length; i++) {
          this.indices_filtered.add(i)
        }
      } else {
        for (let i = 0; i < this.option_list.length; i++) {
          if (this.option_list[i].tag.includes(filter_query_lower)) {
            this.indices_filtered.add(i)
          }
        }
      }
    },
    get_index_by_key(key) {
      for (let i = 0; i < this.keys.length; i++) {
        if (key == this.keys[i]) {
          return i
        }
      }
    },
    get_max_str_length(array) {
      let max_str_length = 0
      for(let i = 0; i < array.length; i++) {
        const value = array[i]
        max_str_length = Math.max(max_str_length, value.length)
      }
      return max_str_length
    },
    on_select(option) {
      if (option.finish_datetime == null) {
        go_to_route(option.page)
      }      
    },
    on_select_all(is_checked) {
      if (is_checked) {
        for (let i = 0; i < this.option_list.length; i++)  {
          this.indices.add(i)
          this.keys_selected.add(parseInt(this.keys[i]))
        }
      } else {
        for (let i = 0; i < this.option_list.length; i++)  {
          this.indices.delete(i)
          this.keys_selected.delete(parseInt(this.keys[i]))
        }
      }
      this.publish_event()
    },
    publish_event() {
      this.$emit("select", Array.from(this.keys_selected))
    }
  }
}
</script>

<style scoped>
.btn-arrow {
  margin: 0;
  padding-left: 20px;
}
.btn-arrow:hover {
  color: var(--highlight-color);
  cursor: pointer;
}
</style>