<template>
  <div style="background-color: var(--primary-color-sub); overflow: hidden">
    <oib-page v-if="$mq == 'mobile'">
      <oib-nav-student :title="title" :hide_bottom_on_mobile="true">
        <template v-slot:left style="width: 5%; min-width: 50px; justify-content: center;">
          <oib-button-icon
          icon="arrow_back"
          style="font-size: 24px;"
          @click="previous_step"
          />
        </template>
      </oib-nav-student>
      <oib-content :show_overlay="false">
        <div :class="$mq" style="min-height: inherit;" class="form-wrapper">
          <div :class="$mq" class='form'>
            <div class="row" style="justify-content: space-between; margin-top: 0; margin-bottom: 30px; width: 100%;">
              <div
              v-for="step, i in form_step_list"
              :key="i"
              style="height: 3px;"
              :style="`margin-top: 50px; width: calc(100% / ${form_step_list.length} - 1px * ${form_step_list.length}); ${i < step_index ? 'background-color: var(--highlight-color);' : 'background-color: var(--contrast-color-p4);'}`"
              ></div>
            </div>
            <div :class="$mq" class="form-content">
              <div class="wrapper column" style="margin: 10px auto 0 auto;">
                <oib-wrapper-ruler />
                <div class="column hide-visibility fade" style="max-width: 600px" ref="steps">
                  <slot></slot>
                </div>        
              </div>
            </div>
          </div>
        </div>
      </oib-content>
      <div style="position: absolute; bottom: 0px; z-index: 1; width: 100%; background-color: var(--primary-color-sub); padding: 10px 0;">
        <div class="wrapper column">
          <oib-button
            style="width: 100%"
            shape="rounded"
            @click="next_button_function"
            :disabled="!form_step_validation_list[step_index - 1]"
            >{{form_step_validation_list.length == step_index ? 'afronden' : 'volgende'}}
          </oib-button>
        </div>
      </div>
    </oib-page>
    <oib-page v-if="$mq == 'desktop'" style="background-color: var(--primary-color-sub);">
      <div class="wrapper column" style="align-items: center; padding-top: 40px; width: 100%;">
        <div class="row form-size-desktop">
          <oib-button-icon
          icon="close"
          style="font-size: 24px; padding-left: 0; margin-left: -4px; margin-right: 20px;"
          @click="$router.back()"
          />
          <h1 style="text-align: left; font-size: 1em">{{ title }}</h1>
        </div>
        <div :class="$mq" style="min-height: inherit;" class="form-wrapper form-size-desktop">
          <div :class="$mq" class='form'>
            <div class="row" style="justify-content: space-between; margin-top: 0; margin-bottom: 30px; width: 100%;">
              <div
              v-for="step, i in form_step_list"
              :key="i"
              style="height: 8px; border-radius: 4px;"
              :style="`width: calc(100% / ${form_step_list.length} - 1px * ${form_step_list.length}); ${i < step_index ? 'background-color: var(--highlight-color);' : 'background-color: var(--contrast-color-p4);'}`"
              ></div>
            </div>
            <div :class="$mq" class="form-content">
              <oib-wrapper-ruler />
              <div class="column" style="display: flex; width: 100%; margin: 10px auto 0 auto;">
                <div class="column hide-visibility fade" style="max-width: 600px; padding-bottom: 50px;" ref="steps">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: absolute; bottom: 0px; z-index: 1; width: 100%; background-color: var(--primary-color-sub); padding: 10px 0 50px 0;">
        <div class="wrapper row form-size-desktop" style="justify-content: space-between;">
          <oib-button
            class="button-size-desktop"
            :style="step_index > 0 ? 'visibility: visible' : 'visibility: hidden'"
            shape="rounded"
            priority="p3"
            @click="previous_step"
            >terug
          </oib-button>
          <oib-button
            class="button-size-desktop"
            shape="rounded"
            @click="next_button_function"
            :disabled="!form_step_validation_list[step_index - 1]"
            >{{form_step_validation_list.length == step_index ? 'afronden' : 'volgende'}}
          </oib-button>
        </div>
      </div>
    </oib-page>
  </div>
</template>

<script>
import OibPage from "../../components/OibPage.vue";
import OibContent from "../../components/OibContent.vue";
import OibNavStudent from "../../components/OibNavStudent.vue"
import OibWrapperRuler from "../../components/oib_wrapper_ruler.vue"
import OibButtonIcon from "../../components/OibButtonIcon.vue"
import OibButton from "../../components/OibButton.vue"

export default {
  name: "OibForm",
  props: {
    form_step_validation_list: {
      required: true
    },
    title: {
      type: String
    },
    submit_text: {
      type: String,
      default: 'Opslaan'
    }
  },
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibWrapperRuler,
    OibButtonIcon,
    OibButton
  },
  data() {
    return {
      step_index: 1,
      form_step_list: [],
      next_button_text: "",
      next_button_function: null,
      animation_ms: this.animation_ms
    }
  },
  mounted() {
    // Apply timeout, so that v-for is able to render
    setTimeout(() => this.init(), 100)
  },
  methods: {
    init() {
      // Skip if childnodes are undefined
      if (!this.$refs.steps) {
        return
      }

      // Get all steps - they must be div elements
      for (let child of this.$refs.steps.childNodes) {
        if (child.tagName == 'DIV') {
          this.form_step_list.push(child)
        }
      }
      this.form_step_list.forEach(el => this.hide(el))
      this.form_step_list.forEach(el => el.classList.add("fade"))
      this.show_element(this.form_step_list[this.step_index - 1])
      this.update_next_button()

      // Now that everythin has been loaded - show the form
      setTimeout(() => {
          this.$refs.steps.classList.remove("hide-visibility")
      }, this.animation_ms)
    },
    update_next_button() {
      if (this.step_index == this.form_step_list.length) {
        this.next_button_text = this.submit_text
        this.next_button_function = this.on_submit
      } else {
        this.next_button_text = "Volgende"
        this.next_button_function = this.next_step
      }
    },
    on_submit() {
      this.$emit("submit")
    },
    previous_step() {
      this.hide_animation(this.form_step_list[this.step_index - 1]);
      this.step_index -= 1

      if (this.step_index == 0) {
        this.$router.back()
      } else {
        // Wait for animation to finish and show previous step
        setTimeout(() => {
          this.show_element(this.form_step_list[this.step_index - 1]);
          this.update_next_button()
        }, this.animation_ms + 5)      
      }      
    },
    next_step() {
      this.hide_animation(this.form_step_list[this.step_index - 1]);
      this.step_index += 1

      // Wait for animation to finish and show next step
      setTimeout(() => {
        this.show_element(this.form_step_list[this.step_index - 1]);
        this.update_next_button()
      }, this.animation_ms + 5)
    },
    go_to_step(index) {
      this.hide_animation(this.form_step_list[this.step_index - 1]);
      this.step_index = index

      setTimeout(() => {
        this.show_element(this.form_step_list[this.step_index - 1]);
        this.update_next_button()
      }, this.animation_ms + 5)
    },
    hide_animation(el) {
      el.classList.add("hide-display")
      setTimeout(() => this.hide(el), this.animation_ms)
    },
    hide(el) {
      el.classList.add("display-none")
    },
    show_element(el) {
      el.classList.remove("display-none")
      el.classList.remove("hide-display")
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style>
@media screen and (min-width:992px) {
  .form-size-desktop {
    width: 700px;
  }
  .button-size-desktop {
    height: 40px;
    line-height: 5px;
    width: 100%;
    max-width: 150px;
  }
}

@media screen and (min-width:1300px) {
  .form-size-desktop {
    width: 800px;
  }
  .button-size-desktop {
    height: 50px;
    width: 100%;
    max-width: 200px;
  }

  .form-content.desktop {
    padding-top: 50px; 
  }
}

.row {
  display: flex;
  flex-direction: row;
}
.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.hide-visibility {
  opacity: 0;
  z-index: -1;
}

.hide-display {
  opacity: 0;
  z-index: -1;
}

.display-none {
  display: none;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-wrapper.desktop {
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
}

.form.mobile {
  height: 100vh;
  width: 100vw;
  margin-top: -50px;
  background-color: var(--primary-color-sub)
}

.form.desktop {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color-sub)
  /* -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); */
}

.form-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  max-width: 550px;
}

.form-content.mobile {
  padding-top: 0;
  padding-bottom: 60px;
  overflow-x: hidden;
}

.form-content.desktop {
  overflow-x: hidden;
  max-height: calc(100vh - 200px);
}

.form-controls-wrapper {
  width: 100%;
}

.form-controls {
  display: flex;
  flex-direction: row;
  padding: 10px 0;
  background: rgb(33,34,47);
  background: linear-gradient(0deg, rgba(33,34,47,1) 0%, rgba(33,34,47,0.9) 100%);
}

.form-controls.desktop {
  -webkit-box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.15); 
  box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.15);
}

.form-controls.mobile {
  -webkit-box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.25); 
  box-shadow: 0px -10px 15px -3px rgba(0,0,0,0.25);
}

.form-controls div {
  justify-content: space-between;
  align-items: center;
}


.form-controls-btn {
  padding: 10px 5px;
  width: 100px;
}

.form-controls-btn.back:disabled {
  visibility: hidden;
  opacity: 0;
}

.form-controls.desktop {
  border-radius: 0 0 10px 10px;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}

.submit:hover {
  cursor: pointer;
}

input:focus {
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    color: var(--primary-color);
}

input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

</style>
