<template>
  <svg style="width: 80px; height: 80px;" id="ecQHiYmD0u11" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 13.522899 13.520253" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><g id="ecQHiYmD0u12" transform="translate(-77.484864-139.049002)"><g transform="matrix(.264583 0 0 0.264583 77.2256 138.311)"><g><path d="M15.66,19.86c2.53-3.24,6.48-5.33,10.91-5.32.04,0,.08,0,.11,0l21.37.02C43.52,7.49,35.6,2.81,26.58,2.79C22.69,2.78,19,3.65,15.69,5.2Z" fill="#00aeef"/><path d="M18.06,39.24c-3.24-2.53-5.33-6.48-5.32-10.91c0-.1.01-.2.01-.3L12.79,6.8C5.7,11.34,1,19.27,0.98,28.3c-.01,3.9.86,7.6,2.42,10.91Z" fill="#00aeef"/><path d="M37.45,36.83c-2.53,3.25-6.48,5.33-10.92,5.33-.11,0-.23-.01-.34-.01L5,42.11c4.53,7.07,12.46,11.77,21.49,11.78c3.91.01,7.62-.87,10.93-2.43Z" fill="#00aeef"/><path d="M35.04,17.46c3.25,2.53,5.33,6.48,5.33,10.92c0,.05,0,.1,0,.15l-.04,21.34c7.06-4.54,11.74-12.45,11.76-21.47.01-3.9-.86-7.61-2.42-10.92Z" fill="#00aeef"/></g></g></g>
  <animateTransform
    attributeName="transform"
    attributeType="XML"
    type="rotate"
    from="0 0 0"
    to="360 0 0"
    dur="10s"
    repeatCount="indefinite"
  />
  </svg>
</template>

<script>
export default {
  name: "OibLoadingAnimation",
};
</script>
