<template>
  <button :class="'menu-item ' + hover">
    <div class="wrapper row" style="align-items: center">
      <div class="column" style="padding-right: 30px;">
        <div class="title">
          <slot name="title"></slot>
        </div>
        <p v-if="description" class="sub-title">{{description}}</p>
      </div>
      <slot name="right"></slot>
    </div>
  </button>
</template>

<script>
export default {
  name: "OibMenuItem",
  props: ["description", "hover"]
}
</script>

<style scoped>
.menu-item {
  padding: 10px 0 10px 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
}

.menu-item .title {
  font-weight: 400;
  font-size: 1.2em;
}

.menu-item .sub-title {
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.hover:hover {
  background-color: var(--primary-color-sub);
  cursor:pointer;
}

.hover {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.hover:hover {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
</style>