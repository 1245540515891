<template>
  <div
  class="row tag-tile"
  style="margin-bottom: 5px; border-radius: 4px; padding: 10px; -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);"
  :style="`background-color: ${color ? color : '#F99F38'}`"
  @click="go_to_search_category()"
  >
      <h4 style="margin: 0; min-width: 90px; max-width: 90px; text-transform: capitalize;">{{title}}</h4>
      <img :src="img_src" alt="" class="drop-shadow" style="width: 60px; height: 60px; transform: translate(0px, 20px) rotate(25deg); background-color: white; border-radius: 2px; padding: 5px;" />
  </div>
</template>

<script>
import {get_book_tag_img} from "../store/api/library.js"

export default {
  name: "OibTagTile",
  props: {
    tag_id: {
      required: true      
    },
    tag_name: {
        type: String,
        required: true
    },
    tag_name_alias: {
        default: null,
        required: false
    },
    color: {
        type: String,
        default: '#F99F38'
    }
  },
  data() {
    return {
      title: null,
      img_src: null
    }
  },
  created() {
    this.img_src = get_book_tag_img(this.tag_name)
    this.title = this.parse_title()
  },
  methods: {
    parse_title() {
      const title = this.tag_name_alias ? this.tag_name_alias : this.tag_name
      return title.replaceAll(' en ', ' & ')
    },
    go_to_search_category() {
      this.$router.push({
        name: "BibliotheekZoekenThema",
        params: {
          tag_id: this.tag_id,
          tag_name: this.tag_name,
          tag_name_alias: this.tag_name_alias ? this.tag_name_alias : this.tag_name,
          color: this.color          
        }
      })
    },
  }
}
</script>

<style scoped>

.tag-tile {
  width: 130px;
  height: 80px;
  transition: scale .15s;
}

.tag-tile:hover {
  cursor: pointer;
  scale: 1.08;
}
</style>