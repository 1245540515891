<template>
  <oib-page>
    <oib-nav-student title="Bieb verwijderen"/>
    <oib-content >
      <oib-menu-item
      description="Type de naam van de bibliotheek die je wil verwijderen."
      style="padding-top: 20px;"
      >
        <template v-slot:title>
          <oib-icon-label
          style="color: white;"
          icon="delete"
          title="Bevestigen"
          direction="row"
          :active="false"
          />
        </template>
      </oib-menu-item>
      <div class="wrapper column">
        <input type="text" :placeholder="library_name_start" v-model="library_name"/>
        <oib-button
          shape="rounded"
          style="margin-top: 50px;"
          :disabled="!is_valid_form"
          @click="submit"
          >Verwijderen</oib-button>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"
import OibButton from "../components/OibButton.vue"

import {delete_libraries} from "../store/api/library.js"
import {reload_libraries} from "../store/library.js"

export default {
  name: "BibliotheekVerwijderen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel,
    OibButton
  },
  data() {
    return {
      library_name_start: "",
      library_name: "",
      error_msg: " ",
      building_access_set: new Set(),
      classroom_access_set: new Set()
    }
  },
  computed: {
    is_valid_form() {
      return this.library_name_start == this.library_name
    },
  },
  methods: {
    async submit() {
      const jwt = this.$store.getters.get_jwt
      const library_id = this.$store.getters.get_active_library_id
      const user_id = this.$store.getters.getUser['user_id']

      // Delete library
      await delete_libraries(jwt, library_id)

      // Reload libraries
      await reload_libraries()

      // Go back to library management screen
      this.$router.push({
        name: 'BibliotheekCollectie',
        params: {
          user_id: user_id
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  mounted() {
    const library_id = this.$store.getters.get_active_library_id
    if (library_id == 0) {
      return
    }
    const library = this.$store.getters.get_library_dict[library_id]
    this.library_name_start = library['library_name']
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}
</style>