<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Bieb toevoegen" @submit="submit">
    <div>
      <oib-input-text
      label="Naam"
      placeholder="Prentenboeken"
      :required="true"
      :error_msg="library_name_error"
      :init_value="library_name"
      @input="set_library_name($event.target.value)"
      />
    </div>
    <div>
      <oib-input-image-preset
      label="Foto"
      description="Een foto of afbeelding om in één oogopslag de bieb te identificeren."
      :required="true"
      :init_value="image_url_init"
      :img_list_suggest="img_list_suggest"
      @input="x => set_image(x)"
      />
    </div>
    <div>
      <oib-input-multi-select
      label="Locatie type"
      description="Waar staat deze bibliotheek?"
      :allow_multi_select="false"
      :required="true"
      :tile_width="140"
      :options="location_category_selectors"
      :keys_init="get_location_category_index()"
      :sort_alphabetically="false"
      @select="x => set_location_category(location_category_selectors[parseInt(x[0])])"
      />
      <oib-input-multi-select
      v-if="location_category == 'Schoolgebouw'"
      label="Schoolgebouw"
      description="Selecteer de locatie van deze bibliotheek. Er is slechts een antwoord mogelijk."
      :allow_multi_select="false"
      :required="true"
      :tile_width="140"
      :options="building_dict"
      @select="x => set_location(x)"
      />
      <oib-input-multi-select
      v-if="location_category == 'Klaslokaal'"
      label="Klaslokaal"
      description="Selecteer de locatie van deze bibliotheek. Er is slechts een antwoord mogelijk."
      :allow_multi_select="false"
      :required="true"
      :tile_width="140"
      :options="classroom_dict"
      @select="x => set_location(x)"
      />
      <oib-input-text
      v-if="location_category == 'Anders'"
      label="Naam"
      placeholder="Locatie naam"
      :required="true"
      @input="set_location_name($event.target.value)"
      />
    </div>
    <div v-for="(building, index) in building_list" :key="building.building_id">
      <oib-form-header :header="building.building_name"/>
      <oib-input-multi-select
      v-if="Object.keys(get_building_classrooms_names(building.building_id)).length > 0"
      label="Toegang"
      :description="`Selecteer de klaslokalen op de ${building.building_name} die toegang hebben tot deze bibliotheek.`"
      :allow_multi_select="true"
      :required="true"
      :options="get_building_classrooms_names(building.building_id)"
      :keys_init="classroom_access_index_set_dict[building.building_id]"
      @select="x => set_classroom_access(index, x)"/>
      <div
      v-if="Object.keys(get_building_classrooms_names(building.building_id)).length == 0"
      >
        <label>Toegang<span style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
        <p class="description">Selecteer de klaslokalen op de {{building.building_name}} die toegang hebben tot deze bibliotheek.</p>
        <p class="description">Deze functie wordt geactiveerd zodra de De Leesapp is gekoppeld aan het leerlingvolgsysteem.</p>
      </div>
    </div>    
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibInputImagePreset from "../components/oib_form/oib_input_image_preset.vue"
import OibInputMultiSelect from "../components/oib_form/oib_input_multi_select.vue"

export default {
  name: "OibFormLibrary",
  props: {
    library_id: {
      default: null
    },
    library_name_init: {
      type: String,
      default: ""
    },
    building_id_init: {
      default: null
    },
    classroom_id_init: {
      default: null
    },
    classroom_access_id_set_init: {
      default: null
    }
  },
  components: {
    OibForm,
    OibFormHeader,
    OibInputText,
    OibInputImagePreset,
    OibInputMultiSelect
  },
  data() {
    return {
      form_step_validation_list: [],
      library_name: "",
      library_name_error: null,
      organisation_id: null,
      location_category: "",
      location_category_selectors: ['Schoolgebouw', 'Klaslokaal', 'Anders'],
      building_id: null,
      classroom_id: null,
      building_list: [],
      building_dict: {},
      classroom_list: [],
      classroom_dict: {},
      classroom_access_index_set_dict: {},
      classroom_access_set_dict: {},
      image_url_init: null,
      image_file: null,
      library_name_dict: {},
      suggestion_map: {
        '^a ': 'a',
        ' a$': 'a',
        ' a ': 'a',
        '^a-': 'a',
        '-a$': 'a',
        ' a-': 'a',
        '-a ': 'a',
        '^b ': 'b',
        ' b$': 'b',
        ' b ': 'b',
        '^b-': 'b',
        '-b$': 'b',
        ' b-': 'b',
        '-b ': 'b',
        '^c ': 'c',
        ' c$': 'c',
        ' c ': 'c',
        '^c-': 'c',
        '-c$': 'c',
        ' c-': 'c',
        '-c ': 'c',
        'prent': 'prent',
        'info': 'info',
        'voorle': 'voorlees',
        'strip': 'strip',
        'start': 'avi_start',
        'm3': 'avi_m3',
        'e3': 'avi_e3',
        'm4': 'avi_m4',
        'e4': 'avi_e4',
        'm5': 'avi_m5',
        'e5': 'avi_e5',
        'm6': 'avi_m6',
        'e6': 'avi_e6',
        'm7': 'avi_m7',
        'e7': 'avi_e7',
        'plus': 'avi_plus',
        '5/6': 'groep_5_6',
        '5-6': 'groep_5_6',
        '5 6': 'groep_5_6',
        '7/8': 'groep_7_8',
        '7-8': 'groep_7_8',
        '7 8': 'groep_7_8',
        'engel': 'engels'
      },
      img_map: {
        'a': require('@/assets/labels/oib/a.png'),
        'b': require('@/assets/labels/oib/b.png'),
        'c': require('@/assets/labels/oib/c.png'),
        'prent': require('@/assets/labels/oib/prent.png'),
        'info': require('@/assets/labels/oib/info.png'),
        'strip': require('@/assets/labels/oib/strip.png'),
        'voorlees': require('@/assets/labels/oib/voorlees.png'),
        'avi_start': require('@/assets/labels/oib/avi_start.png'),
        'avi_m3': require('@/assets/labels/oib/avi_m3.png'),
        'avi_e3': require('@/assets/labels/oib/avi_e3.png'),
        'avi_m4': require('@/assets/labels/oib/avi_m4.png'),
        'avi_e4': require('@/assets/labels/oib/avi_e4.png'),
        'avi_m5': require('@/assets/labels/oib/avi_m5.png'),
        'avi_e5': require('@/assets/labels/oib/avi_e5.png'),
        'avi_m6': require('@/assets/labels/oib/avi_m6.png'),
        'avi_e6': require('@/assets/labels/oib/avi_e6.png'),
        'avi_m7': require('@/assets/labels/oib/avi_m7.png'),
        'avi_e7': require('@/assets/labels/oib/avi_e7.png'),
        'avi_plus': require('@/assets/labels/oib/avi_plus.png'),
        'groep_5_6': require('@/assets/labels/oib/groep_5_6.png'),
        'groep_7_8': require('@/assets/labels/oib/groep_7_8.png'),
        'engels': require('@/assets/labels/oib/engels.png'),
      }
    }
  },
  computed: {
    img_list_suggest() {
      const keys = Object.keys(this.suggestion_map)
      const img_list_suggest = []
      keys.forEach(search_key => {
        const asset_key = this.suggestion_map[search_key]
        const src = this.img_map[asset_key]
        if (this.library_name.toLowerCase().match(search_key) && !img_list_suggest.includes(src)) {
          img_list_suggest.push(src)
        }
      })

      return img_list_suggest
    }
  },
  created() {

    this.organisation_id = this.$store.getters.get_organisation_id_active

    this.$store.getters.get_library_list.forEach(x => {
      this.library_name_dict[x['library_name'].toLowerCase()] = x['library_id']
    })

    this.building_list = this.$store.getters.get_building_list
    this.$store.getters.get_building_list.forEach(x => {
      this.building_dict[x['building_id']] = x['building_name']
    })
    this.classroom_list = this.$store.getters.get_classroom_list

    this.classroom_list.forEach(x => {
      this.classroom_dict[x['classroom_id']] = x['classroom_name']
    })

    // Init validation list
    this.form_step_validation_list = [false, false, false]
    for (let i = 0; i < this.building_list.length; i++) {
      this.form_step_validation_list.push(true)
    }

    // Init classroom access with empty sets
    for (let i = 0; i < this.building_list.length; i++) {
      this.set_classroom_access(i, new Set())
    }    
  },
  methods: {
    get_location_category_index() {
      if (this.location_category == 'Schoolgebouw') {
        return new Set([0])
      } else if (this.location_category == 'Klaslokaal') {
        return new Set([1])
      } else if (this.location_category == 'Anders') {
        return new Set([2])
      }
      return null
    },
    get_building_classrooms(building_id) {
      let classroom_list = []
      this.classroom_list.forEach((x) => {
        if (x['building_id'] == building_id) {
          classroom_list.push(x)
        }
      })
      return classroom_list
    },
    get_building_classrooms_names(building_id) {
      let name_dict = {}
      this.get_building_classrooms(building_id).forEach(x => {
        name_dict[x['classroom_id']] = x['classroom_name']
      })
      return name_dict
    },
    set_library_name(value) {
      this.library_name = value
      this.val_step_1()
    },
    set_image(value) {
      if (value.size > 250) {
        this.image_file = value
        this.val_step_2()
      }      
    },
    set_classroom_access(step_index, indices) {
      const building_id = this.building_list[step_index]['building_id']
      this.classroom_access_set_dict[building_id] = new Set(indices)
    },
    set_location_category(location_category) {

      // Update state
      this.building_id = null
      this.classroom_id = null
      this.location_name = null
      this.location_category = location_category

      this.val_step_3()
    },
    set_location(index) {
      if (this.location_category == 'Schoolgebouw') {
        this.set_building_id(index)
      } else if (this.location_category == 'Klaslokaal') {
        this.set_classroom_id(index)
      }
    },
    set_building_id(building_id) {
      this.building_id = building_id
      this.set_location_name(this.$store.getters.get_building_dict[building_id].building_name)      
    },
    set_classroom_id(classroom_id) {
      this.classroom_id = classroom_id
      this.set_location_name(this.$store.getters.get_classroom_dict[classroom_id].classroom_name)      
    },
    set_location_name(location_name) {
      this.location_name = location_name
      this.val_step_3()
    },
    val_step_1() {

      // Were all required inputs filled?
      const data_entered = this.library_name != ""

      // Is the library a duplicate?
      const name_lower = this.library_name.toLowerCase()
      const duplicate_name = name_lower in this.library_name_dict
      const is_duplicate = duplicate_name && this.library_name_dict[name_lower] != this.library_id

      this.library_name_error = is_duplicate ? "Er bestaat al een bieb met deze naam op school." : null

      // Set validation
      this.form_step_validation_list[0] = data_entered && !is_duplicate
    },
    val_step_2() {
      this.form_step_validation_list[1] = this.image_file != null
    },
    val_step_3() {
      this.form_step_validation_list[2] = this.location_name != null && this.location_name != ''
    },
    async submit() {

      // Get classrooms from all buildings that have access to this library
      let classroom_access_set = new Set()
      for (let building of this.building_list) {
        const building_id = building['building_id'].toString()
        const access_set = this.classroom_access_set_dict[building_id]
        classroom_access_set = new Set([...classroom_access_set, ...access_set]);
      }

      // Publish submit event
      this.$emit("submit", {
        'organisation_id': this.organisation_id,
        'organisation_id_owner': this.organisation_id,
        'library_name': this.library_name,
        'location_name': this.location_name,
        'classroom_access_id_set': classroom_access_set,
        'image_file': this.image_file
      })
    }
  }
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>