<template>
  <oib-page>
    <oib-nav-student title="Bieb" :allowLogout="true" :show_back="false">
      <template v-slot:header><slot name="header">
        <oib-header-search
        :init_search_query="search_query"
        placeholder="Boeken zoeken"
        @click_searchbar="go_to_route('BibliotheekZoeken')"
        >
          <template v-slot:left>
            <oib-button-icon icon="settings" @click="go_to_route('MenuBibliotheek')" style="font-size: 24px;"/>
          </template>
          <template v-slot:search_collection>
            <oib-library-picker-button-inline/>
          </template>
          <template v-slot:on_empty_search>
            <oib-button-book-scan style="padding-right: 20px;" />
          </template>
        </oib-header-search>
      </slot></template>
    </oib-nav-student>

    <oib-content padding_top='padding_top'>
      <oib-tab-bibliotheek tab_active="collectie" />
      <div class="wrapper column">
        <oib-hint
        cookie_id="voeg_een_boek_toe"
        style="max-width: 770px;"
        title="Voeg een boek toe aan de bibliotheek"
        :disable_mute="$store.getters.get_library_selector_list.length > 0 && total_book_count == 0"
        >
        Klik linksboven op <span class="material-icons" style="font-size: 1em;">settings</span> en op 'Inscannen' om boeken aan deze bieb toe te voegen.
        </oib-hint>
        <oib-hint 
        cookie_id="voeg_een_bibliotheek_toe"
        style="max-width: 770px;"
        title="Voeg een bibliotheek toe"
        :disable_mute="$store.getters.get_library_selector_list.length == 0"
        >
          Klik linksboven op <span class="material-icons" style="font-size: 1em;">settings</span> om een bibliotheek toe te voegen.
        </oib-hint>
        <oib-hint 
        v-if="$store.getters.get_library_selector_list.length > 0"
        cookie_id="zoek_een_boek"
        style="max-width: 770px;"
        title="Hoe zoek je een boek?"
        href="https://leesapp.onderwijsinbeeld.nl/functies/boeken-zoeken"
        >
          Bekijk de instructievideo
        </oib-hint>
      </div>

      <!--- analytics -->
      <div class="fade" :style="loaded_book_slides ? 'opacity: 100' : 'opacity: 0'">
        <div v-if="tile_list_last_added.length > 0">
          <div class="wrapper">
            <div class="row section-header">
              <h2 style="margin-top: 0">Onlangs toegevoegd</h2>
              <p @click="go_to_search_filter({'sort': 'Onlangs toegevoegd'})">Bekijk alles ➜</p>
            </div>
          </div>
          <oib-book-swiper
          v-if="tile_list_last_added"
          :user_id="user_id"
          :tile_list="tile_list_last_added"
          :show_availability="false"
          :show_view_more="false"
          style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px;"
          />
        </div>

        <div v-if="tile_list_most_popular.length > 0">
          <div class="wrapper">
            <div class="row section-header">
              <h2 class="h2">Biebtoppers</h2>
              <p @click="go_to_search_filter({'sort': 'Meest gelezen'})">Bekijk alles ➜</p>
            </div>
          </div>
          <oib-book-swiper
          v-if="tile_list_most_popular"
          :user_id="user_id"
          :tile_list="tile_list_most_popular"
          :show_view_more="false"
          style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px"
          />
        </div>
      </div>

      <oib-lazy>
        <div v-if="total_book_count > 0" class="fade" :style="total_book_count > 0 ? 'opacity: 100': 'opacity: 0'">
          <div class="wrapper column" style="margin-bottom: 40px;">
            <div class="row section-header">
              <h2>Collectie analyse</h2>
              <p>Totaal {{total_book_count}}</p>
            </div>
            <div style="height: 1px; width: 100%; max-width: 1342px; background-color: var(--contrast-color-p4);"/>
          </div>
          <oib-swiper style="padding-left: 5%; margin-bottom: 40px">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="auto_delete"
              icon_family="symbol"
              header="Saneren"
              style="width: 270px; margin-right: 50px;"
              >
              <p>Hoe ouder het boek, hoe minder vaak het gekozen wordt. We zien meestal een flinke afname bij boeken ouder dan 2010. Er is veel gebeurd in de afgelopen jaren en de kinderen waren in 2010 nog niet eens geboren. Slijtage, verkleuring, oude informatie en ouderwetse thema's zijn de primaire oorzaken.</p>
              <p>Gebruik de lijsten in de tabel om doelgericht onaantrekkelijk boeken te saneren.</p>
              </oib-section-info>
              <div class="column">
                <table class="styled-table" style="table-layout: fixed; margin: 0">
                  <thead>
                    <tr>
                      <th style="min-width: 130px">Datum uitgifte</th>
                      <th style="min-width: 60px">Aantal</th>
                      <th style="min-width: 60px">Geleend</th>
                      <th style="min-width: 60px">Boekenlijst</th>
                    </tr>
                  </thead>
                  <tbody style="width: 300px;">
                    <tr v-for="x in book_release_year_data" :key="x">
                      <th>{{x.label}}</th>
                      <td>{{x.copies_total}}</td>
                      <td>{{x.borrow_count_sum}}x</td>
                      <td @click="go_to_search_filter(x.filter)">Bekijk ➜</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
          <oib-swiper style="padding-left: 5%; margin-top: 50px">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="exit_to_app"
              icon_family="symbol"
              header="Boeken verplaatsen"
              style="width: 270px; margin-right: 50px;"
              >
              <p>Het is belangrijk om aan iedere doelgroep een breed aanbod te presenteren. Dat belang wordt verder versterkt wanneer de boeken verdeelt zijn over klassen, omdat het aanbod dan minder breed is. Staan er boeken in de klassenbieb die beter naar een andere klas verplaatst kunnen worden?</p>
              <p>Gebruik de lijsten in de tabel om boeken te vinden die niet aansluiten bij de doelgroep van de bieb.</p>
              </oib-section-info>
              <div class="column">
                <table class="styled-table" style="table-layout: fixed; margin: 0">
                  <thead>
                    <tr>
                      <th style="min-width: 130px">Leeftijd</th>
                      <th style="min-width: 60px">Aantal</th>
                      <th style="min-width: 60px">Geleend</th>
                      <th style="min-width: 60px">Boekenlijst</th>
                    </tr>
                  </thead>
                  <tbody style="width: 300px;">
                    <tr v-for="x in book_age_data" :key="x">
                      <th>{{x.label}}</th>
                      <td>{{x.copies_total}}</td>
                      <td>{{x.borrow_count_sum}}x</td>
                      <td @click="go_to_search_filter(x.filter)">Bekijk ➜</td>
                    </tr>
                  </tbody>
                </table>
                <h5 style="width: 100%; text-align: right; margin-bottom: 0;">* Sommige boeken passen binnen meerdere leeftijdscategorieën</h5>
              </div>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
          <oib-swiper style="padding-left: 5%; margin-top: 50px;">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="tips_and_updates"
              icon_family="symbol"
              header="Divers aanbod"
              style="width: 270px; margin-right: 50px;"
              >
              <p>De meeste schoolbibliotheken bevatten vrijwel alleen leesboeken, maar niet iedereen vindt het leuk om een altijd een leesboek te lezen. Leerkrachten hebben behoefte aan boeken die zijn geschreven om voor te lezen. Leergierige leerlingen lezen graag informatieve boeken en soms is het gewoon lekker om er een stripboek bij te pakken. Het belangrijkste is dat de leerlingen met plezier blijven lezen.</p>
              <p>Hoe breed is het aanbod in jullie schoolbieb?</p>
              </oib-section-info>
              <table class="styled-table" style="table-layout: fixed; margin: 0">
                <thead>
                  <tr>
                    <th style="min-width: 130px">Boek categorie</th>
                    <th style="width: 60px">Aantal</th>
                    <th style="width: 60px">Geleend</th>
                    <th style="width: 60px">Boekenlijst</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="x in book_type_data" :key="x">
                    <th>{{x.label}}</th>
                    <td>{{x.copies_total}}</td>
                    <td>{{x.borrow_count_sum}}x</td>
                    <td @click="go_to_search_filter(x.filter)">Bekijk ➜</td>
                  </tr>
                </tbody>
              </table>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
          
          <!-- Only show if all libraries are selected and student_count > 0 (does not make outside of this context) -->
          <oib-swiper v-if="student_count > 0 && active_library_selector == 0" style="padding-left: 5%; margin-top: 50px;">
            <div class="wrapper row" style="width: 100%">
              <oib-section-info
              img_url=""
              icon="timeline"
              icon_family="symbol"
              header="Investeringsplan"
              style="width: 270px; margin-right: 50px;"
              >
              <p>Om de kwaliteit van de bibliotheek te borgen is het belangrijk om jaarlijks 10% van de boeken te saneren en budget te reserveren voor de aanschaf van nieuwe boeken.</p>
              <p>De eerste jaren werk je toe naar de optimale collectie omvang voor jullie school. Zodra je een optimale collectie hebt bereikt, vervang je enkel nog jaarlijks de gesaneerde boeken.</p>
              <oib-button
                style="height: 40px; margin-bottom: 10px;"
                priority="p1"
                @click="go_to_investeringsplan"
                >Maak een investeringsplan
              </oib-button>
              </oib-section-info>
              <div style="max-width: 450px; margin-right: 40px">
                <table class="styled-table" style="table-layout: fixed; margin: 0">
                  <thead>
                    <tr>
                      <th style="min-width: 70px">Omvang</th>
                      <th style="width: 90px">Boeken/<br>leerling</th>
                      <th style="width: 100px">Aantal boeken</th>
                      <th style="width: 100px">Jaarlijks<br>saneren</th>
                      <th style="width: 100px">Jaarlijks<br>budget</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th style="width: 100px">Huidig</th>
                      <td style="width: 90px">{{ (total_book_count / student_count).toFixed(2) }}</td>
                      <td style="width: 100px">{{ total_book_count }}</td>
                      <td style="width: 100px">{{ (total_book_count * 0.1).toFixed(0) }}</td>
                      <td style="width: 100px">€{{ (total_book_count * 0.1 * book_price).toFixed(0) }},-</td>
                    </tr>
                    <tr v-for="x in book_investment_data" :key="x">
                      <th style="width: 100px">{{x.label}}</th>
                      <td style="width: 90px">{{x.count}}</td>
                      <td style="width: 100px">{{x.count * student_count}}</td>
                      <td style="width: 100px">{{(x.count * student_count * 0.1).toFixed(0)}}</td>
                      <td style="width: 100px">€{{(x.count * student_count * 0.1 * book_price).toFixed(0)}},-</td>
                    </tr>
                  </tbody>
                </table>
                <h5 style="width: 100%; text-align: right">* Bij een jaarlijkse sanering van 10%, €{{book_price}},- per boek en {{student_count}} leerlingen</h5>
              </div>
              
              <div style="width: 50px;"/>
            </div>
          </oib-swiper>
        </div>
      </oib-lazy>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue";
import OibBookSwiper from "../components/OibBookSwiper.vue";
import OibButtonIcon from '../components/OibButtonIcon.vue'
import OibSwiper from "../components/oib_swiper.vue"
import OibSectionInfo from "../components/oib_section_info.vue"
import OibButtonBookScan from '../components/oib_button_book_scan.vue'
import OibButton from "../components/OibButton.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibLibraryPickerButtonInline from '../components/oib_library_picker_button_inline.vue'
import OibLazy from '../components/oib_lazy.vue'
import OibTabBibliotheek from '../components/oib_tab_bibliotheek.vue'
import OibHint from '../components/oib_hint.vue'

import { parse_query } from '../store/request'
import {get_books} from "../store/api/library"
import {init_library_selectors} from "../store/library"
import { get_datetime_str } from '../store/utils'
import {has_role} from '../store/utils'
import {go_to_route} from '../store/navigation'
import { get_libraries_metrics_books_release_years } from '../store/api/library_metrics'
import { get_libraries_metrics_books_ages } from '../store/api/library_metrics'
import { get_libraries_metrics_books_types } from '../store/api/library_metrics'

export default {
  name: "BookProgress",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibBookSwiper,
    OibButtonIcon,
    OibSwiper,
    OibSectionInfo,
    OibButtonBookScan,
    OibButton,
    OibHeaderSearch,
    OibLibraryPickerButtonInline,
    OibLazy,
    OibTabBibliotheek,
    OibHint
  },
  computed: {
    active_library_selector() {
      return this.$store.getters.get_active_library_selector
    },
    active_book_edition_id() {
        const book = this.store.getters.getBook(isbn13)
        return book ? book.book_edition_id : null
    },
    selector_name() {
      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    },
    tile_list_last_added() {
      const book_dict = this.$store.getters.get_books_last_added_dict
      if (this.active_library_selector in book_dict) {
        return book_dict[this.active_library_selector]
      }
      return []
    },
    tile_list_most_popular() {
      const book_dict = this.$store.getters.get_books_most_popular_library_dict
      if (this.active_library_selector in book_dict) {
        return book_dict[this.active_library_selector]
      }
      return []
    },
    total_book_count() {
      const book_count_dict = this.$store.getters.get_book_count_library_dict
      if (this.active_library_selector in book_count_dict) {
        return book_count_dict[this.active_library_selector]
      } else {
        return 0
      }
    },
    book_release_year_data() {
      const release_year_dict = this.$store.getters.get_book_release_year_library_dict
      if (this.active_library_selector in release_year_dict) {
        return release_year_dict[this.active_library_selector]
      }
      return []
    },
    book_type_data() {
      const book_type_dict = this.$store.getters.get_book_type_library_dict
      if (this.active_library_selector in book_type_dict) {
        return book_type_dict[this.active_library_selector]
      }
      return []
    },
    book_age_data() {
      const age_dict = this.$store.getters.get_book_age_library_dict
      if (this.active_library_selector in age_dict) {
        return age_dict[this.active_library_selector]
      }
      return []
    },
  },
  data() {
    return {
      search_query: "",
      tile_list_author_recommendations: [],
      tile_list_series_recommendations: [],
      book_title: "",
      author_name: "",
      series_title: "",
      user_id: null,
      student_count: 0,
      book_limit: 8,
      loaded_book_slides: false,
      has_libraries: false,
      book_price: 16,
      book_investment_data: [
        {'label': 'Minimaal', 'count': 3},
        {'label': 'Voldoende', 'count': 5},
        {'label': 'Optimaal', 'count': 7}
      ],
      chart: {
        type: 'bar'
      },
    }
  },
  created() {
    // Skip if logged out
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    this.user_id = user['user_id']
    this.student_count = this.$store.getters.get_school.student_count

    init_library_selectors()
    const library_selector_list = this.$store.getters.get_library_selector_list
    this.has_libraries = library_selector_list.length > 0

    if (this.has_libraries) {
      this.load_book_slides()
    }
  },
  async mounted() {

    document.documentElement.scrollTop = 0

    // Skip if logged out
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    this.$store.commit("set_search_query", '')
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  watch: {
    active_library_selector() {

      // Return if user is unknown
      const user = this.$store.getters.getUser
      if (!user) {
        return
      }

      this.load_book_slides()
    }
  },
  methods: {
    // import
    has_role,
    go_to_route,

    // local
    async load_book_slides() {
      this.loaded_book_slides = this.tile_list_last_added.length > 0 && this.tile_list_most_popular.length > 0
      await Promise.all([
        this.load_last_added(),
        this.load_most_popular()
      ])
      this.loaded_book_slides = true
    },
    load_collection_analyses() {
      Promise.all([
        this.load_book_type_data(),
        this.load_book_age_data(),
        this.load_book_release_year_data()
      ])
    },
    go_to_investeringsplan() {
      const school = this.$store.getters.get_school
      const branch_number = school['branch_number']

      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      setTimeout(() => {
        window.open(`https://onderwijsinbeeld.nl/maak-een-schoolbibliotheek-investeringsplan?id=${branch_number}&book_count=${this.total_book_count}&student_count=${this.student_count}`, '_blank');
      })
    },
    go_to_search_filter(query) {
      // Parse query
      let query_parsed = parse_query(query)
      if (query_parsed == '') {
        query_parsed = null
      }

      this.$router.push({
        name: "BibliotheekZoekenFilter",
        params: {
          query: query_parsed
        }
      });
    },
    async load_last_added() {

      const library_selector_list = this.$store.getters.get_library_selector_list
      const library_id_group = library_selector_list[this.active_library_selector]

      const jwt = this.$store.getters.get_jwt
      const query = {
        'select': 'copy_id,library_id,isbn13,book_edition_id,addition_datetime,book_title',
        'library_id': library_id_group.query['library_id'],
        'sort': 'addition_datetime,DESC',
        'limit': this.book_limit
      }

      let tile_list = []
      const json = await get_books(jwt, 'copies', query)
      json['books'].forEach( x => {
        tile_list.push({
          'isbn13': x['isbn13'],
          'book_edition_id': x['book_edition_id'],
          'book_title': x['book_title'],
          'sub_text': x['addition_datetime'],
        })
      })

      const tile_list_old = this.$store.getters.get_books_last_added_dict[this.active_library_selector]
      const changed = JSON.stringify(tile_list_old) != JSON.stringify(tile_list)
      if (changed) {
        this.$store.commit("set_books_last_added", {
          library_id: this.active_library_selector,
          books: tile_list
        })
        this.load_collection_analyses()
      }
    },
    async load_most_popular() {
      const library_selector_list = this.$store.getters.get_library_selector_list
      const library_id_group = library_selector_list[this.active_library_selector]

      const jwt = this.$store.getters.get_jwt
      const query = {
        'select': 'library_id,isbn13,book_edition_id,book_title,author_name,copies_in_use,copies_total,borrow_count_sum',
        'library_id': library_id_group.query['library_id'],
        'organisation_id': this.$store.getters.get_organisation_id_active,
        'sort': 'borrow_count_sum,DESC',
        'limit': this.book_limit
      }

      let tile_list = []
      const json = await get_books(jwt, 'editions', query)
      json['books'].forEach( x => {
        tile_list.push({
          'isbn13': x['isbn13'],
          'book_edition_id': x['book_edition_id'],
          'book_title': x['book_title'],
          'sub_text': x['author_name'],
          'marker': x['borrow_count_sum'] + 'x',
          'copies_in_use': x['copies_in_use'],
          'copies_total': x['copies_total']
        })
      })

      this.$store.commit("set_books_most_popular_library", {
        library_id: this.active_library_selector,
        books: tile_list
      })
    },
    async get_book(isbn13) {
      // Download isbn13 if unknown
      isbn13 = isbn13.toString()
      let book = this.$store.getters.getBook(isbn13)
      if (!book) {
        await this.$store.dispatch("load_books", {
          'jwt': this.$store.getters.get_jwt,
          'isbn13': isbn13
        }).then(
          () => book = this.$store.getters.getBook(isbn13)
        )
      }

      return {
        'isbn13': isbn13,
        'book_edition_id': book['book_edition_id'],
        'book_title': book['book_title'],
        'sub_text': book['author_name']
      }
    },
    is_intersection(setA, setB) {
        let _intersection = new Set()
        for (let elem of setB) {
            if (setA.has(elem)) {
                _intersection.add(elem)
            }
        }
        return _intersection.size > 0
    },
    get_isbn13_list_reading() {
      // Get user_id
      if (!(this.user_id in this.$store.getters.get_progress_state_dict)) {
        return []
      }

      const READING = 1
      return this.$store.getters.get_progress_state_dict[this.user_id][READING];
    },
    get_isbn13_list_borrowed(library_id_list) {
      let isbn13_list = []
      const books_borrowed = this.$store.getters.get_books_borrowed[this.user_id]
      library_id_list.forEach(library_id => {
        if (library_id in books_borrowed) {
          for (const book of books_borrowed[library_id]) {
            isbn13_list.push(book['isbn13'])
          }
        }
      })

      return isbn13_list
    },
    load_book_type_data() {

        const jwt = this.$store.getters.get_jwt

        get_libraries_metrics_books_types(jwt, {
            'library_id': this.get_active_library_id(),
            'organisation_id': this.$store.getters.get_organisation_id_active
        }).then(json => {
            console.log(json)
            this.set_book_type_data(json['metric_list'])
        })
    },
    set_book_type_data(data) {
        let book_type_data = []
        for (let i = 0; i < data.length; i++) {
            book_type_data.push({
                'label': data[i]['book_type_id'],
                'filter': {'sort': 'Meest gelezen', 'book_type': data[i]['book_type_id']},
                'copies_total': data[i]['copy_count'],
                'borrow_count_sum': data[i]['borrow_count']
            })
        }

        this.$store.commit("set_book_type_library", {
            library_id: this.active_library_selector,
            books: book_type_data
        })

        console.log('iiiiii')
        console.log(book_type_data)

        const total_book_count = data.reduce((partialSum, a) => partialSum + a['copy_count'], 0)
        console.log(total_book_count)
        this.set_book_count_library(total_book_count)
    },
    set_book_count_library(book_count) {
        this.$store.commit("set_book_count_library", {
            library_id: this.active_library_selector,
            book_count: book_count
        })

        if (book_count >= 100) {
            this.$store.dispatch('create_task', {
            'jwt': this.$store.getters.get_jwt,
            'task': {
                'organisation_id': this.$store.getters.get_organisation_id_active,
                'task_title': 'ADD_LIBRARY_BOOKS',
                'finish_datetime': get_datetime_str()
            }
            })
        }
    },
    load_book_age_data() {
        const jwt = this.$store.getters.get_jwt

        get_libraries_metrics_books_ages(jwt, {
            'library_id': this.get_active_library_id(),
            'organisation_id': this.$store.getters.get_organisation_id_active
        }).then(json => {
            console.log(json)
            this.set_book_age_data(json['metric_list'])
        })
    },
    set_book_age_data(data) {
        const labels = [
            '0 t/m 3',
            '4 t/m 5',
            '6 t/m 8 (A)',
            '9 t/m 11 (B)',
            '12 t/m 15 (C)',
            '16+ (D)',
            'Onbekend'
        ]

        console.log(labels)

        let book_age_data = []
        for (let i = 0; i < data.length; i++) {
            book_age_data.push({
                'label': 'x',
                'filter': {
                    'sort': 'Meest gelezen',
                    'age_known': 1,
                    'age_min': data[i]['age_min'],
                    'age_max': data[i]['age_max']
                },
                'copies_total': data[i]['copy_count'],
                'borrow_count_sum': data[i]['borrow_count'],
            })
        }

        this.$store.commit("set_book_age_library", {
            library_id: this.active_library_selector,
            books: book_age_data
        })
    },
    load_book_release_year_data() {
        const jwt = this.$store.getters.get_jwt

        get_libraries_metrics_books_release_years(jwt, {
            'library_id': this.get_active_library_id()
        }).then(json => {
            this.set_release_year_data(json['metric_list'])
        })
    },
    set_release_year_data(data) {

        let book_release_year_data = []
        for (let i = 0; i < data.length; i++) {
                book_release_year_data.push({
                    'label': 'x',
                    'filter': {
                        'sort': 'Meest gelezen',
                        'min_release_date': `${data[i]['release_year_min']}-01-01`,
                        'max_release_date': `${data[i]['release_year_max']}-01-01`
                    },
                    'copies_total': data[i]['copy_count'],
                    'borrow_count_sum': data[i]['borrow_count']
                })
        }

        this.$store.commit("set_book_release_year_library", {
            library_id: this.active_library_selector,
            books: book_release_year_data
        })
    },
    get_active_library_id() {
      const library_selector_list = this.$store.getters.get_library_selector_list
      if (this.active_library_selector != null) {
        return library_selector_list[this.active_library_selector].query['library_id']
      }
      return null      
    },
    pick_library() {
      this.$router.push({
        name: 'BibliotheekKiezenGroep',
        params: {
          'user_id': this.user_id,
          'library_route': 'back'
        }
      });
    }
  }
};
</script>

<style scoped>

.row {
  display: flex;
  flex-direction: row;
}

.section-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1342px;
}

.section-header h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.section-header p {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 0.7em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section-header p:hover {
  cursor: pointer;
}

.reset-margin {
  margin-left: 50px;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.tile-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px;
  max-width: 1200px;
}

.tile-grid div{
  width: 100%;
  height: 130px;
}

@media screen and (min-width:740px) {
  .tile-grid div{
    width: 300px;
    height: 150px;
  }
}

@media screen and (min-width:940px) {
  .tile-grid div{
    width: 350px;
    height: 130px;
  }
}


@media screen and (min-width:1250px) {
  .tile-grid div{
    width: 275px;
    height: 200px;
  }
}

@media screen and (min-width:1450px) {
  .tile-grid div{
    width: 350px;
    height: 180px;
  }
}
</style>