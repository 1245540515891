<template>
  <div>
    <h2>{{book_title}}</h2>
    <p class="author">{{author_name}}</p>
    <oib-rating :rating="rating" style="color: white"/>
  </div>
</template>

<script>
import OibRating from './oib_rating.vue'

export default {
  name: 'OibHeaderBook',
  props: ['book_title', 'author_name'],
  components: {
    OibRating
  },
  data () {
    return {
      rating: 4,
    }
  }
};
</script>

<style scoped>
/* Header */
h2 {
  margin: 0;
}

.desktop h2 {
  font-size: 50px;
}

.desktop .author {
  font-size: 20px;
}

</style>
