import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"

export async function get_libraries_metrics_products(jwt, query) {
  /* Query the metrics of library products.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/metrics/products?${query_string}`)
}

export async function get_libraries_metrics_books_borrow_count(jwt, query) {
  /* Query the metrics of library products grouped by borrow_count.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/metrics/books/borrow-count?${query_string}`)
}

export async function get_libraries_metrics_books_release_years(jwt, query) {
  /* Query the metrics of library products grouped by borrow_count.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/metrics/books/release-years?${query_string}`)
}

export async function get_libraries_metrics_books_ages(jwt, query) {
  /* Query the metrics of library products grouped by borrow_count.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/metrics/books/ages?${query_string}`)
}

export async function get_libraries_metrics_books_types(jwt, query) {
  /* Query the metrics of library products grouped by borrow_count.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/metrics/books/types?${query_string}`)
}

export async function get_libraries_metrics_books_labels_nbd(jwt, query) {
  /* Query the metrics of library products grouped by borrow_count.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/metrics/books/labels/nbd?${query_string}`)
}
