import store from './index'
import { attempt_school_partner_login } from "./user.js"
import { login_library_module } from "./library.js"
import {logout} from "./user.js"

// eslint-disable-next-line no-unused-vars
async function fetch_catch_401(request_path, msg, timeout) {
  /*
  A fetch wrapper that automatically handles a 401 status code.

  On 401 status code detected:
  - First attempt to refresh resources.
  - If JWT does not change, reload the session.

  :param str request_path: the request patch.
  :param str msg: a json string with the request body.
  :param int timeout: the maximum amount of ms the request should take.
  */

  const jwt_0 = store.getters.get_jwt
  const jwt_creation_datetime = store.getters.get_jwt_creation_datetime
  
  // Refresh session if jwt was out of date
  if (jwt_creation_datetime != null) {
    const jwt_age = new Date() - jwt_creation_datetime
    if (jwt_age > 1000 * 3600 * 24) {
      window.location.reload(true)
    }
  }

  // Run request
  let response = await fetch(get_url(request_path), msg)
  if (response.status == 401) {

    // Refresh token
    if (request_path != '/jwt/refresh/resources') {
      await store.dispatch("jwt_refresh_resources")
    }

    // Log into other modules if refresh was succesful and execute request
    const jwt_1 = store.getters.get_jwt
    if (jwt_0 == jwt_1) {
      logout()
    } else {
      await attempt_school_partner_login()
      await login_library_module()
      msg.headers.set('Authorization', store.getters.get_jwt)
      response = await fetch(get_url(request_path), msg)
    }
  }
  return response
}

export function get_uri() {
    const url = new URL(window.location.href);
    const uri = `${url.protocol}//${url.hostname}`;
    return uri
}

export function get_url(request_path) {
    return `${get_uri()}${request_path}`;
}

export function parse_query(query) {

    if (!query) return ""

    // Generate query string
    const query_keys = Object.keys(query)
    let key_value_string_pairs = []
    for (let i = 0 ; i < query_keys.length; i++) {
        key_value_string_pairs.push(`${query_keys[i]}=${query[query_keys[i]]}`)
    }
    return key_value_string_pairs.join('&')
}

export async function request_get(jwt, request_path, timeout=5000) {
  /*
  :param str jwt: the json web token that should authorize this request.
  :param str request_path: the path of the request.
  :param int timeout: the maximum amount of ms the request should take.
  */

  // run request
  const msg = {
    method: 'GET',
    headers: new Headers({
        'Authorization': jwt,
    }),
  }
  return await fetch_catch_401(request_path, msg, timeout)
}

export async function request_options(jwt, request_path) {
  /*
  :param str jwt: the json web token that should authorize this request.
  :param str request_path: the path of the request.
  */

  // run request
  return await fetch(get_url(request_path), {
      method: 'OPTIONS',
      headers: new Headers({
          'Authorization': jwt,
      }),
  })
}

export async function request_get_json(jwt, request_path, timeout=5000) {
    /*
    :param str jwt: the json web token that should authorize this request.
    :param str request_path: the path of the request.
    :param int timeout: the maximum amount of ms the request should take.
    */

    const response = await request_get(jwt, request_path, timeout);
    const response_json = await response.json();
    return response_json
}

export async function request_put(jwt, request_path, body, timeout=5000) {
    /*
    :param str jwt: the json web token that should authorize this request.
    :param str request_path: the path of the request.
    :param dict body: the body of the request.
    :
    */

    let msg = {method: 'PUT'}
    let header_dict = {'Authorization': jwt}

    if (typeof body !== 'undefined') {
      if (body instanceof FormData) {
        msg['body'] = body
      }
      else {
        header_dict['Content-Type'] = 'application/json'
        msg['body'] = JSON.stringify(body)
      }      
    }
    msg['headers'] = new Headers(header_dict)

    // Run request
    return await fetch_catch_401(request_path, msg, timeout)
}

export async function request_delete(jwt, request_path, timeout=5000) {
  /*
  :param str jwt: the json web token that should authorize this request.
  :param str request_path: the path of the request.
  :param dict body: the body of the request.
  :
  */

  let msg = {method: 'DELETE'}
  let header_dict = {'Authorization': jwt}
  if (typeof body !== 'undefined') {
    header_dict['Content-Type'] = 'application/json'
  }
  msg['headers'] = new Headers(header_dict)

  // Run request
  return await fetch_catch_401(request_path, msg, timeout)
}

export async function request_post(jwt, request_path, body, timeout=5000) {
    /*
    :param str jwt: the json web token that should authorize this request.
    :param str request_path: the path of the request.
    :param dict body: the body of the request.
    :
    */

    let msg = {}
    if (jwt == undefined) {
      msg = {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(body)
      }
      
    }
    else {
        if (body == undefined) {
          msg = {
            method: 'POST',
            headers: new Headers({
              'Authorization': jwt,
            })
          }
        }
        else if (body instanceof FormData) {
          msg = {
            method: 'POST',
            headers: new Headers({
              'Authorization': jwt,
            }),
            body: body
          }
        }
        else {
          msg = {
            method: 'POST',
            headers: new Headers({
              'Authorization': jwt,
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(body)
          }
        }
    }

    return await fetch_catch_401(request_path, msg, timeout)
}

export async function request_post_plain(request_path, body) {
  /*
  :param str jwt: the json web token that should authorize this request.
  :param str request_path: the path of the request.
  :param dict body: the body of the request.
  :
  */

  const msg = {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body)
  }

  return await fetch(get_url(request_path), msg)
}
