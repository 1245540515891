<template>
  <oib-page>
    <oib-nav-student />
    <mq-layout mq="xs-md">
      <oib-header title="Lezen">
        <template v-slot:left> <oib-button-logout /></template>
      </oib-header>
    </mq-layout>

    <oib-row-stack style="height: 100vh">
      <oib-title style="overflow: hidden" :style="gradient" title="lezen">
        <oib-row-stack>
          <oib-content>
            <div class="focused-message">
              <div class="content">
                <p class="mark">03</p>
                <div class="divider" />
                <h1 class="h1">
                  Is het stil<br />
                  om je heen?
                </h1>
                <p>
                  Zorg ervoor dat het stil is om je heen. Anders hoor ik je niet goed.
                </p>
              </div>
              <oib-button v-on:click="goToInstructie2">Het is stil</oib-button>
            </div>
          </oib-content>
          <mq-layout style="overflow: scroll; flex-basis: 100%;" mq="lg+">
            <oib-book-header :isbn13="isbn13" />
          </mq-layout>
        </oib-row-stack>
      </oib-title>
    </oib-row-stack>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibHeader from "../components/OibHeader.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue";
import OibButtonLogout from "../components/OibButtonLogout.vue";
import OibButton from "../components/OibButton.vue";
import OibRowStack from '../components/OibRowStack.vue';
import OibBookHeader from '../components/OibBookHeader.vue';
import OibTitle from '../components/OibTitle.vue';

export default {
  name: "Opdracht",
  components: {
    OibPage,
    OibHeader,
    OibContent,
    OibNavStudent,
    OibButtonLogout,
    OibButton,
    OibRowStack,
    OibBookHeader,
    OibTitle,
  },
  computed: {
    isbn13() {
      return this.$store.getters.getExerciseIsbn13;
    },
    gradient () {
      return this.$store.getters.getBookHighlightedGradient;
    },
  },
  methods: {
    goToInstructie2() {
      this.$router.push({
        name: "Instructie2",
      });
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.focused-message {
  height: initial;
  justify-content: initial;
  margin: initial;
  margin-left: 50px;
}
</style>