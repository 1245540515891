import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"

export async function get_logs(jwt, query) {
  /* Query the logs in the logging service.

  :param dict query: the query to execute.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/logs?${query_string}`)
}

export async function get_logs_metrics_query(jwt, time_unit, query) {
  /* Query the logs in the logging service.

  :param dict query: the query to execute.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/logs/metrics/${time_unit}?${query_string}`)
}
