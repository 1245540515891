import { get_libraries_books_copies } from "../api/library.js"

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default ({
    state: {

        // Library
        library_dict: {},
        library_selector_list: [],
        user_library_dict: {},
        active_library_id: 0,
        active_library_selector: 0,        

        // Borrowed books per library
        books_borrowed: {},

        // Books
        bookDict: {},

        // Conversion
        book_edition_id_to_isbn13_list: {},

        // Tips
        book_similarity_dict: {},

        series_tips_book_edition_id_dict: {},
        series_tips_book_edition_id: "",
        series_tips_series_title: "",
        series_tips_book_title: "",

        author_tips_book_edition_id_dict: {},
        author_tips_book_edition_id: "",
        author_tips_author_name: "",
        author_tips_book_title: ""
    },
    mutations: {
        // library
        set_libraries(state, library_list) {
          state.library_dict = {}
          library_list.forEach(library => state.library_dict[library['library_id']] = library)          
        },
        set_library_selector_list(state, library_selector_list) {
          state.library_selector_list = library_selector_list
        },
        set_active_library_id(state, library_id) {
          state.active_library_id = library_id
        },
        set_active_library_selector(state, selector_id) {
          state.active_library_selector = selector_id
        },
        reset_libraries(state) {
          state.library_dict = {}
          state.library_selector_list = []
          state.user_library_dict = {}
          state.active_library_id = 0
          state.active_library_selector = 0
        },

        // books borrowed
        set_books_borrowed(state, payload) {
          const user_id = payload['user_id']
          const borrowed_list = payload['borrowed_list']
          
          // init borrowed state with empty lists for each library
          state.books_borrowed[user_id] = {}
          borrowed_list.forEach(x => state.books_borrowed[user_id][x['library_id']] = [])

          // add borrowed books to their respective library list
          borrowed_list.forEach(x => state.books_borrowed[user_id][x['library_id']].push({
            'copy_id': x['copy_id'],
            'isbn13': x['isbn13'].toString(),
            'library_id': x['library_id'],
            'borrowed_datetime': x['borrowed_datetime'],
          }))
        },
        add_library_user(state, payload) {
          const user_id = payload['user_id']
          const library_id = payload['library_id']

          if (!(user_id in state.user_library_dict)) {
            state.user_library_dict[user_id] = new Set()
          }

          state.user_library_dict[user_id].add(parseInt(library_id))
        }
    },
    actions: {
        async reset_libraries(state) {
          state.commit("reset_libraries")
          state.commit("reset_library_cache")
          await sleep(100)
        },
        async load_books_borrowed(state, payload) {

            // Get book copies that user is currently borrowing
            const jwt = payload['jwt']
            const query = {
              'select': 'copy_id,library_id,isbn13,borrowed_datetime',
              'user_id': payload['user_id'],
              'limit': 100000,
              'page': 0
            }
            const json = await get_libraries_books_copies(jwt, query)
            state.commit("set_books_borrowed", {
              user_id: payload['user_id'],
              borrowed_list: json['copy_list']
            })

            // Get all isbn13_list of books that user is borrowing
            let isbn13_list = []
            json['copy_list'].forEach(x => isbn13_list.push(x['isbn13'].toString()))

            // Load all isbn13 numbers if not loaded yet
            await state.dispatch("load_books", isbn13_list)
        }
    },
    modules: {
    },
    getters: {
        // library
        get_library_dict: state => state.library_dict,
        get_library_list: state => Object.keys(state.library_dict).map(x => state.library_dict[x]),
        get_library_selector_list: state => state.library_selector_list,
        get_active_library_id: state => state.active_library_id,
        get_active_library_id_list: state => {
          if (state.active_library_selector == 0) {
            return []
          } else {
            const library_id_group = state.library_selector_list[state.active_library_selector]
            return library_id_group.query['library_id'].split(',')
          }          
        },
        get_active_library_selector: state => state.active_library_selector,
        get_books_borrowed: state => state.books_borrowed,
        get_user_library_dict: state => state.user_library_dict,
    }
})
