<template>
  <div>
    <div style="position: absolute; width: 100%; max-width: 1200px; overflow: hidden;">
      <img style="width: calc(100% + 5px); filter: blur(5px); margin: -10px 0 0 -10px" :src="cover_url"/>      
    </div>
    <div style="position: absolute">
      <div class="radial-cut" :class="$mq"></div>
      <div style="background-color: var(--primary-color); height: 1000px; width: 100vw"></div>
    </div>
  </div>
</template>
  
<script>
import {get_book_cover} from "../store/api/library"

export default {
    name: "OibBookBackground",
    props: {
        isbn13: {
        required: true
        }
    },
    computed: {
        book_product() {
            return this.$store.getters.get_books(this.isbn13);
        },
        book_color () {
        if (!this.book_product) {
            return 'var(--highlight-color)'
        }

        return this.book_product['color'] ? `#${this.book_product['color']}` : 'var(--highlight-color)';
        }
    },
    data() {
        return {
        cover_url: null,
        }
    },
    async mounted() {
        this.cover_url = get_book_cover(this.isbn13, 'l')
    },
};
</script>

<style scoped>
.tiny-text {
font-weight: 400;
color: rgba(255, 255, 255, 0.9);
text-transform: uppercase;
letter-spacing: 2px;
font-size: 0.8em;
}

.radial-cut {
    height: 70vh;
    width: 100vw;
    max-width: 1200px;
    max-height: 800px;
    background: radial-gradient(ellipse at top left, rgba(27, 23, 37, 0) 10%, rgba(27, 23, 37, 1) 70%);
    
}

.desktop.radial-cut {
    background-size: 100% 100%;
}

.mobile.radial-cut {
    background-size: 120% 100%;
}
</style>
