<template>
  <div>
    <img src="../assets/icon_stop.svg" alt="stop_met_lezen" class="button-quit">
  </div>
</template>

<script>
export default {
  name: 'OibButtonQuitBook'
}
</script>

<style scoped>
  .button-quit {
    width: 50px;
    height: 50px;
    padding-top: 5px;
    cursor: pointer;
  }
</style>