export default ({
  state: {
    user_test_list_dict: {},
  },
  mutations: {
    reset_test_cache(state) {
      state.user_test_list_dict = {}
    },
    add_user_test_list(state, payload) {
      state.user_test_list_dict[payload.user_id] = payload.test_result_list
    }
  },
  getters: {
    get_user_test_list_dict: state => state.user_test_list_dict
  }
})
