<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Organisatie toevoegen" ref="form">
    <div>
      <oib-input-multi-select-descriptive
        description="Wat voor organisatie wil je toevoegen?"
        :allow_multi_select="false"
        :required="true"
        :sort_alphabetically="false"
        :options="organisation_type_selector_list"
        @select="x => on_click(organisation_type_selector_list[parseInt(x[0])])"
      />
    </div>
    <div></div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"

import { go_to_route } from "../store/navigation";

export default {
  name: "MenuLeerkrachtenToevoegen",
  components: {
    OibForm,
    OibInputMultiSelectDescriptive
  },
  data() {
    return {
      form_step_validation_list: [false],
      organisation_type_selector_list: [
        {'tag': 'School', 'details': ''},
        {'tag': 'Boekwinkel', 'details': ''},
        {'tag': 'Bibliotheek', 'details': ''}
      ],
    }
  },
  methods: {
    go_to_lvs_connect_form(){
      this.$router.push({
        name: 'LvsKoppelenAutorisatie',
        params: {
          'user_group': 'leerkrachten'
        }
      });
    },
    go_to_add_user_manual() {
      go_to_route('BiebhulpToevoegen')
    },
    on_click(option) {
      if (option.tag == 'School') {
        setTimeout(() => go_to_route('OrganisatieToevoegenSchool'), 500)
      } else if (option.tag == 'Boekwinkel') {
        setTimeout(() => go_to_route('OrganisatieToevoegenPartner', {'organisation_type': "BOOKSHOP"}), 500)
      } else if (option.tag == 'Bibliotheek') {
        setTimeout(() => go_to_route('OrganisatieToevoegenPartner', {'organisation_type': "LIBRARY"}), 500)
      }
    }
  }
};
</script>
