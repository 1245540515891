<template>
  <div v-if="library_id != null" class="library-section">
    <button class="book-row fade" v-on:click="toggle" style="z-index: 2" @click="click_foldable">
      <div class="book-row-content wrapper">
        <div class="book-row-content-main" style="width: 60%">
          <slot>
            <img class="avatar" :src="img_url" />
          </slot>
          <div class="book-row-details">
            <h3 class="h4">{{ library_name }}</h3>
            <p class="subh4">
              {{ library_location }}
            </p>
          </div>
        </div>
        <div :class="$mq" class="availability">
          <div :style="`opacity: ${library_id == 0 ? '0' : '100'}`">
            <div v-if="copies_available > 0" style="background-color: var(--highlight-color); border-radius: 6px; padding: 5px; text-align: right: 40px; height: 20px">
              <p style="color: white; font-weight: 700; line-height: 0; height: 20px; font-size: 0.8em; margin-top: 10px; width: 40px;">{{availability}}</p>
            </div>
            <div v-if="copies_available == 0" style="background-color: var(--contrast-color-p4); border-radius: 6px; padding: 5px; text-align: right: 40px; height: 20px">
              <p style="color: white; font-weight: 700; line-height: 0; height: 20px; font-size: 0.9em; margin-top: 10px; width: 40px;">{{availability}}</p>
            </div>
          </div>
          <div style="width: 100px; text-align: right;">
            <span class="material-icons next">{{active ? 'expand_less' : 'expand_more'}}</span>
          </div>
        </div>
      </div>
    </button>
    <div ref="book_list" class="fade" style="height: 100%; max-height: 0; opacity: 0; padding-bottom: 0; visibility: hidden">
      <button v-for="book in book_list" :key="book.isbn13" class="book-row fade" v-on:click="click">
        <div class="book-row-content">
          <div class="book-row-content-main" style="width: 100%; margin-left: 22px;">
            <slot>
              <img class="avatar" style="height: 50px; width: 36px;" :src="get_book_cover(book.isbn13)" />
            </slot>
            <div class="book-row-details">
              <h3 class="h4">{{ book.isbn13 }}</h3>
              <p class="subh4">
                <span v-if="isbn13 == book.isbn13" style="display: flex; flex-direction: row; color: var(--highlight-color); font-weight: 400;">
                  <span class="material-icons" style="font-size: 1.4em">search</span>
                  {{ isbn13 == book.isbn13 ? 'Gescand' : ' ' }}
                </span>
              </p>
            </div>
          </div>
          <div :class="$mq" class="availability">
            <div :style="`opacity: ${library_id == 0 ? '0' : '100'}`">
              <div v-if="book.copies_total - book.copies_in_use > 0" style="background-color: var(--highlight-color); border-radius: 6px; padding: 5px; text-align: right: 40px; height: 20px; width: 40px;">
                <p style="color: white; font-weight: 700; line-height: 0; height: 20px; font-size: 0.8em; margin-top: 10px; width: 40px;">{{book.copies_total - book.copies_in_use}}/{{book.copies_total}}</p>
              </div>
              <div v-if="book.copies_total - book.copies_in_use == 0" style="background-color: var(--contrast-color-p4); border-radius: 6px; padding: 5px; text-align: right: 40px; height: 20px">
                <p style="color: white; font-weight: 700; line-height: 0; height: 20px; font-size: 0.9em; margin-top: 10px; width: 40px;">{{book.copies_total - book.copies_in_use}}/{{book.copies_total}}</p>
              </div>
            </div>
            <div style="width: 100px; text-align: right;">
              <oib-button
                v-if="button_text && button_text == 'Lenen'"
                style="width: 80px; padding: 5px; height: 30px;"
                :style="has_access && can_borrow ? '' : 'background-color: var(--negative-color); border-color: var(--negative-color)'"
                class="next"
                @click="button_clicked(book.isbn13)"
                :disabled="disable_borrow(book)"
              >{{ has_access && can_borrow ? 'Lenen' : 'Help'}}</oib-button>
              <oib-button
                v-if="button_text && button_text == 'Verhuizen'"
                style="width: 80px; padding: 5px; height: 30px;"
                class="next"
                @click="button_clicked(book.isbn13)"
                :disabled="disable_move(book)"
              >{{ button_text }}</oib-button>
            </div>
          </div>     
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import OibButton from "./OibButton.vue"
import {get_book_cover} from "../store/api/library.js"
import { has_role } from "../store/utils";

export default {
  name: "OibListItemLibraryBook",
  components: {
    OibButton
  },
  props: ["img_url", "library_id", "isbn13", "copies_total", "copies_in_use", "active", "book_list", "button_text", "user_id", "has_access", "can_borrow"],
  data() {
    return {
      availability: "?/?",
      copies_available: 0,
      show_more: false,
      library_name: "",
      library_location: "",
      max_section_height: 0
    }    
  },
  watch: {
    active() {

      this.$refs.book_list.style.maxHeight = this.active ? `${90 * this.book_list.length + 10}px` : "0px"
      this.$refs.book_list.style.opacity = this.active ? "1" : "0"
      this.$refs.book_list.style.padding_bottom = this.active ? "10px" : "0"

      if (this.active) {
        this.$refs.book_list.style.visibility = "visible"
      } else {
        setTimeout(() => {
          if (this.$refs.book_list) {
            this.$refs.book_list.style.visibility = 'hidden', 200
          }          
        })
      }   
    },
    copies_total() {
      this.copies_available = this.copies_total-this.copies_in_use
      this.availability = `${this.copies_available}/${this.copies_total}`
    },
  },
  created() {
    this.copies_available = this.copies_total-this.copies_in_use
    this.availability = `${this.copies_available}/${this.copies_total}`
    this.library_name = this.get_library_name(this.library_id)
    this.library_location = this.get_library_location(this.library_id)
  },
  methods: {
    toggle() {
      this.show_more = !this.show_more
    },
    get_book_cover(isbn13) {
      return get_book_cover(isbn13, "s")
    },
    get_library_name(library_id) {
      if (library_id == 0) {
        return 'Thuis'
      }
      const library_dict = this.$store.getters.get_library_dict
      const library = library_dict[library_id]
      return library.library_name
    },
    get_library_location(library_id) {
      if (library_id == 0) {
        return ""
      }

      const library_dict = this.$store.getters.get_library_dict
      return library_dict[library_id].location_name
    },
    button_clicked(isbn13) {
      this.$emit('button_clicked', isbn13)
    },
    click_foldable() {
      this.$emit('click_foldable')
    },
    disable_borrow(book) {
      if (this.button_text == 'Lenen') {
        // Cannot borrow if no copies available
        if (book.copies_total - book.copies_in_use == 0) {
          return true
        }

        // Cannot borrow for itself if user is system admin or library_partner
        if (this.$store.getters.getUser['user_id'] == this.user_id && has_role(['SYSTEM_ADMIN', 'BOOKSHOP_OWNER'])) {
          return true
        }
      }

      return false
    },
    disable_move(book) {
      // Cannot borrow if this isbn13 is not the isbn13 if the scanned book
      if (this.isbn13 != 'null' && book.isbn13 != this.isbn13) {
        return true
      }

      return false
    }
  }
};
</script>

<style scoped>
.library-section:hover {
  background-color: rgba(255, 255, 255, 0.01);
  -webkit-box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05); 
  box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05);
  border-radius: 4px;
}

.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  padding: 0px;
}

.book-row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.next {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #636465;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.availability {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 200px;
}

.availability.desktop {
  padding-right: 20px;
}

</style>