<template>
  <oib-form-book-add :init_isbn13="isbn13" @submit="x => submit(x)"/>
</template>

<script>
import OibFormBookAdd from "../components/oib_form_book_add.vue";

import {put_book_products_users_scrape_data} from "../store/api/book_scraper.js"
import {post_book_products_users_scrape_resource} from "../store/api/book_scraper.js"
import {post_book_products_users_scrape} from "../store/api/book_scraper.js"

const LANGUAGE_SERVER = {
  0: 'DUTCH',
  1: 'ENGLISH',
  2: 'GERMAN',
  3: 'FRENCH',
  4: 'SPANISH',
  5: 'FRYSIAN',
  6: 'RUSSIAN'
}

const BINDING_METHOD_SERVER = {
  0: 'SOFTBACK',
  1: 'HARDBACK'
}

const BOOK_TYPE_SERVER = {
    0: 'LEESBOEK',
    1: 'INFORMATIEF',
    2: 'PRENTENBOEK',
    3: 'STRIPBOEK',
    4: 'DICHTBUNDEL',
    5: 'VOORLEESBOEK',
    6: 'ORIENTATIE_OP_LEZEN',
    7: 'ZOEKBOEK',
    8: 'AANWIJSBOEK',
    9: 'SAMENLEESBOEK',
    10: 'VAKLITERATUUR'
}

export default {
  name: "BoekToevoegen",
  props: ['isbn13'],
  components: {
    OibFormBookAdd
  },
  methods: {
    async submit(body) {

      const user = this.$store.getters.getUser
      if (!user) {
        return
      }

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt

      // Upload book cover
      let cover_url = null
      if (body['cover']) {
        let formData = new FormData();
        formData.append("resource", body['cover']);
        const response = await post_book_products_users_scrape_resource(jwt, body['isbn13'], user.user_id, formData)
        const json = await response.json()
        cover_url = json['url']
      }

      // Upload book data to custom scraper dataset
      const user_id = this.$store.getters.getUser['user_id']
      await put_book_products_users_scrape_data(
          jwt,
          body['isbn13'],
          user_id,
          body['book_title'],
          BOOK_TYPE_SERVER[body['book_type']],
          body['author_name'],
          body['series_title'],
          body['release_number'],
          LANGUAGE_SERVER[body['book_language']],
          body['dyslexia'],
          body['avi'],
          body['age_min'],
          body['age_max'],
          body['publisher_name'],
          BINDING_METHOD_SERVER[body['binding_method']],
          body['page_count'],
          body['release_date'],
          null,
          [],
          cover_url
      )

      // Force scraper to rescrape the book
      post_book_products_users_scrape(jwt, body['isbn13'], user_id)

      // Route to bibliotheek beheer
      this.$router.push({
        name: 'BibliotheekCollectie',
        params: {
          user_id: user_id
        }
      });
    },
  }
};
</script>
