<template>
  <oib-form :form_step_validation_list="form_step_validation_list" @submit="submit">
    <div>
      <oib-form-header header="Upload boek cover"/>
      <oib-input-image
      label="Cover"
      placeholder="schoolbieb"
      :required="true"
      :init_value="cover_url"
      @input="x => set_image(x)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputImage from "../components/oib_form/oib_input_image.vue"

import {get_book_cover} from "../store/api/library.js"

export default {
  name: "OibFormBookVoteCover",
  props: {
    isbn13: {
      required: true
    },
    init_cover: {
      default: ""
    }
  },
  components: {
    OibForm,
    OibFormHeader,
    OibInputImage
  },
  data() {
    return {
      form_step_validation_list: [false],
      cover_url: "",
      cover_file: null,
    }
  },
  mounted() {
    this.cover_url = get_book_cover(this.isbn13, 'l')
    this.val_step_1()
  },
  methods: {
    set_image(value) {
      this.cover_file = value
      this.val_step_1()
    },
    async val_step_1() {
      this.form_step_validation_list[0] = this.cover_file != null
    },
    async submit() {
      // Publish submit event
      this.$emit("submit", {
        'cover': this.cover_file
      })
    }    
  }
};
</script>
