<template>
  <oib-form-organisation-style
  v-if="organisation"
  :init_organisation_name="organisation.organisation_name"
  @submit="x => submit(x)"
  />
</template>

<script>
import OibFormOrganisationStyle from "../components/oib_form_organisation_style.vue";

import {put_organisations} from "../store/api/user.js"
import {get_organisations} from "../store/api/user.js"

export default {
  name: "OrganisatieAanpassen",
  components: {
    OibFormOrganisationStyle
  },
  data() {
    return {
      organisation: null
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  async created() {
    // Skip on refresh
    if (!this.$store.getters.getActiveSession) {
      return
    }

    const jwt = this.$store.getters.get_jwt
    const organisation_id = this.$store.getters.get_organisation_id_active
    
    const organisation = await get_organisations(jwt, organisation_id)
    if (!organisation) {
      this.$router.back()
    }

    this.organisation = organisation
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt

      // Update library
      await put_organisations(
          jwt,
          this.organisation['organisation_id'],
          this.organisation['coc_number'],
          this.organisation['coc_business_number'],
          body['organisation_name'],
          this.organisation['organisation_type'],
          this.organisation['website_url'],
          this.organisation['organisation_creation_datetime']
      )

      // Route back
      this.$router.back()
    }
  }
};
</script>
