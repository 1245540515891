<template>
  <oib-page>
    <oib-nav-student title="Missies" :allowLogout="true"/>
    <oib-content :show_overlay="true">
      <div class="wrapper column center" style="height: 85vh;">
        <div>
          <p>Geen nieuwe missies</p>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"

export default {
  name: "Missies",
  components: {
    OibPage,
    OibContent,
    OibNavStudent
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
