<template>
  <header class="header" :style="style">
    <div class="row header-content" :class="$mq" style="width: 5%; min-width: 50px; justify-content: center;">
      <slot name="left"><oib-button-back v-if="show_back" :has_backdrop="show_backdrop" /></slot>
    </div>

    <div class="header-content" :class="$mq" :style="$mq == 'desktop' ? 'width: calc(90% - 200px)': 'width: 90%'">
      <slot name="center"><h1 style="text-align: left;">{{ title }}</h1></slot>
    </div>
    <div class="header-content" :class="$mq" style="width: 5%; min-width: 50px; justify-content: center;">
      <div class="header-side-slot">
        <slot name="right"></slot>
      </div>
    </div>
  </header>
</template>


<script>
import OibButtonBack from "./OibButtonBack.vue";

export default {
  name: "OibHeader",
  components: {
    OibButtonBack,
  },
  props: {
    title: {
      type: String,
      required: false
    },
    show_back: {
      type: Boolean,
      required: false,
      default: true
    },
    show_backdrop: {
      type: Boolean,
      required: false,
      default: false
    },
    background: {
      type: String,
      required: false,
    },
    width: {
      required: false
    }
  },
  data() {
    return {
      style: "background-color: transparent"
    }
  },
  mounted() {
    window.addEventListener('scroll', this.on_scroll);
    this.on_scroll()
  },
  unmounted() {
    window.removeEventListener('scroll', this.on_scroll);
  },
  methods: {
    on_scroll() {
      // Do not add background to header on scroll if icons have backdrop
      if (this.show_backdrop) {
        return
      }

      const opacity = Math.min((window.scrollY - 25) / 100, 1);
      const SHADOW_CAP = 0.4;
      const opacity_shadow = opacity > SHADOW_CAP ? SHADOW_CAP : opacity;

      let background = "background-color: rgba(33,34,47, 1);"
      if (this.background == 'transparent') {
        background = `
        background: rgba(33,34,47, ${opacity});
        background: linear-gradient(0deg, rgba(33,34,47,${opacity * 0.9}) 0%, rgba(33,34,47,${opacity}) 100%);
        `
      }
      this.style = `
      ${background}
      -webkit-box-shadow: 0px 10px 13px -7px rgba(0,0,0,${opacity_shadow});
      box-shadow: 0px 10px 13px -7px rgba(0,0,0,${opacity_shadow});`
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  position: fixed;
  color: #ffffff;
  width: 100%;
  height: 50px;
  text-align: center;
  margin: 0;
  z-index: 3;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: auto;
}

.header-content {
    width: 100%;
}

.header-content h1 {
  font-size: 1em;
  height: inherit;
  margin: 0;
  width: 90%;
}

.header-side-slot {
  display: flex;
  justify-content: center;
  height: inherit;
  margin: 0;
  width: 100%;
  max-width: 40px;
}
</style>