<template>
  <oib-form :form_step_validation_list="form_step_validation_list" @submit="submit">
    <div>
      <oib-form-header header="Vormgeving"/>
      <oib-input-text
      label="Schoolnaam"
      placeholder="Naam van de school"
      :required="true"
      :error_msg="error_msg"
      :init_value="init_organisation_name"
      @input="set_organisation_name($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"

export default {
  name: "OibFormOrganisationStyle",
  props: {
    init_organisation_name: {
      default: ""
    }
  },
  components: {
    OibForm,
    OibFormHeader,
    OibInputText
  },
  data() {
    return {
      form_step_validation_list: [false],
      organisation_name: ""
    }
  },
  async mounted() {
    this.organisation_name = this.init_organisation_name
    this.val_step_1()
  },
  methods: {
    set_organisation_name(value) {
      this.organisation_name = value
      this.val_step_1()
    },
    async val_step_1() {
      this.form_step_validation_list[0] = this.organisation_name.length > 5
    },
    async submit() {
      // Publish submit event
      this.$emit("submit", {
        'organisation_name': this.organisation_name
      })
    }
  }
};
</script>
