<template>
  <oib-page>
    <mq-layout mq="xs-md">
      <oib-header title="Analyse" />
      <oib-nav-student />
      <playback-exercise :exercise_data="exercise"/>
      <reading-errors :exercise_data="exercise"/>
    </mq-layout>

    <mq-layout mq="lg+">
      <oib-row-stack style="height: 100vh">
        <oib-nav-student />
        <oib-title title="Analyse">
          <oib-row-stack>
            <reading-errors :exercise_data="exercise"/>
            <playback-exercise :exercise_data="exercise"/>
          </oib-row-stack>
        </oib-title>
      </oib-row-stack>
    </mq-layout>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibHeader from "../components/OibHeader.vue";
import OibRowStack from '../components/OibRowStack.vue';
import OibNavStudent from '../components/OibNavStudent.vue';
import OibTitle from '../components/OibTitle.vue';
import ReadingErrors from '../components/ReadingErrors.vue';
import PlaybackExercise from '../components/PlaybackExercise.vue';

export default {
  name: "Analyse",
  props: ["user_id", "id_reading_exercise"],
  components: {
    OibPage,
    OibHeader,
    OibRowStack,
    OibNavStudent,
    OibTitle,
    ReadingErrors,
    PlaybackExercise,
  },
  data() {
    return {
      exercise: undefined,
    };
  },
  methods: {
    async loadExercise() {
      // Get all books from the school library
      const url = new URL(window.location.href);
      const request =
        url.protocol +
        "//" +
        url.hostname +
        "/users/" +
        this.user_id +
        "/exercise-analyses/" +
        this.id_reading_exercise;

      const headers = {
        method: "get",
        headers: new Headers({
          Authorization: this.$store.getters.getJwt,
        }),
      };

      const response = await fetch(request, headers);
      const response_json = await response.json();

      this.exercise = response_json;
    },
    getErrorTypeIcon(errorType) {
      return this.errorTypeIconLookup[errorType];
    },
  },
  mounted() {
    this.loadExercise();
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>