<template>
  <div>
    <div class="wrapper column">
      <div ref="oib_content" style="max-width: 1500px">
        <div v-if="query && Object.keys(query).length == 0 && !search_query" class="row" style="justify-content: space-between; flex-wrap: wrap; margin-bottom: 100px;">
          <slot></slot>
        </div>
        <div v-if="(query && Object.keys(query).length > 0) && row_count > 0 && column_count > 0">
          <div v-for="y in row_count" :key="y" class="row book-row">
            <div v-for="x in column_count" :key="x">
              <book-tile
              v-if="get_tile(x, y)"                
              :user_id="get_user_id()"
              :isbn13="get_tile(x, y).isbn13"
              :book_edition_id="get_tile(x, y).book_edition_id"
              :book_title="get_tile(x, y).book_title"
              :sub_text="get_tile(x, y).sub_text"
              :isbn13_scanned="get_tile_scanned(x, y).isbn13"
              :marker="get_tile(x, y).marker"
              :copies_in_use="get_tile(x, y).copies_in_use"
              :copies_total="get_tile(x, y).copies_total"
              :marker_width="marker_width"
              :show_checkbox="show_checkbox"
              @select="on_select(get_tile(x, y).copy_id)"
              />
              <div v-if="!get_tile(x, y)" style="width: 150px; height: 255px"/>
            </div>
          </div>
        </div>
      </div>
      <div ref="bottom"/>
    </div>
  </div>
</template>

<script>
import BookTile from "../components/BookTile.vue"

import { get_books } from "../store/api/library"
import { get_books_search } from "../store/api/book_search";
import { get_books_recommendations } from "../store/api/book_recommendation";

export default {
  name: "BookSearch",
  props: {
    query: {
      required: false,
      default: null
    },
    read_count_var: {
      required: false,
      default: 'borrow_count_sum'
    },
    filter_library: {
      type: Boolean
    },
    sub_text_key: {
      type: String,
      default: 'author_name'
    },
    show_checkbox: {
      type: String,
      default: 'never'
    }
  },
  components: {
    BookTile
  },
  data() {
    return {
      show_filter: false,
      user_id: null,
      marker_width: 40,
      row_count: null,
      column_count: null,
      search_query: '',
      book_tile_width: 160,
      search_limit: 0,
      query_running: false
    }
  },
  async mounted() {

    // Do not run if user is not defined - User did refresh or logout
    if (!this.$store.getters.getUser) {
      return
    }

    // Init vars
    this.on_resize()
    this.search_limit = this.get_limit()

    // Add events
    window.addEventListener('scroll', this.on_scroll);
    window.addEventListener('resize', this.on_resize);

    // Start search
    // Since some mobile browsers resize on mounted, add timeout to wait for resize event
    // Without this timeout there will be a mismatch between the query_limit and the search_limit
    // this.search();
  },
  // async updated() {
  //   this.search();
  // },
  unmounted () {
    window.removeEventListener('scroll', this.on_scroll);
    window.removeEventListener('resize', this.on_resize);
  },
  computed: {
    active_library_selector() {
      return this.$store.getters.get_active_library_selector
    },
    selector_name() {
      if (!this.filter_library) {
        return 'Boeken die nog niet op school staan'
      }

      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    }
  },
  watch: {
    active_library_selector() {
      this.search()
    },
    query() {
      this.search()
    },
    $route: {
      immediate: true,
      handler(){
        if (this.$route.name == 'BibliotheekZoeken') {
          this.search();
        }
      }
    }
  },
  methods: {
    compute_row_count(total_count) {
      const row_count_rest = total_count % this.column_count;
      let row_count_filled = Math.floor(total_count / this.column_count);

      // Add additional row if not all tiles fit in the grid aspect ratio
      if (row_count_rest != 0) {
        row_count_filled += 1;
      }

      this.row_count = row_count_filled
    },
    //TODO: Move to mounted
    get_user_id() {
      if (this.user_id) {
        return this.user_id
      }

      const user = this.$store.getters.getUser
      if (!user) return null
      return user['user_id']
    },
    on_scroll() {
      // Have we reached the bottom?
      //let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;
      if (this.has_reached_bottom(this.$refs.bottom)) {

        // If the search_limit stayed the same, increase the page.
        const search_page = this.$store.getters.get_search_page
        if ((search_page + 1) * this.search_limit == this.$store.getters.get_search_result.length) {
          
          // If so load new search_page
          this.$store.commit("set_search_page", search_page + 1)
          this.search()
        }
      }
    },
    on_resize() {

      // Compute column_count
      const min_spacing = 10
      const total_width = this.$refs.oib_content.clientWidth;
      let z = (total_width + min_spacing) / (this.book_tile_width + min_spacing)

      // Compute if extra tile could fit minimal space requirement
      const space_needed_for_extra_tile = (Math.ceil(z) - z) * this.book_tile_width;
      const space_minimal_for_extra_tile = Math.ceil(z) * min_spacing;
      z = space_needed_for_extra_tile < space_minimal_for_extra_tile ? Math.ceil(z) : Math.floor(z)

      // If limit changed due to a resize, reset and search with new limit
      if (this.column_count == null) {
        this.column_count = z
        this.search_limit = this.get_limit();
        this.search()
      } else if (this.column_count != z) {
        window.scrollTo(0, 0)
        this.column_count = z
        this.$store.commit("set_search_result", [])
        this.$store.commit("set_search_page", 0)
        this.search_limit = this.get_limit();
        this.search() 
      }
    },
    has_reached_bottom(el) {
        var rect = el.getBoundingClientRect();
        return rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    },
    get_tile(x, y) {
      // Correct for 1 indexed loop
      x--; y--;

      // Get isbn13 for these coordinates in the grid
      const search_result = this.$store.getters.get_search_result

      const grid_coordinate_1d = y * this.column_count + x;
      if (grid_coordinate_1d > search_result.length - 1) {
        return null
      }
      const tile = search_result[y * this.column_count + x]
      return tile
    },
    get_tile_scanned(x, y) {
      const search_query = this.$store.getters.get_search_query
      return this.is_number(search_query) ? this.get_tile(x, y) : {'isbn13': null}
    },
    get_limit() {
        // Compute search-limit
        const book_tile_height = 220;
        const height = window.innerHeight | document.documentElement.clientHeight;
        const row_count_per_search = Math.ceil(height / book_tile_height);
        return row_count_per_search * this.column_count;
    },
    reset_search() {
      // Init search variables
      this.row_count = 0
      this.$store.commit("set_search_result", [])
      this.$store.commit("set_search_page", 0)
      this.$store.commit("set_search_request", {})
    },
    async search() {

      if (!this.query || !this.query.query || !this.query.grouping) {
        return
      }

      // Build query
      let query = JSON.parse(JSON.stringify(this.query.query));

      // Reset search if new search uses different parameters
      const query_prev = this.$store.getters.get_search_request
      let query_prev_ignore_pagination = {}
      Object.assign(query_prev_ignore_pagination, query_prev);
      delete query_prev_ignore_pagination['limit']
      delete query_prev_ignore_pagination['page']

      if (JSON.stringify(query) != JSON.stringify(query_prev_ignore_pagination)) {
        this.row_count = 0
        this.$store.commit("set_search_result", [])
        this.$store.commit("set_search_page", 0)
      }

      this.query_running = true

      // Set this search_query as search_query_prev
      this.$store.commit("set_search_request", query)

      // Add pagination
      query['limit'] = this.search_limit
      query['page'] = this.$store.getters.get_search_page

      // Set marker width
      if ('user_id_recommendation' in query || 'isbn13_recommendation' in query) {
        this.marker_width = 80
      }

      // Skip if this query was already executed
      if (JSON.stringify(query_prev) == JSON.stringify(query)) {
        this.compute_row_count(this.$store.getters.get_search_result.length)
        return
      }

      // Run search_request - duplicate request protection is build in
      const jwt = this.$store.getters.get_jwt
      let search_result = this.$store.getters.get_search_result

      if ('search' in query && query['search'] != '') {
        query['select'] = 'isbn13,item_name,item_type'
        const json = await get_books_search(jwt, query)

        this.query_running = false

        // Parse result
        json['item_list'].forEach(x => {
          // let read_count = null
          // if (this.read_count_var in x) {
          //   if (this.read_count_var == 'release_number') {
          //     if (x[this.read_count_var] != null) {
          //       read_count = ' Deel ' + x[this.read_count_var]
          //       this.marker_width = 50
          //     }
          //   } else {
          //     if (x[this.read_count_var] == null) {
          //       read_count = '0x'
          //     } else if (x[this.read_count_var] != undefined) {
          //       read_count = x[this.read_count_var] + 'x'
          //       this.marker_width = 40
          //     }
          //   }
          // }

          let obj = {
            'isbn13': x['isbn13'],
            'book_edition_id': x['isbn13'],
            'book_title': x['item_name'],
            'sub_text': x['item_type'],
            // 'marker': read_count,
            'copies_in_use': 1,
            'copies_total': 1
          }
          search_result.push(obj)
        })
      } else if ('user_id_recommendation' in query) {
        let query_local = Object.assign({}, query);
        query_local['user_id'] = query_local['user_id_recommendation']
        delete query_local['user_id_recommendation']

        const json = await get_books_recommendations(jwt, query_local)

        json['recommendation_list'].forEach(x => {
          search_result.push({
            'isbn13': x['isbn13'],
            'book_edition_id': x['book_edition_id'],
            'book_title': x['book_title'],
            'sub_text': x['author_name'],
            'marker': x['borrow_count'] < 2 ? 'Onderbelicht' : null,
            'copies_in_use': x['in_use_count'],
            'copies_total': x['total_count']
          })
        })
      } else if ('isbn13_recommendation' in query) {
        let query_local = Object.assign({}, query);
        query_local['isbn13'] = query_local['isbn13_recommendation']
        delete query_local['isbn13_recommendation']

        const json = await get_books_recommendations(jwt, query_local)

        json['recommendation_list'].forEach(x => {
          search_result.push({
            'isbn13': x['isbn13'],
            'book_edition_id': x['book_edition_id'],
            'book_title': x['book_title'],
            'sub_text': x['author_name'],
            'marker': x['borrow_count'] < 2 ? 'Onderbelicht' : null,
            'copies_in_use': x['in_use_count'],
            'copies_total': x['total_count']
          })
        })
      } else {
        const json = await get_books(jwt, this.query.grouping, query)

        this.query_running = false

        // Parse result
        json['books'].forEach(x => {
          let read_count = null
          if (this.read_count_var in x) {
            if (this.read_count_var == 'release_number') {
              if (x[this.read_count_var] != null) {
                read_count = ' Deel ' + x[this.read_count_var]
                this.marker_width = 50
              }
            } else {
              if (x[this.read_count_var] == null) {
                read_count = '0x'
              } else if (x[this.read_count_var] != undefined) {
                read_count = x[this.read_count_var] + 'x'
                this.marker_width = 40
              }
            }
          }

          let obj = {
            'isbn13': x['isbn13'],
            'book_edition_id': x['book_edition_id'],
            'book_title': x['book_title'],
            'sub_text': x[this.sub_text_key],
            'marker': read_count,
            'copies_in_use': x['copies_in_use'],
            'copies_total': x['copies_total']
          }
          if ('copy_id' in x) {
            obj['copy_id'] = x['copy_id']
          }
          search_result.push(obj)
        })
      }

      // Set search_result
      this.compute_row_count(search_result.length)
      this.$store.commit("set_search_result", search_result)
    },
    is_number(string) {
      return /^\d+$/.test(string)
    },
    on_select(copy_id) {
      this.$emit("select", copy_id)
    }
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.submit.mobile {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  padding: 10px 0;
  text-align: center;  
  background-color: var(--primary-color-sub);
}

.submit.mobile:hover {
  cursor: pointer;
}

.submit.mobile button {
  width: 100%;
  max-width: 400px;
  padding: 10px 30px;
}

.sort-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}

.filter-icon {
  margin-right: 20px;
  margin-top: 2px;
}

.sort-section-header {
  justify-content: space-between;
  align-items: center;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}

.tag-tile:hover {
  cursor: pointer;
}
</style>
