<template>
  <oib-form ref="form" :form_step_validation_list="form_step_validation_list" title="Adres wijzigen" @submit="submit">
    <div>
      <oib-input-multi-select-descriptive
        v-if="address_list.length > 0"
        description="Welk adres wil je wijzigen?"
        :allow_multi_select="false"
        :required="true"
        :sort_alphabetically="false"
        :options="address_list"
        @select="x => edit_address(address_list[parseInt(x[0])])"
      />
    </div>
    <div>
      <oib-form-header header="Adres"/>
      <oib-input-text
      label="Postcode"
      placeholder="7101BN"
      :required="true"
      :error_msg="null"
      :init_value="init_zipcode"
      @input="set_zipcode($event.target.value)"
      />
      <oib-input-text
      label="Huisnummer"
      placeholder="22a"
      :required="true"
      :error_msg="null"
      :init_value="init_house_number"
      @input="set_house_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Straatnaam"
      placeholder="Beatrixpark"
      :required="true"
      :error_msg="null"
      :init_value="init_street_name"
      @input="set_street_name($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Stad"
      placeholder="Winterswijk"
      :required="true"
      :error_msg="null"
      :init_value="init_city"
      @input="set_city($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Provincie"
      placeholder="GELDERLAND"
      :required="true"
      :error_msg="null"
      :init_value="init_province"
      @input="set_province($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
    <div>
      <oib-form-header header="Coördinaten"/>
      <oib-input-text
      label="Latitude"
      placeholder="51.9810029"
      :required="true"
      :error_msg="null"
      :init_value="init_latitude"
      @input="set_latitude($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Longitude"
      placeholder="6.7015163"
      :required="true"
      :error_msg="null"
      :init_value="init_longitude"
      @input="set_longitude($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
    <div>
      <oib-form-header header="Naam"/>
      <oib-input-text
      label="Locatie naam"
      :required="true"
      :error_msg="null"
      :init_value="init_street_name == init_location_name ? street_name : init_location_name"
      @input="set_location_name($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibFormHeader from "../components/oib_form/oib_form_header.vue";
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"

import { put_buildings } from "../store/api/user.js"
import { get_buildings_query } from "../store/api/user.js";
import { isZipcode } from "../store/validate.js";

export default {
  name: "OibFormOrganisationAddress",
  components: {
    OibForm,
    OibInputText,
    OibFormHeader,
    OibInputMultiSelectDescriptive
  },
  data() {
    return {
      form_step_validation_list: [false, false, false, false],
      building: null,
      building_list: [],
      init_zipcode: "",
      zipcode: "",
      init_house_number: "",
      house_number: "",
      init_street_name: "",
      street_name: "",
      init_city: "",
      city: "",
      init_province: "",
      provincie: "",
      init_latitude: "",
      latitude: "",
      init_longitude: "",
      longitude: "",
      init_location_name: "",
      location_name: "",
      province_list: [
        'DRENTHE',
        'FLEVOLAND',
        'FRIESLAND',
        'GELDERLAND',
        'GRONINGEN',
        'LIMBURG',
        'NOORD_BRABANT',
        'NOORD_HOLLAND',
        'OVERIJSSEL',
        'UTRECHT',
        'ZEELAND',
        'ZUID_HOLLAND'
      ],
      address_list: []
    }
  },
  async created() {

    const jwt = this.$store.getters.get_jwt
    const organisation_id = this.$store.getters.get_organisation_id_active
    const json = await get_buildings_query(jwt, {'organisation_id': organisation_id})
    const building_list = json['building_list']
    if (building_list.length == 0) {
      this.$router.back()
      return
    }

    this.building_list = building_list
    this.building_list.forEach(x => this.address_list.push({
      'tag': x['building_name'], 'address': x
    }))
  },
  methods: {
    edit_address(selector) {

      this.building = selector.address

      this.init_zipcode = this.building['zipcode']
      this.set_zipcode(this.init_zipcode)

      this.init_house_number = this.building['house_number']
      this.set_house_number(this.init_house_number)

      this.init_street_name = this.building['street_name']
      this.set_street_name(this.init_street_name)

      this.init_city = this.building['city']
      this.set_city(this.init_city)

      this.init_province = this.building['province']
      this.set_province(this.init_province)

      this.init_latitude = this.building['latitude']
      this.set_latitude(this.init_latitude)

      this.init_longitude = this.building['longitude']
      this.set_longitude(this.init_longitude)

      this.init_location_name = this.building['building_name']
      this.set_location_name(this.init_location_name)

      setTimeout(() => this.$refs.form.next_step(), 500)
    },
    set_zipcode(value) {
      this.zipcode = value
      this.val_step_1()
    },
    set_house_number(value) {
      this.house_number = value
      this.val_step_1()
    },
    set_street_name(value) {
      this.street_name = value
      if (this.init_street_name == this.init_location_name) {
        this.set_location_name(value)
      }
      this.val_step_1()
    },
    set_city(value) {
      this.city = value
      this.val_step_1()
    },
    set_province(value) {
      this.province = value
      this.val_step_1()
    },
    set_latitude(value) {
      this.latitude = parseFloat(value)
      this.val_step_2()
    },
    set_longitude(value) {
      this.longitude = parseFloat(value)
      this.val_step_2()
    },
    set_location_name(value) {
      this.location_name = value
      this.val_step_3()
    },
    is_float(val) {
      return !Number.isInteger(val) && Number.isFinite(val)
    },
    val_step_1() {
      let check_list = []
      check_list.push(isZipcode(this.zipcode))
      check_list.push(this.house_number != null && this.house_number.length > 0)
      check_list.push(this.street_name != null && this.street_name.length > 3)
      check_list.push(this.city != null && this.city.length > 2)
      check_list.push(this.province != null && this.province_list.includes(this.province))

      this.form_step_validation_list[1] = !check_list.includes(false)
    },
    val_step_2() {
      let check_list = []
      check_list.push(this.latitude != null && this.is_float(this.latitude))
      check_list.push(this.longitude != null && this.is_float(this.longitude))

      this.form_step_validation_list[2] = !check_list.includes(false)
    },
    val_step_3() {
      return this.form_step_validation_list[3] = this.location_name && this.location_name.length > 3
    },
    async submit() {

      // Update building
      const jwt = this.$store.getters.get_jwt
      await put_buildings(
        jwt,
        this.building['building_id'],
        this.building['organisation_id'],
        this.location_name,
        this.city,
        this.province,
        this.zipcode,
        this.street_name,
        this.house_number,
        this.latitude,
        this.longitude,
        this.building['building_creation_datetime']
      )

      setTimeout(() => {this.$router.back()}, 500);
    }
  }
};
</script>
