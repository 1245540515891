<template>
  <oib-page>
    <oib-nav-student :allowLogout="true" :show_back="false">
      <template v-slot:header><slot name="header">
        <oib-header-search
        placeholder="Stel een vraag"
        @click_searchbar="go_to_stel_een_vraag()"
        :show_back_button="false"
        :show_logout_button="true"
        >
        <template v-slot:search_collection>
          <a
          :href="$mq == 'desktop' ? 'https://wa.me/310643150049' : 'whatsapp://send?phone=310643150049'"
          style="background-color: #25d366; border-radius: 2px; padding: 5.5px 3px; width: 100px; margin-right: 10px"
          >
            <div class="row" style="display: flex; flex-direction: row; align-items: center; justify-content: center">
              <img
              style="height: 20px; width: 20px;"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/512px-WhatsApp.svg.png?20220228223904"
              />
              <p style="margin: 0 0 0 10px; font-size: 0.7em; color: white; font-weight: bold">WhatsApp</p>
            </div>
          </a>
        </template>
        </oib-header-search>
      </slot></template>
    </oib-nav-student>
    <oib-content padding_top="padding_top">
      <section class="wrapper column">
        <div class="column" style="width: 100%; max-width: 350px;">
          <h1 class="h1" style="margin-top: 0">Ondersteuning en tips</h1>
          <div
          class="row"
          style="align-items: center; justify-content: space-between;"
          @click="go_to_route('Handleidingen')"
          >
            <div class="row" style="align-items: center;">
              <p style="margin-right: 20px; margin-top: 5px; margin-bottom: 5px">📋</p>
              <p style="font-size: 0.8em; color: white; margin-top: 5px; margin-bottom: 5px">Bekijk handleidingen</p>
            </div>
            <p class="btn-arrow fade" style="margin: 0">➜</p>
          </div>
          
          <div
          class="row"
          style="align-items: center; justify-content: space-between;"
          @click="go_to_route(action.route)"
          v-if="action"
          >
            <div class="row" style="align-items: center;">
              <p style="margin-right: 20px; margin-top: 5px; margin-bottom: 5px">🎯</p>
              <p style="font-size: 0.8em; color: white; margin-top: 5px; margin-bottom: 5px">Volgende taak: {{action.text}}</p>
            </div>
            <p class="btn-arrow fade" style="margin: 0">➜</p>
          </div>
        </div>
      </section>
      <div class="wrapper column" style="margin-top: 20px;">
        <div class="column" style="width: 100%; max-width: 350px;">
          <h4 class="h4" style="z-index: 1">Binnenkort in dit scherm</h4>
          <oib-checklist-static
            :required="true"
            :options="[
              {task: true, title: 'Boekpromoties, lesbrieven en tips van je partner', 'page': 'GebruikersLeerkrachten'},
              {task: true, title: 'Deel tips en succesverhalen met andere scholen', 'page': 'GebruikersLeerkrachten'},
            ]"
          />
        </div>
      </div>
      <oib-post
      header="Grote zomerupdates en visie 2025"
      datetime="2024-06-30 23:03:00"
      >
      <p>Het team achter de Leesapp wil jullie enorm bedanken! Het aantal scholen dat maandelijks start blijft groeien waardoor we ons team vanaf morgen wederom met twee ontwikkelaars mogen uitbreiden.</p>

      <b style="color: white">Android en iOS apps</b>
      <p>Een groeiende groep gebruikers vraagt om nog meer kwaliteit en daar gaan we voor zorgen.</p>
      <p>We zijn daarom al een tijdje bezig met de ontwikkeling van twee apps speciaal ontwikkeld voor Android en iOS. Daarmee lossen we de drie primaire technische uitdagingen op:</p>
      <ul>
        <li>Je blijft echt altijd ingelogd.</li>
        <li>De camera werkt altijd omdat de app speciaal is gemaakt voor een specifiek model.</li>
        <li>De app werkt ook snel op oudere apparaten.</li>
      </ul>
      <p>We publiceren de beta-versie voor Android in augustus en de beta-versie voor iOS na de herfstvakantie. Een aanvullende updatemail hierover volgt in augustus.</p>

      <b style="color: white">Updates in juli</b>
      <p>Verder staan er een heleboel grote updates klaar om gepubliceerd te worden.</p>
      <ul>
        <li>Betrokkenheid volgen:
          <ul>
            <li>Breng in beeld welke kids hulp nodig hebben bij het kiezen van een boek.</li>
          </ul>
        </li>
        <li>Geschikte leeftijd gebaseerd op leesgedrag:
          <ul>
            <li>De leeftijd die de uitgeverij voor het boek bepaalt is doorgaans een marketingtruc.</li>
            <li>De waanzinnige boomhut is natuurlijk niet echt een boek voor 9 t/m 12 jaar.</li>
            <li>Uit onze data blijkt dat de slagingskans het grootst is voor kids van 7 t/m 10 jaar.</li>
            <li>Binnenkort wordt daarom deze datagedreven leeftijd in de app weergeven.</li>
          </ul>
        </li>
        <li>Volgen welke boeken mee naar huis zijn genomen</li>
      </ul>
      <b style="color: white">Updates in augustus</b>
      <ul>
        <li>Collectie advies:
          <ul>
            <li>Zoek naar boeken die nog niet in de collectie staan en die aansluiten bij jullie identiteit.</li>
            <li>Bekijk incomplete boeken series.</li>
            <li>Zoek naar leverbare boeken voor een thema zoals "vulkanen".</li>
            <li>Zoek naar leverbare boeken voor een bepaalde leeftijd.</li>
            <li>Zoek naar leverbare boeken van een boektype zoals "informatief".</li>
          </ul>
        </li>
        <li>Favorieten en bestellijsten:
          <ul>
            <li>Bouw samen met een kinderboekwinkel of bibiotheek een bestellijst.</li>
            <li>Bekijk de favoriete boeken van alle leerlingen en leerkrachten op school.</li>
            <li>Voeg eenvoudig jullie favoriete boeken toe aan een bestellijst</li>
          </ul>
        </li>
        <li>
          Koppeling SomToday voor middelbare scholen.
        </li>
      </ul>
      <b style="color: white">Visie 2025</b>
      <p>Ruim drie jaar geleden sprongen Tom en Stijn in het diepe met een visie en veel open eindjes. Intussen is die visie haarscherp en dat betekent dat we de Leesapp in beton kunnen gieten.</p>
      <p>De nieuwe versies voor Android en iOS garanderen kwaliteit en stabiliteit, maar dat gaat ten koste van snelheid en flexibiliteit. Drie verschillende platformen tegelijk onderhouden kost immers veel meer tijd.</p>
      <p>Daarom richten we ons komend jaar op content, communicatie en klantsucces. Hoe haal je het meest uit de Leesapp? Denk hierbij aan:</p>
      <ul>
        <li>Video's met implementatie tips voor verschillende situaties.</li>
        <li>Dagelijkse boektips voor leerkrachten in de app en op sociale media.</li>
        <li>Posts van je lokale bieb of kinderboekwinkel in de app.</li>        
      </ul>
      <p><br>Bedankt voor het vertrouwen! We houden jullie op de hoogte.</p>
      <p>Met dankbare groeten,</p>
      <p>Stijn Dautzenberg</p>
      <p>Ook namens Tom Buunk en de rest van ons groeiende team</p>
      
      </oib-post>
      <oib-post
      header="3.31.0 - Onderhoud tips"
      datetime="2024-06-30 21:05:00"
      >
      <ul>
        <li>Nieuwe taak toegevoegd voor boeken die langer dan 2 maanden geleend worden.</li>
        <li>Nieuwe filter toegevoegd: "langst geleend".</li>
        <li>Filter perspectieven zoals "Onlangs toegevoegd" en "langst geleend" weergeven nu boeken gegroepeerd per datum.</li>
        <li>Je kunt nu met een klik een bulkactie uitvoeren op alle boeken op een datum.</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.30.0 - Uitbreiding inladen boekbestanden"
      datetime="2024-05-14 02:21:28"
      >
      <ul>
        <li>Uitbreiding inladen boekbestanden
          <ul>
            <li>Kies zelf de labels die je wilt koppelen.</li>
            <ul>
              <li>Eigen labels.</li>
              <li>OIB labels op basis van isbn.</li>
              <li>NBD labels op basis van bestelnummer.</li>
            </ul>
            <li>Missende isbn en bestelnummers in database worden automatisch opgezocht.</li>
            <li>Duidelijke instructie toegevoegd.</li>
          </ul>
        </li>
        <li>Fout opgelost bij het inladen van de kleuren van thema tegels.</li>
        <li>Fout opgelost bij het synchroniseren van de groepsindeling.</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.29.0 - Staging omgeving en kleuren"
      datetime="2024-05-10 02:21:28"
      >
      <ul>
        <li>Ondersteuning voor staging omgeving
          <ul>
            <li>Essentieel voor het testen van de nieuwe Android en iOS apps.</li>
          </ul>
        </li>
        <li>Verbeterde kleuren berekend voor boekkaften.</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.28.0 - Ondersteuning wisselcollecties dBos"
      datetime="2024-04-28 14:51:00"
      >
      <ul>
        <li>Ondersteuning wisselcollecties dBos
          <ul>
            <li>Bibliotheken kunnen zelf collecties bouwen en met scholen delen.</li>
            <li>Scholen mogen zelf geen boeken toevoegen aan deze collecties, maar wel boeken lenen.</li>
            <li>Uitleencijfers van een collectie bijhouden per collectie en per school.</li>
            <li>De architectuur ondersteunt het voor de toekomst. De implementatie in de app volgt later.</li>
          </ul>
        </li>
        <li>Introductie van nieuwe zwart-wit labels voor dBos scholen.</li>
        <li>Fout opgelost waarbij leerlingen boeken zelfstandig konden inleveren wanneer ze die permissies eigenlijk niet hadden.</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.26.0 - Ondersteuning denominaties en schooltypen"
      datetime="2024-04-20 23:16:22"
      >
      <ul>
        <li>Ondersteuning denominaties en schooltypen
          <ul>
            <li>Voeg een denominatie toe aan iedere school.</li>
            <li>Voeg een school type toe aan iedere school (PO, SO, SBAO, VO, VSO).</li>
            <li>Belangrijk voor boeksuggesties in de winkel.</li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.25.0 - Refresh login token API"
      datetime="2024-04-19 22:52:53"
      >
      <ul>
        <li>Refresh login token API
          <ul>
            <li>Publiceer een nieuwe API waarmee je langer ingelogd kunt blijven.</li>
            <li>Zal pas in gebruik worden genomen na de zomervakantie wanneer de Android en iOS app klaar zijn.</li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.24.17 - Thema's en tips"
      datetime="2024-04-17 21:52:00"
      >
      <ul>
        <li>Thema's
          <ul>
            <li>Vervang het thema "seizoenen" door "liefde en relaties" in het snelklik scherm.</li>
            <li>Meer labels in webshop</li>
          </ul>
        </li>
        <li>Tips
          <ul>
            <li>Geef een tip voor het toevoegen van accounts nadat het LAS is gekoppeld.</li>
            <li>Weergeef een link naar informatie over rollen en permissies.</li>
          </ul>
        </li>
        <li>Fout opgelost waarbij collectieoverzicht niet geupdate werd.</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.24.0 - Bibliotheek verhuizen"
      datetime="2024-03-20 23:48:00"
      >
      <ul>
        <li>Formulieren
          <ul>
            <li>Formulier toegevoegd voor het verhuizen van een bibliotheek naar een andere school.</li>
            <li>Formulier toegevoegd voor het toevoegen van een locatie</li>
            <li>Fout opgelost waarbij je niet kon scrollen in een formulier op desktop.</li>
          </ul>
        </li>
        
      </ul>
      </oib-post>
      <oib-post
      header="3.23.0 - Makkelijker zelf lenen"
      datetime="2024-03-16 00:56:00"
      >
      <ul>
        <li>Makkelijker zelf lenen
          <ul>
            <li>Leen nu zelf eenvoudig een boek met één druk op de knop.</li>
            <li>Scan je boekenlegger, klik op "nieuw boek lenen" en scan de barcode.</li>
          </ul>
        </li>
        <li>Bibliotheek toevoegen/aanpassen
          <ul>
            <li>Voeg nu een aangepaste locatienaam toe zoals "De 1e verdieping" of "Bijgebouw"</li>
          </ul>
        </li>
        <li>Gebruikservaring
          <ul>
            
            <li>Weergeef de bibliotheek waaraan je een boek toevoegt in de popup.
              <ul>
                <li>Shoutout naar Marijke! Netherlands School Nairobi.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.22.0 - Meer formulieren"
      datetime="2024-03-13 23:19:00"
      >
      <ul>
        <li>Meer formulieren
          <ul>
            <li>Formulier toegevoegd voor het wijzigen van de naam van de school.</li>
            <li>Formulier toegevoegd voor het wijzigen van het adres van de school (Alleen systeem administrator).</li>
          </ul>
        </li>
        <li>Bibliotheek toevoegen/aanpassen
          <ul>
            <li>Presenteer suggesties voor bibliotheek afbeeldingen op basis van de naam van de bibliotheek.</li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.21.0 - Verbeterde formulieren"
      datetime="2024-03-07 20:55:00"
      >
      <ul>
        <li>Verbeterde formulieren
          <ul>
            <li>Niet meer per ongeluk wegnavigeren uit het formulier</li>
            <li>Duidelijkere plek voor de "volgende" knop</li>
          </ul>
        </li>
        <li>Fout opgelost waarbij je werd doorgestuurd naar het verkeerde scherm nadat jezelf een boek uitlas.</li>
        <li>Verschillende series van Zwijsen worden nu wel correct ingeladen.</li>
        <li>Ongeveer 700 informatieve kijkdoos boekjes toegevoegd. (Bedankt KC Platijn!)</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.20.0 - Barcodes printen"
      datetime="2024-02-28 22:56:00"
      >
      <ul>
        <li>Barcodes printen
          <ul>
            <li>Weergeef barcode printen knop onderaan de boek pagina nadat je een isbn-nummer zoekt.</li>
            <li>Ondersteuning voor zoeken op isbn10 nadat een succesvolle aanvraag</li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.19.0 - Ondersteuning inladen IEP-toetsen"
      datetime="2024-02-27 00:16:00"
      >
      <ul>
        <li>Ondersteuning inladen IEP-toetsen
          <ul>
            <li>Ondersteuning inladen IEP-toetsen.</li>
            <li>Het inladen van .pdf bestanden wordt niet meer ondersteund.</li>
          </ul>
        </li>
        <li>Ondersteuning voor zoeken op oude ISBN10 nummers.</li>
        <li>Complete upgrade voor koppeling van ParnasSys en ESIS
          <ul>
            <li>Data die s'nachts wordt gesynchroniseerd kan nu overdag worden hergebruikt.</li>
            <li>Inladen van groepen en toetsen is aanzienlijk sneller.</li>
            <li>Betere synchronisatie van koppelingstatus met actieve taken.</li>
            <li>Gekopieerde spaties worden nu genegeerd bij het opslaan van de autorisatiesleutel</li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.18.0 - Weergeef NBD/PIM labels"
      datetime="2024-02-08 00:54:00"
      >
      <ul>
        <li>Weergeef NBD/PIM labels
          <ul>
            <li>Scholen die samenwerken met bibliotheek de Bollenstreek zien nu de NBD/PIM labels.</li>
            <li>In de nabije toekomst kunnen scholen zelf instellen welke labels ze willen weergeven.</li>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.17.0 - Uitbreiding navigatie"
      datetime="2024-02-04 19:27:00"
      >
      <ul>
        <li>Uitbreiding navigatie
          <ul>
            <li>Winkel toegevoegd aan navigatie.</li>
            <li>Update feed toegevoegd aan het "Voor jou" scherm.</li>
            <li>Schoolcontextmenu aangepast voor partners.</li>
            <ul>
              <li>Partners zien nu altijd in één oogopslag welke school is ingeladen.</li>
            </ul>
          </ul>
        </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.16.0 - Leesconsulent rol toegevoegd"
      datetime="2024-01-30 17:36:10"
      >
      <ul>
          <li>Leesconsulent rol toegevoegd
            <ul>
              <li>Leesconsulenten hebben dezelfde permissies als andere partners.</li>
              <li>Daarnaast hebben ze ook dezelfde permissies als de biebbeheerder op school.</li>
              <li>Leesconsulenten kunnen nu meehelpen in de bibliotheek op school.</li>
            </ul>
          </li>
          <li>Aanvullende privacy uitbreidingen</li>
          <ul>
            <li>Zichtbare leerlinggegevens voor biebouders en leesconsulenten nog verder teruggebracht conform de AVG.</li>
          </ul>
      </ul>
      </oib-post>
      <oib-post
      header="3.15.0 - Sneller uitlenen via leerkracht"
      datetime="2024-01-30 03:28:31"
      >
      <ul>
          <li>Sneller uitlenen via leerkracht
            <ul>
              <li>Het aantal handelingen voor het uitlenen van boeken is teruggebracht van 5 naar 3 wanneer er slechts één mogelijke optie is.</li>
            </ul>
          </li>
      </ul>
      </oib-post>
      <oib-post
      header="3.14.0 - Backcovers ingeladen"
      datetime="2024-01-21 00:26:04"
      >
      <ul>
          <li>Backcovers ingeladen
            <ul>
              <li>Naast kaften worden nu ook afbeeldingen van de achterkant van boeken opgeslagen.</li>
            </ul>
          </li>
          <li>Fout opgelost waarbij biebouders toch zelf boeken konder lenen.</li>
      </ul>
      </oib-post>
      <oib-post
      header="3.13.0 - Verhoog variëteit in boektips"
      datetime="2024-01-16 23:48:52"
      >
        <ul>
          <li>Verhoog variëteit in boektips
            <ul>
              <li>De boektips worden nu 50x sneller opgehaald.</li>
              <li>Adviseer om verder te lezen in de serie wanneer de volgorde relevant is.</li>
              <li>Adviseer maximaal 3 boeken van dezelfde serie.</li>
              <li>Adviseer maximaal 5 boeken van dezelfde auteur.</li>
            </ul>
          </li>
          <li>Koppel bibliotheken aan organisaties i.p.v. scholen.</li>
          <ul>
            <li>Biedt ondersteuning voor het inladen van openbare bibliotheek collecties.</li>
          </ul>
          <li>Inladen labels openbare bibliotheken
            <ul>
              <li>PIM genre labels toegevoegd aan het boekenbestand</li>
              <li>NBD genre labels toegevoegd aan het boekenbestand</li>
              <li>NBD leeftijd labels toegevoegd aan het boekenbestand</li>          
            </ul>
          </li>          
        </ul>
      </oib-post>
      <oib-post
      header="3.12.27 - Uitbreiding CB koppeling"
      datetime="2024-01-16 22:24:12"
      >
        <ul>
          <li>Uitbreiding CB koppeling
            <ul>
              <li>Prijs informatie toegevoegd aan boekenbestand.</li>
              <li>Beschikbaarheid en leverdatum toegevoegd aan boekenbestand.</li>
              <li>Nur-codes toegevoegd aan boekenbestand</li>
              <li>Deze data is belangrijk voor het samenstellen van (geautomatiseerde) advieslijsten ter aanvulling op het huidige boekenbestand.</li>
            </ul>
          </li>
        </ul>
      </oib-post>

      <div class="wrapper column">
        <!-- <div v-if="task_finished('ADD_LIBRARY_BOOKS')" class="row" style="max-width: 700px; flex-wrap: wrap; justify-content: space-between;">
          <oib-tile-img
            style="width: 100%; margin-right: 0; height: 350px; margin-top: 5px"
            img_url="https://onderwijsinbeeld.nl/assets/img/de-schoolbieb-op-orde.webp"
            icon="menu_book"
            icon_family="symbol"
            header="De schoolbieb op orde"
            body="Hoe je de schoolbieb in één dag op orde krijgt op de meest efficiënte wijze."
            @click="go_to_link('https://onderwijsinbeeld.nl/de-schoolbieb-op-orde')">
          ></oib-tile-img>
        </div>
        <div v-if="task_finished('ADD_LIBRARY_BOOKS')" class="row" style="max-width: 700px; flex-wrap: wrap; justify-content: space-between;">
          <oib-tile-img
            style="width: 100%; margin-right: 0; height: 350px; margin-top: 5px"
            img_url="https://onderwijsinbeeld.nl/assets/img/blije_kids_wittevrouwen.jpg"
            icon="favorite"
            icon_family="symbol"
            header="Leesbevordering zonder lesmethode"
            body="Hoe je in 2 maanden tijd het leesplezier van iedere leerling verhoogt door ze altijd een leuk en passend boek te laten lezen."
            @click="go_to_link('https://onderwijsinbeeld.nl/leesbevordering-zonder-lesmethode')">
          ></oib-tile-img>
        </div> -->
      </div>     
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibContent from "../components/OibContent.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
// import OibButton from "../components/OibButton.vue"
import OibPost from "../components/oib_post.vue"
import OibChecklistStatic from "../components/oib_checklist_static.vue"

import {get_bucket} from "../store/bucket.js"
import {get_organisations} from '../store/api/user.js'
import {get_schools_partners_query} from '../store/api/user.js'
import { go_to_route } from "../store/navigation";
import { has_role } from "../store/utils.js";

export default {
  name: "Support",
  components: {
    OibPage,
    OibNavStudent,
    OibContent,
    OibHeaderSearch,
    // OibButton,
    OibPost,
    OibChecklistStatic
  },
  computed: {
    can_manage_library() {
      const user = this.$store.getters.getUser
      if (!user) {
        return false
      }
      return has_role(['LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_SUPPORT', 'SYSTEM_ADMIN'])
    },
    action() {
      const user = this.$store.getters.getUser
      if (!user) {
        return false
      }

      if (has_role(['SCHOOL_ADMIN']) && !this.task_finished('LINK_LVS')) {
        return {
          text: 'Koppel het leerlingvolgsysteem',
          route: 'GebruikersLeerkrachten'
        }
      } else if (has_role(['SCHOOL_ADMIN']) && !this.task_finished('ADD_TEACHER_ACCOUNTS')) {
        return {
          text: 'Voeg 5 accounts toe aan leerkrachten',
          route: 'GebruikersLeerkrachten'
        }
      }else if (has_role(['LIBRARY_ASSISTENT', 'LIBRARIAN']) && !this.task_finished('ADD_LIBRARY')) {
        return {
          text: 'Voeg 3 bibliotheken toe',
          route: 'BibliotheekCollectie'
        } 
      } else if (has_role(['LIBRARIAN']) && !this.task_finished('ADD_LIBRARY_BOOKS')) {
        return {
          text: 'Voeg 100 boeken toe aan de bibliotheek',
          route: 'BibliotheekCollectie'
        }
      } else if (has_role(['LIBRARY_ASSISTENT', 'LIBRARIAN']) && !this.task_finished('LOAD_STUDENTS')) {
        return {
          text: 'Laad de leerlingen in',
          route: 'GebruikersLeerlingen'
        } 
      } else if (has_role(['LIBRARY_ASSISTENT', 'LIBRARIAN']) && !this.task_finished('LINK_STUDENT_QR_CODES')) {
        return {
          text: 'Koppel een boekenlegger',
          route: 'GebruikersLeerlingen'
        } 
      } else if (has_role(['LIBRARY_ASSISTENT', 'LIBRARIAN']) && !this.task_finished('LOAD_READING_TESTS')) {
        return {
          text: 'Laad de leestoetsen in',
          route: 'GebruikersLeerlingen'
        } 
      }
      return null
    }
  },
  methods: {
    // Import
    go_to_route,

    // Local
    task_finished(task_title) {
      const task_dict = this.$store.getters.get_task_dict
      return task_title in task_dict && task_dict[task_title].finish_datetime != null
    },
    async load_school_partner() {
      const jwt = this.$store.getters.get_jwt
      const school_id = this.$store.getters.get_school_id_active
      if (!school_id) {
        return
      }

      const school = this.$store.getters.get_school
      this.branch_number = school['branch_number']

      let organisation_id = this.$store.getters.get_organisation_id 
      let organisation_id_active = this.$store.getters.get_organisation_id_active
      if (organisation_id == organisation_id_active || organisation_id == 1) {
        const json = await get_schools_partners_query(jwt, {'school_id': school_id})
        const school_partner_list = json['school_partner_list']
        if (school_partner_list.length == 0) {
          return
        }

        this.partner_organisation_id = school_partner_list[0].organisation_id
      } else{
        this.partner_organisation_id = organisation_id
      }
      
      const organisation = await get_organisations(jwt, this.partner_organisation_id)
      this.partner_organisation_name = organisation.organisation_name    
    },
    search(search_query) {

      // If search query is empty - display all questions
      if (search_query == "") {
        this.display_all()
        return
      }

      const search_words = search_query.toLowerCase().split(" ")
      let match_points = {}
      for (let i = 0; i < this.q_and_a.length; i++) {
        match_points[i] = 0
        const title = this.q_and_a[i]['title']
        const keywords = this.q_and_a[i]['keywords']
        for (let k = 0; k < search_words.length; k++) {
          if (title.includes(search_words[k])) {
            match_points[i] += 1
          }
          for (let x = 0; x < keywords.length; x++) {
            const keyword = keywords[x]
            if (search_words[k].includes(keyword)) {
              match_points[i] += 2
            }
          }
        }
      }

      const dsu = (arr1, arr2) => arr1
        .map((item, index) => [arr2[index], item]) // add the args to sort by
        .sort(([arg1], [arg2]) => arg2 - arg1) // sort by the args
        .map(([, item]) => item); // extract the sorted items

      let keys = []
      let values = []
      for (const [key, value] of Object.entries(match_points))  {
        
        // Filter failed matches
        if (value > 0) {
          keys.push(key)
          values.push(value)
        }        
      }

      this.search_indices = dsu(keys, values)
      this.search_indices.length = Math.min(this.search_indices.length, 4);
      
      let result_list = []
      this.search_indices.forEach(x => result_list.push(this.q_and_a[x]))

      if (result_list.length == 0) {
        result_list = [{'title': 'Niets gevonden'}]
      }

      return result_list
    },
    display_all(){
      this.search_indices = []
      for (var i = 0; i < this.q_and_a.length; i++) {
        this.search_indices.push(i);
      }
    },
    go_to_stel_een_vraag() {
      go_to_route('StelEenVraag')
    },
    go_to_link(href) {
      // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
      setTimeout(() => {
        window.open(href, '_blank');
      })
    },
    get_organisation_image_url(organisation_id) {
      return `${get_bucket('media')}/organisations/l/${organisation_id}.webp`
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    // Skip if logged out
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    this.user_id = user['user_id']
    
    if(this.can_manage_library) {
      this.load_school_partner()
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.display_all() 
  },
  data() {
    return {
      branch_number: null,
      partner_organisation_id: null,
      partner_organisation_name: '',
      search_query: "",
      search_indices: [],
      // Related pages are based on keywords
      q_and_a: [
      {
          "title": "Hoe installer ik de app op Android-devices?",
          "href": "https://play.google.com/store/apps/details?id=nl.onderwijsinbeeld.leesapp",
          "keywords": ["installeren", 'android', 'samsung', 'store', 'download', 'telefoon', 'mobiel'],
        },
        {
          "title": "Hoe installer ik de app op Apple-devices?",
          "href": "https://apps.apple.com/us/app/de-leesapp/id1637641808",
          "keywords": ["installeren", 'iphone', 'ios', 'store', 'download', 'telefoon', 'mobiel'],
        },
        {
          "title": "Hoe voeg ik een account toe?",
          "href": "https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding/#hoe_voeg_ik_accounts_toe",
          "keywords": ["account", 'leerkracht', 'ouder'],
        },
        {
          "title": "Hoe koppel ik het leerlingvolgsysteem?",
          "href": "https://onderwijsinbeeld.nl/leerlingvolgsysteem-koppelen-handleiding",
          "keywords": ["lvs", 'volg', 'parna', 'esis'],
        },
        {
          "title": "Hoe koppel ik een boekenlegger?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boekenleggers_koppelen",
          "keywords": ["inloggen", "boekenlegger", 'qr', 'kwijt'],
        },
        {
          "title": "Help de boekenlegger is kwijt!",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#help_de_boekenlegger_is_kwijt",
          "keywords": ["inloggen", "boekenlegger", 'qr', 'kwijt', 'opnieuw'],
        },
        {
          "title": "Hoe geef ik boek voortgang door?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boek_voortgang_doorgeven",
          "keywords": ["voortgang"],
        },
        {
          "title": "Hoe stop ik met een boek?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#hoe_stop_ik_met_een_boek",
          "keywords": ["voortgang", "stop"],
        },
        {
          "title": "Hoe voeg ik een bibliotheek toe?",
          "href": "https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#bibliotheken_toevoegen",
          "keywords": ["toevoeg"],
        },
                {
          "title": "Hoe voeg ik een boek toe aan de bibliotheek?",
          "href": "https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#boeken_inscannen",
          "keywords": ["toevoeg", "scan"],
        },
        {
          "title": "Hoe leent een leerling een boek?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boeken_uitlenen",
          "keywords": ["leen", "lenen"],
        },
        {
          "title": "Hoe levert een leerling een boek in?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#boeken_inleveren",
          "keywords": ["lever", 'terug'],
        },
        {
          "title": "Kunnen leerlingen ook een boek van thuis lezen?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#kunnen_leerlingen_ook_een_boek_van_thuis_lezen",
          "keywords": ["boek", 'leen', 'lenen', "lever", 'uitle', 'thuis', 'eigen'],
        },
        {
          "title": "Wie gaat de boeken uitlenen?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#wie_gaat_de_boeken_uitlenen",
          "keywords": ["leen", "lenen"],
        },
        {
          "title": "Welke apparaten kunnen we gebruiken?",
          "href": "https://onderwijsinbeeld.nl/leesgedrag-volgen-een-praktische-handleiding/#geschikte_devices",
          "keywords": ["tablet", 'laptop', 'chromebook', "devic", 'appara', 'geschikt', 'ipad'],
        },
        {
          "title": "Hoe rubriceren we de schoolbibliotheek?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#hoe_rubriceren_we_de_schoolbibliotheek",
          "keywords": ['rubriceer', 'organiseer', 'sorteer', 'categori'],
        },
        {
          "title": "Hoe categoriseren we de schoolbibliotheek?",
          "href": "https://onderwijsinbeeld.nl/de-biebscan-een-praktische-handleiding/#saneren_en_organiseren",
          "keywords": ['rubriceer', 'organiseer', 'sorteer', 'categori'],
        },
        {
          "title": "Welke labels plakken we op de boeken?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#welke_labels_plakken_we_op_de_boeken",
          "keywords": ['rubriceer', 'organiseer', 'sorteer', 'categori', 'label'],
        },
        {
          "title": "Boeken op een centrale plek of verspreid over klassen?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#boeken_op_een_centrale_plek_of_verspreid_over_klassen",
          "keywords": ['klas', 'centra', 'locatie'],
        },
        {
          "title": "Welke boeken gaan we saneren?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#welke_boeken_gaan_we_saneren",
          "keywords": ['sane', 'opruim', 'opscho'],
        },
        {
          "title": "Hoe vaak mogen leerlingen een boek ruilen?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#hoe_vaak_mogen_leerlingen_een_boek_ruilen",
          "keywords": ['ruil', 'lenen'],
        },
        {
          "title": "Hoe houden we de bieb op orde?",
          "href": "https://onderwijsinbeeld.nl/de-complete-schoolbieb-gids/#hoe_houden_we_de_bieb_op_orde",
          "keywords": ['opruim', 'schoon', 'orde', 'netjes'],
        },
        {
          "title": "Hoe houd ik de bieb toegankelijk?",
          "href": "https://onderwijsinbeeld.nl/hoe-maak-je-de-schoolbieb-toegankelijker/",
          "keywords": ['toegang', 'zichtbaar'],
        }
      ]
    }
  }
};
</script>

<style scoped>

.background-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 30%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%);
}

.tile-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px;
}

.tile-grid div{
  width: 100%;
  height: 150px;
}

.two-tiles {
  width: 100%;
  height: 220px;
}

@media screen and (min-width:600px) {
  .two-tiles {
    width: 43%;
    height: 300px;
    margin-right: 0;
  }
}

@media screen and (min-width:740px) {
  .tile-grid div{
    width: 220px;
    height: 230px;
  }

  .two-tiles {
    width: 44%;
    height: 300px;
    margin-right: 0;
  }
}

@media screen and (min-width:1230px) {
  .tile-grid div{
    width: 270px;
    height: 230px;
  }

  .two-tiles {
    width: 45%;
    height: 300px;
  }
}

.search-result {
  color: var(--primary-color);
  padding: 2px 0 2px 15px;
  font-weight: 400;
}

.search-result:hover {
  cursor: pointer;
}

.search-result p {
  color: var(--primary-color);
  font-size: 0.8em;
}

.action {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: var(--primary-color-sub);
  padding: 20px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  max-width: 660px;
}

.action:hover {
  background-color: var(--primary-color-p3);
  cursor: pointer;
}

.action p {
  margin: 0;
  font-weight: bold;
  color: white;
  font-size: 0.9em;
}

/* p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.tile {
  display: flex;
  flex-direction: column;
  height: 280px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.34);
}

.tile:hover {
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.70); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.70);
  cursor: pointer;
}

@media screen and (min-width:320px) {
  .tile {
    width: 100%;
  }
}

@media screen and (min-width:1050px) {
  .tile {
    width: 700px;
    height: 350px;
    margin-right: 40px;
  }
}

.tile-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 20px 20px 0 20px;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%,  rgba(0,0,0,0) 30%, rgba(0,0,0,0.5) 90%, rgba(0,0,0,0.5) 100%);
} */

</style>
