<template>
  <button class="oib-button fade" ref="oibButton" :class="shape + ' ' + priority" :style="{'--p1-color': color}">
    <slot></slot>
  </button>
</template>


<script>
export default {
  name: "OibButton",
  props: {
    expand: {
      default: false,
    },
    mount: {
      type: String,
      default: "none",
    },
    priority: {
      type: String,
      default: "p1",
    },
    shape: {
      type: String,
      default: "squared"
    },
    color: {
      type: String,
      default: 'var(--highlight-color)'
    }
  },
  computed: {
    style() {
      return {'--p1-color': this.color}
    }
  },
  mounted() {
    if (this.expand) {
      this.$refs.oibButton.classList.add("expand");
    }
    if (this.mount == "bottom") {
      this.$refs.oibButton.classList.add("mount-bottom");
    }
  }
};
</script>


<style scoped>
.fade {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.oib-button {
  cursor: pointer;
}

.oib-button.p1:enabled:hover {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 0px 3px 19px -6px rgba(0, 174, 239, 0.71);
  box-shadow: 0px 3px 19px -6px rgba(0, 174, 239, 0.71);
}

.oib-button.p1 {
  background-color: var(--p1-color);
  color: var(--contrast-color);
  border: 1px solid var(--p1-color);
}

.oib-button.p2 {
  background-color: transparent;
  color: var(--contrast-color);
  border: 1px solid var(--highlight-color);
}

.oib-button.p3 {
  background-color: transparent;
  color: var(--contrast-color);
  border: 1px solid var(--contrast-color-p4);
}

.oib-button.p4 {
  background-color: rgba(0, 0, 0, 0.38);
  backdrop-filter: blur(5px);
  color: var(--contrast-color);
  /* border: 1px solid var(--contrast-color-p4); */
}

.oib-button:disabled {
  background-color: var(--contrast-color-p4);
  pointer-events: none;
  border-color: var(--contrast-color-p4);
}

.oib-button.expand {
  width: 100%;
  max-width: 400px;
}

.oib-button.mount-bottom {
  position: fixed;
  bottom: 80px;
  width: 300px;
}

.oib-button {
  text-align: center;
}

.oib-button.pill {
  width: 250px;
  padding: 15px 30px;
  border-radius: 30px;
  font-weight: 700;
  text-transform: uppercase;
}

.oib-button.rounded {
  width: 250px;
  padding: 15px 30px;
  border-radius: 10px;
  font-weight: 700;
  text-transform: uppercase;
}


.oib-button.squared {
  border-radius: 5px;
  font-weight: normal;
  cursor: pointer;
}
</style>