export default ({
  state: {
    book_recommendation_list_dict: {},
    user_recommendation_list_dict: {},
  },
  mutations: {
    add_book_recommendation_list(state, body) {
      if (!(body.isbn13 in state.book_recommendation_list_dict)) {
        state.book_recommendation_list_dict[body.isbn13] = {}
      }
      state.book_recommendation_list_dict[body.isbn13][body.library_id_str] = body.recommendation_list
    },
    add_user_recommendation_list(state, body) {
      if (!(body.user_id in state.user_recommendation_list_dict)) {
        state.user_recommendation_list_dict[body.user_id] = {}
      }
      state.user_recommendation_list_dict[body.user_id][body.library_id_str] = body.recommendation_list
    },
  },
  getters: {
    get_book_recommendation_list: (state) =>  (book_edition_id, library_id_str) => {
      if (!(book_edition_id in state.book_recommendation_list_dict)) {
        return undefined
      }
      return state.book_recommendation_list_dict[book_edition_id][library_id_str]
    },
    get_user_recommendation_list: (state) => (user_id, library_id_str) => {
      if (!(user_id in state.user_recommendation_list_dict)) {
        return undefined
      }
      return state.user_recommendation_list_dict[user_id][library_id_str]
    }
  }
})
