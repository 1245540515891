<template>
  <div>
    <oib-header title="Scan de barcode" style="z-index: 100" :show_back="true"/>

    <div class="fullscreen" ref="wrapper" style="position: fixed">
      <oib-barcode-reader
      @decode="x => on_decode(x.barcode, x)"
      format="ean13"
      ref="barcode_reader"
      :view_finder_border_color="view_finder_border_color"
      />
    </div>
    <div style="position: fixed; background-color: var(--primary-color-sub); top: 50px; width: 100%; z-index: 10; padding-top: 20px; padding-bottom: 10px;">
      <div class="wrapper column">
        <oib-search-bar-dropdown
        @search="x=>queue_search_query(x)"
        :search_result="book_title_search_result"
        :search_frequency_ms="10"
        :init_value="search_init_value"
        ref="search_bar"
        >
          <div v-if="isbn_valid && isbn13_on_internet && !isbn13_in_database">
            <div class="select-option" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="book-row-content-main">
                <div style="margin-right: 40px;">
                  <h3 class="h4">{{search_query}} is onbekend</h3>
                  <p class="subh4">
                    {{isbn13_requested.includes(search_query) ? 'Dit boek is aan de wachtlijst toegevoegd': 'Wil je dit kinderboek aanvragen?'}}
                  </p>
                </div>
                <oib-button
                  style="width: 80px; padding: 5px; height: 30px;"
                  class="next"
                  @click="request_book"
                  :disabled="isbn13_requested.includes(search_query)"
                  >Aanvragen
                </oib-button>
              </div>
            </div>
          </div>
          <div v-if="isbn_valid && !isbn13_on_internet && !isbn13_in_database">
            <div class="select-option" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="book-row-content-main">
                <div style="margin-right: 40px;">
                  <h3 class="h4">{{search_query}} is onbekend</h3>
                  <p class="subh4">
                    Stuur een berichtje naar onze Whatsapp helpdesk.                    
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isbn_valid && isbn13_in_database == false">
            <div class="select-option" style="padding-top: 20px; padding-bottom: 20px;">
              <div class="book-row-content-main">
                <div style="margin-right: 40px;">
                  <h3 class="h4">{{search_query}} is geen geldig isbn</h3>
                  <p class="subh4">
                    Heb je een typefoutje gemaakt?                    
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isbn13_in_database">
            <div v-for="item in book_title_search_result" :key="item" class="select-option">
              <div class="book-row-content-main" @click="submit_search_result(search_query, item)">
                <img class="avatar" :src="getCoverUrl(item.isbn13)" />
                <div>
                  <h3 class="h4">{{item.item_name}}</h3>
                  <p class="subh4">
                    {{ item.isbn13 }} | {{ item.isbn10 ? item.isbn10 : 'X' }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </oib-search-bar-dropdown>
      </div>
    </div>
    <audio ref="barcode_beep">
      <source src="../assets/barcode_beep.mp3" />
    </audio>
  </div>
</template>

<script>
import OibHeader from "../components/OibHeader.vue"
import OibBarcodeReader from "../components/barcode_reader_advanced3.vue";
import OibSearchBarDropdown from "../components/oib_search_bar_dropdown.vue"
import OibButton from "../components/OibButton.vue"

import {get_books_search} from "../store/api/book_search.js"
import {get_book_cover} from "../store/api/library.js"
import {get_book_products_scrape_metadata_query} from "../store/api/book_scraper.js"
import {post_book_products_users_scrape} from "../store/api/book_scraper.js"
import {is_valid_isbn} from "../store/isbn.js"
import {convert_isbn_10_to_13} from "../store/isbn.js"

export default {
  name: 'OibBarcodeReaderBook',
  props: {
    user_id: {
      required: true
    },
    view_finder_border_color: {
      type: String,
      default: 'var(--highlight-color)'
    }
  },
  components: { 
    OibHeader,
    OibBarcodeReader,    
    OibSearchBarDropdown,
    OibButton
  },
  data() {
    return {
      book_title_search_result: [],
      book_title: "",
      max_search_result_cap: 5,
      decode_unknown_threshold: 100,
      search_query: "",
      query_running: false,
      isbn_valid: false,
      isbn13_in_database: null,
      isbn13_on_internet: false,
      isbn13_requested: [],
      search_init_value: null
    }
  },
  methods: {
    async on_decode (search_str, result) {

      const isbn13 = result.barcode
      const certainty = result.certainty
      
      // Handle scanner errors
      const book_info = await this.get_book_info(isbn13)

      if (book_info == 'Error - Unknown') {
        if (certainty > this.decode_unknown_threshold) {
          this.$emit("decode_unknown", {'isbn13': isbn13})
          this.$refs.search_bar.focus()
          this.search_init_value = isbn13
          this.$refs.search_bar.search(isbn13)
        }
      }
      else {
        // Play barcode noise
        this.$refs.barcode_beep.play();

        this.unknown_count = 0
        this.$emit("decode", {
          'isbn13': isbn13,
          'book_edition_id': book_info['item_id'],
          'book_title': book_info['item_name'],
          'search_str': search_str
        });
      }
    },
    async get_book_info(isbn13) {
      /*
      Attempts to get the book_edition of this isbn13.
      It returns an error if something is wrong with the isbn13 code.

      :param str isbn13: the isbn13 value to validate.

      :return str error: the error code

      Possible error codes:
      - Invalid - invalid isbn13 code
      - Unknown - valid but unknown by the server
      - null - the isbn13 is valid and known by the server
      */

      //if (!(isbn13.startsWith("97890") || isbn13.startsWith("97894"))) {
      //  return 'Error - Invalid'
      //}

      const jwt = this.$store.getters.getJwt
      const json = await get_books_search(jwt, {
        'search': isbn13,
        'select': 'isbn13,item_id,item_name',
        'item_type': 'BOOK'
      })
      if (json === 'undefined') {
        return 'Error - Unknown'
      }

      if (json['item_list'].length == 0) {
        return 'Error - Unknown'
      }
      else {
        return json['item_list'][0]
      }
    },
    queue_search_query(search_query) {
      this.search_query = search_query
      this.search_isbn13(search_query)
    },
    async search_isbn13(search_query) {

      if (search_query == null || search_query.length == 0) {
        this.book_title_search_result = []
        return
      }

      this.isbn13_in_database = true

      // Run search query
      if (!this.query_running) {
        this.query_running = true
        const jwt = this.$store.getters.getJwt
        const json = await get_books_search(jwt, {
          'search': search_query,
          'select': 'isbn13,item_name',
          'item_type': 'BOOK'
        }).finally(() => this.query_running = false)
        this.book_title_search_result = json['item_list']

        if (this.book_title_search_result.length == 0) {
          this.on_empty_search_result(search_query)
        }
      }

      // Run new query if search_query changed during request
      if (this.search_query != search_query) {
        this.search_isbn13(this.search_query)
      }
    },
    async on_empty_search_result(search_query) {
      //const is_isbn13 = search_query.length == 13 && !isNaN(search_query)
      this.isbn_valid = false
      this.isbn13_in_database = false
      
      if (is_valid_isbn(search_query)) {
        this.isbn_valid = true

        const jwt = this.$store.getters.getJwt
        const isbn13 = convert_isbn_10_to_13(this.search_query)
        const json = await get_book_products_scrape_metadata_query(jwt, {'isbn13': isbn13})

        // This possible, we haven't checked yet
        this.isbn13_on_internet = json['metadata_list'].length == 0
      }
    },
    submit_search_result(search_str, result){
      this.on_decode(search_str, {
        'barcode': result.isbn13,
        'certainty': 1000
      })
    },
    request_book() {

      // Get isbn13
      let isbn13 = this.search_query
      this.isbn13_requested.push(this.search_query)

      if (this.search_query.length == 10) {
        isbn13 = convert_isbn_10_to_13(this.search_query)
        this.isbn13_requested.push(isbn13)
      }
      
      // Scrape
      const jwt = this.$store.getters.getJwt
      post_book_products_users_scrape(jwt, isbn13, this.user_id)
    },
    getCoverUrl(isbn13) {
      if (isbn13) {
        return get_book_cover(isbn13, 's')
      }
      else {
        return "https://us.123rf.com/450wm/teploleta/teploleta1801/teploleta180100105/94540385-seamless-vector-pattern-with-doodle-questions-marks-on-a-blackboard-background.jpg?ver=6";
      }
    },
    pause() {
      this.$refs.barcode_reader.pause()
    },
    continue() {
      this.$refs.barcode_reader.continue()
    },
  },
}
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}

.select-option {
  background-color: var(--primary-color-sub);
  padding: 10px;
}

.select-option:hover {
  background-color: var(--primary-color-p3);
  cursor:pointer;
}

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.h4 {
  color: var(--contrast-color)
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}
</style>