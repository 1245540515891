<template>
  <!-- Slider main container -->
  <div class="swiper-container">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper" :style="'padding-left: ' + paddingLeft">
      <!-- Slides -->
      <div class="swiper-slide" style="cursor: pointer">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper JS
import Swiper from "swiper";
// import Swiper styles
import "swiper/swiper-bundle.css";

export default {
  name: "OibBookSlider",
  props: ["paddingLeft"],
  data() {
    return {
      library_selector_list: []
    }
  },
  methods: {
    create_swiper() {
      new Swiper(".swiper-container", {
        grabCursor: true,
        direction: 'horizontal',
        slidesPerView: 'auto',
        freeMode: true,
      });
    }
  },
  async mounted() {
    this.create_swiper()
  },
  updated() {
    // Required to ensure proper spacing after clickthrough/update
    this.create_swiper()
  }
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
  padding-top: 10px; /* Allows for animations in pixels above slides */
  margin-top: -10px;
}

.swiper-slide { width: auto; }

.tags-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
</style>
