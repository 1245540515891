<template>
  <div class="button-icon" :class="direction">
    <span :class="icon_class" style="margin-bottom: 1px" :style="`${title ? '' : 'opacity: 0;'} ${icon_style}`">{{ icon }}</span>
    <span class="title" :style="title_style">{{ title }}</span>
  </div>
</template>

<script>
export default {
  name: "OibIconLabel",
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false
    },
    direction: {
      type: String,
      required: false,
      default: 'row'
    },
    active: {
      type: Boolean,
      required: false,
      default: true
    },
    icon_style: {
      type: String,
      required: false
    },
    title_style: {
      type: String,
      required: false
    },
    icon_family: {
      type: String,
      default: 'icons'
    }
  },
  computed: {
    icon_class() {
      if (this.icon_family == 'icons') {
        return this.active ? "material-icons" : "material-icons-outlined";
      }
      return 'material-symbols-outlined'
    }
  }
};
</script>

<style scoped>
.button-icon {
  display: flex;
  align-items: center;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}

.button-icon.column {
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.button-icon.row {
  flex-direction: row;
  justify-content: left;
  margin-bottom: 10px;
}

.button-icon.column .title {
  font-size: inherit;
}

.button-icon.row .title{
  font-size: inherit;
  margin-left: 15px;
  text-align: left;
}

.button-icon.column span {
  width: 100%;
  text-align: center;
}
</style>
