import { post_jwt_refresh_resources } from "../api/user.js"


export default {
    state: {
        jwt: "",
        jwt_creation_datetime: null,
        login_error_message: "",
        login_error_instructions: ""
    },
    mutations: {
        set_jwt(state, jwt) {
          state.jwt = jwt;
          state.jwt_creation_datetime = new Date()
        },
        set_login_error_message(state, message) {
          state.login_error_message = message;
        },
        set_login_error_instructions(state, instructions) {
          state.login_error_instructions = instructions;
        }
    },
    actions: {
      async jwt_refresh_resources(state) {
        const response = await post_jwt_refresh_resources(state.state.jwt)
        if (response.status == 401) {
          console.log("Warning: Failed to refresh jwt")
          console.log(state.state.get_jwt_creation_datetime)
          console.log(state.state.jwt)
        } else {
          const response_json = await response.json()
          state.commit("set_jwt", response_json.jwt)
        }
      },
    },
    modules: {

    },
    getters: {
        getJwt: state => state.jwt,
        get_jwt: state => state.jwt,
        getActiveSession: state => state.jwt != "",
        get_jwt_creation_datetime: state => state.jwt_creation_datetime,
        get_login_error_message: state => state.login_error_message,
        get_login_error_instructions: state => state.login_error_instructions
    }
}
