<template>
  <oib-button-icon
  :icon="icon"
  :style="style"
  @click="toggle"
  :active="true"
  icon_class="material-icons"
  />
</template>

<script>
import OibButtonIcon from "./OibButtonIcon.vue"

import {get_users_book_products_favorites} from "../store/api/book_progress"
import {put_users_book_products_favorites} from "../store/api/book_progress"
import {delete_users_book_products_favorites} from "../store/api/book_progress"

export default {
  name: 'OibButtonAddToWishlist',
  props: ['user_id', 'isbn13'],
  components: {
    OibButtonIcon
  },
  computed: {
    icon () {
      return this.added_to_wishlist ? 'favorite' : 'favorite_border'
    },
    style () {
      return this.added_to_wishlist ? 'color: var(--highlight-color);' : 'color: white;';
    }
  },
  async mounted() {
    const jwt = this.$store.getters.get_jwt
    const json = await get_users_book_products_favorites(jwt, {
      user_id: this.user_id,
      isbn13: this.isbn13
    })
    this.added_to_wishlist = json['book_user_favorite_list'].length == 1
  },
  methods: {
    toggle () {
      this.added_to_wishlist = !this.added_to_wishlist
      
      const jwt = this.$store.getters.get_jwt
      if (this.added_to_wishlist) {
        put_users_book_products_favorites(jwt, this.user_id, this.isbn13)
      } else {
        delete_users_book_products_favorites(jwt, this.user_id, this.isbn13)
      }
      
    }
  },
  data () {
    return {
      added_to_wishlist: false,
    }
  }
}
</script>
