export function is_valid_isbn(str) {

    var sum,
        weight,
        digit,
        check,
        i;

    if (str.length != 10 && str.length != 13) {
        return false;
    }

    if (str.length == 13) {
        sum = 0;
        for (i = 0; i < 12; i++) {
            digit = parseInt(str[i]);
            if (i % 2 == 1) {
                sum += 3*digit;
            } else {
                sum += digit;
            }
        }
        check = (10 - (sum % 10)) % 10;
        return (check == str[str.length-1]);
    }

    if (str.length == 10) {
        weight = 10;
        sum = 0;
        for (i = 0; i < 9; i++) {
            digit = parseInt(str[i]);
            sum += weight*digit;
            weight--;
        }
        check = (11 - (sum % 11)) % 11
        if (check == 10) {
            check = 'X';
        }
        return (check == str[str.length-1].toUpperCase());
    }
}

export function convert_isbn_10_to_13(str) {
    var c;
    var checkDigit = 0;
    var result = "";

    c = '9';
    result += c;
    checkDigit += (c - 0) * 1;

    c = '7';
    result += c;
    checkDigit += (c - 0) * 3;

    c = '8';
    result += c;
    checkDigit += (c - 0) * 1;

    for ( let i = 0; i < 9; i++ ) {  // >
        c = str.charAt(i);
        if ( i % 2 == 0 )
            checkDigit += (c - 0) * 3;
        else
            checkDigit += (c - 0) * 1;
        result += c;
    }
    checkDigit = (10 - (checkDigit % 10)) % 10;
    result += (checkDigit + "");

    return ( result );
}