import store from './index'

import { get_users_test_results } from "./api/test.js"

export async function get_user_test_list(user_id) {

  // Pull data from cache
  const user_test_list_dict = store.getters.get_user_test_list_dict
  if (user_id in user_test_list_dict) {
    return user_test_list_dict[user_id]
  }

  // Load data into cache
  const jwt = store.getters.get_jwt
  const json = await get_users_test_results(jwt, {
    'user_id': user_id,
    'is_decisive': 1,
    'sort': 'didactic_age,ASC',
    'limit': 1000,
    'page': 0
  })
  store.commit("add_user_test_list", {
    'user_id': user_id,
    'test_result_list': json['test_result_list']
  })

  return json['test_result_list']
}
