<template>
  <div :class="show_class">
    <section style="position: fixed; top: 0px; left: 0px; z-index: 3; width: 100vw;">
      <div class="wrapper column" style="height: 100vh; max-width: 330px; justify-content: center; z-index: 10">
        <div class="column" style="align-items: center; background-color: var(--primary-color-sub); padding: 20px 20px; border-radius: 10px;">
          <oib-loading-animation style="margin-top: 20px;"/>
          <div style="border: 2px solid white; width: 280px; height: 20px; border-radius: 40px; margin-top: 30px;">
            <div class="fade" :style="`background-color: var(--highlight-color); height: 20px; width: ${progress}%; border-radius: 40px;`"/>
          </div>
          <p>{{ text }}</p>
        </div>
      </div>
    </section>
    <div class="overlay-modal" :class="show_class"/>
    </div>
</template>

<script>
import OibLoadingAnimation from "../components/oib_loading_animation.vue"

export default {
  name: "OibModalLoading",
  props: {
    progress: {
      type: Number,
      required: true
    },
    text: {
      type: String,
      required: false
    }
  },
  components: {
    OibLoadingAnimation
  },
  data() {
    return {
      previous_show_time: new Date(),
      show_class: "hide",
      animation_style: 'animation-duration: 0'
    }
  },
  methods: {
    show() {
      this.animation_style = 'animation-duration: 0.8s'
      this.show_class = "show"      
    },
    hide() {
      this.animation_style = 'animation-duration: 0.8s'
      this.show_class = "hide"      
    },
    toggle() {
      this.animation_style = 'animation-duration: 0.8s'
      this.show_class = this.show_class == 'show' ? '' : 'show'
    }
  }
}
</script>


<style scoped>
.hide {
  display: none
}

.overlay-modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  z-index: 0;
}

.overlay-modal.show {
  opacity: 0.3;
  z-index: 2;
}

.popup-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}

@keyframes slide_up {
  0%   {opacity: 1; max-height: 0;}
  100% {max-height: 400px;}
}


@keyframes slide_down {
  0%   {max-height: 400px; padding: 0 15px; opacity: 1}
  98%  {max-height: 0px;}
  99%  {opacity: 1; padding: 0 15px;}
  100% {opacity: 0; padding: 0px}
}


.popup {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 6px 6px 0 0;
  max-height: 0;
  transition: all 0.25s ease-out;
  overflow: hidden;
  background-color: var(--primary-color-p3);
  animation-name: slide_down;
  padding: 0px;
  opacity: 0;
}

.popup.show {
    max-height: 500px;
    opacity: 100;
    animation-name: slide_up;
    padding: 0 15px;
}


</style>