import {get_organisations_tasks_query, put_organisations_tasks} from "../api/task"
import {get_logs_metrics_query} from "../api/logging.js"
import {get_books} from "../api/library.js"
import {get_datetime_str} from "../utils.js"

export default {
  state: {
    task_dict: {},
    lvs_sync_request: null,
    teacher_list: null,
    student_list: null,
    library_list: null,
    last_test_datetime: null,
    book_waste_data: null,
    book_advice_metric_list_accept: [],
    book_advice_metric_list_ignore: [],
    book_advice_total_count: null,
    book_advice_accept_perc: null,
    task_borrow_count_metric_list: null
  },
  actions: {
    async create_task(state, payload) {

      const jwt = payload.jwt
      const organisation_id = payload.task.organisation_id
      const task_title = payload.task.task_title
      const finish_datetime = payload.task.finish_datetime
      const task_dict = state.state.task_dict

      const is_created = task_title in task_dict
      const create_datetime = is_created ? task_dict[task_title].create_datetime : get_datetime_str()

      const is_finished = is_created && task_dict[task_title].finish_datetime == null && finish_datetime != null
      if (!is_created || is_finished) {
        await put_organisations_tasks(
          jwt,
          organisation_id,
          task_title,
          create_datetime,
          finish_datetime
        )
      }
      state.commit("set_task", {
        'organisation_id': organisation_id,
        'task_title': task_title,
        'create_datetime': create_datetime,
        'finish_datetime': finish_datetime
      })
    },
    async load_task_dict(state, payload) {

      const jwt = payload.jwt
      const organisation_id = payload.organisation_id

      // Get task dict on the server side
      let json = await get_organisations_tasks_query(jwt, {
        'organisation_id': organisation_id,
        'sort': 'create_datetime,DESC',
        'limit': 100
      })
      json['task_list'].forEach(task => {
        if (!(task.task_title in state.state.task_dict)) {
          state.commit("set_task", task)
        }
      })

      // Initialise tasks
      const task_list = [
        // start-up
        'LINK_LVS',
        'ADD_TEACHER_ACCOUNTS',
        'ADD_LIBRARY',
        'ADD_LIBRARY_BOOKS',
        'LOAD_STUDENTS',
        'LINK_STUDENT_QR_CODES',
        'LOAD_READING_TESTS',

        // improvements
        'READ_NEW_BOOKS_50',
        'READ_NEW_BOOKS_75',
        'READ_NEW_BOOKS_90',
        'BOOK_ADVICE_ACCEPTANCE_25',
        'BOOK_ADVICE_ACCEPTANCE_50',
        'BOOK_ADVICE_ACCEPTANCE_60'
      ]


      task_list.forEach(task_title => {
        if (!(task_title in state.state.task_dict)) {
          state.dispatch("create_task", {
            'jwt': jwt,
            'task': {
              'organisation_id': organisation_id,
              'task_title': task_title,
              'finish_datetime': null
            }
          })
        }
      })
    },
    async load_task_book_advice_acceptance(state, payload) {     

      const jwt = payload.jwt
      const organisation_id = payload.organisation_id

      const json_ignore = await get_logs_metrics_query(
        jwt,
        'days',
        {
          'organisation_id': organisation_id,
          'log_type': [40, 41, 42, 43],
          'sort': 'datetime_logged,DESC',
          'limit': 30          
        }
      )

      const json_accept = await get_logs_metrics_query(
        jwt,
        'days',
        {
          'organisation_id': organisation_id,
          'log_type': [44, 45, 46, 47],
          'sort': 'datetime_logged,DESC',
          'limit': 30
        }
      )

      json_accept['logs_metrics'] = json_accept['logs_metrics'].reverse()
      json_ignore['logs_metrics'] = json_ignore['logs_metrics'].reverse()

      let last_30_days = []
      for (let index = -30; index < 0; index++) {
        const start = new Date(); 
        last_30_days.push(new Date(start.getFullYear(),start.getMonth(),start.getDate()+index))
      }

      let ignore_dict = {}
      let accept_dict = {}

      last_30_days.forEach(date => ignore_dict[date] = {x: date, y: 0})
      last_30_days.forEach(date => accept_dict[date] = {x: date, y: 0})

      json_ignore['logs_metrics'].forEach(metric => {
        const datetime = new Date(metric.datetime_logged)
        ignore_dict[datetime] = {
          x: datetime,
          y: metric.log_count
        }
      })
      json_accept['logs_metrics'].forEach(metric => {
        const datetime = new Date(metric.datetime_logged)
        accept_dict[datetime] = {
          x: datetime,
          y: metric.log_count
        }
      })

      // Check if any events were recorded in the past 90 days
      let accept_count_30_days = 0
      let ignore_count_30_days = 0
      let book_advice_metric_list_accept = []
      let book_advice_metric_list_ignore = []
      for (let i = 0; i < last_30_days.length; i++) {
        const date = last_30_days[i]
        const accept_count = accept_dict[date].y
        const ignore_count = ignore_dict[date].y
        book_advice_metric_list_accept.push({x: date, y: accept_count})
        book_advice_metric_list_ignore.push({x: date, y: ignore_count})
        accept_count_30_days += accept_count
        ignore_count_30_days += ignore_count
      }

      // Compute 30 day avg
      let total_count = accept_count_30_days + ignore_count_30_days
      let accept_perc = total_count > 0 ? parseInt(accept_count_30_days / total_count * 100) : 0

      state.commit("set_task_book_advice_metric_list_accept", book_advice_metric_list_accept)
      state.commit("set_task_book_advice_metric_list_ignore", book_advice_metric_list_ignore)
      state.commit("set_task_book_advice_metric", {
        'book_advice_total_count': total_count,
        'book_advice_accept_perc': accept_perc
      })

      state.dispatch("update_task_book_advice_acceptance", {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
    },
    async load_task_read_new_books(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const library_list = state.state.library_list
      
      let book_waste_data = null
      if (library_list && library_list.length > 0) {
        let library_id_list = []
        library_list.forEach(library => library_id_list.push(library.library_id))

        const json_1 = await get_books(jwt, 'types', {
          'library_id': library_id_list.join(','),
          'select': 'isbn13,book_type,copies_total,borrow_count',
          'min_release_date': '2019-01-01',
          'min_borrow_count': 1,
          'book_type': 'LEESBOEK,MAKKELIJK_LEESBOEK,EERSTE_LEESBOEK,INFORMATIEF,STRIPBOEK,ORIENTATIE_OP_LEZEN',
        })
        let copies_count_read_1 = 0
        json_1['books'].forEach(x => copies_count_read_1 += x.copies_total)

        const json_0 = await get_books(jwt, 'types', {
          'library_id': library_id_list.join(','),
          'select': 'isbn13,book_type,copies_total,borrow_count',
          'min_release_date': '2019-01-01',
          'book_type': 'LEESBOEK,MAKKELIJK_LEESBOEK,EERSTE_LEESBOEK,INFORMATIEF,STRIPBOEK,ORIENTATIE_OP_LEZEN',
        })
        console.log(json_0)
        let copies_count_total = 0
        json_0['books'].forEach(x => copies_count_total += x.copies_total)

        // Set total amount of books that have never been read
        book_waste_data = [
          copies_count_total - copies_count_read_1,
          copies_count_read_1
        ]
      }

      state.dispatch('sync_task_read_new_books', {
        'jwt': jwt,
        'organisation_id': organisation_id,
        'book_waste_data': book_waste_data
      })
    },
    async load_task_borrow_count(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const library_list = state.state.library_list

      let result_list = null
      if (library_list && library_list.length > 0) {

        let library_id_list = []
        library_list.forEach(library => library_id_list.push(library.library_id))

        //let tile_list = []
        const json = await get_books(jwt, 'borrow-count', {
          'library_id': library_id_list.join(','),
          'select': 'isbn13,book_type,copies_total,borrow_count,borrow_count_sum',
          'book_type': 'LEESBOEK,INFORMATIEF,STRIPBOEK,ORIENTATIE_OP_LEZEN',
          'sort': 'borrow_count,DESC',
          'limit': 100
        })
        const metric_list = json['books']

        let total_book_count = 0
        let total_borrow_count = 0
        metric_list.forEach(metric => {
          total_book_count += metric['copies_total']
          total_borrow_count += metric['borrow_count_sum']
        })

        // const borrow_count_10_perc = total_borrow_count / 10
        const book_count_10_perc = parseInt(total_book_count / 10)

        result_list = []
        let borrow_count = 0
        let book_count = 0
        for (let i = 0; i < metric_list.length; i++) {
          let k = 0
          const metric_book_count = metric_list[i].copies_total
          const borrow_count_per_book = metric_list[i].borrow_count_sum / metric_book_count
          while (k < metric_book_count) {
            book_count += 1
            borrow_count += borrow_count_per_book
            if (book_count >= book_count_10_perc) {
              result_list.push(parseInt(borrow_count / total_borrow_count * 100))
              book_count = 0
              borrow_count = 0
              
            }
            k += 1
          }
        }
      }
      state.dispatch('sync_task_borrow_count_metrics', {
        'jwt': jwt,
        'organisation_id': organisation_id,
        'metric_list': result_list
      })
    },

    // SYNC
    sync_task_lvs_sync_request(state, payload) {
      state.commit("set_task_lvs_sync_request", payload.lvs_sync_request)

      state.dispatch('update_task_lvs_link', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
    },
    sync_task_teacher_list(state, payload) {
      state.commit("set_task_teacher_list", payload.teacher_list)

      state.dispatch('update_task_add_teacher_accounts', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
    },
    sync_task_library_list(state, payload) {
      state.commit("set_task_library_list", payload.library_list)

      state.dispatch('update_task_add_library', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
    },
    sync_task_student_list(state, payload) {
      state.commit("set_task_student_list", payload.student_list)

      state.dispatch('update_task_load_students', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
      state.dispatch('update_task_link_student_qr_codes', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
      state.dispatch('update_task_load_reading_tests', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
    },
    sync_task_read_new_books(state, payload) {
      state.commit("set_task_read_new_books", payload.book_waste_data)

      state.dispatch('update_task_read_new_books', {
        'jwt': payload.jwt,
        'organisation_id': payload.organisation_id
      })
    },
    sync_task_borrow_count_metrics(state, payload) {
      state.commit("set_task_borrow_count_metric_list", payload.metric_list)
    },

    // ACCOUNTS
    update_task_lvs_link(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const lvs_sync_request = state.state.lvs_sync_request

      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'LINK_LVS',
          'finish_datetime': lvs_sync_request != null && lvs_sync_request['is_succesful'] ? lvs_sync_request['datetime_fulfilled'] : null
        }
      })
    },
    async update_task_add_teacher_accounts(state, payload) {

      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const teacher_list = state.state.teacher_list

      let account_count = 0
      teacher_list.forEach(user => {
        if (user.account_email != null || user.id_token != null) {
          account_count += 1
        }
      })

      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'ADD_TEACHER_ACCOUNTS',
          'finish_datetime': account_count >= 5 ? get_datetime_str() : null
        }
      })
    },

    // DE SCHOOLBIEB OP ORDE
    async update_task_add_library(state, payload) {

      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const library_list = state.state.library_list

      let first_creation_datetime = null
      library_list.forEach(library => {
        if (first_creation_datetime == null || library.creation_datetime < first_creation_datetime) {
          first_creation_datetime = library.creation_datetime
        }
      })

      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'ADD_LIBRARY',
          'finish_datetime': library_list.length >= 3 ? first_creation_datetime : null
        }
      })
    },

    async update_task_add_library_books(state, payload) {

      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const library_book_copy_list = payload.library_book_copy_list

      let first_creation_datetime = null
      library_book_copy_list.forEach(copy => {
        if (first_creation_datetime == null || copy.addition_datetime < first_creation_datetime) {
          first_creation_datetime = copy.addition_datetime
        }
      })

      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'ADD_LIBRARY_BOOKS',
          'finish_datetime': library_book_copy_list.length > 0 ? first_creation_datetime : null
        }
      })
    },

    // IEDERE LEERLING HET JUISTE BOEK
    update_task_load_students(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const student_list = state.state.student_list

      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'LOAD_STUDENTS',
          'finish_datetime': student_list.length > 0 ? get_datetime_str() : null
        }
      })

      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'RETURN_BOOKS',
          'finish_datetime': student_list.length > 0 ? get_datetime_str() : null
        }
      })
    },
    update_task_link_student_qr_codes(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const student_list = state.state.student_list

      // Get finish status
      let first_id_token_datetime = null
      let count = 0
      student_list.forEach(student => {
        if (student.has_id_token) {
          count += 1
          if (!first_id_token_datetime || student.last_update < first_id_token_datetime) {
            first_id_token_datetime = student.last_update
          }
        }
      });

      // Update task
      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'LINK_STUDENT_QR_CODES',
          'finish_datetime':count >= 20 ? first_id_token_datetime : null
        }
      })
    },
    update_task_load_reading_tests(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const student_list = state.state.student_list

      // Get finish status
      let last_test_datetime = null
      student_list.forEach(student => {
        if (student.last_test_datetime && !last_test_datetime || student.last_test_datetime > last_test_datetime) {
          last_test_datetime = student.last_test_datetime
        }
      });

      const now = new Date()
      const year = now.getFullYear()
      const month = now.getMonth()
      let target_test_date = null
      const feb = 1
      const jun = 5
      if (month <= feb) {
        target_test_date = year - 1 + '-05-01'
      } else if (month > feb && month < jun) {
        target_test_date = year + '-01-01'
      } else if (month >= jun) {
        target_test_date = year + '-05-01'
      }

      state.commit("set_task_last_test_datetime", last_test_datetime)

      // Update task
      const is_finished = last_test_datetime != null && last_test_datetime >= target_test_date
      state.dispatch('create_task', {
        'jwt': jwt,
        'task': {
          'organisation_id': organisation_id,
          'task_title': 'LOAD_READING_TESTS',
          'finish_datetime':is_finished ? last_test_datetime : null
        }
      })
    },

    // IMPROVEMENTS
    update_task_read_new_books(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const book_waste_data = state.state.book_waste_data

      let read_perc = null
      if (book_waste_data) {
        const not_read_count = book_waste_data[0]
        const read_count = book_waste_data[1]
        const total_count = not_read_count + read_count
        read_perc = read_count / total_count * 100
      }

      const lvl_list = [50, 75, 90]
      for (let i = 0; i < lvl_list.length; i++) {
        state.dispatch('create_task', {
          'jwt': jwt,
          'task': {
            'organisation_id': organisation_id,
            'task_title': 'READ_NEW_BOOKS_' + lvl_list[i],
            'finish_datetime': read_perc && read_perc >= lvl_list[i] ? get_datetime_str() : null
          }
        })
      }
    },
    update_task_book_advice_acceptance(state, payload) {
      const jwt = payload.jwt
      const organisation_id = payload.organisation_id
      const total_count = state.state.book_advice_total_count
      const accept_perc = state.state.book_advice_accept_perc

      const lvl_list = [25, 50, 60]
      for (let i = 0; i < lvl_list.length; i++) {
        state.dispatch('create_task', {
          'jwt': jwt,
          'task': {
            'organisation_id': organisation_id,
            'task_title': 'BOOK_ADVICE_ACCEPTANCE_' + lvl_list[i],
            'finish_datetime': total_count > 20 && accept_perc >= lvl_list[i] ? get_datetime_str() : null
          }
        })
      }      
    }
  },
  mutations: {
    set_task(state, payload) {
      state.task_dict[payload.task_title] = payload
    },
    reset_tasks(state) {
      state.task_dict = {}
      state.lvs_sync_request = null
      state.teacher_list = null
      state.student_list = null
      state.library_list = null
      state.book_waste_data = null
      state.book_advice_metric_list_accept = []
      state.book_advice_metric_list_ignore = []
      state.book_advice_total_count = null
      state.book_advice_accept_perc = null
      state.task_borrow_count_metric_list = null
    },
    set_task_lvs_sync_request(state, lvs_sync_request) {
      state.lvs_sync_request = lvs_sync_request
    },
    set_task_teacher_list(state, teacher_list) {
      state.teacher_list = teacher_list
    },
    set_task_library_list(state, library_list) {
      state.library_list = library_list
    },
    set_task_student_list(state, student_list) {
      state.student_list = student_list
    },
    set_task_last_test_datetime(state, last_test_datetime) {
      state.last_test_datetime = last_test_datetime
    },
    set_task_read_new_books(state, book_waste_data) {
      state.book_waste_data = book_waste_data
    },
    set_task_book_advice_metric_list_accept(state, book_advice_metric_list) {
      state.book_advice_metric_list_accept = book_advice_metric_list
    },
    set_task_book_advice_metric_list_ignore(state, book_advice_metric_list) {
      state.book_advice_metric_list_ignore = book_advice_metric_list
    },
    set_task_book_advice_metric(state, payload) {
      state.book_advice_total_count = payload.book_advice_total_count
      state.book_advice_accept_perc = payload.book_advice_accept_perc
    },
    set_task_borrow_count_metric_list(state, metric_list) {
      state.task_borrow_count_metric_list = metric_list
    }
  },
  getters: {
    get_task_dict: state => state.task_dict,
    get_task_last_test_datetime: state => state.last_test_datetime,
    get_task_book_waste_data: state => state.book_waste_data,
    get_task_book_advice_metric_list_accept: state => state.book_advice_metric_list_accept,
    get_task_book_advice_metric_list_ignore: state => state.book_advice_metric_list_ignore,
    get_task_book_advice_total_count: state => state.book_advice_total_count,
    get_task_book_advice_accept_perc: state => state.book_advice_accept_perc,
    get_task_borrow_count_metric_list: state => state.task_borrow_count_metric_list
  }
}
