<template>
  <div>
    <div>
      <div v-if="!inSubmitForm">
        <oib-button
          v-if="!isRecording && !inSubmitForm"
          @click="startRecording()"
          >Start</oib-button
        >
        <oib-button v-if="mayNotStopRecording" disabled
          >Aan het luisteren ...</oib-button
        >
        <oib-button v-if="mayStopRecording" @click="stopRecording()"
          >Stop</oib-button
        >
      </div>
      <div v-if="inSubmitForm">
        <div class="column">
          <oib-button @click="uploadAudio()">Opslaan</oib-button>
        </div>
      </div>
    </div>
    <audio ref="bell">
      <source src="../assets/bell.mp3" />
    </audio>
  </div>
</template>

<script>
import OibButton from "./OibButton.vue";
import Recorder from "../recorder.js";

export default {
  name: "OibAudioRecorder",
  components: {
    OibButton,
  },
  data() {
    return {
      audioContext: null,
      recorder: null,
      isRecording: false,
      timeRequirementReached: false,
      minRecordingLength: 150000,
      inSubmitForm: false,
      retries: 0,
      maxRetries: 1,
    };
  },
  computed: {
    mayNotStopRecording() {
      return this.isRecording && !this.timeRequirementReached;
    },
    mayStopRecording() {
      return this.isRecording && this.timeRequirementReached;
    },
    stoppedRecording() {
      return !this.isRecording && this.timeRequirementReached;
    },
  },
  methods: {
    startRecording() {
      // initialise recorder if required
      if (!this.recorder) {
        this.initRecorder();
      } else {
        this.recorder && this.recorder.record();
        this.isRecording = true;
        setTimeout(() => this.stopRecording(), this.minRecordingLength);
      }
    },
    stopRecording() {
      this.recorder && this.recorder.stop();

      // Play finished sound
      this.$refs.bell.play();

      // Reset recorder state
      this.timeRequirementReached = true;
      this.inSubmitForm = true;
      this.isRecording = false;
    },
    resetRecorder() {
      this.timeRequirementReached = false;
      this.inSubmitForm = false;
      this.retries += 1;
      this.recorder.clear();
    },
    initRecorder() {
      try {
        // webkit shim
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        navigator.getUserMedia =
          navigator.getUserMedia || navigator.webkitGetUserMedia;
        window.URL = window.URL || window.webkitURL;

        this.audioContext = new AudioContext();
      } catch (e) {
        alert("No web audio support in this browser!");
      }

      // config, callbackSuccess, callbackError
      const self = this;
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
            googAutoGainControl: false,
            channelCount: 1,
          },
        })
        .then(function (stream) {
          /* use the stream */
          const input = self.audioContext.createMediaStreamSource(stream);

          self.recorder = new Recorder(input);

          // Start recording
          self.startRecording();
        })
        .catch(function (err) {
          console.log("No live audio input: " + err);
        });
    },
    createDownloadLink() {
      this.recorder &&
        this.recorder.exportWAV(function (blob) {
          const url = URL.createObjectURL(blob);
          const li = document.createElement("li");
          const au = document.createElement("audio");
          const hf = document.createElement("a");

          au.controls = true;
          au.src = url;
          hf.href = url;
          hf.download = new Date().toISOString() + ".wav";
          hf.innerHTML = hf.download;
          li.appendChild(au);
          li.appendChild(hf);
          document.getElementById("recordingslist").appendChild(li);
        });
    },
    uploadAudio() {
      const isbn13 = this.$store.getters.getExerciseIsbn13;
      const user_id = this.$store.getters.getUser['user_id'];
      const jwt = this.$store.getters.getJwt;

      // Format log time
      const d = new Date();
      const yyyy = d.getFullYear();
      const dd = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const mm = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
      const HH = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
      const MM = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      const SS = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();

      const datetime_str = yyyy + "-" + mm + "-" + dd + ' ' + HH + ':' + MM + ':' + SS

      this.recorder &&
        this.recorder.exportWAV(function (blob) {
          // Create file
          //const url_audio = URL.createObjectURL(blob);
          const formData = new FormData();
          formData.append("isbn13", isbn13);
          formData.append("file", blob);
          formData.append("datetime_logged", datetime_str)

          // Get request url
          const url = new URL(window.location.href);
          const request = url.protocol + "//" + url.hostname + "/users/" + user_id + "/exercises";

          const headers = {
            method: "post",
            headers: new Headers({
              Authorization: jwt,
            }),
            body: formData,
          };

          fetch(request, headers);
        });

      this.resetRecorder();
    }
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.bottom-card {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
