<template>
  <oib-page>
    <oib-nav-student title="Leesprofiel">
      <template v-slot:left>
        <span class="material-icons" @click="custom_back()">arrow_back</span>
      </template>
    </oib-nav-student>
    <oib-content>
      <div class="wrapper column" style="height: 85vh;">
        <h1 class="capitalize">{{key_list[key_list.length - 1]}}</h1>
        <div v-for="(tree, name) in tree_displayed" :key="name">
          <div class="node" @click="increase_depth(name)">
            <span class="capitalize">{{name}}</span>
            <span v-if="tree_displayed[name] !== undefined" class="material-icons">chevron_right</span>
          </div>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"

export default {
  name: "ReadingProfile",
  components: {
    OibPage,
    OibContent,
    OibNavStudent
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  computed: {
    tree_displayed() {
      let tree = this.tag_tree
      for (let i = 0; i < this.key_list.length; i++) {
        tree = tree[this.key_list[i]]
      }
      return tree
    }
  },
  methods: {
    increase_depth (tag) {
      if (this.tree_displayed[tag] !== undefined) {
        this.key_list.push(tag)
      }
    },
    custom_back () {
      if (this.key_list.length > 1) {
        this.key_list.pop()
      } else {
        this.$router.back()
      }
    }
  },
  data() {
    return {
      key_list: ['root'],

      // Categories are not tags
      tag_tree: {
        'root': {
          'interesses': {
            'hobbies': {
              'sport': {
                'teamsport': {
                  'voetbal': undefined,
                  'hockey': undefined,
                  'handbal': undefined,
                  'basketbal': undefined,
                  'tennis': undefined,
                  'rugby': undefined,
                  'korfbal': undefined,
                },
                'wintersport': {
                  'skiën': undefined,
                  'schaatsen': undefined,
                  'ijshockey': undefined,
                },
                'solo-sport': {
                  'fitness': undefined,
                  'atletiek': undefined,
                  'turnen': undefined,
                  'golf': undefined,
                  'schaken': undefined,
                  'pingpong': undefined,
                  'badminton': undefined,
                  'wielrennen': undefined,
                  'bmx': undefined,
                  'paardrijden': undefined,
                },
                'vechtsport': {
                  'boksen': undefined,
                  'judo': undefined,
                  'karate': undefined,
                  'worstelen': undefined,
                  'taekwondo': undefined,
                },
                'watersport': {
                  'zwemmen': undefined,
                  'waterpolo': undefined,
                  'zeilen': undefined,
                  'kano': undefined,
                  'schoonspringen': undefined,
                  'surfen': undefined,
                },
                'olympische spelen': undefined
              },
              'kunst': {
                'muziek': undefined,
                'dansen': undefined,
                'knutselen': undefined,
                'tekenen': undefined,
                'toneel': undefined,
                'ballet': undefined,
                'goochelen': undefined,
                'circus': undefined
              },
              'digitaal': {
                'gamen': undefined,
                'film en tv': undefined,
                'vloggen': undefined
              },
              'buitenleven': {
                'scouting': undefined,
                'tuinieren': undefined,
                'kamperen': undefined,
                'boomhut': undefined
              },
              'koken': undefined,
              'yoga': undefined,
              'kleren en mode': undefined,
              'skateboarden': undefined,
              'bowlen': undefined,
              'fotografie': undefined
            },
            'mysterie en magie': {
              'fantasie wezens': {
                'draken': undefined,
                'vampieren': undefined,
                'weerwolven': undefined,
                'zombies': undefined,
                'geesten': undefined,
                'feeën': undefined,
                'elfjes': undefined,
                'eenhoorns': undefined,
                'zeemeerminnen': undefined,
                'heksen': undefined,
                'tovenaars': undefined,
                'mummies': undefined,
                'orks': undefined,
                'kabouters': undefined,
                'reuzen': undefined
              },
              'geheimen': undefined,
              'magie': undefined,
              "ufo's en aliens": undefined
            },
            'mens en maatschappij': {
              'geloof': {
                'boedisme': undefined,
                'christendom': undefined,
                'hindoeïsme': undefined,
                'islam': undefined,
                'jodendom': undefined,
                'filosofie': undefined,
                'mythen en legenden': undefined
              },
              'beroepen': {
                'dokter': undefined,
                'brandweer': undefined,
                'politie': undefined,
                'bouwvakker': undefined,
                'kapper': undefined,
                'archeoloog': undefined,
                'architect': undefined,
                'bakker': undefined,
                'boer': undefined
              },
              'bijzondere dagen': {
                'kerst': undefined,
                'sinterklaas': undefined,
                'ramadan': undefined,
                'pasen': undefined,
                'verjaardag': undefined,
                'vakantie': undefined,
                'moeder- en vaderdag': undefined,
                'nieuwjaar': undefined,
                'sint maarten': undefined,
                'carnaval': undefined,
                'halloween': undefined,
                'valentijnsdag': undefined,
              },
              'volkeren': { //TODO geschiedenis
                'egyptenaren': undefined,
                'grieken en romeinen': undefined,
                'cowboys en indianen': undefined,
                'vikingen': undefined
              },
              'middeleeuwen': {
                'ridders': undefined,
                'kastelen': undefined,
                'zwaardvechten': undefined,
                'boogschieten': undefined,
                'kruistochten': undefined,
              },
              'oorlog': {
                'veldslag': undefined,
              },
              'politiek': undefined,
              'slavernij': undefined,
              'prinsen en prinsessen': undefined,
              'land en cultuur': undefined,
              'stad en dorp': undefined,
              'afval': undefined,
              'vlaggen': undefined,
              'hunnebedden en zwerfkeien': undefined
            },
            'wetenschap en techniek': {
              'voertuigen': {
                'lucht- en ruimtevaart': undefined,
                "auto's en vrachtwagens": undefined,
                'boten': undefined,
                'treinen': undefined
              },
              'computers en internet': {
                'programmeren': undefined,
                'robots': undefined,
                'kunstmatige intelligentie': undefined
              },
              'de ruimte': {
                'planeten': undefined,
                'sterren': undefined,
                'ruimtereizen': undefined
              },
              'tijdreizen': undefined,
              'het menselijk lichaam': undefined,
              'uitvindingen': undefined,
              'hoe het werkt': undefined,
              'wiskunde en cijfers': undefined,
            },
            'dier en natuur': {
              'dieren': {
                'huisdieren': {
                  'honden': undefined,
                  'katten': undefined,
                  'konijnen': undefined,
                  'hamsters': undefined,
                  "cavia's": undefined,
                },
                'boerderijdieren': undefined,
                "paarden en pony's": undefined,
                'vissen en zeedieren': undefined,
                'rupsen en vlinders': undefined,
                'vogels': undefined,
                'insecten': undefined,
                'dinosaurussen': undefined,
                'dierenasiel': undefined,
                'dierentuin': undefined,
                'kinderboerderij': undefined
              },
              'ecosystemen': {
                'zee en oceaan': undefined,
                'jungle en tropen': undefined,
                'de woestijn': undefined,
                'noord- en zuidpool': undefined,
                'het bos': undefined
              },
              'seizoenen': {
                'winter': undefined,
                'lente': undefined,
                'zomer': undefined,
                'herfst': undefined
              },
              'natuurrampen': {
                'aardbevingen': undefined,
                'vulkanen': undefined,
                "tornado's en orkanen": undefined,
                'overstroming': undefined
              },
              'planten en bloemen': undefined,
              'het weer': undefined,
              'milieu en klimaat': undefined,
              'fossielen': undefined,
            },
            'actie en avontuur': {
              'misdaad': {
                'diefstal': undefined,
                'moord': undefined,
                'ontvoering': undefined
              },
              'overleven': undefined,
              'onbewoond eiland': undefined,
              'apocalyps': undefined,
              'zoektocht': undefined,
              'piraten': undefined,
              'superhelden': undefined,
              'ontdekkingsreis': undefined,
            },
            'opgroeien': {
                'school': {
                  'schoolkamp': undefined,
                  'schoolreis': undefined,
                  'eindmusical': undefined,  
                  'de brugklas': undefined,
                },
               'dagelijks leven': {
                'eten en drinken': undefined,
                'samen spelen': undefined,
                'familie': undefined,
                'logeren': undefined,
                'supermarkt': undefined,
                'huis en wonen': undefined
              },
              'liefde en leed': {
                'vriendschap': undefined,
                'liefde': undefined,
                'trouwen': undefined,
                'seks': undefined,
                'baby': undefined,
                'scheiden': undefined,
                'ziekte': undefined,
                'de dood': undefined,
              },
              'voorlichting': {
                'gezonde voeding': undefined,
                'geld': undefined,
                'hacken': undefined,
                'pesten': undefined,
                'verslaving': undefined,
                'verkeer': undefined,
                'sociale media': undefined,
                'misbruik': undefined
              },
              'persoonlijke ontwikkeling': {
                'jezelf zijn': undefined,
                'alleen zijn': undefined,
                'leiderschap': undefined,
                'verantwoordelijkheid': undefined
              },
              'leren': {
                'kloklezen': undefined,
                'getallen en tellen': undefined,
                'letters en lezen': undefined,
                'kleuren': undefined
              },
              'verhuizen': undefined,
              'armoede': undefined
            },
          },
          'tijd en ruimte': {
            'tijd': {
              'geschiedenis': {
                'prehistorie': undefined,
                'de oudheid': undefined,
                'middeleeuwen': undefined,
                'gouden eeuw': undefined,
                'wereldoorlog': undefined,
              },
              'moderne tijd': undefined,
              'toekomst': undefined
            },
            'realisme': {
              'fantasie': undefined,
              'realistisch': undefined,
              'waargebeurd': undefined
            },
            'omgeving': {
              'De wijde wereld': {
                'Europa': {
                  'België': undefined,
                  'Duitsland': undefined,
                  'Frankrijk': undefined,
                  'Spanje': undefined,
                  'Italië': undefined,
                  'Verenigd Koninkrijk': undefined,
                  'Rusland': undefined,
                  'Polen': undefined
                },
                'Noord-Amerika': undefined,
                'Zuid-Amerika': undefined,
                'Azië': undefined,
                'Australie': undefined,
                'Afrika': undefined,
              },
              'Nederland': undefined,
              'fantasiewereld': undefined
            },
          },
          'genres': {
            'fantasie': undefined,
            'science-fiction': undefined,
            'roman': undefined,
            'historische roman': undefined,
            'thriller': undefined,
            'verhalende non-fictie': undefined,
            'informatief': undefined,
            'sprookje': undefined,
            'gedicht en rijm': undefined,
            'detective': undefined
          },
          'sfeer': {
            'grappig': undefined,
            'griezelig': undefined,
            'spannend': undefined,
            'mooi': undefined,
            'verdrietig': undefined,
            'ontroerend': undefined,
            'vrolijk': undefined,
            'geheimzinnig': undefined,
            'avontuurlijk': undefined,
            'bijzonder': undefined,
            'saai': undefined,
            'voorspelbaar': undefined,
            'onvoorspelbaar': undefined,
            'schattig': undefined,
            'gek': undefined,
            'leerzaam': undefined
          },
          'vormgeving': {
            'verhaal': undefined,
            'prentenboek': undefined,
            'stripboek': undefined
          },
          'hoofdpersonen': {
            'leeftijd': {
              'kind - 0 tot 4 jaar': undefined,
              'kind - 4 tot 9 jaar': undefined,
              'kind - 9 tot 12 jaar': undefined,
              'kind - 12 tot 18 jaar': undefined,
              'volwassene': undefined
            },
            'uiterlijk': {
              'jongen/man': undefined,
              'meisje/vrouw': undefined,
              'groot': undefined,
              'klein': undefined,
              'dik': undefined,
              'dun': undefined
            },
            'kenmerken': {
              'leider': undefined,
              'slim': undefined,
              'bang': undefined,
              'dapper': undefined,
              'laf': undefined,
              'aardig': undefined,
              'ondeugend': undefined,
              'verdrietig': undefined,
              'onzeker': undefined,
              'sterk': undefined
            },
            'situatie': {
              'slaaf': undefined,
              'wees': undefined,
              'handicap': undefined,
              'gescheiden ouders': undefined,
              'eenzaam': undefined,
              'ziek': undefined,
              'migrant': undefined,
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.node {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--highlight-color);
  padding: 15px 15px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.wrapper .capitalize:first-letter {
  text-transform: capitalize
}

.node span {
  font-size: 1em;
}

</style>