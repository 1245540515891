<template>
  <oib-form
    title="Wachtrij uitleg ☕"
    submit_text="Bevestigen"
    :form_step_validation_list="form_step_validation_list"
    @submit="submit"
  >
    <div>
      <oib-form-header :header="`Je synchronisatieverzoek is toegevoegd aan de wachtrij`"/>
      <p style="font-size: 0.9em;">Het synchroniseren duurt doorgaans tussen de 30 seconden en 3 minuten. Indien dit langer duurt dan verwacht, horen we het graag.</p>
      <p style="font-size: 0.9em;">Je kunt in de tussentijd gewoon de Leesapp blijven gebruiken.</p>
      <p style="font-size: 0.9em;">* Klik rechtboven op het vinkje om door te gaan.</p>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"

export default {
  name: "OibFormLvsSyncConfirmation",
  components: {
    OibForm,
    OibFormHeader,
  },
  data() {
    return {
      form_step_validation_list: [true],
    }
  },
  methods: {
    async submit() {
      this.$router.go(-2)
    }
  }
};
</script>
