<template>
  <oib-content>
    <div v-if="exercise_data">
      <div class="wrapper">
        <h2>Leesfouten</h2>
      </div>
      <oib-list-item
        v-for="mistake in exercise_data.mistakes"
        :key="mistake.mistake_id"
        :title="mistake.word"
        :subtext="mistake.error_type"
      >
        <oib-avatar-icon :icon="getErrorTypeIcon(mistake.error_type)" />
      </oib-list-item>
    </div>
  </oib-content>
</template>

<script>
import OibContent from "./OibContent.vue";
import OibListItem from "./OibListItem.vue";
import OibAvatarIcon from "./OibAvatarIcon.vue";

export default {
  name: "Analyse",
  props: ["exercise_data"],
  components: {
    OibContent,
    OibListItem,
    OibAvatarIcon,
  },
  data() {
    return {
      errorTypeIconLookup: {
        "ReadingErrorEnum.ADD": "add",
        "ReadingErrorEnum.MODIFY": "edit",
        "ReadingErrorEnum.DELETE": "delete",
      },
    };
  },
  methods: {
    getErrorTypeIcon(errorType) {
      return this.errorTypeIconLookup[errorType];
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 0;
}

.book-row:hover {
  background-color: #21222f;
}

.book-row-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.next {
  width: 20px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);
  color: #636465;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.subh4 span {
  font-size: 1.3em;
  padding-right: 4px;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
</style>