<template>
  <library-selector-picker @submit="download()" title="Bibliotheek downloaden" />
</template>

<script>
import LibrarySelectorPicker from "../components/library_selector_picker.vue";

import {get_books} from "../store/api/library.js"
import { utils, write } from "xlsx"

export default {
  name: "BibliotheekUploaden",
  components: {
    LibrarySelectorPicker
  },
  data() {
    return {
      submitting: false,
      progress: 0,
      book_type_dict: {
        'LEESBOEK': 0,
        'INFORMATIEF': 1,
        'PRENTENBOEK': 2,
        'STRIPBOEK': 3,
        'DICHTBUNDEL': 4,
        'VOORLEESBOEK': 5,
        'ORIENTATIE_OP_LEZEN': 6,
        'ZOEKBOEK': 7,
        'AANWIJSBOEK': 8,
        'SAMENLEESBOEK': 9,
        'VAKLITERATUUR': 10,
        'EERSTE_LEESBOEK': 11,
        'MAKKELIJK_LEESBOEK': 12
      },
      language_dict: {
        'DUTCH': 0,
        'ENGLISH': 1,
        'GERMAN': 2,
        'FRENCH': 3,
        'SPANISH': 4,
        'FRYSIAN': 5,
      }
    }
  },
  methods: {
    get_date_str() {
      const d = new Date();
      const yyyy = d.getFullYear();
      const dd = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      const mm = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;

      return yyyy + "_" + mm + "_" + dd
    },
    s2ab(s) { 
      var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      var view = new Uint8Array(buf);  //create uint8array as viewer
      for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
      return buf;    
    },
    async download () {
      const jwt = this.$store.getters.get_jwt
      const library_selector_id = this.$store.getters.get_active_library_selector
      const library_selector = this.$store.getters.get_library_selector_list[library_selector_id]
      const json = await get_books(jwt, 'products', {
        select: 'isbn13,book_title,author_name,release_date,book_type,book_language,borrow_count_sum,copies_total,copies_in_use',
        sort: 'book_title,ASC',
        library_id: library_selector.query.library_id,
        limit: 10000
      })

      // Init
      let total = 0
      let data_collection = []
      let data_book_type = []
      let data_language = []

      for (let key in this.book_type_dict) {
        data_book_type.push({categorie: key, aantal: 0, percentage: 0})
      }
      for (let key in this.language_dict) {
        data_language.push({taal: key, aantal: 0, percentage: 0})
      }

      // Parse data
      json['books'].forEach(book => {
        total += book['copies_total']

        data_collection.push({
          'isbn13': book['isbn13'],
          'titel': book['book_title'],
          'auteur': book['author_name'],
          'datum_publicatie': book['release_date'],
          'categorie': book['book_type'],
          'taal': book['book_language'],
          'aantal': book['copies_total'],
          'aantal geleend': book['copies_in_use'],
          'aantal geleend totaal': book['borrow_count_sum']
        })
        data_book_type[this.book_type_dict[book['book_type']]]['aantal'] += book['copies_total']
        data_language[this.language_dict[book['book_language']]]['aantal'] += book['copies_total']
      })

      data_book_type.forEach(row => {
        row['percentage'] = (row['aantal'] / total * 100).toFixed(2).toString() + '%'
      })
      data_language.forEach(row => {
        row['percentage'] = (row['aantal'] / total * 100).toFixed(2).toString() + '%'
      })

      // Worksheet - collection
      const worksheet_collection = utils.json_to_sheet(data_collection)
      worksheet_collection['!cols'] = [
        {wch:13},
        {wch:40},
        {wch:20},
        {wch:10},
        {wch:20},
        {wch:12},
        {wch:8},
        {wch:8},
        {wch:8},
        {wch:20},
        {wch:20},
        {wch:10},
        {wch:10},
      ];

      utils.sheet_add_json(worksheet_collection, data_book_type, {skipHeader: false, origin: "K1"})
      utils.sheet_add_json(worksheet_collection, data_language, {skipHeader: false, origin: "K17"})

      // Create workbook
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet_collection, 'Collectie overzicht')

      const workbook_out = write(workbook, {bookType:'xlsx',  type: 'binary'});
      const workbook_blob = new Blob([this.s2ab(workbook_out)],{type:"application/octet-stream"})

      // Save as
      const filename = `${library_selector.name.toLowerCase().replace(/ /g,"_")}_${this.get_date_str()}.xlsx`
      var tempLink = document.createElement("a");
      tempLink.setAttribute('href', URL.createObjectURL(workbook_blob));
      tempLink.setAttribute('download', filename);
      tempLink.click();

      URL.revokeObjectURL(tempLink.href);

      // Return
      this.$router.back()
    },
  }
};
</script>
