<template>
  <div class="column">
    <label :class="$mq" v-if="label">
      {{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span>
    </label>
    <p v-if="description" class="description">{{description}}</p>
    <input
    :type="type"
    :placeholder="placeholder"
    @input="x => on_input(x)"
    ref="input"
    class="input-field fade"
    :class="value && value.length > 0 ? error_msg == null ? 'valid' : 'invalid' : ''"
    @focus="in_focus = true"
    @focusout="in_focus = false"
    />
    <slot name="hint"></slot>
    <p
    class="fade"
    style="color: var(--negative-color); font-size: 0.8em; margin: 5px 0 5px 0; min-height: 20px;"
    :style="!in_focus && value && value.length > 0 ? 'opacity: 1' : 'opacity: 0'">{{ error_msg }}</p>
  </div>
</template>

<script>
export default {
  name: "OibInputText",
  props: {
    init_value: {
      default: ""
    },
    label: {
      type: String,
      required: false
    },
    description: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    error_msg: {
      type: String,
      default: ""
    },
    show_error: {
      type: Boolean,
      default: true
    },
    type: {
      default: 'text'
    }
  },
  data() {
    return {
      value: "",
      in_focus: false
    }
  },
  mounted() {
    setTimeout(() => this.set_value(this.init_value), 100)
  },
  watch: {
    init_value() {
      this.set_value(this.init_value)
    },
    error_msg() {
      console.log(this.error_msg)
      console.log(this.error_msg == null)
    }
  },
  methods: {
    set_value(value) {
      if (this.$refs.input) {
        if (value == null) {
          value == ""
        }
        this.$refs.input.value = value
        this.value = value
      }
    },
    on_input(event) {
      this.value = event.target.value
      this.$emit("input", event);
    }
  }
}
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 5px;
}

* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.input-field:focus {
  border-bottom: none !important;
}

.input-field.valid:not(:focus) {
  box-shadow: inset 0 -3px 0 var(--highlight-color);
}

.input-field.invalid:not(:focus) {
  box-shadow: inset 0 -3px 0 var(--negative-color);
}


</style>