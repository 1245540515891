<template>
  <button :class="`${$mq} ${classList}`" ref="buttonLabel">
    <slot class="title"></slot>
  </button>
</template>

<script>
export default {
  name: "OibButtonLabel",
  props: {
    active: {
      default: false,
    },
  },
  computed: {
    classList() {
      // watch it
      const defaultClasses = "button-label fade";
      if (this.active) {
        return defaultClasses + " active";
      } else {
        return defaultClasses;
      }
    },
  },
};
</script>

<style scoped>
.button-label {
  display: flex;
  flex-direction: column;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid var(--contrast-color-p4);
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 11px;
  font-weight: 400;

}

.active,
.button-label.desktop:hover {
  cursor: pointer;
  border: 1px solid var(--highlight-color);
  background-color: var(--highlight-color);  
}

.fade {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.1s ease-in;
  -moz-transition: all 0.1s ease-in;
  -o-transition: all 0.1s ease-in;
  -ms-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
</style>
