<template>
  <div class="oib-row-stack">
    <slot></slot>
  </div>
</template>

<style scoped>
.oib-row-stack {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  width: 100%;
  overflow: auto;
}
</style>