<template>
  <div class="popup-wrapper">
    <div :class="'popup ' + showstyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: ["duration"],
  data() {
    return {
      previous_show_time: new Date(),
      showstyle: ""
    }
  },
  methods: {
    show() {
      this.showstyle = "show"
      this.previous_show_time = new Date()
      setTimeout(() => {
        // Only hide if this was triggered by the last input
        const now = new Date()
        if (now - this.previous_show_time >= this.duration) {
          this.showstyle = ""
        }        
      }, this.duration);
    },
    hide() {
      this.showstyle = ""
    }
  }
}
</script>


<style scoped>
.popup-wrapper {
  position: fixed;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.popup {
  display: flex;
  width: 80%;
  overflow: auto;
  background-color: white;
  border-radius: 6px;
  max-width: 400px;
}

.popup-wrapper .popup {
    max-height: 0;
    transition: all 0.25s ease-out;
    overflow: hidden;
    background: white;
    padding: 15px;
    opacity: 0;
}

.popup.show {
    max-height: 500px;
    transition: all 0.5s ease-in;
    opacity: 100;
}
</style>