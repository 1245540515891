<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Boek toevoegen" @submit="submit">
    <div>
      <oib-form-header header="Thema vormgeving"/>
      <oib-input-color
      label="Kleur"
      :required="true"
       :init_value="init_color"
      @input="set_color($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputColor from "../components/oib_form/oib_input_color.vue"

import {get_tags_query} from "../store/api/book.js"

export default {
  name: "OibFormUpdateTag",
  props: ["tag_id"],
  components: {
    OibForm,
    OibFormHeader,
    OibInputColor,
  },
  data() {
    return {
      form_step_validation_list: [false],
      init_color: "",
      color: "",
      tag_name: "",
      search_key_list: []
    }
  },
  async mounted() {
    const jwt = this.$store.getters.get_jwt
    const json = await get_tags_query(jwt, {'tag_id': this.tag_id})
    const tag = json['tag_list'][0]
    this.init_color = tag.color
    this.tag_name = tag.tag_name
    this.search_key_list = tag.search_key_list
  },
  methods: {
    set_color(color) {
      this.color = color.substring(1)
      this.validate_step_1()
    },
    validate_step_1() {
      this.form_step_validation_list[0] = this.color.length == 6
    },
    async submit() {
      this.$emit("submit", {
        'tag_id': this.tag_id,
        'tag_name': this.tag_name,
        'color': this.color,
        'search_key_list': this.search_key_list
      })
    }
  }
};
</script>

<style scoped>

.avatar {
  width: 56px;
  height: 70px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 2px;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}
</style>