<template>
  <section>
    <div class="row" style="padding-left: 5%">
      <div class="tab" @click="to_gebruikers_leerlingen">
        <h3>Leerlingen</h3>
        <div
        class="fade"
        :style="tab_active =='leerlingen' ? 'opacity: 100' : 'opacity: 0'"
        />
      </div>
      <div style="width: 40px"></div>
      <div class="tab" @click="to_gebruikers_leerkrachten">
        <h3>Leerkrachten</h3>
        <div
        class="fade"
        :style="tab_active =='leerkrachten' ? 'opacity: 100' : 'opacity: 0'"
        />
      </div>
    </div>
    <div style="border-bottom: 1px solid var(--primary-color-p4); margin-bottom: 20px"></div>
  </section>
</template>

<script>
export default {
  name: "OibTabGebruikers",
  props: {
    tab_active: {
      type: String,
      required: true
    }
  },
  methods: {
    to_gebruikers_leerlingen() {
      this.$router.push({
        name: "GebruikersLeerlingen"
      });
    },
    to_gebruikers_leerkrachten() {
      this.$router.push({
        name: "GebruikersLeerkrachten"
      });
    }
  }
}
</script>

<style scoped>

.tab:hover {
  cursor: pointer;
}

.tab h3 {
  font-size: 0.9em;
  font-weight: 700;
  margin-top: 0
}

.tab div {
  border-bottom: 2px solid var(--highlight-color);
  border-radius: 1px;
  margin-bottom: 0;
}
</style>