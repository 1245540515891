<template>
  <div style="width: 100%;" ref="grid_base">
    <!-- <p>{{ item_list }}</p>
    <p>{{ row_count }}</p>
    <p>{{ column_count }}</p> -->
    <div v-if="row_count > 0 && column_count > 0">
      <div v-for="y in row_count" :key="y" class="row book-row">
        <div v-for="x in column_count" :key="x">
          <book-tile
          v-if="get_tile(x, y)"                
          :user_id="user_id"
          :isbn13="get_tile(x, y).isbn13"
          :book_edition_id="get_tile(x, y).book_edition_id"
          :book_title="get_tile(x, y).item_name"
          :sub_text="get_tile(x, y).sub_text"
          :isbn13_scanned="get_tile_scanned(x, y).isbn13"
          :marker="get_tile(x, y).marker"
          :copies_in_use="get_tile(x, y).copies_in_use"
          :copies_total="get_tile(x, y).copies_total"
          :marker_width="marker_width"
          @select="on_select(get_tile(x, y).copy_id)"
          />
          <div v-if="!get_tile(x, y)" style="width: 150px; height: 255px"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BookTile from "../components/BookTile.vue"

export default {
  name: "OibBookGridStatic",
  props: {
    item_list: {
      required: false,
      default: []
    },
  },
  components: {
    BookTile
  },
  data() {
    return {
      show_filter: false,
      user_id: null,
      marker_width: 40,
      row_count: null,
      column_count: null,
      search_query: '',
      book_tile_width: 160,
      search_limit: 0,
      query_running: false,
    }
  },
  async mounted() {

    // Do not run if user is not defined - User did refresh or logout
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    if (!this.user_id) {
      this.user_id = user['user_id']
    }

    // Init vars
    this.on_resize()

    // Add events
    window.addEventListener('resize', this.on_resize);
  },
  updated() {
    this.on_resize()
  },
  unmounted () {
    window.removeEventListener('resize', this.on_resize);
  },
  watch: {
    item_list() {
      if (this.item_list.length > 0) {
        setTimeout(() => this.on_resize(), 1)
      }      
    }
  },
  methods: {
    compute_row_count(total_count) {
      const row_count_rest = total_count % this.column_count;
      let row_count_filled = Math.floor(total_count / this.column_count);

      // Add additional row if not all tiles fit in the grid aspect ratio
      if (row_count_rest != 0) {
        row_count_filled += 1;
      }

      this.row_count = row_count_filled
    },
    on_resize() {

      // Compute column_count
      const min_spacing = 10
      const total_width = this.$refs.grid_base.clientWidth;
      let z = (total_width + min_spacing) / (this.book_tile_width + min_spacing)

      // Compute if extra tile could fit minimal space requirement
      const space_needed_for_extra_tile = (Math.ceil(z) - z) * this.book_tile_width;
      const space_minimal_for_extra_tile = Math.ceil(z) * min_spacing;
      this.column_count = space_needed_for_extra_tile < space_minimal_for_extra_tile ? Math.ceil(z) : Math.floor(z)
      this.compute_row_count(this.item_list.length)
    },
    get_tile(x, y) {
      // Correct for 1 indexed loop
      x--; y--;

      // Get isbn13 for these coordinates in the grid
      const search_result = this.item_list

      const grid_coordinate_1d = y * this.column_count + x;
      if (grid_coordinate_1d > search_result.length - 1) {
        return null
      }
      const tile = search_result[y * this.column_count + x]
      return tile
    },
    get_tile_scanned(x, y) {
      const search_query = this.$store.getters.get_search_query
      return this.is_number(search_query) ? this.get_tile(x, y) : {'isbn13': null}
    },
    is_number(string) {
      return /^\d+$/.test(string)
    },
    on_select(copy_id) {
      this.$emit("select", copy_id)
    }
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.submit.mobile {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  padding: 10px 0;
  text-align: center;  
  background-color: var(--primary-color-sub);
}

.submit.mobile:hover {
  cursor: pointer;
}

.submit.mobile button {
  width: 100%;
  max-width: 400px;
  padding: 10px 30px;
}

.sort-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}

.filter-icon {
  margin-right: 20px;
  margin-top: 2px;
}

.sort-section-header {
  justify-content: space-between;
  align-items: center;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}

.tag-tile:hover {
  cursor: pointer;
}
</style>
