<template>
  <div class="selector fade">
    <div class="wrapper">
      <div class="log-book-row">
        <div class="log-book-row-main">
          <oib-avatar :img_url="image_url"/>
          <div class="log-book-row-details">
            <h3 class="h4">{{ header }}</h3>
            <p> {{ text }} </p>
          </div>
        </div>
        <div style="width: 100px; text-align: right;">
          <oib-button
            style="width: 80px; padding: 5px; height: 30px;"
          >{{ button_text }}</oib-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OibButton from "./OibButton.vue"
import OibAvatar from "./oib_avatar.vue"

export default {
  name: "OibListButton",
  props: ["image_url", "header", "text", "button_text"],
  components: {
    OibButton,
    OibAvatar
  },
};
</script>

<style scoped>
.log-book-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
}

.log-book-row:hover {
  cursor: pointer;
}

.log-book-row-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 65%;
}

.log-book-row-main img {
  width: 40px;
  height: 50px;
  object-fit: cover;
  padding-right: 20px;
  border-radius: 2px;
}

.log-book-row-details p {
  margin: 0;
  font-size: 10px;
  color: #888888;
}

.icon {
  color: var(--contrast-color-p4);
}

.selector:hover {
  background-color: rgba(255, 255, 255, 0.03);
}
</style>
