<template>
    <library-picker
    :user_id="user_id"
    :physical_access_only="true"
    @input="library_id => move_book(library_id)"
    />
</template>

<script>
import LibraryPicker from "../components/library_picker.vue"

import {get_libraries_books_copies} from "../store/api/library.js"
import {put_libraries_books_copies} from "../store/api/library.js"

export default {
  name: "BibliotheekBoekVerplaatsen",
  props: ['user_id'],
  components: {
    LibraryPicker
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    async move_book(library_id_new) {

      // Parse query params
      const library_id_list = Object.keys(this.$store.getters.get_library_dict)
      let query = {
        'library_id': library_id_list.join(','),
        'limit': 100000,
        'page': 0
      }
      new URLSearchParams(window.location.search).forEach((v, k) => query[k] = v)

      // Get copy list
      const jwt = this.$store.getters.get_jwt
      const json = await get_libraries_books_copies(jwt, query)
      const copy_list_old = json['copy_list']

      // Move all if copy_id is provided, move 1 if only isbn13 is provided
      let copy_list_new = []
      if ('copy_id' in query) {
        copy_list_old.forEach(copy => copy_list_new.push(copy))
      } else if ('isbn13' in query) {
        copy_list_new.push(copy_list_old[0])
      }

      // Execute
      for (let i = 0; i < copy_list_new.length; i++) {
        const copy = copy_list_new[i]
        await put_libraries_books_copies(
          jwt,
          copy['library_id'],
          copy['isbn13'],
          copy['copy_id'],
          parseInt(library_id_new),
          copy['isbn13'],
          copy['addition_datetime']
        )
      }

      setTimeout(this.$router.back, 200);
    }
  }
};
</script>

<style scoped>
.reset-margin {
  margin-left: 50px;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    display: block;
    overflow-x: auto;
    margin-right: 40px;
}

.styled-table thead tr {
    background-color: var(--highlight-color);
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.style-table td {
  min-width: 100px;
}

.styled-table th {
  min-width: 160px;
  text-align: left;
}

.styled-table tbody tr {
    border-bottom: 1px solid #21222f;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #21222f;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--highlight-color);
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: var(--highlight-color);
}

.styled-table tr td:nth-child(2) { width:100% }

</style>