<template>
  <div
    ref="booktile"
    class="column fade book-tile-wrapper"
    style="width: var(--book-width); height: calc(var(--book-width) / 4 * 5 + 65); cursor: pointer;"
    :style="`opacity: ${opacity}`"
    v-if="(typeof isbn13 !== 'undefined')"
    >
    <div
    style="position: relative; width: var(--book-width); height: calc(var(--book-width) / 4 * 5)"
    :class="show_checkbox == 'on_hover' ? 'show_on_hover' : ''"
    >
      <div
      v-if="show_checkbox == 'on_hover' || show_checkbox == 'always'"
      style="width: 0; height: 0; position: absolute;"
      class="checkbox"
      >
        <div @click="on_select" :style="is_selected ? 'width: var(--book-width); height: calc(var(--book-width) / 4 * 5)': ''">
          <div
          class="fade"
          style="padding: 7px; height: 20px; width: 20px;"
          >
            <oib-checkbox ref="checkbox" style="height: 20px; width: 20px;" :set_checked="is_selected" style_type="show_placeholder"/>
          </div>
        </div>
      </div>
      <div
      v-if="!image_not_found"
      @click="on_click"
      @touchstart="on_touchstart"
      class="book-tile fade"
      :style="is_selected ? 'width: 80%; height: 80%; padding: 12.33% 10%; background-color: rgba(255, 255, 255, 0.1);' : ''"      
      >
        <img 
        class="book-tile"
        :src="cover_src"
        :onload="set_image_loaded"
        :onerror="set_image_not_found"
        >
        <div v-if="marker" class="marker" :style="`width: ${marker_width}px`">
          <h3>{{marker}}</h3>
        </div>
      </div>
      <img
      v-if="progress_state && progress_state == 3"
      src="../assets/svg/red_x.svg"
      style="position: absolute; width: 100%; height: 100%; top: 0%; left: 0%; color: red; z-index: 10; font-size: calc(var(--book-width) * 1.4); text-align: center; line-height: auto;"
      />
      <div
      v-if="image_not_found"
      @click="on_click"
      @touchstart="on_touchstart"      
      class="book-tile fade"
      :style="is_selected ? 'width: 80%; height: 80%; padding: 12.33% 10%; background-color: rgba(255, 255, 255, 0.1)' : ''"
      >
        <div        
        style="width: 100%; height: 100%; border-radius: 4px;"
        :style="`padding: 5px; display: flex; justify-content: center; align-items: center; background: ${get_random_color()}; color: rbg(150, 150, 150); text-align: center; box-sizing: border-box;`"
        >
          <h3>{{book_title}}</h3>
        </div>
        <div v-if="marker" class="marker" :style="`width: ${marker_width}px`">
          <h3>{{marker}}</h3>
        </div>
      </div>
    </div>
    <div style="text-align: left" @click="on_click">
      <h3 class="cut-text">{{ book_title }}</h3>
      <div class="row" style="height: 20px; align-items: center;">
        <div v-if="is_library_tile && is_available" style="background-color: var(--highlight-color); border-radius: 6px; padding: 0 5px 0 5px; margin-right: 7px; height: inherit;">
          <p style="color: white; font-weight: 700; line-height: 0; height: 20px; font-size: 0.7em; margin-top: 10px;">{{availability}}</p>
        </div>
        <div v-if="is_library_tile && !is_available" style="background-color: var(--contrast-color-p4); border-radius: 6px; padding: 0 5px 0 5px; margin-right: 7px; height: inherit;">
          <p style="color: white; font-weight: 700; line-height: 0; height: 20px; font-size: 0.7em; margin-top: 10px;">{{availability}}</p>
        </div>
        <p style="font-size: 0.7em; height: 20px;">{{sub_text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {get_book_cover} from "../store/api/library"
import {on_click_isbn13_router} from "../store/click"
import OibCheckbox from "./OibCheckbox.vue"

export default {
  name: "BookTile",
  props: {
    user_id: {
      required: true
    },
    isbn13: {
      required: true,
    },
    isbn13_scanned: {},
    book_edition_id: {
      required: false
    },
    book_title: {
      type: String,
      required: false
    },
    sub_text: {
      type: String,
      required: false
    },
    copies_in_use: {
      type: Number,
      default: null,
      required: false,
    },
    copies_total: {
      type: Number,
      default: null,
      required: false,
    },
    marker: {
      type: String,
      default: null,
      required: false
    },
    marker_width: {
      type: Number,
      default: 40
    },
    progress_state: {
      type: Number,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    set_checked: {
      type: Boolean
    },
    show_checkbox: {
      type: String,
      default: 'never'
    }
  },
  components: {
    OibCheckbox
  },
  data() {
    return {
      cover_src: null,
      image_not_found: false,
      is_library_tile: false,
      is_available: false,
      availability: "?/?",
      opacity: 0,
      is_selected: false,
      touchstart_time: null,
      touching: false
    }    
  },
  watch: {
     isbn13() {
      this.init()
     },
     set_checked() {
      if (this.is_selected != this.set_checked) {
        this.on_select()
      }
     },
     show_checkbox() {
      if (this.show_checkbox == 'never' || this.show_checkbox == 'on_hover') {
        this.is_selected = false
      }
     }
  },
  created() {
    this.init()
  },
  mounted() {
    addEventListener("touchmove", this.on_touchmove)
  },
  unmounted() {
    removeEventListener("touchmove", this.on_touchmove)
  },
  methods: {
    init() {
      this.cover_src = get_book_cover(this.isbn13, 'm')
      this.is_library_tile = this.copies_in_use != null && this.copies_total != null
      if (this.is_library_tile) {
        this.availability = `${this.copies_total - this.copies_in_use}/${this.copies_total}`  
        this.is_available = this.copies_in_use < this.copies_total
      }
    },
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    get_random_color() {
      const color_1 = `rgb(${this.randomIntFromInterval(10, 150)}, ${this.randomIntFromInterval(180, 180)}, 205)`
      const color_2 = `rgb(${this.randomIntFromInterval(10, 150)}, ${this.randomIntFromInterval(180, 180)}, 205)`
      const color = `linear-gradient(${this.randomIntFromInterval(0, 45)}deg, ${color_1} 0%, ${color_2} 100%);`
      return color
    },
    set_image_loaded() {
      this.opacity = 1
    },
    set_image_not_found() {
      this.opacity = 1
      this.image_not_found = true
    },
    get_intersection(list_0, list_1) {
      var intersection = list_0.filter(function(n) {
        return list_1.indexOf(n) !== -1;
      });
      return intersection
    },
    on_click() {
      if (this.disabled) {
        return
      }

      on_click_isbn13_router(
          this.user_id,
          this.book_edition_id,
          this.isbn13,
          this.isbn13_scanned,
          this.$store.getters.get_active_library_id_list,
          false
      )
    },
    on_select() {
      this.is_selected = !this.is_selected
      this.$emit("select", this.is_selected)
    },
    set_checkbox(is_selected) {
      this.is_selected = is_selected
    },
    on_touchstart() {
      const touchstart_time = new Date()
      this.touchstart_time = touchstart_time
      this.touching = true

      if (['on_hover', 'always'].includes(this.show_checkbox)) {
        setTimeout(() => {
          if (this.touching && touchstart_time == this.touchstart_time) {
            this.on_select()
          }
        }, 450)
      }
    },
    on_touchmove(event) {
      if (this.touching) {
        const bounding_box = this.$refs.booktile.getBoundingClientRect()
        const page_x = event.targetTouches[0].pageX
        const page_y = event.targetTouches[0].pageY
        if (page_x < bounding_box.left || page_x > bounding_box.right || page_y < bounding_box.top || page_y > bounding_box.bottom) {
          this.touching = false
        }
      }
    }
  }
};
</script>

<style scoped>
.book-tile {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.book-tile-wrapper:hover {
  transition: transform .15s;
  transform: translateY(-4px)
}

h3 {
  font-size: 0.8em;
}

p {
  font-size: 0.8em;
  color: #acaeb0;
}

.row {
  display: flex;
  flex-direction: row;
}

.subtext {
  line-height: 0;
  font-size: 0.7em;
}
.icon{
  font-size: 14px;
  padding-right: 5px;
  color: var(--highlight-color)
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: var(--book-width);
  white-space: nowrap;
}

.marker {
  height: 0;
  transform: translate(0px, -40px);  
}

.marker h3 {
  margin: 0;
  padding: 2px 0;
  background-color: rgba(27, 23, 37, 0.8);
}

.show_on_hover .checkbox {
  opacity: 0;
}

.show_on_hover:hover .checkbox {
  opacity: 1;
}
</style>