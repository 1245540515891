<template>
  <oib-form-update-tag :tag_id="tag_id" @submit="x => submit(x)"/>
</template>

<script>
import OibFormUpdateTag from "../components/oib_form_update_tag.vue";

import { put_tags } from "../store/api/book.js"

export default {
  name: "ThemaAanpassen",
  props: ['tag_id'],
  components: {
    OibFormUpdateTag
  },
  methods: {
    async submit(body) {

      const user = this.$store.getters.getUser
      if (!user) {
        return
      }

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt

      put_tags(
        jwt,
        this.tag_id,
        body['tag_name'],
        body['color'],
        body['search_key_list']
      )

      // Route to bibliotheek beheer
      this.$router.back()
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
