import { getRole } from './cache/cacheUser';
import store from './index'

// compare both regular types and arrays
export function comp(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;

    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; ++i) if (a[i] !== b[i]) return false;
    
    return true;
}

export function get_date_str() {
  const d = new Date();
  const yyyy = d.getFullYear();
  const dd = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
  const mm = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;

  return yyyy + "-" + mm + "-" + dd
}

export function get_datetime_str() {
  const d = new Date();
  const date_str = get_date_str()
  const HH = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
  const MM = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
  const SS = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();

  return date_str + ' ' + HH + ':' + MM + ':' + SS
}

export function has_role(role_list_valid, user_id=null) {
  /*
  Returns whether the user with given user_id has any of the roles submitted.

  If no user_id is given, the function assumes the signed in user_id.
  */

  let user = user_id == null ? store.getters.getUser : store.getters.getUsers[user_id]

  if (!user) {
    return false
  }

  const role_id_list = user.role_id_list
  if (!role_id_list) {
    return false
  }

  let role_list = []
  role_id_list.forEach(role_id => role_list.push(getRole(role_id)['role']))

  for (let i = 0; i < role_list_valid.length; i++) {
    if (role_list.includes(role_list_valid[i])) {
      return true
    }
  }
  return false
}

function randomIntFromInterval(min, max) { // min and max included 
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function get_random_color() {
  const color_1 = `rgb(${randomIntFromInterval(10, 150)}, ${randomIntFromInterval(180, 180)}, 205)`
  const color_2 = `rgb(${randomIntFromInterval(10, 150)}, ${randomIntFromInterval(180, 180)}, 205)`
  const color = `linear-gradient(${randomIntFromInterval(0, 45)}deg, ${color_1} 0%, ${color_2} 100%);`
  return color
}