<template>
  <div class="fade img-frame" :style="`opacity: ${opacity}`">
    <img
      v-if="!image_not_found"
      class="img-frame"
      :src="img_url"
      :onload="set_image_loaded"
      :onerror="set_image_not_found"
    />
    <div v-if="image_not_found" class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: ${get_random_color()}`"/>
  </div>
</template>

<script>
import {get_random_color} from "../store/utils"

export default {
  name: "OibAvatar",
  props: ["img_url"],
  data() {
    return {
      opacity: 0,
      image_not_found: false
    }
  },
  methods: {
    get_random_color() {
      return get_random_color()
    },
    set_image_loaded() {
      this.opacity = 1
    },
    set_image_not_found() {
      this.opacity = 1
      this.image_not_found = true
    }
  } 
};
</script>

<style scoped>
.img-frame {
  width: 50px;
  height: 62px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 2px;
}
</style>