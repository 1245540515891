<template>
  <div class="column">
    <label>{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
    <oib-search-bar-dropdown
      :placeholder="placeholder"
      :init_value="init_value"
      :search_result="search_result"
      :search_query="search_query"
      :search_delay="search_delay"
      :search_frequency_ms="search_frequency_ms"
      @search="search_query=>search_local(search_query)"
      @oib_input="value => $emit('oib_input', value)"
      @click="focussed = true"
      @focusout="focussed = false"
    >
      <div
      v-if="allow_custom_input && in_search_result(search_key, search_query)"
      @click="on_click(create_new())"
      class="select-option"
      >
        <p class="subh4" style="width: 40px;">
          Nieuw: 
        </p>
        <h3 class="h4" style="color: var(--primary-color)"> {{search_query}}</h3>
      </div>
      <div v-for="item in search_result" :key="item">
        <div class="select-option" @click="on_click(item)">
          <slot :item="item"></slot>
        </div>
      </div>
    </oib-search-bar-dropdown>
    <oib-icon-label
      v-if="!(error_msg == null)"
      style="color: red; font-size: 0.8em; height: 50px;"
      icon="error"
      :title="focussed ? '' : error_msg"
      direction="row"
      :active="false"
    />
  </div>
</template>

<script>
import OibIconLabel from "../../components/oib_icon_label.vue"
import OibSearchBarDropdown from "../oib_search_bar_dropdown.vue"

export default {
  name: "OibInputDropdownSearch",
  components: {
    OibIconLabel,
    OibSearchBarDropdown
  },
  props: {
    init_value: {
      default: ""
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    error_msg: {
      type: String,
      default: ""
    },
    search_key: {
      type: String,
      required: true,
    },
    search: {
      required: true
    },
    search_delay: {
      type: Number,
      required: false,
      default: 500
    },
    allow_custom_input: {
      required: false,
      default: true
    },
    search_frequency_ms: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      value: "",
      search_result: [],
      search_query: "",
      focussed: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.value = this.create_new()
    }, 150)
  },
  watch: {
    init_value() {
      this.search_query = this.init_value
    }
  },
  methods: {
    create_new() {
      let obj = {}
      obj[this.search_key] = this.search_query
      return obj
    },
    on_click(input) {
      this.search_query = input[this.search_key]
      this.value = input
      this.$emit("oib_click", input);
    },
    in_search_result(var_name, var_value) {
      let book_title_list = []
      for (let i = 0; i < this.search_result.length; i++) {
        book_title_list.push(this.search_result[i][var_name])
      }
      return !book_title_list.includes(var_value)
    },
    async search_local(search_query) {
      this.search_result = await this.search(search_query)
      this.search_query = search_query
    }
  }
}
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
}

* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.book-row-content-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.select-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  color: var(--primary-color);
}

.select-option:hover {
  cursor: pointer;
}
</style>