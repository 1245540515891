<template>
  <div class="nav-header">
    <div
    v-if="$mq == 'mobile'"
    class="row"
    style="box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.3); position: fixed; width: 100%; align-items: center;"
    :style="background_style"
    >
      <div class="row" style="width: 5%; justify-content: center;" :style="show_back_button || show_logout_button ? 'min-width: 50px' : ''">
        <slot name="left">
          <oib-button-logout v-if="show_logout_button"/>  
          <oib-button-back v-if="show_back_button"/>            
        </slot>
      </div>
      <div class="wrapper row" style="margin: 0">
        <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <slot name="search_collection"></slot>
        </div>
        <p class="material-icons" style="height: 0; line-height: 12px; margin-right: -10px; font-size: 20px">search</p>
        <oib-search-bar
        ref="search_bar"
        :placeholder="placeholder"
        :show_search_icon="false"
        :init_value="$store.getters.get_search_query"
        :search_frequency_ms="search_frequency_ms"
        shape="block"
        style="height: 50px; background-color: transparent; color: white;"
        @search="x => $emit('search', x)"
        @input="e => search_query = e.target.value"
        @click="$emit('click_searchbar')"
        />
      </div>

      <span
      v-if="search_query!=''"
      class="material-icons"
      style="font-size: 24px; margin-right: 20px;"
      @click="$refs.search_bar.clear()"
      >
      close</span>
      <slot v-if="search_query==''" name="on_empty_search"></slot>
    </div>
    <div v-if="$mq == 'desktop'" class="row" style="margin-left: 200px; height: 50px; position: fixed; width: 100%; align-items: center; background-color: var(--primary-color)">
      <div style="width: calc((100% - 200px) * 0.05); position: fixed; z-index: 3">
        <div class="row" style="width: 100%; min-width: 50px; justify-content: center;">
            <slot name="left">
              <oib-button-logout v-if="show_logout_button"/>
              <oib-button-back v-if="show_back_button" style="margin-top: 1px;"/>              
            </slot>
        </div>
      </div>
      <div style="width: calc(100% - 200px); position: fixed;">
        <div class="wrapper row" style="height: 30px;">
          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <slot name="search_collection"></slot>
          </div>
          <oib-search-bar
          ref="search_bar"
          :placeholder="placeholder"
          :show_search_icon="false"
          :init_value="$store.getters.get_search_query"
          :search_frequency_ms="search_frequency_ms"
          shape="block"
          style="height: 30px;"
          @search="x => $emit('search', x)"
          @input="e => search_query = e.target.value"
          @click="$emit('click_searchbar')"
          />
          <div style="background-color: var(--contrast-color); transform: translate(-1px, 0); width: 30px">
            <span
            v-if="search_query!=''"
            class="material-icons clickable"
            style="font-size: 24px; margin-top: 3px; color: var(--primary-color)"
            @click="$refs.search_bar.clear()"
            >
            close</span>
          </div>
          <div style="padding-left: 20px;">
            <slot name="on_empty_search"></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OibSearchBar from "./OibSearchBar.vue"
import OibButtonBack from "./OibButtonBack.vue";
import OibButtonLogout from "./OibButtonLogout.vue";

export default {
  name: "OibHeaderSearch",
  props: {
    placeholder: {
      type: String,
      required: false,
      default: "Doorzoeken"
    },
    init_search_query: {
      type: String,
      required: false,
      default: ''
    },
    init_focus: {
      type: Boolean,
      default: false
    },
    show_back_button: {
      default: true
    },
    show_logout_button: {
      default: false
    },
    transparent: {
      default: false
    },
    search_frequency_ms: {
      type: Number,
      default: 1000
    }
  },
  components: {
    OibSearchBar,
    OibButtonBack,
    OibButtonLogout
  },
  data() {
    return {
      search_query: '',
      background_style: ''
    }
  },
  mounted() {
    if (this.init_focus) {
      this.$refs.search_bar.focus()
    }

    window.addEventListener('scroll', this.on_scroll);
    this.on_scroll()
  },
  unmounted() {
    window.removeEventListener('scroll', this.on_scroll);
  },
  methods: {
    on_scroll() {
      // Do not add background to header on scroll if icons have backdrop
      if (this.show_backdrop) {
        return
      }

      if (this.$mq == 'mobile') {
        this.$refs.search_bar.blur()
      }

      const opacity = Math.max(0.5, Math.min((window.scrollY - 25) / 100, 1))

      let background = "background-color: rgba(33,34,47, 1);"
      if (this.transparent) {
        background = `
        background: rgba(33,34,47, ${opacity});
        background: linear-gradient(0deg, rgba(33,34,47,${opacity * 0.9}) 0%, rgba(33,34,47,${opacity}) 100%);
        `
      }
      this.background_style = background
    }
  }
}
</script>


<style scoped>

.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.nav-header {
  position: fixed;
  z-index: 2;
}

.desktop {
  margin-left: 200px;
}
</style>
