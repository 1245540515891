<template>
  <oib-form-library-access
  v-if="classroom_access_id_set_start"
  :library_id="library_id"
  :classroom_access_id_set_init="classroom_access_id_set_start"
  @submit="x => submit(x)"
  />
</template>

<script>
import OibFormLibraryAccess from "../components/oib_form_library_access.vue";

import {put_classrooms_id_libraries_id} from "../store/api/library.js"
import {load_library_user_access} from "../store/library.js"
import { reload_libraries } from "../store/library.js";

function difference(setA, setB) {
    let _difference = new Set(setA)
    for (let elem of setB) {
        _difference.delete(elem)
    }
    return _difference
}

export default {
  name: "BibliotheekAanpassenToegang",
  props: ['library_id'],
  components: {
    OibFormLibraryAccess
  },
  data() {
    return {
      classroom_access_id_set_start: null
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  created() {
    // Skip on refresh
    if (!this.$store.getters.getActiveSession) {
      return
    }

    // Library vars init
    const library = this.$store.getters.get_library_dict[this.library_id]
    let classroom_id_set = new Set()
    library.classroom_access.forEach(obj => {
      if (obj.has_access) {
        classroom_id_set.add(obj.classroom_id)
      }      
    })

    // Library access vars init
    if (classroom_id_set.has(null)) {
      classroom_id_set.delete(null)
    }
    this.classroom_access_id_set_start = classroom_id_set
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt

      // Update access
      const classroom_id_create = difference(body.classroom_access_id_set, this.classroom_access_id_set_start);
      const classroom_id_delete = difference(this.classroom_access_id_set_start, body.classroom_access_id_set);

      classroom_id_create.forEach(async (classroom_id) => await put_classrooms_id_libraries_id(jwt, classroom_id, this.library_id, 1))
      classroom_id_delete.forEach(async (classroom_id) => await put_classrooms_id_libraries_id(jwt, classroom_id, this.library_id, 0))

      // Reload libraries
      await reload_libraries()

      // Compute user access
      const user_id = this.$store.getters.getUser['user_id']
      load_library_user_access(user_id)

      // Route back
      this.$router.back()
    }
  }
};
</script>
