<template>
  <div class="log-book-row" v-if="book" @click="on_click">
    <div class="log-book-row-main">
      <img :src="get_book_cover(book.isbn13)" />
      <div class="log-book-row-details">
        <h3 class="h4">{{ book.book_title }}</h3>
        <p> {{ book.last_update_datetime }}<span v-if="book.library"> • {{ book.library.library_name }}</span></p>
      </div>
    </div>
    <div class="row" style="width: 140px; justify-content: space-between;">
      <oib-button-icon
        icon="home"
        style="font-size: 24px;"
      />
      <oib-button
        style="width: 80px; padding: 5px; height: 30px;"
      >Inleveren</oib-button>
    </div>
  </div>
</template>

<script>
import OibButton from "./OibButton.vue"
import OibButtonIcon from "./OibButtonIcon.vue"

import {get_book_cover} from "../store/api/library.js"
import {on_click_isbn13_router} from "../store/click"

export default {
  name: "OibListItemLogbook",
  props: ["user_id", "book"],
  components: {
    OibButton,
    OibButtonIcon
  },
  data() {
    return {
      library_name: "",
    }
  },
  mounted() {
    console.log(this.book)
    this.library_name = 'Thuis'
    if (this.book.library_id) {
      this.library_name = this.$store.getters.get_library_dict[this.book.library_id].library_name
    }
  },
  methods: {
    get_book_cover(isbn13) {
      return get_book_cover(isbn13, 's')
    },
    async on_click() {
      const isbn13 = this.book.isbn13
      const book = await this.$store.dispatch("load_book", isbn13)

      on_click_isbn13_router(
        this.user_id,
        book['book_edition_id'],
        isbn13,
        isbn13,
        [this.book.library_id],
        false
      )
    }
  },
};
</script>

<style scoped>
.log-book-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-size: 14px;
  padding: 10px 0;
}

.log-book-row:hover {
  cursor: pointer;
}

.log-book-row-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 65%;
}

.log-book-row-main img {
  width: 40px;
  height: 50px;
  object-fit: cover;
  padding-right: 20px;
  border-radius: 2px;
}

.log-book-row-details p {
  margin: 0;
  font-size: 10px;
  color: #888888;
}

.icon {
  color: var(--contrast-color-p4);
}
</style>
