<template>
    <div ref="lazy" class="fade hide">
        <slot v-if="should_render"></slot>
    </div>
</template>

<script lang="ts">

  export default {
    props: {
      renderOnIdle: {
        default: false
      }
    },
    data() {
        return {
            should_render: false
        }
    },
    mounted() {
        // Enable lazy loading for mobile devices
        if (this.$mq == 'desktop') {
            this.render()
        } else {
            setTimeout(() => this.render(), 300)
        }
    },
    methods: {
        render() {
            this.should_render = true
            this.$refs.lazy.classList.remove('hide')
        }
    }
  }
</script>

<style>
.hide {
    opacity: 0
}
</style>
