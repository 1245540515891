<template>
  <oib-page>
    <oib-nav-student title="Avi-Stopwatch"/>
    <oib-modal ref="modal_sort">
      <div class="wrapper column" style="max-width: 400px">
        <oib-button-icon icon="drag_handle" style="font-size: 30px; padding: 0; color: var(--contrast-color-p2)"/>
        <h2 class="h2">Avi-leeskaart formulier</h2>
        <div style="margin-bottom: 30px">
          <div class="row">
            <p style="margin-bottom: 0">Naam</p>
            <p style="margin-bottom: 0; width: 209px;">{{user_name}}</p>
          </div>
          <div class="row">
            <p style="margin-bottom: 0">Leeskaart</p>
            <p style="margin-bottom: 0; width: 209px;">{{avi_card_active}}</p>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <p style="margin-bottom: 0">Leestijd</p>
            <p style="margin-bottom: 0; width: 209px;">{{time_measured}}</p>
          </div>
          <div class="row" style="margin-bottom: 20px;">
            <p style="margin: 0">Leesfouten</p>
            <oib-input-text
            placeholder="0"
            :required="false"
            :init_value="reading_mistake_count"
            :error_msg="null"
            type="number"
            @input="set_reading_mistake_count($event.target.value)"
            />
          </div>
          <div class="row">
            <p style="margin-top: 0">Niveau</p>
            <p style="margin-top: 0; width: 209px;">{{reading_level}}</p>
          </div>
        </div>
        <p class="close-modal clickable" :style="form_is_valid ? 'color: var(--highlight-color)' : ''" @click="submit">VOLTOOIEN</p>
      </div>
    </oib-modal>
    <oib-content v-if="$mq=='mobile'">
      <div class="wrapper column" style="height: 80vh; max-width: 330px; text-align: center; justify-content: center">
        <p>Deze experimentele functie werkt alleen op een laptop of computer.</p>
      </div>
    </oib-content>
    <oib-content v-if="$mq=='desktop'">
      <div class="wrapper column" style="max-width: 600px; margin-bottom: 200px;">
        <div class="clock">
          <div class="outer-clock-face">
            <div class="marking marking-one"></div>
            <div class="marking marking-two"></div>
            <div class="marking marking-three"></div>
            <div class="marking marking-four"></div>
            <div class="inner-clock-face">
              <!--<div class="hand hour-hand"></div>
              <div class="hand min-hand"></div>
              <div class="hand second-hand"></div>-->
              <p style="width: 100%; height: inherit; text-align: center; margin-top: 50%; line-height: 0; font-size: 35px">
                {{String(minutes).padStart(2, '0')}}:{{String(seconds).padStart(2, '0')}}:{{String(milliseconds / 10).padStart(2, '0')}}
              </p>              
            </div>
          </div>
        </div>
        <div class="row" style="justify-content: space-around; margin-bottom: 50px;">
          <oib-button
            v-if="!turned_on && !awaiting_speech"
            shape="pill"
            priority="p4"
            v-on:click="start_listening"
            style="width: 250px"
            >Start met luisteren
          </oib-button>
          <oib-button
            v-if="!turned_on && awaiting_speech"
            shape="pill"
            priority="p4"
            :disabled="true"
            style="width: 250px"
            >Aan het luisteren ...
          </oib-button>
          <oib-button
            v-if="turned_on"
            shape="pill"
            priority="p4"
            v-on:click="stop_stopwatch"
            style="width: 250px"
            >Stop
          </oib-button>
        </div>
        <!-- <div class="row lap" style="width: 100%; border-bottom: solid var(--contrast-color-p4) 1px; margin-bottom: 10px; color: var(--contrast-color-p4)">
          <p style="width: 160px">VOORSPELLING 01.3%</p>
          <p>M6A</p>
          <p>NIVEAU B</p>
        </div>
        <div class="row lap" style="width: 100%; border-bottom: solid var(--contrast-color-p4) 1px; margin-bottom: 10px; color: var(--contrast-color-p4)">
          <p style="width: 160px">VOORSPELLING 96.3%</p>
          <p>E5A</p>
          <p>NIVEAU B</p>
        </div>
        <div class="row lap" style="width: 100%; border-bottom: solid var(--contrast-color-p4) 1px; margin-bottom: 10px; color: var(--contrast-color-p4)">
          <p style="width: 160px">VOORSPELLING 02.4%</p>
          <p>M5A</p>
          <p>NIVEAU B</p>
        </div> -->
        <div v-for="(lap, index) in laps" :key="lap.index" class="row lap" style="width: 100%; border-bottom: solid var(--contrast-color-p4) 1px; margin-bottom: 10px;" :style="index == 0 ? 'color: var(--highlight-color)' : 'color: var(--contrast-color-p3)'">
          <p class="mono" style="width: 190px">Tijd: {{parse_seconds(lap.score)}} - Fout {{lap.error_count}}</p>
          <p class="mono">{{lap.test_part_type}}</p>
          <p class="mono">Niveau {{lap.skill_level}}</p>
        </div>
        <!-- <p>{{word_string}}</p> -->
      </div>      
      <oib-audio-recorder ref="audio_recorder"/>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibButton from "../components/OibButton.vue"
import OibModal from "../components/oib_modal.vue"
import OibButtonIcon from "../components/OibButtonIcon.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibAudioRecorder from "../components/OibAudioRecorder_v2.vue"
import DiffMatchPatch from 'diff-match-patch';

import {put_upload_audio} from "../store/api/media"
import {put_users_test_results} from "../store/api/test"
import {post_users_test_results} from "../store/api/test"
import {get_users_test_results} from "../store/api/test"

export default {
  name: "AviStopwatch",
  props: ["user_id"],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButton,
    OibModal,
    OibButtonIcon,
    OibInputText,
    OibAudioRecorder
  },
  data() {
    return {
      user_name: '',
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      reading_mistake_count: '',
      form_is_valid: false,
      time_seconds: 0,
      time_measured: '',
      laps: [],
      turned_on: false,
      awaiting_speech: false,
      interval_method: null,
      speech_recognition: null,
      char_timestamps: {},
      word_timestamps: [],
      word_string: '',
      didactic_age_dict: {
        'M3': 5,
        'E3': 10,
        'M4': 15,
        'E4': 20,
        'M5': 25,
        'E5': 30,
        'M6': 35,
        'E6': 40,
        'M7': 45,
        'E7': 50,
        'PLUS': 55
      },
      avi_card_active: null,
      avi_card_finished: false,
      avi_cards: {
        'AVI_2018_M3A': {
          name: 'AVI_2018_M3A',
          min_time_i: 131,
          min_time_f: 163,
          min_error_i: 10,
          min_error_f: 16,
          keywords: ['hut', 'boom', 'dak', 'tak', 'mus'],
          start: 'tom zegt een hut is fijn',
          end: 'die is ook leuk dag mus'
        },
        'AVI_2018_E3A': {
          name: 'AVI_2018_E3A',
          min_time_i: 116,
          min_time_f: 142,
          min_error_i: 8,
          min_error_f: 14,
          keywords: ['vacht', 'wit', 'krul', 'leert', 'blaft', 'pootje', 'poot', 'knappe'],
          start: 'suss heeft pas een hond',
          end: 'je bent een knappe hond'
        },
        'AVI_2018_M4A': {
          name: 'AVI_2018_M4A',
          min_time_i: 109,
          min_time_f: 139,
          min_error_i: 9,
          min_error_f: 14,
          keywords: ['zuchtend', 'grappig', 'schudden', 'verbaasd', 'saai', 'proest', 'draaistoel', 'minuut'],
          start: 'zie je hem al vraagt sem',
          end: 'duidelijk saai is hij niet'
        },
        'AVI_2018_E4A': {
          name: 'AVI_2018_E4A',
          min_time_i: 130,
          min_time_f: 162,
          min_error_i: 8,
          min_error_f: 14,
          keywords: ['stapt', 'bus', 'schone', 'achteren', 'schoolreis', 'dagje', 'moeras', 'opgelet', 'blubberig', 'planken', 'touw', 'opeens', 'glibberen', 'zwaait', 'blubber', 'prut', 'bovenbenen', 'schone', 'kleren'],
          start: 'tessa stapt in de bus heb',
          end: 'nee die ben ik vergeten'
        },
        'AVI_2018_M5A': {
          name: 'AVI_2018_M5A',
          min_time_i: 122,
          min_time_f: 144,
          min_error_i: 9,
          min_error_f: 15,
          keywords: ['trefbal', 'eindelijk', 'eerder', 'teams', 'maximaal', 'springt', 'ontwijkt', 'makkelijk', 'klasgenoten', 'gooit', 'afvallers', 'speelveld', 'verschrikt', 'domino', 'kaatst', 'overeind', 'onverwacht', 'kampioen'],
          start: 'oke tijd voor trefbal zegt juf',
          end: 'te raken yes eindelijk kampioen'
        },
        'AVI_2018_E5A': {
          name: 'AVI_2018_E5A',
          min_time_i: 117,
          min_time_f: 137,
          min_error_i: 9,
          min_error_f: 16,
          keywords: ['regenjas', 'fles', 'zonnebrandcréme', 'voetbaltas', 'trap', 'trekt', 'voordeur', 'dicht', 'achterbank', 'vader', 'chauffeur', 'moeder', 'intussen', 'chocola', 'finale', 'genoeg', 'getraind', 'beaamt', 'wed', 'betere', 'spelers', 'voegt', 'beker', 'gemengd', 'voetbal', 'clubs', 'veroveren', 'jongens', 'meisjes', 'team', 'fanatiek', 'prijzenkast', 'verslaan'],
          start: 'robin propt zijn regenjas en de',
          end: 'thousiast en de anderen knikken'
        },
        'AVI_2018_M6A': {
          name: 'AVI_2018_M6A',
          min_time_i: 107,
          min_time_f: 119,
          min_error_i: 7,
          min_error_f: 12,
          keywords: ['deuren', 'ramen', 'koelte', 'dieven', 'dol', 'geopende', 'privé', 'agenten', 'rondrijdende', "politieauto's", 'woningen', 'tegelijk', 'schaduwrijk', 'afdakje', 'bewaakt', 'erf', 'aankomt', 'blaffen', 'direct', 'controleren', 'honden', 'actief', 'twintig', 'waakhond', 'vaste', 'ritueel', 'gewillig', 'huisdieren', 'peperdure', 'rashondjes', 'mogen', 'binnen', 'rondlopen', 'gestolen'],
          start: 'op een tropisch eiland is het',
          end: 'rondlopen worden ze gestolen'
        },
        'AVI_2018_E6A': {
          name: 'AVI_2018_E6A',
          min_time_i: 97,
          min_time_f: 111,
          min_error_i: 8,
          min_error_f: 14,
          keywords: ['dromerig', 'staren', 'scharrelt', 'vanachter', 'aandacht', 'ernstige', 'kwestie', 'dagen', 'schoolplein', 'spullen', 'leerlingen', 'verdwenen', 'ballen', 'materialenhok', 'kleuters', 'lunchtrommel', 'hockeystick', 'middagpauze', 'vertellen', 'vinger', 'volgens', 'gedaan', 'verband', 'privacy', 'dader', 'niemand', 'beschuldigen', 'geirriteerd', 'springtouw', 'reageert', 'verbluft', 'reactie', 'oorverdovend', 'applaus'],
          start: 'samira zit in de klas dromerig',
          end: 'vend applaus los in de klas'
        },
        'AVI_2018_M7A': {
          name: 'AVI_2018_M7A',
          min_time_i: 111,
          min_time_f: 122,
          min_error_i: 8,
          min_error_f: 13,
          keywords: ['spiegelbeeld', 'normaal', 'terwijl', 'zenuwen', 'geinterviewd', 'presentator', 'programma', 'geinstalleerd', 'ademt', 'voelt', 'raar', 'beetje', 'gevraagd', 'theater', 'centrum', 'gratis', 'mochten', 'gebruiken', 'voorstelling', 'gegeven', 'uitverkocht', 'kaartjes', 'verkocht', 'kwamen', 'schraapt', 'keel', 'wilden', 'geld', 'inzamelen', 'kinderafdeling', 'ziekenhuis', 'ideaal', 'contact', 'familie', 'initiatief', 'beetje', 'lachend', 'cheque', 'overhandigen', 'directeur', 'gigantische', 'wauw', 'euro', 'resultaat'],
          start: 'ravi staart naar zijn spiegel',
          end: 'wat een fantastisch resultaat'
        },
        'AVI_2018_E7A': {
          name: 'AVI_2018_E7A',
          min_time_i: 125,
          min_time_f: 141,
          min_error_i: 10,
          min_error_f: 17,
          keywords: ['toekomst', 'voorspellen', 'verleden', 'bestuderen', 'weten', 'vroeger', 'apparaten', 'grootte', 'pasten', 'hadden', 'toetsenbord', 'peperduur', 'bovendien', 'tergend', 'langzaam', 'konden', 'jaar', 'niets', 'geworden', 'beduidend', 'schrikbarend', 'ontwikkeling', 'zette', 'goedkoper', 'continu', 'verwachting', 'dezelfde', 'snelheid', 'anderhalf', 'verdubbelt', 'geheugenruimte', 'halveert', 'aanschafprijs', 'betekent', 'uitermate', 'compact', 'voordelig', 'plat', 'creditcard', 'overal', 'stoppen', 'programmeert', 'bestuurt', 'gewoon', 'overbodig', 'ultramoderne', 'opzicht', 'reusachtige', 'machines'],
          start: 'wie de toekomst wil voorspellen',
          end: 'reusachtige machines van vroeger'
        },
        'AVI_2018_PLUSA': {
          name: 'AVI_2018_PLUSA',
          min_time_i: 116,
          min_time_f: 136,
          min_error_i: 10,
          min_error_f: 15,
          keywords: ['mieren', 'herseninhoud', 'zandkorrel', 'termieten', 'uiterst', 'indrukwekkende', 'prestaties', 'leveren', 'termiet', 'weinig', 'bijzondere', 'wapenfeiten', 'verwachten', 'miljoenen', 'exemplaren', 'opmerkelijkste', 'dingen', 'nesten', 'bouwen', 'torens', 'bouwwerken', 'ondergrondse', 'vertrekken', 'broedkamer', 'termietenkoningin', 'eitjes', 'kamers', 'verzorgd', 'verblijven', 'voedsel', 'verzameld', 'opvallend', 'vernuftig', 'ventilatiesysteem', 'produceren', 'lichaamswarmte', 'warmte', 'nest', 'warme', 'stijgt', 'gaatjes', 'blaast', 'koele', 'zuurstofrijke', 'doorheen', 'koelen', 'modder', 'verdampt', 'temperatuur', 'koud', 'intelligent'],
          start: 'ze zijn iets groter dan mieren',
          end: 'van die termieten nietwaar'
        },
        'AVI_2018_M3B': {
          name: 'AVI_2018_M3B',
          min_time_i: 138,
          min_time_f: 173,
          min_error_i: 9,
          min_error_f: 15,
          keywords: ['pup', 'hok', 'bot', 'ramt'],
          start: 'sem is een pup hij zit in een',
          end: 'met loes mee wat fijn voor sem'
        },
        'AVI_2018_E3B': {
          name: 'AVI_2018_E3B',
          min_time_i: 126,
          min_time_f: 154,
          min_error_i: 11,
          min_error_f: 19,
          keywords: ['sneeuw', 'vlug', 'broertje', 'slee', 'gemaakt', 'winkel', 'tuin', 'heuvel', 'scheurt', 'omlaag', 'mama', 'opzij', 'gilt', 'rolt', 'pech'],
          start: 'roos kijkt uit het raam er ligt',
          end: 'roos pech want wie wint er nu'
        },
        'AVI_2018_M4B': {
          name: 'AVI_2018_M4B',
          min_time_i: 105,
          min_time_f: 133,
          min_error_i: 9,
          min_error_f: 15,
          keywords: ['ridder', 'paard', 'lans', 'overkant', 'gevaarlijk', 'harnas', 'bibberen', 'winnaar', 'kus', 'prinses', 'begrijpt', 'niks', 'draaft', 'duwt', 'sigaar'],
          start: 'ridder stan zit op zijn paard',
          end: 'geen kus maar wel een knipoog'
        },
        'AVI_2018_E4B': {
          name: 'AVI_2018_E4B',
          min_time_i: 135,
          min_time_f: 167,
          min_error_i: 11,
          min_error_f: 19,
          keywords: ['sport', 'crossen', 'nodig', 'goede', 'gedeeltes', 'steil', 'crossfiets', 'remmen', 'banden', 'profiel', 'veldje', 'brede', 'kistje', 'waanzinnige', 'schans', 'ergens', 'buurt', 'berg', 'zand', 'scheuren', 'stuntfiets', 'kunstjes', 'achterwiel', 'voorwiel', 'rondjes', 'draaien', 'wiel', 'onbelangrijk', 'stunten', 'vervangen', 'hoofd'],
          start: 'een stoere sport is crossen met',
          end: 'maar je hebt maar een hoofd'
        },
        'AVI_2018_M5B': {
          name: 'AVI_2018_M5B',
          min_time_i: 128,
          min_time_f: 151,
          min_error_i: 10,
          min_error_f: 16,
          keywords: ['voorzichtig', 'popgroep', 'zingen', 'hobby', 'project', 'muziek', 'ingestudeerd', 'piano', 'akelig', 'typisch', 'lukken', 'gespannen', 'luid', 'boxen', 'lied', 'afgelopen', 'duim', 'beginnen', 'applaudisseren', 'mooie', 'concert'],
          start: 'jara loopt voorzichtig het loka',
          end: 'meteen wel een concert geven'
        },
        'AVI_2018_E5B': {
          name: 'AVI_2018_E5B',
          min_time_i: 120,
          min_time_f: 140,
          min_error_i: 11,
          min_error_f: 19,
          keywords: ['troebel', 'bodem', 'zeespiegel', 'bovenkant', 'oppervlak', 'aparte', 'herkennen', 'duikbril', 'snorkel', 'rondkijkt', 'charme', 'onderwaterwereld', 'vissen', 'kleuren', 'kwallen', 'schildpadden', 'slang', 'zeester', 'zeeslak', 'kreeften', 'koraal', 'groeit', 'gras', 'leeft', 'bestaat', 'snorkelen', 'heldere', 'vakantieactiviteit', 'speciale', 'fotograferen'],
          start: 'in ons land is de zee altijd',
          end: 'water ook nog eens fotograferen'
        },
        'AVI_2018_E6B': {
          name: 'AVI_2018_E6B',
          min_time_i: 92,
          min_time_f: 104,
          min_error_i: 6,
          min_error_f: 11,
          keywords: ['vertrokken', 'tenminste', 'kruipt', 'tafels', 'stoelen', 'vanochtend', 'genomen', 'mobieltje', 'broekzak', 'smartphone', 'mogelijk', 'verloren', 'beangstigt', 'zichzelf', 'inmiddels', 'gevonden', 'bosjes', 'gespeeld', 'garages', 'speer', 'struiken', 'ogenblikken', 'functioneert', 'opgelucht'],
          start: 'alle kinderen zijn al lang vertr',
          end: 'nog nooit zo opgelucht geweest'
        },
        'AVI_2018_M7B': {
          name: 'AVI_2018_M7B',
          min_time_i: 121,
          min_time_f: 133,
          min_error_i: 9,
          min_error_f: 16,
          keywords: ['enger', 'haai', 'walvis', 'portugees', 'oorlogsschip', 'ongeveer', 'angstaanjagendste', 'tegenkomen', 'denkt', 'schip', 'neteldier', 'oftewel', 'verzameling', 'poliepen', 'kwalletjes', 'ogenschijnlijk', 'werkelijkheid', 'afzonderlijke', 'functie', 'buik', 'lange', 'tentakels', 'waarmee', 'slachtoffers', 'uitschakelen', 'bijtend', 'gif', 'dertig', 'zeil', 'drijft', 'vangt', 'waait', 'monster', 'oceaan', 'praktisch', 'zwemmers', 'zodra', 'wegkomen', 'overigens', 'noordzee', 'gruwelijke', 'zeemonster'],
          start: 'het is misschien wel enger dan',
          end: 'gruwelijke zeemonster niet voor'
        },
        'AVI_2018_E7B': {
          name: 'AVI_2018_E7B',
          min_time_i: 108,
          min_time_f: 142,
          min_error_i: 7,
          min_error_f: 12,
          keywords: ['speurtocht', 'routebeschrijving', 'nood', 'finish', 'ijsje', 'groepje', 'superspeurders', 'schreeuwt', 'blijkt', 'echter', 'moeilijker', 'gedacht', 'uur', 'kwijt', 'moppert', 'beschrijving', 'totaal', 'triomfantelijk', 'bordje', 'waarop', 'gewonnen', 'alvast', 'bestellen', 'bolletjes', 'betaalt', 'verkoopster', 'verschijnt', 'gezicht', 'vooruit', 'geirriteerde', 'blijken', 'verkeerd', 'kanariegele', 'ophalen', 'ijsjes', 'tevoorschijn', 'verklappen', 'waterijsje'],
          start: 'vandaag gaan we op speurtocht',
          end: 'eren kregen maar een waterijsje',
        },
        'AVI_2018_PLUSB': {
          name: 'AVI_2018_PLUSB',
          min_time_i: 114,
          min_time_f: 135,
          min_error_i: 10,
          min_error_f: 15,
          keywords: ['houdt', 'lekker', 'tegenwoordig', 'geleden', 'duizenden', 'jaren', 'egypte', 'maakten', 'reukwater', 'kruiden', 'hars', 'bomen', 'handelaren', 'heerlijkst', 'geurende', 'specerijen', 'importeerden', 'parfum', 'populairder', 'pruikentijd', 'opmerkelijke', 'reden', 'heersten', 'ziektes', 'pest', 'verspreid', 'wasten', 'nauwelijks', 'gebrekkige', 'hygiéne', 'stinken', 'verdoezelen', 'gebruikte', 'hoeveelheden', 'destijds', 'fris', 'parfummakers', 'ontdekten', 'bepaalde', 'geuren', 'achterwerk', 'mannelijke', 'civetkat', 'woest', 'aantrekkelijk', 'vrouwtjes', 'hetzelfde', 'gold', 'bevers', 'muskusratten', 'effect', 'sterk', 'dachten', 'stopten', 'producten', 'gebruikt', 'stoffen', 'zulke', 'onfrisse', 'goedjes'],
          start: 'iedereen houdt ervan om lekker',
          end: 'je er daarom niet meer in tegen'
        }
      }
    }
  },
  beforeCreate() {
   if (!this.$store.getters.getActiveSession) {
     this.$router.push({
       name: "Login",
     });
   }
  },
  computed: {
    reading_level() {
      if (!this.avi_card_active) {
        return null
      }
      const avi_card = this.avi_cards[this.avi_card_active]

      let time_level = null
      if (this.time_seconds < avi_card.min_time_i) {
        time_level = 0
      } else if (this.time_seconds < avi_card.min_time_f) {
        time_level = 1
      } else {
        time_level = 2
      }

      let error_level = null
      if (this.reading_mistake_count < avi_card.min_error_i) {
        error_level = 0
      } else if (this.reading_mistake_count < avi_card.min_error_f) {
        error_level = 1
      } else {
        error_level = 2
      }

      const level_list = ['Beheersing', 'Instructie', 'Frustratie']
      const level_int = Math.max(time_level, error_level)
      return level_list[level_int]
    }
  },
  async mounted() {
    const user = this.$store.getters.getUsers[this.user_id]
    if (!user) {
      return
    }

    this.user_name = user.user_name

    const jwt = this.$store.getters.get_jwt
    const json = await get_users_test_results(jwt, {
      'user_id': this.user_id,
      'test_date': this.get_date_str(),
      'sort': 'test_datetime,DESC'
    })
    json['test_result_list'].forEach(test_result => {
      this.laps.push({
        'index': '✔',
        'test_result_id': test_result.test_result_id,
        'didactic_age': test_result.didactic_age,
        'didactic_age_equivalent': test_result.didactic_age_equivalent,
        'test_datetime': test_result.test_datetime,
        'score': test_result.score,
        'error_count': test_result.error_count,
        'test_part_type': test_result.test_part_type,
        'skill_level': test_result.skill_level,
        'annotation': test_result.annotation
      })
    });
  },
  beforeUnmount() {
    if (this.speech_recognition) {
      this.speech_recognition.stop()
    }
  },
  methods: {
    parse_seconds(seconds_total) {
      const minutes = String(Math.floor(seconds_total / 60)).padStart(2, '0')
      const seconds = String(seconds_total % 60).padStart(2, '0')
      return minutes + ':' + seconds
    },
    compute_didactic_age() {

      // Grade value
      const user_access = this.$store.getters.get_user_access_dict[this.user_id]
      const [group_id] = user_access.group_id_set
      const group = this.$store.getters.get_group_dict[group_id]
      const grade_value = (group.grade - 3) * 10

      // Month value
      const month_dict = {
        9: 1,
        10: 2,
        11: 3,
        12: 4,
        1: 5,
        2: 6,
        3: 7,
        4: 8,
        5: 9,
        6: 10,
        7: 10,
        8: 10
      }
      
      const month_value = month_dict[new Date().getMonth() + 1]

      // Total value      
      return grade_value + month_value
    },
    reset_stopwatch() {
      this.minutes = 0
      this.seconds = 0
      this.milliseconds = 0
      this.turned_on = false
      this.awaiting_speech = false
      this.reading_mistake_count = ''
      this.form_is_valid = false
      this.char_timestamps = {}
      clearInterval(this.interval_method)      
    },
    start_listening() {
      this.awaiting_speech = true

      var speech_recognition_class = webkitSpeechRecognition;
      this.speech_recognition = new speech_recognition_class();
      this.speech_recognition.lang = 'nl';
      this.speech_recognition.continuous = true;
      this.speech_recognition.interimResults = true;
      this.speech_recognition.maxAlternatives = 1;

      // This runs when the speech recognition service returns result
      const self = this
      const dmp = new DiffMatchPatch.diff_match_patch();
      dmp.Match_Threshold=0.45

      this.speech_recognition.onresult = function(event) {

          // concatenate results
          let text = ""
          for(let i = 0; i < event.results.length; i++) {
            text += event.results[i][0].transcript
          }
          
          if (!text) {
            return
          }

          text = text.toLowerCase()
          self.word_string = text

          // Store timestamp that character was recorded
          if (!(text.length in self.char_timestamps)) {
            self.char_timestamps[text.length] = new Date()
          }

          // Find matching avi card - if found -> start stopwatch at start of avi card
          let match_index = null
          if (!self.turned_on && !self.avi_card_finished) {
            for (let key in self.avi_cards) {
              const avi_card = self.avi_cards[key]
              match_index = dmp.match_main(text, avi_card.start, 0);
              
              if (match_index >= 0) {
                let timestamp = null
                for (let i = -20; i < 20; i++) {
                  if (match_index - i in self.char_timestamps) {
                    timestamp = self.char_timestamps[match_index - i]
                    break
                  }
                }
                const now = new Date()
                const diff_ms = (now - timestamp)
                self.start_stopwatch(diff_ms)
                self.avi_card_active = avi_card.name
              }
            }
          } else if(self.turned_on && !self.avi_card_finished) {

            const avi_card = self.avi_cards[self.avi_card_active]
            match_index = dmp.match_main(text, avi_card.end, text.length - 1);
            if (match_index > 0) {
              self.avi_card_finished = true
              self.stop_stopwatch()              
            }
          }
      };

      // start recognition
      this.speech_recognition.start();
    },
    async start_stopwatch(offset_ms) {

      if (this.turned_on) {
        return
      }

      this.reset_stopwatch()
      this.turned_on = true
   
      await this.$refs.audio_recorder.startRecording()

      // Set offset
      this.seconds = Math.floor(offset_ms / 1000)
      this.milliseconds = (offset_ms % 1000 / 1000).toFixed(2) * 1000

      // Set increment time interval
      this.increment_time()
      this.interval_method = setInterval(() => this.increment_time(), 10)
    },
    pause_stopwatch() {
      clearInterval(this.interval_method)
    },
    stop_stopwatch() {
      this.turned_on = false
      this.speech_recognition.stop()
      this.$refs.audio_recorder.stopRecording()
      const minutes = String(this.minutes).padStart(2, '0')
      const seconds = String(this.seconds).padStart(2, '0')
      const milliseconds = String(this.milliseconds / 10).padStart(2, '0')
      this.time_seconds = this.minutes * 60 + this.seconds
      this.time_measured = `${minutes}:${seconds}:${milliseconds}`
      this.pause_stopwatch()
      this.$refs.modal_sort.toggle()      
    },
    increment_time() {
      if (this.milliseconds == 990) {
        this.milliseconds = 0
        this.seconds += 1
        if (this.seconds == 59) {
          this.minutes += 1
          this.seconds = 0
        }  
      } else {
        this.milliseconds += 10
      }        
    },
    set_reading_mistake_count(value) {
      this.reading_mistake_count = value 
      this.validate_form()
    },
    validate_form() {
      this.form_is_valid = !isNaN(this.reading_mistake_count)
    },
    identify_avi_card(text) {
      const word_list = new Set(text.split(' '))
      let avi_card_relevancy = {}
      let best_match_count = 0
      let best_match_name = null

      // Get best match
      for (let key in this.avi_cards) {
        const avi_card = this.avi_cards[key]
        const result = new Set([...avi_card.keywords].filter(i => word_list.has(i)));
        avi_card_relevancy[avi_card.name] = result
        if (result.size > best_match_count) {
          best_match_count = result.size
          best_match_name = avi_card.name
        }
      }

      return best_match_count > 1 ? best_match_name : null
    },
    get_date_str() {
        const d = new Date();
        const yyyy = d.getFullYear();
        const dd = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
        const mm = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;

        return yyyy + "-" + mm + "-" + dd
    },
    get_datetime_str() {
      const d = new Date();
      const date_str = this.get_date_str()
      const HH = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
      const MM = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      const SS = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();

      return date_str + ' ' + HH + ':' + MM + ':' + SS
    },
    async submit() {

      // Format log time
      const date_str = this.get_date_str()
      const datetime_str = this.get_datetime_str()

      // Add lap
      const lap = {
        'index': '✔',
        'test_part_type': this.avi_card_active,
        'didactic_age': this.compute_didactic_age(),        
        'didactic_age_equivalent': this.didactic_age_dict[this.avi_card_active.slice(9, 11)],
        'test_datetime': datetime_str,
        'score': parseInt(this.time_measured.slice(0, 2)) * 60 + parseInt(this.time_measured.slice(4, 5)),
        'error_count': parseInt(this.reading_mistake_count),
        'skill_level': this.reading_level[0],
        'annotation': ''
      }
      this.laps.unshift(lap)

      // Upload audio      
      const jwt = this.$store.getters.get_jwt

      this.$refs.audio_recorder.createDownloadLink()

      this.$refs.audio_recorder.exportWAV(((blob) => {
          let formData = new FormData();
          formData.append("audio", blob);
          put_upload_audio(jwt, 'avi_test_cards', `${lap['test_part_type']}_${date_str}_${this.user_id}`, formData)   
        })
      )

      // Create avi test
      await post_users_test_results(
        jwt,
        this.user_id,
        lap.test_part_type,
        lap.didactic_age,
        lap.didactic_age_equivalent,
        0,
        lap.test_datetime,
        lap.score,
        lap.error_count,
        lap.skill_level,
        ''
      )

      // Get test_result_id
      const json = await get_users_test_results(jwt, {
        'user_id': this.user_id,
        'test_datetime': lap.test_datetime,
        'test_part_type': lap.test_part_type
      })
      json['test_result_list'].forEach(x => this.laps[0].test_result_id = x.test_result_id)

      // Possible set an avi card to is_decisive
      let max_didactic_age_equivalent = 0
      this.laps.forEach(lap => {
        max_didactic_age_equivalent = Math.max(max_didactic_age_equivalent, lap.didactic_age_equivalent)
      })

      let is_finished = true
      this.laps.forEach(lap => {
        if (lap.didactic_age_equivalent == max_didactic_age_equivalent && lap.skill_level != 'F') {
          is_finished = false
        }
      })

      let best_valid_lap = null
      if (is_finished) {
        this.laps.forEach(lap => {
          if (lap.skill_level != 'F') {
            if (!best_valid_lap || lap.didactic_age_equivalent > best_valid_lap.didactic_age_equivalent) {
              best_valid_lap = lap
            }
          }
        })

        put_users_test_results(
          jwt,
          this.user_id,
          best_valid_lap.test_result_id,
          best_valid_lap.test_part_type,
          best_valid_lap.didactic_age,
          best_valid_lap.didactic_age_equivalent,
          1,
          best_valid_lap.test_datetime,
          best_valid_lap.score,
          best_valid_lap.error_count,
          best_valid_lap.skill_level,
          best_valid_lap.annotation
        )
      }

      // Reset
      this.reset_stopwatch()
      this.$refs.modal_sort.hide()

      this.avi_card_finished = false

      
    }
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.lap p {
  color: inherit;
  margin-bottom: 0;
  font-size: 12px;
  letter-spacing: 1.5px;
}

body {
  margin: 0;
  font-size: 2rem;
  display: flex;
  flex: 1;
  min-height: 100vh;
  align-items: center;
}

.clock {
  width: 30rem;
  height: 30rem;
  border: 7px solid var(--primary-color);
  box-shadow: -4px -4px 10px rgba(67,67,67,0.5),
                inset 4px 4px 10px rgba(0,0,0,0.5),
                inset -4px -4px 10px rgba(67,67,67,0.5),
                4px 4px 10px rgba(0,0,0,0.3);
  border-radius: 50%;
  margin: 50px auto;
  position: relative;
  padding: 2rem;
 
}

.outer-clock-face {
  position: relative;
  width: 110%;
  height: 110%;
  border-radius: 100%;
  background: var(--primary-color);
  background: rgba(0, 0, 0, 0);
  overflow: hidden;
  margin-left: -5%;
  margin-top: -5%;
}

.outer-clock-face::after {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  transform: rotate(90deg)
}

.outer-clock-face::before,
.outer-clock-face::after,
.outer-clock-face .marking{
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background: #bdbdcb;
  background: radial-gradient(circle, rgba(27,23,37,0) 0%, rgba(27,23,37,0) 88%, rgba(255,255,255,1) 100%, rgba(27,23,37,0.78) 100%);
  z-index: 0;
  left: 49%;
}

.outer-clock-face .marking {
  background: #bdbdcb;
  background: radial-gradient(circle, rgba(27,23,37,0) 0%, rgba(27,23,37,0) 88%, rgba(255,255,255,1) 100%, rgba(27,23,37,0.78) 100%);
  width: 2px;
}

.outer-clock-face .marking.marking-one {
  -webkit-transform: rotate(30deg);
  -moz-transform: rotate(30deg);
  transform: rotate(30deg)
}

.outer-clock-face .marking.marking-two {
  -webkit-transform: rotate(60deg);
  -moz-transform: rotate(60deg);
  transform: rotate(60deg)
}

.outer-clock-face .marking.marking-three {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  transform: rotate(120deg)
}

.outer-clock-face .marking.marking-four {
  -webkit-transform: rotate(150deg);
  -moz-transform: rotate(150deg);
  transform: rotate(150deg)
}

.inner-clock-face {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background: var(--primary-color);
  background: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  z-index: 1;
}

.hand {
  width: 50%;
  right: 50%;
  height: 6px;
  background: #61afff;
  position: absolute;
  top: 50%;
  border-radius: 6px;
  transform-origin: 100%;
  transform: rotate(90deg);
  transition-timing-function: cubic-bezier(0.1, 2.7, 0.58, 1);
}

.hand.hour-hand {
  width: 30%;
  z-index: 3;
}

.hand.min-hand {
  height: 3px;
  z-index: 10;
  width: 40%;
}

.hand.second-hand {
  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(27,23,37,0) 8%, rgba(27,23,37,0) 100%);
  width: 100%;
  height: 30px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.clock {
  width: 200px;
  height: 200px;
}

.mono {
  font-family: 'Roboto Mono', monospace;
}

@media screen and (min-width:640px) {
  .clock {
    width: 300px;
    height: 300px;
  }
}

</style>
