<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Leerlingen activeren" @submit="submit">
    <div>
      <oib-input-multi-select
        v-if="loaded"
        label="Groepen"
        description="Selecteer alle groepen waarvan de leerlingen aan de slag gaan met de Leesapp."
        :allow_multi_select="true"
        :required="true"
        :options="lvs_groups"
        :keys_init="lvs_groups_selected_set"
        @select="x => select_group(x)"
      />
      <div v-if="!has_role(['SYSTEM_ADMIN', 'SYSTEM_SUPPORT', 'SCHOOL_ADMIN'])">
        <p>Je hebt geen permissies om deze taak uit te voeren. Vraag de schoolbeheerder om leerlingen te (de)activeren.</p>
      </div>
      <div v-if="!$store.getters.get_lvs_download_result">
        <p>Geen download resultaat uit het LAS gevonden.</p>
      </div>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputMultiSelect from "../components/oib_form/oib_input_multi_select.vue"
import {has_role} from "../store/utils"

export default {
  name: "OibFormLvsGroups",
  components: {
    OibForm,
    OibInputMultiSelect
  },
  data() {
    return {
      form_step_validation_list: [false],
      lvs_groups: ['xx'],
      lvs_system_dict_server: ['PARNASSYS', 'ESIS'],
      lvs_system: null,
      authorization_key: "",
      loaded: false,
      lvs_groups_selected: [],
      lvs_groups_selected_set: new Set()
    }
  },
  async mounted() {
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    if (!this.has_role(['SYSTEM_ADMIN', 'SYSTEM_SUPPORT', 'SCHOOL_ADMIN'])) {
      return
    }

    const school = this.$store.getters.get_school
    if (!school) {
      return
    }
    const branch_number = school['branch_number']

    await this.$store.dispatch('load_lvs', {
      'jwt': this.$store.getters.get_jwt,
      'branch_number': branch_number
    })

    const download_result = this.$store.getters.get_lvs_download_result
    if (!download_result || !download_result['classroom_list']) {
      return
    }
    this.lvs_groups = download_result['classroom_list'].sort()

    const lvs_link = this.$store.getters.get_lvs_link
    this.lvs_groups_selected_set = new Set()

    for (let i = 0; i < this.lvs_groups.length; i++) {
      if (lvs_link['classroom_list'].includes(this.lvs_groups[i])) {
        this.lvs_groups_selected_set.add(i)
      }
    }

    this.loaded = true
    this.form_step_validation_list[0] = true
  },
  methods: {
    // Imported
    has_role,

    // Local
    select_group(group_selected_list) {
      this.lvs_groups_selected = group_selected_list
    },
    async val_step_1() {

      if (this.lvs_system == null || this.authorization_key.length < 7) {
        this.form_step_validation_list[0] = false
        return
      }

      this.form_step_validation_list[0] = true
    },
    async submit() {

      let lvs_group_name_list = []
      this.lvs_groups_selected.forEach(x => {
        lvs_group_name_list.push(this.lvs_groups[parseInt(x)])
      })

      // Publish submit event
      this.$emit("submit", {
        'classroom_list': lvs_group_name_list,
      })
    }
  }
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>