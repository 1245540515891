<template>
  <oib-page>
    <oib-nav-student title="Stoppen met boek"/>
    <oib-content>
      <div class="wrapper column center" style="height: 85vh;">
        <h1>Waarom wil je stoppen?</h1>
        <oib-button
        v-for="option in options"
        :key="option"
        shape="rounded"
        priority="p2"
        style="margin-top: 20px;"
        @click="submit_quit_reason"
        >{{option}}</oib-button>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibButton from '../components/OibButton.vue'

import {quit_book} from "../store/book_progress"
import {on_done_isbn13_router} from "../store/click"

export default {
  name: "BoekMeningStoppen",
  props: ['user_id', 'isbn13'],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButton
  },
  data() {
    return {
      options: ['Te makkelijk', 'Te moeilijk', 'Te lang', 'Saai', 'Niet leuk', 'Verkeerde keuze']
    }
  },
  methods: {
    async submit_quit_reason() {
      await quit_book(parseInt(this.user_id), this.isbn13)

      const book = this.$store.getters.getBook(this.isbn13)
      const library_id_list = Object.keys(this.$store.getters.get_library_dict)

      await on_done_isbn13_router(
        this.user_id,
        book['book_edition_id'],
        this.isbn13,
        library_id_list
      )
    }
  }
};
</script>
