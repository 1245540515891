<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Partner toevoegen" @submit="submit">
    <div>
      <oib-form-header header="Organisatie"/>
      <oib-input-text
      label="Naam"
      placeholder="Jenaplanschool Wittevrouwen"
      :required="true"
      :error_msg="organisation_name_error_msg"
      :init_value="init_organisation_name"
      @input="set_organisation_name($event.target.value)"
      />
      <oib-input-text
      label="KvK nummer"
      placeholder="80686044"
      :required="true"
      :error_msg="coc_number_error_msg"
      :init_value="init_coc_number"
      @input="set_coc_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="KvK vestigingsnummer"
      placeholder="000047027819"
      :required="true"
      :error_msg="coc_business_number_error_msg"
      :init_value="init_coc_business_number"
      @input="set_coc_business_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Website"
      placeholder="https://onderwijsinbeeld.nl"
      :required="false"
      :error_msg="null"
      :init_value="init_website_url"
      @input="set_website_url($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
    <div>
      <oib-form-header header="Locatie"/>
      <oib-input-text
      label="Postcode"
      placeholder="7101BN"
      :required="true"
      :error_msg="null"
      :init_value="init_zipcode"
      @input="set_zipcode($event.target.value)"
      />
      <oib-input-text
      label="Huisnummer"
      placeholder="22a"
      :required="true"
      :error_msg="null"
      :init_value="init_house_number"
      @input="set_house_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Straatnaam"
      placeholder="Beatrixpark"
      :required="true"
      :error_msg="null"
      :init_value="init_street_name"
      @input="set_street_name($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Stad"
      placeholder="Winterswijk"
      :required="true"
      :error_msg="null"
      :init_value="init_city"
      @input="set_city($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Provincie"
      placeholder="GELDERLAND"
      :required="true"
      :error_msg="null"
      :init_value="init_province"
      @input="set_province($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Latitude"
      placeholder="51.9810029"
      :required="true"
      :error_msg="null"
      :init_value="init_latitude"
      @input="set_latitude($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Longitude"
      placeholder="6.7015163"
      :required="true"
      :error_msg="null"
      :init_value="init_longitude"
      @input="set_longitude($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibFormHeader from "../components/oib_form/oib_form_header.vue";

import { post_organisations } from "../store/api/user.js"
import { post_organisations_buildings } from "../store/api/user.js"
import { get_organisations_query } from "../store/api/user.js";

export default {
  name: "OrganisatieToevoegenPartner",
  props: {
    organisation_type: {
      type: String,
      required: true
    }
  },
  components: {
    OibForm,
    OibInputText,
    OibFormHeader
  },
  data() {
    return {
      form_step_validation_list: [false, false],
      brin: "",
      dependance_code: "",
      nick_name: "",
      last_name: "",
      duplicate_warning: "",
      init_organisation_name: "",
      organisation_name: "",
      organisation_name_error_msg: null,
      init_coc_number: "",
      coc_number: "",
      coc_number_error_msg: null,
      init_coc_business_number: "",
      coc_business_number: "",
      coc_business_number_error_msg: null,
      init_website_url: "",
      website_url: "",
      init_zipcode: "",
      zipcode: "",
      init_house_number: "",
      house_number: "",
      init_street_name: "",
      street_name: "",
      init_city: "",
      city: "",
      init_province: "",
      provincie: "",
      init_latitude: "",
      latitude: "",
      init_longitude: "",
      longitude: "",
      province_list: [
        'DRENTHE',
        'FLEVOLAND',
        'FRIESLAND',
        'GELDERLAND',
        'GRONINGEN',
        'LIMBURG',
        'NOORD_BRABANT',
        'NOORD_HOLLAND',
        'OVERIJSSEL',
        'UTRECHT',
        'ZEELAND',
        'ZUID_HOLLAND'
      ]
    }
  },
  methods: {
    set_organisation_name(value) {
      this.organisation_name = value
      this.val_step_1()
    },
    set_coc_number(value) {
      this.coc_number = value
      this.val_step_1()
    },
    set_coc_business_number(value) {
      this.coc_business_number = value
      this.val_step_1()
    },
    set_website_url(value) {
      this.website_url = value
      this.val_step_1()
    },
    set_zipcode(value) {
      this.zipcode = value
      this.val_step_3()
    },
    set_house_number(value) {
      this.house_number = value
      this.val_step_3()
    },
    set_street_name(value) {
      this.street_name = value
      this.val_step_3()
    },
    set_city(value) {
      this.city = value
      this.val_step_3()
    },
    set_province(value) {
      this.province = value
      this.val_step_3()
    },
    set_latitude(value) {
      this.latitude = parseFloat(value)
      this.val_step_3()
    },
    set_longitude(value) {
      this.longitude = parseFloat(value)
      this.val_step_3()
    },
    is_float(val) {
      return !Number.isInteger(val) && Number.isFinite(val)
    },
    val_step_1() {
      let check_list = []
      const organisation_name_valid = this.organisation_name != null && this.organisation_name.length > 3
      if (organisation_name_valid) {
        this.organisation_name_error_msg = null
      } else {
        this.organisation_name_error_msg = "Minstens 4 karakters"
      }

      const coc_number_valid = this.coc_number != null && this.coc_number.length == 8
      if (coc_number_valid) {
        this.coc_number_error_msg = null
      } else {
        this.coc_number_error_msg = "Exact 8 cijfers"
      }

      const coc_business_number_valid = this.coc_business_number != null && this.coc_business_number.length == 12
      if (coc_business_number_valid) {
        this.coc_business_number_error_msg = null
      } else {
        this.coc_business_number_error_msg = "Exact 12 cijfers"
      }

      check_list.push(organisation_name_valid)
      check_list.push(coc_number_valid)
      check_list.push(coc_business_number_valid)
      console.log(check_list)

      this.form_step_validation_list[0] = !check_list.includes(false)
    },
    val_step_3() {
      let check_list = []
      check_list.push(this.zipcode != null && this.zipcode.length == 6)
      check_list.push(this.house_number != null && this.house_number.length > 0)
      check_list.push(this.street_name != null && this.street_name.length > 3)
      check_list.push(this.city != null && this.city.length > 2)
      check_list.push(this.province != null && this.province_list.includes(this.province))
      check_list.push(this.latitude != null && this.is_float(this.latitude))
      check_list.push(this.longitude != null && this.is_float(this.longitude))

      this.form_step_validation_list[1] = !check_list.includes(false)
    },
    async submit() {

      // Create organisation
      const jwt = this.$store.getters.get_jwt
      let response = await post_organisations(
        jwt,
        this.coc_number,
        this.coc_business_number,
        this.organisation_name,
        this.organisation_type,
        this.website_url
      )

      let organisation_id = null
      if (response.status == 201) {
        let json = await response.json()
        organisation_id = json['organisation_id']
      }
      else if (response.status == 409) {
        let json = await get_organisations_query(jwt, {'coc_business_number': this.coc_business_number})
        organisation_id = json['organisation_list'][0]['organisation_id']
      }

      // Create building
      response = await post_organisations_buildings(
        jwt,
        organisation_id,
        this.street_name,
        this.city,
        this.province,
        this.zipcode,
        this.street_name,
        this.house_number,
        this.latitude,
        this.longitude
      )

      setTimeout(() => {this.$router.go(-2)}, 500);
    }
  }
};
</script>
