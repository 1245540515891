<template>
  <div class="fade img-frame" :style="`opacity: ${opacity}`">
    <img
      v-if="!image_not_found"
      class="img-frame"
      :src="img_url"
      :onload="set_image_loaded"
      :onerror="set_image_not_found"
    />
    <div v-if="image_not_found" class="img-frame" :style="`display: flex; justify-content: center; align-items: center; background: ${get_random_color()}`"/>
  </div>
</template>

<script>
import {get_book_cover} from "../store/api/library.js"

export default {
  name: "OibBookCover",
  props: ["isbn13"],
  data() {
    return {
      opacity: 0,
      img_url: null,
      image_not_found: false
    }
  },
  mounted() {
    this.img_url = get_book_cover(this.isbn13, "s")
  },
  methods: {
    randomIntFromInterval(min, max) { // min and max included 
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    get_random_color() {
      const color_1 = `rgb(${this.randomIntFromInterval(10, 150)}, ${this.randomIntFromInterval(180, 180)}, 205)`
      const color_2 = `rgb(${this.randomIntFromInterval(10, 150)}, ${this.randomIntFromInterval(180, 180)}, 205)`
      const color = `linear-gradient(${this.randomIntFromInterval(0, 45)}deg, ${color_1} 0%, ${color_2} 100%);`
      return color
    },
    set_image_loaded() {
      this.opacity = 1
    },
    set_image_not_found() {
      this.opacity = 1
      this.image_not_found = true
    }
  } 
};
</script>

<style scoped>
.img-frame {
  width: 78px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 2px;
}

@media screen and (min-width:640px) {
  .img-frame {
    width: 50px;
    height: 62px;
    object-fit: cover;
    margin-right: 20px;
    border-radius: 2px;
  }
}
</style>