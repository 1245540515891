<template>
  <oib-form-book-vote-page-count :init_page_count="init_page_count" @submit="x => submit(x)"/>
</template>

<script>
import OibFormBookVotePageCount from "../components/oib_form_book_vote_page_count.vue";

import { post_book_products_users_scrape } from "../store/api/book_scraper"
import { get_book_products_users_scrape_data } from "../store/api/book_scraper"
import { put_book_products_users_scrape_data } from "../store/api/book_scraper"

export default {
  name: "BoekStemmenPaginas",
  props: ['user_id', 'isbn13'],
  components: {
    OibFormBookVotePageCount
  },
  data() {
    return {
      init_page_count: "",
      json: null
    }
  },
  async created() {
    const jwt = this.$store.getters.get_jwt
    const response = await get_book_products_users_scrape_data(jwt, this.isbn13, this.user_id)
    if (response.status == 200) {
      this.json = await response.json()
      if (this.json['page_count']) {
        this.init_page_count = this.json['page_count']
      }      
    } else {
      this.json = {
        'book_title': null,
        'book_type': null,
        'author_name': null,
        'series_title': null,
        'release_number': null,
        'book_language': null,        
        'dyslexia': null,
        'avi': null,
        'age_min': null,
        'age_max': null,
        'publisher_name': null,
        'binding_method': null,
        'page_count': null,
        'release_date': null,
        'cover': null,
        'tags': []
      }
    }
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      const isbn13 = this.isbn13
      
      // Vote on book
      const jwt = this.$store.getters.get_jwt
      put_book_products_users_scrape_data(
          jwt,
          isbn13,
          this.user_id,
          this.json.book_title,
          this.json.book_type,
          this.json.author_name,
          this.json.series_title,
          this.json.release_number,
          this.json.book_language,
          this.json.dyslexia,
          this.json.avi,
          this.json.age_min,
          this.json.age_max,
          this.json.publisher_name,
          this.json.binding_method,
          parseInt(body['page_count']),
          this.json.release_date,
          null,
          this.json.tags,
          this.json.cover
      ).then(() => {
        post_book_products_users_scrape(jwt, isbn13, this.user_id)
      })      

      this.$router.back();
    }
  }
};
</script>
