<template>
  <div class="tile-overlay" :href="href" target="_blank">
    <oib-icon-label
    title_style="color: white; font-family: 'PT Sans', sans-serif; font-weight: 700; font-size: 19px;"
    :icon="icon"
    :icon_family="icon_family"
    icon_style="color: var(--highlight-color)"
    :title="header"
    direction="row"
    :active="false"
    />
    <slot>
      <p style="width: 100%; color: white;">{{body}}</p>
    </slot>
  </div>
</template>

<script>
import OibIconLabel from '../components/oib_icon_label.vue'

export default {
  name: "OibTileImg",
  props: {
    img_url: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true,
    },
    icon_family: {
      required: false,
    },
    header: {
      type: String,
      required: true
    },
    body: {
      body: String,
      required: false
    },
    href: {
      body: String,
      required: false
    },
    href_text: {
      body: String,
      required: false,
      default: 'Lees meer ➜'
    }
  },
  components: {
    OibIconLabel
  }
};
</script>

<style scoped>
.reset-margin {
  margin-left: 50px;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}
.row {
  display: flex;
  flex-direction: row;
}

.tile {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  height: 280px;
  border-radius: 10px;
  margin-bottom: 20px;
}

@media screen and (min-width:320px) {
  .tile {
    width: 100%;
  }
}

@media screen and (min-width:1050px) {
  .tile {
    width: 300px;
    margin-right: 40px;
  }
}

.tile-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}


</style>