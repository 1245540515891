<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="School toevoegen" @submit="submit">
    <div>
      <oib-input-text
      label="Vestigingsnummer"
      placeholder="00AB01"
      style="margin-bottom: 20px;"
      :required="true"
      :error_msg="null"
      @input="set_branch_number($event.target.value)"
      />
    </div>
    <div>
      <oib-form-header header="Organisatie"/>
      <oib-input-text
      label="Naam"
      placeholder="Jenaplanschool Wittevrouwen"
      :required="true"
      :error_msg="null"
      :init_value="init_organisation_name"
      @input="set_organisation_name($event.target.value)"
      />
      <oib-input-text
      label="KvK nummer"
      placeholder="80686044"
      :required="true"
      :error_msg="null"
      :init_value="init_coc_number"
      @input="set_coc_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="KvK vestigingsnummer"
      placeholder="000047027819"
      :required="true"
      :error_msg="null"
      :init_value="init_coc_business_number"
      @input="set_coc_business_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Website"
      placeholder="https://onderwijsinbeeld.nl"
      :required="true"
      :error_msg="null"
      :init_value="init_website_url"
      @input="set_website_url($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
    <div>
      <oib-form-header header="Locatie"/>
      <oib-input-text
      label="Postcode"
      placeholder="7101BN"
      :required="true"
      :error_msg="null"
      :init_value="init_zipcode"
      @input="set_zipcode($event.target.value)"
      />
      <oib-input-text
      label="Huisnummer"
      placeholder="22a"
      :required="true"
      :error_msg="null"
      :init_value="init_house_number"
      @input="set_house_number($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Straatnaam"
      placeholder="Beatrixpark"
      :required="true"
      :error_msg="null"
      :init_value="init_street_name"
      @input="set_street_name($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Stad"
      placeholder="Winterswijk"
      :required="true"
      :error_msg="null"
      :init_value="init_city"
      @input="set_city($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Provincie"
      placeholder="GELDERLAND"
      :required="true"
      :error_msg="null"
      :init_value="init_province"
      @input="set_province($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Latitude"
      placeholder="51.9810029"
      :required="true"
      :error_msg="null"
      :init_value="init_latitude"
      @input="set_latitude($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Longitude"
      placeholder="6.7015163"
      :required="true"
      :error_msg="null"
      :init_value="init_longitude"
      @input="set_longitude($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibFormHeader from "../components/oib_form/oib_form_header.vue";

import { get_organisations_scrape } from "../store/api/school_partner.js"
import { post_organisations } from "../store/api/user.js"
import { post_organisations_schools } from "../store/api/user.js"
import { post_organisations_buildings } from "../store/api/user.js"
import { get_organisations_query } from "../store/api/user.js";

export default {
  name: "BiebhulpToevoegen",
  components: {
    OibForm,
    OibInputText,
    OibFormHeader
  },
  data() {
    return {
      form_step_validation_list: [false, false, false],
      brin: "",
      dependance_code: "",
      nick_name: "",
      last_name: "",
      duplicate_warning: "",
      init_organisation_name: "",
      organisation_name: "",
      init_coc_number: "",
      coc_number: "",
      init_coc_business_number: "",
      coc_business_number: "",
      init_website_url: "",
      website_url: "",
      init_zipcode: "",
      zipcode: "",
      init_house_number: "",
      house_number: "",
      init_street_name: "",
      street_name: "",
      init_city: "",
      city: "",
      init_province: "",
      provincie: "",
      init_latitude: "",
      latitude: "",
      init_longitude: "",
      longitude: "",
      province_list: [
        'DRENTHE',
        'FLEVOLAND',
        'FRIESLAND',
        'GELDERLAND',
        'GRONINGEN',
        'LIMBURG',
        'NOORD_BRABANT',
        'NOORD_HOLLAND',
        'OVERIJSSEL',
        'UTRECHT',
        'ZEELAND',
        'ZUID_HOLLAND'
      ]
    }
  },
  methods: {
    async set_branch_number(value) {
      this.brin = ""
      this.dependance_code = ""

      if (value.length == 6) {
        const jwt = this.$store.getters.get_jwt
        this.brin =  value.slice(0, 4)
        this.dependance_code =  value.slice(4, 6)

        const json = await get_organisations_scrape(jwt, {
          'brin': this.brin,
          'dependance_code': this.dependance_code
        })
        const organisation_list = json['organisation_list']

        if (organisation_list.length == 1) {
          const organisation = organisation_list[0]

          this.init_organisation_name = organisation['organisation_name']
          this.set_organisation_name(this.init_organisation_name)
          
          this.init_coc_number = organisation['coc_number']
          this.set_coc_number(this.init_coc_number)

          this.init_coc_business_number = organisation['coc_business_number']
          this.set_coc_business_number(this.init_coc_business_number)

          this.init_website_url = organisation['website_url']
          this.set_website_url(this.init_website_url)

          this.init_zipcode = organisation['zipcode']
          this.set_zipcode(this.init_zipcode)

          this.init_house_number = organisation['house_number']
          this.set_house_number(this.init_house_number)

          this.init_street_name = organisation['street_name']
          this.set_street_name(this.init_street_name)

          this.init_city = organisation['city']
          this.set_city(this.init_city)

          this.init_province = organisation['province']
          this.set_province(this.init_province)

          this.init_latitude = organisation['latitude']
          this.set_latitude(this.init_latitude)

          this.init_longitude = organisation['longitude']
          this.set_longitude(this.init_longitude)
        }
      }
      this.val_step_1()
    },
    set_organisation_name(value) {
      this.organisation_name = value
      this.val_step_2()
    },
    set_coc_number(value) {
      this.coc_number = value
      this.val_step_2()
    },
    set_coc_business_number(value) {
      this.coc_business_number = value
      this.val_step_2()
    },
    set_website_url(value) {
      this.website_url = value
      this.val_step_2()
    },
    set_zipcode(value) {
      this.zipcode = value
      this.val_step_3()
    },
    set_house_number(value) {
      this.house_number = value
      this.val_step_3()
    },
    set_street_name(value) {
      this.street_name = value
      this.val_step_3()
    },
    set_city(value) {
      this.city = value
      this.val_step_3()
    },
    set_province(value) {
      this.province = value
      this.val_step_3()
    },
    set_latitude(value) {
      this.latitude = parseFloat(value)
      this.val_step_3()
    },
    set_longitude(value) {
      this.longitude = parseFloat(value)
      this.val_step_3()
    },
    is_float(val) {
      return !Number.isInteger(val) && Number.isFinite(val)
    },
    val_step_1() {
      this.form_step_validation_list[0] = this.brin.length == 4 && this.dependance_code.length == 2
    },
    val_step_2() {
      let check_list = []
      check_list.push(this.organisation_name != null && this.organisation_name.length > 3)
      check_list.push(this.coc_number != null && this.coc_number.length == 8)
      check_list.push(this.coc_business_number != null && this.coc_business_number.length == 12)
      check_list.push(this.website_url != null && this.website_url.length > 10)

      this.form_step_validation_list[1] = !check_list.includes(false)
    },
    val_step_3() {
      let check_list = []
      check_list.push(this.zipcode != null && this.zipcode.length == 6)
      check_list.push(this.house_number != null && this.house_number.length > 0)
      check_list.push(this.street_name != null && this.street_name.length > 3)
      check_list.push(this.city != null && this.city.length > 2)
      check_list.push(this.province != null && this.province_list.includes(this.province))
      check_list.push(this.latitude != null && this.is_float(this.latitude))
      check_list.push(this.longitude != null && this.is_float(this.longitude))

      this.form_step_validation_list[2] = !check_list.includes(false)
    },
    async submit() {

      // Create organisation
      const jwt = this.$store.getters.get_jwt
      let response = await post_organisations(
        jwt,
        this.coc_number,
        this.coc_business_number,
        this.organisation_name,
        'SCHOOL',
        this.website_url
      )

      let organisation_id = null
      if (response.status == 201) {
        let json = await response.json()
        organisation_id = json['organisation_id']
      }
      else if (response.status == 409) {
        let json = await get_organisations_query(jwt, {'coc_business_number': this.coc_business_number})
        organisation_id = json['organisation_list'][0]['organisation_id']
      }

      // Create school
      response = await post_organisations_schools(
        jwt,
        organisation_id,
        this.brin,
        this.dependance_code,
        'PO',
        'OPENBAAR',
        0,
        0
      )

      // Create building
      response = await post_organisations_buildings(
        jwt,
        organisation_id,
        this.street_name,
        this.city,
        this.province,
        this.zipcode,
        this.street_name,
        this.house_number,
        this.latitude,
        this.longitude
      )

      setTimeout(() => {this.$router.go(-2)}, 500);
    }
  }
};
</script>
