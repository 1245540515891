<template>
  <oib-form ref="form" :form_step_validation_list="form_step_validation_list" title="Adres toevoegen" @submit="submit">
    <div>
      <oib-form-header header="Adres"/>
      <div class="row2">
        <oib-input-text
        label="Postcode"
        placeholder="7101BN"
        :required="true"
        :error_msg="zipcode_error_msg"
        @input="set_zipcode($event.target.value)"
        />
        <oib-input-text
        label="Huisnummer"
        placeholder="22a"
        :required="true"
        :error_msg="null"
        @input="set_house_number($event.target.value)"
        />
      </div>
      <oib-input-text
      label="Straatnaam"
      placeholder="Beatrixpark"
      :required="true"
      :error_msg="null"
      @input="set_street_name($event.target.value)"
      />
      <div class="row2" style="justify-content: space-between;">
        <oib-input-text
        label="Stad"
        placeholder="Winterswijk"
        :required="true"
        :error_msg="null"
        @input="set_city($event.target.value)"
        />
        <oib-input-text
        label="Provincie"
        placeholder="GELDERLAND"
        :required="true"
        :error_msg="province_error_msg"
        @input="set_province($event.target.value)"
        />
      </div>
    </div>
    <div>
      <oib-form-header header="Coördinaten"/>
      <oib-input-text
      label="Latitude"
      placeholder="51.9810029"
      :required="true"
      :error_msg="null"
      @input="set_latitude($event.target.value)"
      style="margin-top: 20px"
      />
      <oib-input-text
      label="Longitude"
      placeholder="6.7015163"
      :required="true"
      :error_msg="null"
      @input="set_longitude($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
    <div>
      <oib-form-header header="Naam"/>
      <oib-input-text
      label="Locatie naam"
      :required="true"
      :error_msg="null"
      @input="set_location_name($event.target.value)"
      style="margin-top: 20px"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"
import OibFormHeader from "../components/oib_form/oib_form_header.vue";

import { post_organisations_buildings } from "../store/api/user.js"
import { get_buildings_query } from "../store/api/user.js";
import { load_location_selectors } from "../store/user.js";

import { isZipcode } from "../store/validate.js";
import { isProvince } from "../store/validate.js";
import { isHouseNumber } from "../store/validate.js";

export default {
  name: "OibFormOrganisationAddress",
  components: {
    OibForm,
    OibInputText,
    OibFormHeader
  },
  data() {
    return {
      form_step_validation_list: [false, false, false],
      building: null,
      building_list: [],
      init_zipcode: "",
      zipcode: "",
      zipcode_error_msg: null,
      init_house_number: "",
      house_number: "",
      house_number_error_msg: null,
      init_street_name: "",
      street_name: "",
      init_city: "",
      city: "",
      init_province: "",
      provincie: "",
      province_error_msg: null,
      init_latitude: "",
      latitude: "",
      init_longitude: "",
      longitude: "",
      init_location_name: "",
      location_name: "",
      address_list: []
    }
  },
  methods: {
    set_zipcode(value) {
      this.zipcode = value
      this.val_step_1()
    },
    set_house_number(value) {
      this.house_number = value
      this.val_step_1()
    },
    set_street_name(value) {
      this.street_name = value
      if (this.init_street_name == this.init_location_name) {
        this.set_location_name(value)
      }
      this.val_step_1()
    },
    set_city(value) {
      this.city = value
      this.val_step_1()
    },
    set_province(value) {
      this.province = value
      this.val_step_1()
    },
    set_latitude(value) {
      this.latitude = parseFloat(value)
      this.val_step_2()
    },
    set_longitude(value) {
      this.longitude = parseFloat(value)
      this.val_step_2()
    },
    set_location_name(value) {
      this.location_name = value
      this.val_step_3()
    },
    is_float(val) {
      return !Number.isInteger(val) && Number.isFinite(val)
    },
    val_step_1() {
      let check_list = []
      this.zipcode_error_msg = isZipcode(this.zipcode)
      
      this.house_number_error_msg = isHouseNumber(this.house_number)
      this.province_error_msg = isProvince(this.province)

      check_list.push(this.zipcode_error_msg == null)
      check_list.push(this.house_number_error_msg == null)
      check_list.push(this.street_name != null && this.street_name.length > 3)
      check_list.push(this.city != null && this.city.length > 2)
      check_list.push(this.province_error_msg == null)

      this.form_step_validation_list[0] = !check_list.includes(false)
    },
    val_step_2() {
      let check_list = []
      check_list.push(this.latitude != null && this.is_float(this.latitude))
      check_list.push(this.longitude != null && this.is_float(this.longitude))

      this.form_step_validation_list[1] = !check_list.includes(false)
    },
    val_step_3() {
      return this.form_step_validation_list[2] = this.location_name && this.location_name.length > 3
    },
    async submit() {

      // Update building
      const jwt = this.$store.getters.get_jwt
      const organisation_id = this.$store.getters.get_organisation_id_active
      await post_organisations_buildings(
        jwt,
        organisation_id,
        this.location_name,
        this.city,
        this.province,
        this.zipcode,
        this.street_name,
        this.house_number,
        this.latitude,
        this.longitude
      )

      const json = await get_buildings_query(jwt, {'organisation_id': organisation_id})
      json['building_list'].forEach(building => this.$store.commit("add_building", building))
      load_location_selectors()

      setTimeout(() => {this.$router.back()}, 500);
    }
  }
};
</script>

<style>
.row2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row2 div {
  width: 100%
}

@media screen and (min-width:320px) {
  .row2 div {
    width: 45%;
  }
}
</style>