<template>
  <oib-page>
    <oib-nav-student title="Recensie" :allowLogout="false" />
    <oib-content>
      <div style="padding-top: 35vh; justify-content: space-between;">

        <div>
          <!-- Tags -->
          <div v-if="page < pages.length && pages[page]['question_type'] == 'tag_select_multi'">
            <div class="wrapper row" style="justify-content: space-between;">
              <button class="icon-button fade" :disabled="page == 0">
                <span 
                  :disabled="page == 0"
                  class="material-icons clickable"
                  @click="prev"
                > arrow_back </span>
              </button>
            
              <h2> {{pages[page].title}} </h2>
        
              <button class="icon-button fade" :disabled="!question_answered">
                <span class="material-icons clickable" @click="next"> arrow_forward </span>
              </button>
            </div>
            <div class="wrapper centered" style="display: flex; flex-direction: column;">
              <!-- Display a 3x3 tag grid that is interleaved by string length -->
              <div v-for="i in [0, 1, 2]" :key="i" class="label-row">
                <oib-button-label
                  v-for="k in interleaved[i]"
                  :key="k"
                  :active="pages[page]['tags_selected'].includes(tags_displayed[k])"
                  @click="toggleTag(tags_displayed[k])"
                  class="label-spacer"
                  :style="label_padding(k)">
                {{ tags_displayed[k] }}
                </oib-button-label>
              </div>
              <a
                v-if="pages[page]['tags'].length > 9"
                style="text-align: center; padding-top: 15px; font-size: 14px;"
                @click="next_tag_page()"
              >Meer kiezen</a>
            </div>
          </div>
          <!-- Realism -->
          <div v-if="page < pages.length && pages[page]['question_type'] == 'tag_select_single'" class="wrapper row" style="justify-content: space-between">
            <button class="icon-button fade" :disabled="page == 0">
              <span 
                :disabled="page == 0"
                class="material-icons clickable"
                @click="prev"
              > arrow_back </span>
            </button>
            <div>
              <h2> Is dit verhaal echt gebeurd? </h2>
              <div class="centered" style="display: flex; flex-direction: column; justify-content: center; margin: auto">
                <oib-button-label class="label-spacer scale" :active="realism==='fantasie'" @click="update_realism_tag('fantasie')"> Nee, het is fantasie. </oib-button-label>
                <oib-button-label class="label-spacer scale" :active="realism==='realistisch'" @click="update_realism_tag('realistisch')"> Nee, maar het zou wel kunnen. </oib-button-label>
                <oib-button-label class="label-spacer scale" :active="realism==='waargebeurd'" @click="update_realism_tag('waargebeurd')"> Ja, het is echt gebeurd. </oib-button-label>
              </div>
            </div>
            <button class="icon-button fade" :disabled="!question_answered">
              <span class="material-icons clickable" @click="next"> arrow_forward </span>
            </button>
          </div>
        <!-- Star Rating -->
          <div v-if="page < pages.length && pages[page]['question_type'] == 'rating'">
            <h2> {{pages[page].title}} </h2>
            <div  class="wrapper row" style="justify-content: space-between">
              <button class="icon-button fade" :disabled="page == 0">
                <span 
                  :disabled="page == 0"
                  class="material-icons clickable"
                  @click="prev"
                > arrow_back </span>
              </button>
              <div>
                <div class="centered">
                  <span 
                    v-for="star_id in range(Object.keys(pages[page]['rating_word']).length)"
                    :key="star_id"
                    class="clickable"
                    :class="rating>=star_id?'material-icons':'material-icons-outlined'"
                    @click="update_rating(star_id)"
                    :style="'color: ' + pages[page].color + '; width: 45px; height: 36px; font-size: 36px'"> 
                    {{pages[page].icon}} 
                  </span>
                </div>
                <p style="text-align: center; padding-top: 20px; font-size: 14px;">{{pages[page].rating_word[rating]}}</p>
              </div>
              <button class="icon-button fade" :disabled="!question_answered">
                <span class="material-icons clickable" @click="next"> arrow_forward </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibButtonLabel from '../components/OibButtonLabel.vue';
import OibContent from '../components/OibContent.vue';
import OibNavStudent from '../components/OibNavStudent.vue';
import OibPage from "../components/OibPage.vue";

export default {
  name: "Recensie",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButtonLabel,
  },
  props: ['isbn13'], // TODO pass isbn13
  methods: {
    // you'd rather have OibButtonLabel.vue track the click state
    // so we don't have to search the tag list every time
    toggleTag(tag) {
      const tag_index = this.pages[this.page]['tags_selected'].indexOf(tag);
      if (tag_index===-1) this.pages[this.page]['tags_selected'].push(tag);
      else this.pages[this.page]['tags_selected'].splice(tag_index, 1);
      this.question_answered = this.pages[this.page]['tags_selected'].length > 0
    },
    prev() {
      if (this.page > 0) {
        this.page -= 1;
        
        // Update tags displayed
        this.init_tag_page();

        // Update question answered state
        this.question_answered = true
      }
    },
    next() {
      this.page += 1;
      if (this.page == this.pages.length) {
        this.finish()
      }
      // Update tags displayed
      else if (this.pages[this.page]['question_type'] == 'tag_select_multi') {
        this.init_tag_page();
      
        // Update question answered state
        this.question_answered = this.pages[this.page]['tags_selected'].length > 0
      }
    },
    init_tag_page() {
      this.tags_index = 0;
      this.next_tag_page();
    },
    next_tag_page() {

      const tags_list = this.pages[this.page]['tags']
      const tags_selected = this.pages[this.page]['tags_selected']

      // Add all selected tags to next page
      this.tags_displayed = []
      this.tags_displayed.push.apply(this.tags_displayed, tags_selected)

      // Go to next tag page and fill the remaining tag slots
      let tags_new = []
      const tag_new_max = this.GRID_SIZE - tags_selected.length
      const tags_index_start = this.tags_index

      while (tags_new.length < tag_new_max) {
        
        // Check if tag_new is a valid new tag
        const tag_new = tags_list[this.tags_index]

        if (!(tags_selected.includes(tag_new)) && !(tags_new.includes(tag_new))) {
          tags_new.push(tags_list[this.tags_index])
        }
        
        // Update index
        this.tags_index += 1;
        if (this.tags_index > tags_list.length - 1) {
          this.tags_index = 0;
        }

        // Check if we went full-circle, to avoid endless loop
        if (tags_index_start == this.tags_index) break;
      }

      // sort tags based on length in order to space tags equally in grid
      //tags_new.sort((a, b) => (a.length > b.length ? 1 : -1));

      // Add new tags to tags_displayed
      this.tags_displayed.push.apply(this.tags_displayed, tags_new)

      // Transpose so that tags_selected are displayed first, instead of interleaved
      //this.tags_displayed = this.transpose_grid_list(this.tags_displayed)
    },
    transpose_grid_list(list){
      return [
        list[0], list[3], list[6],
        list[1], list[4], list[7],
        list[2], list[5], list[8],
      ]
    },
    update_realism_tag(realism_tag) {
      this.realism = realism_tag;
      this.question_answered = true;
    },
    finish() {
      // Parse review form
      //const d = new Date();
      //const yyyy = d.getFullYear();
      //const dd = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      //const mm = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
      //const review_date = yyyy + "-" + mm + "-" + dd
      //const review_time = d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds();
      //const review_datetime = `${review_date} ${review_time}`
//
      //let tags = []
      //for (let i = 0; i < this.pages.length; i++) {
      //  if (this.pages[i]['question_type'] == 'tag_select_multi') {
      //    tags.push.apply(tags, this.pages[i]['tags_selected'])
      //  }
      //}
//
      //tags.push(this.realism)

      // Get book_edition_id
      //const book_products = this.$store.getters.book_products
      //const book_edition_id = book_products[this.isbn13]['book_edition_id']

      // Add review to server
      //put_users_book_editions_review({
      //  jwt: this.$store.getters.getJwt,
      //  user_id: this.$store.getters.getUser.user_id,
      //  book_edition_id: book_edition_id,
      //  grade: this.rating,
      //  tags: this.tags_selected,
      //  review_datetime: review_datetime
      //});

      // Update client state from READING TO READ after review was submitted
      // The server state was already updated when the review started.
      this.$store.commit('updateBookState', {
        user_id: this.$store.getters.getUser.user_id,
        isbn13: this.isbn13,
        old_state: 1,
        new_state: 2
      })

      // Route student back to library
      this.openLezen();
    },
    shuffle(array) {
      return array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value)
    },
    label_padding(index) {
      if ((index % 3) == 0) return "padding-right: 10px;"
      if ((index % 3) == 2) return "padding-left: 10px;"
      return ""
    },
    openLezen() {
      this.$router.push({
        name: "BibliotheekLezen"
      });
    },
    update_rating(rating) {
      this.rating = rating
      this.question_answered = true
    },
    range(length) {
      let array = []
      for (let i = 0; i < length; i++) {
        array.push(i)
      }
      return array
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  data() {
    return {
      page: 0,
      rating: undefined,
      realism: undefined,
      question_answered: false,
      ROW_LENGTH: 3,
      COLUMN_LENGTH: 3,
      PAGE_COUNT: 4,
      QUESTION_TAG_SELECT_MULTI: 0,
      QUESTION_TAG_SELECT_SINGLE: 1,
      QUESTION_RATING: 2,
      GRID_SIZE: 0,
      tags_selected: [],
      tags_displayed: [],
      tags_index: 0,
      pages: [
        /*
        {
          'question_type': 'tag_select_multi',
          'page_index': 0,
          'title': 'Wie komen er in het boek voor?',
          'tags_selected': [],
          'tags': [
            'Leraren',
            'Vrienden',
            'Familie',
            'Huisdieren',
            'Dieren',
            'Piraten',
            'Ridders',
            'Superhelden',
            'Draken',
            'Elfjes',
            'Zeemeerminnen',
            'Weerwolven',
            'Vampieren',
            'Heksen',
            'Reuzen',
            'Zombies',
            'Robots',
            'Mummies',
            'Monsters',
          ]
        },
        {
          'question_type': 'tag_select_multi',
          'page_index': 1,
          'title': 'Wanneer speelt het verhaal zich af?',
          'tags_selected': [],
          'tags': [
            'Prehistorie',
            'Grieken & Romeinen',
            'Middeleeuwen',
            'Het wilde westen',
            'Wereldoorlog',
            'Nu',
            'Toekomst',
            'De gouden eeuw',
          ]
        },
        {
          'question_type': 'tag_select_multi',
          'page_index': 2,
          'title': 'Ik vond dit boek:',
          'tags_selected': [],
          'tags': [
            'Grappig',
            'Spannend',
            'Griezelig',
            'Mooi',
            'Verdrietig',
            'Geheimzinnig',
            'Bijzonder',
            'Saai',
            'Voorspelbaar',
            'Gek'
          ]
        },
        {
          'question_type': 'tag_select_multi',
          'page_index': 3,
          'title': 'Welke woorden passen bij dit boek?',
          'tags_selected': [],
          'tags': [
            'Brandweer',
            'Politie',
            'Doktor',
            'Kapper',
            'Bouwvakker',
            'Boer',
            'School',
            'Samen spelen',
            'Voetbal',
            'Hockey',
            'Handbal',
            'Zwemmen',
            'Korfbal',
            'Paardrijden',
            'Fietsen',
            'Verjaardag',
            'Sinterklaas',
            'Kerst',
            'Pasen',
            'Suikerfeest',
            'Bruiloft',
            "Auto's",
            'Vliegtuigen',
            'Boten',
            'De ruimte',
            'Treinen',
            'Pesten',
            'Sociale media',
            'Liefde',
            'Afscheid nemen',
            'Ziekte',
            'Internet',
            'Computers',
            'Eenzaamheid',
            'Scheiden',
            'Geloof',
            'Muziek',
            'Koken',
            'Knutselen',
            'Toneel',
            'Goochelen',
            'Tekenen',
            'Ballet',
            'Dansen'
          ]
        },
        {
          'page_index': 4,
          'question_type': 'tag_select_single',
        },*/
        {
          'title': 'Hoe leuk vond je dit boek?',
          'page_index': 0,
          'question_type': 'rating',
          'icon': 'star_rate',
          'color': '#daa520',
          'rating_word': {
            0: 'Zeer onvoldoende',
            1: 'Onvoldoende',
            2: 'Voldoende',
            3: 'Leuk',
            4: 'Zeer leuk'
          },
        },
        {
          'title': 'Hoe moeilijk vond je dit boek?',
          'page_index': 1,
          'question_type': 'rating',
          'icon': 'circle',
          'color': '#00aeef',
          'rating_word': {
            0: 'Te makkelijk',
            1: 'Makkelijk',
            2: 'Uitdagend',
            3: 'Moeilijk',
            4: 'Te moeilijk'
          },
        },
      ],
    }
  },
  mounted() {
    this.GRID_SIZE = this.ROW_LENGTH * this.COLUMN_LENGTH;

    if ('tags' in this.pages[this.page]) {
      this.tags_displayed = this.pages[this.page]['tags'].slice(0, this.GRID_SIZE);
    }    

    // Randomize all tags
    for (let i = 0; i < this.pages.length; i++) {
      if (this.pages[i]['question_type'] == 'tag_select_multi') {
        this.pages[i]['tags'] = this.shuffle(this.pages[i]['tags'])
      }
    }
  },
  computed: {
    interleaved() {
      let index_grid = [[], [], []]
      for (let i = 0; i < this.tags_displayed.length; i++) {
        const row_index = Math.floor(i / 3)
        index_grid[row_index].push(i)
      }
      return index_grid
    }
  }
};
</script>

<style scoped>
p {
  margin: auto;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  align-self: center;
  width: 100%;
}
.scale {
  width: 100%;
}

h2 {
  text-align: center;
  margin-top: 5px;
}

.label-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.label-spacer {
  padding-top: 10px;
}

.icon-button {
  background: none;
  color: white;
  height: 24px;
}

.icon-button:disabled {
  background: none;
  color: white;
  height: 24px;
  opacity: 0;
}

.fade {
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}

</style>