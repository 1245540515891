<template>
  <div @click="go_to_book_scan()">
    <img
    src="../assets/icon_barcode_search.svg"
    alt="scan_barcode"
    style="width: 25px; height: 25px; padding-top: 5px; cursor: pointer;"
    >
  </div>
</template>

<script>

export default {
  name: 'OibButtonBookScan',
  methods: {
    go_to_book_scan() {
      const user = this.$store.getters.getUser
      this.$router.push({
        name: 'BoekScan',
        params: {
          user_id: user.user_id,
          fast_borrow: false
        }
      });
    },
  },
  data () {
    return {
      active: false,
    }
  }
}
</script>

<style>
.button-book-scan {
  color: white;
  width: 25px;
  height: 25px;
  padding-top: 5px;
  cursor: pointer;
}
</style>
