<template>
  <vue-barcode ref="vueBarcode" :value="isbn13" format="EAN13" :flat="true"></vue-barcode>
</template>

<script>
import VueBarcode from 'vue3-barcode';

export default {
  name: 'OibBarcode',
  props: ['isbn13'],
  components: {
    VueBarcode
  },
  methods: {
    download() {
      function triggerDownload(imgURI, filename) {
        const a = document.createElement('a');
        a.download = filename; // filename
        a.target = '_blank';
        a.href = imgURI;

        // trigger download button
        // (set `bubbles` to false here.
        // or just `a.click()` if you don't care about bubbling)
        a.dispatchEvent(new MouseEvent('click', {
          view: window,
          bubbles: false,
          cancelable: true
        }));
      }

      const svgNode = this.$refs.vueBarcode.$el.children[0];
      const svgString = (new XMLSerializer()).serializeToString(svgNode);
      const svgBlob = new Blob([svgString], {
        type: 'image/svg+xml;charset=utf-8'
      });

      const DOMURL = window.URL || window.webkitURL || window;
      const url = DOMURL.createObjectURL(svgBlob);

      const image = new Image();
      image.width = svgNode.width.baseVal.value;
      image.height = svgNode.height.baseVal.value;
      image.src = url;

      const isbn13 = this.isbn13
      image.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0);
        DOMURL.revokeObjectURL(url);

        const imgURI = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        triggerDownload(imgURI, `barcode_${isbn13}.png`);
      };
    }
  }
}
</script>
