import { request_put } from "../request.js"
import { parse_query } from "../request.js"
import { request_get_json } from "../request.js"

export async function put_users_book_products_details(jwt, user_id, isbn13, page_count) {
  /* Create a school library.

  :param int user_id: the unique id of the user that votes.
  :param int isbn13: the isbn13 of the book the user is voting on.
  :param int page_count: the total amount of pages in the book according to the user.
  */

  const request_path = `/users/${user_id}/book-products/${isbn13}/details`
  const body = {'page_count': page_count}

  return await request_put(jwt, request_path, body)
}


export async function get_users_book_products_details(jwt, query) {
  /* Query the votes on a book. */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/users/book-products/details?${query_string}`)

}

export async function get_tags_query(jwt, query) {
  /* Query tags. */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/tags?${query_string}`)
}

export async function put_tags(jwt, tag_id, tag_name, color, search_key_list) {
  /* Update tag */

  const request_path = `/tags/${tag_id}`
  const body = {
    'tag_name': tag_name,
    'color': color,
    'search_key_list': search_key_list
  }

  return await request_put(jwt, request_path, body)
}
