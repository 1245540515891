<template>
  <div :id="id">
    <oib-search-bar
    :placeholder="placeholder"
    :init_value="init_value"
    @search="x => search(x)"
    @input="e => search_query_self = e.target.value"
    @focus="is_visible=true"
    @focusout="focusout"
    :search_frequency_ms="search_frequency_ms"
    ref="search_bar"
    />
    <div style="height: 0">
      <div
      v-if="is_visible && search_result"
      @click="click"
      :style="`z-index: 2; border-radius: 18px; transform: translate(0, 10px); width: ${width}px; background-color: white`"
      tabindex="0"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

import OibSearchBar from "../components/OibSearchBar.vue"

export default {
  name: "OibSearchBarDropdown",
  props: {
    init_value: {
      default: ""
    },
    search_result: {
      type: Array,
      required: true,
    },
    search_query: {
      type: String,
      required: false,
      default: "",
    },
    search_frequency_ms: {
      type: Number,
      default: 1000
    },
    placeholder: {
      type: String,
      default: "Zoek en filter ..."
    }
  },
  components: {
    OibSearchBar
  },
  data() {
    return {
      search_query_self: "",
      is_mounted: false,
      is_visible: false,
      id: "",
      width: 0
    }
  },
  watch: {
    search_query(value) {
      this.search_query_self = value
    }
  },
  created() {
    this.id = 'id' + uuidv4().replace(/-/g, '_')
  },
  mounted() {
    this.width = this.$refs.search_bar.$el.offsetWidth
    this.search_query_self = this.search_query
    this.is_mounted = true
  },
  methods: {
    search(search_query) {
      if (document.activeElement === this.$refs.search_bar.$el) {
        this.$emit("search", search_query);

        if (this.width == 0) {
          this.width = this.$refs.search_bar.$el.offsetWidth
        }

        // Enable result visibility - triggers the spawn of the dropdown
        this.is_visible = true
      }
    },
    focus() {
      this.$refs.search_bar.$el.focus()
    },
    focusout() {
      // Hide after a delay, such that the click event on the dropdown can still be registered
      setTimeout(() => this.is_visible = false, 200)
    },
    click() {
      this.is_visible = false
    }
  }
}
</script>
