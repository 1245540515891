<template>
  <div>
    <oib-form-lvs
    :error_msg="error_msg"
    @submit="x => submit(x)"
    />
    <oib-modal-loading ref="modal_loading" :progress="progress" :text="text"/>
  </div>
</template>

<script>
import OibFormLvs from "../components/oib_form_lvs.vue";
import OibModalLoading from '../components/oib_modal_loading.vue'

import {put_schools_lvs} from "../store/api/lvs.js"
import {get_schools_lvs_query} from "../store/api/lvs.js"
import {get_classrooms_query} from "../store/api/user.js"
import {post_schools_lvs_sync} from "../store/api/lvs.js"
import {get_schools_lvs_sync_query} from "../store/api/lvs.js"
import {get_schools_lvs_download_results_query} from "../store/api/lvs.js"
import {has_role} from "../store/utils"

export default {
  name: "LvsKoppelenAutorisatie",
  props: ['user_group'],
  components: {
    OibFormLvs,
    OibModalLoading
  },
  data() {
    return {
      error_msg: null,
      submitting: false,
      progress: 0,
      text: ''
    }
  },
  async mounted() {
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    if (!has_role(['SYSTEM_ADMIN', 'SYSTEM_SUPPORT', 'SCHOOL_ADMIN'])) {
      return
    }

    if (this.user_group != 'leerlingen') {
      return
    }

    // If connection already made and loading students, replace route with activate classrooms
    const school = this.$store.getters.get_school
    console.log(school)
    if (!school) {
      return
    }

    await this.$store.dispatch('load_lvs', {
      'jwt': this.$store.getters.get_jwt,
      'branch_number': school['branch_number']
    })

    const sync_request = this.$store.getters.get_lvs_sync_request
    const connection_valid = sync_request && sync_request['is_succesful']
    if (connection_valid) {
      this.$router.replace({
        name: 'LvsKoppelenGroepen'
      })
    }
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined or already submitting
      if (!body || this.submitting) {
        return
      }

      this.text = 'LVS koppelen'
      this.$refs.modal_loading.show()

      this.submitting = true
      this.error_msg = null

      const jwt = this.$store.getters.get_jwt
      const school = this.$store.getters.get_school
      const branch_number = school['branch_number']
      const lvs_type = body['lvs_type']

      // Get old classroom_list if lvs link exists
      let classroom_list = []
      let json = await get_schools_lvs_query(jwt, {
        'branch_number': branch_number,
        'lvs_type': lvs_type
      })
      const lvs_list = json['lvs_list']
      if (lvs_list.length > 0) {
        classroom_list = lvs_list[0].classroom_list
      }

      // Update lvs link
      await put_schools_lvs(jwt, branch_number, lvs_type, body['authorization_key'], classroom_list)

      this.text = `Gegevens ophalen uit ${lvs_type}`
      const status_code = await this.handle_sync_request(branch_number, lvs_type)

      // Update lvs cache and related tasks
      this.progress = 30
      this.text = `${lvs_type} koppeling updaten`
      await this.$store.dispatch('load_lvs', {
        'jwt': this.$store.getters.get_jwt,
        'branch_number': branch_number
      })

      // Succesful sync
      if (status_code == 1) {

        // Get download result with complete classroom_list
        this.progress = 35
        this.text = 'Groepsindeling ophalen'
        json = await get_schools_lvs_download_results_query(jwt, {
          'branch_number': branch_number,
          'lvs_type': lvs_type
        })
        const download_result = json['download_result_list'][0]        

        // Verify that all classrooms are loaded
        this.progress = 40
        this.text = 'Groepsindeling inladen ...'
        await this.load_classrooms(branch_number, lvs_type, download_result['classroom_list'])

        if (this.user_group == 'leerlingen') {
          this.$router.replace({
            name: 'LvsKoppelenGroepen',
          });
        } else if (this.user_group == 'leerkrachten') {
          this.$router.go(-2)
        }
      }

      // Failed sync
      else if (status_code == 2) {
        this.submitting = false
        this.error_msg = 'Autorisatie sleutel was niet geldig'
        this.$refs.modal_loading.hide()
      }

      // Exceeded waiting time
      else if (status_code == 3) {
        this.submitting = false
        this.error_msg = 'Synchroniseren duurt langer dan verwacht. Neem contact op.'
        this.$refs.modal_loading.hide()
      }
    },
    async handle_sync_request(branch_number, lvs_type) {
      /*
      Sync data and return status_code

      1 = Succesful sync
      2 = Failed sync
      3 = Wait time exceeded
      */

      const jwt = this.$store.getters.get_jwt
      await post_schools_lvs_sync(jwt, branch_number, lvs_type, 0, 1)

      let attempt = 0
      while (attempt < 20) {
        
        let json = await get_schools_lvs_sync_query(jwt, {
          'branch_number': branch_number,
          'lvs_type': lvs_type,
          'sort': 'datetime_requested,DESC',
          'limit': 1
        })
        const sync_request_list = json['sync_request_list']

        if (sync_request_list.length > 0 && sync_request_list[0]['datetime_fulfilled'] != null) {
          return sync_request_list[0]['is_succesful'] == 1 ? 1 : 2
        }

        await this.sleep(1000)
        attempt += 1
      }

      return 3
    },
    async load_classrooms(classroom_list) {

      const jwt = this.$store.getters.get_jwt

      // Await until classrooms are loaded
      const len_classroom_list = classroom_list.length

      let attempt = 0
      while (attempt < 10) {
        
        const json = await get_classrooms_query(jwt, {
          'organisation_id': this.$store.getters.get_organisation_id_active,
          'schoolyear': this.$store.getters.get_schoolyear
        })

        if (json['classroom_list'].length > 0) {
          json['classroom_list'].forEach(classroom => this.$store.commit("add_classroom", classroom))
        }
        if (json['classroom_list'].length == len_classroom_list) {
          break
        }

        await this.sleep(1000)
        attempt += 1
      }

      this.progress = 100
      await this.sleep(1000)
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  }
};
</script>
