export default {
  state: {
      margin_left: 0,
      width: 0
  },
  mutations: {
      set_margin_left(state, margin_left) {
          state.margin_left = margin_left;
      },
      set_width(state, width) {
        state.width = width;
      },
  },
  actions: {

  },
  modules: {

  },
  getters: {
      get_margin_left: state => state.margin_left,
      get_width: state => state.width
  }
}
