<!--https://www.geeksforgeeks.org/how-to-style-a-checkbox-using-css/-->

<template>
  <div v-on:click.prevent>
    <input class="inp-cbx" :id="id" type="checkbox" style="margin: 0; display: none;" v-model="checked"/>
    <label class="cbx" :class="style_type" :for="id"><span>
      <svg width="12px" height="10px">
        <use xlink:href="#check"></use>
      </svg></span>
    </label>
    <!--SVG Sprites-->
    <svg class="inline-svg">
      <symbol id="check" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </symbol>
    </svg>
  </div>
  <!-- <label :class="$mq" class="wrapper-checkbox"> {{ label }}
    <input type="checkbox" ref="checkbox" v-model="checked">
    <span class="checkbox-custom"></span>
  </label> -->
</template>

<script>

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export default {
  name: "OibCheckbox",
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    set_checked: {
      type: Boolean,
      default: false,
      required: false,
    },
    style_type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      id: '',
      checked: false,
    }
  },
  watch: {
    set_checked : function (val) {
      this.checked = val;
    },
    checked : function (val) {
      this.$emit('update', val);
    }
  },
  mounted() {
    this.id = 'id' + uuidv4().replace(/-/g, '_')
    if (this.set_checked) {
      this.checked = this.set_checked
    }    
  }
};
</script>

<style scoped>

* {
  box-sizing: border-box;
}
.cbx {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.2s ease;
  margin-top: 4px;
}

.cbx span {
  float: left;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span:first-child {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  transform: scale(1);
  border: 2px solid var(--contrast-color-p4);
  transition: all 0.2s ease;
  box-shadow: 0 1px 1px rgba(0,16,75,0.05);
}
.cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #fff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:last-child {
  line-height: 18px;
}
.cbx.show_placeholder span:first-child {
  border-color: none;
  background-color: rgba(0, 0, 0, 0.6);
}
.cbx.default:hover span:first-child {
  border-color: var(--highlight-color);
}
.inp-cbx {
  position: absolute;
  visibility: hidden;
}
.inp-cbx:checked + .cbx span:first-child {
  background: var(--highlight-color);
  border-color: var(--highlight-color);
  animation: wave 0.4s ease;
}
.inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.cbx.show_placeholder span:first-child {
  background: rgba(0, 0, 0, 0.6);
  border-color: rgba(0, 0, 0, 0);
  /* animation: wave 0.4s ease; */
}
/* .cbx.show_placeholder span:first-child svg {
  stroke-dashoffset: 0;
} */

.inline-svg {
  position: absolute;
  width: 0;
  height: 0;
  pointer-events: none;
  user-select: none;
}
.cbx {
  width: 100%;
  margin-bottom: 4px;
  display: inline-block;
}

@-moz-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-webkit-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@-o-keyframes wave {
  50% {
    transform: scale(0.9);
  }
}
@keyframes wave {
  50% {
    transform: scale(0.9);
  }
}

</style>