<template>
  <div class="scanner-container">
      <!-- <div :style="view_finder_style" class="fade overlay" /> -->
      <div :style="view_finder_style" class="fade view-finder top-left ver"/>
      <div :style="view_finder_style" class="fade view-finder top-left hor"/>
      <div :style="view_finder_style" class="fade view-finder top-right ver"/>
      <div :style="view_finder_style" class="fade view-finder top-right hor"/>
      <div :style="view_finder_style" class="fade view-finder bottom-right ver"/>
      <div :style="view_finder_style" class="fade view-finder bottom-right hor"/>
      <div :style="view_finder_style" class="fade view-finder bottom-left ver"/>
      <div :style="view_finder_style" class="fade view-finder bottom-left hor"/>
      <p style="position: fixed; bottom: 210px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{result_dict}}</p>
      <p style="position: fixed; bottom: 180px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{resolution}}</p>
      <p style="position: fixed; bottom: 150px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{width_crop.toFixed(2)}}x{{height_crop.toFixed(2)}}</p>
      <p style="position: fixed; bottom: 120px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{width_pixels}}x{{height_pixels}}</p>
      <p style="position: fixed; bottom: 90px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{width_pixels_canvas}}x{{height_pixels_canvas}}</p>
      <p style="position: fixed; bottom: 60px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{window_height}}</p>
      <p style="position: fixed; bottom: 30px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{video_element_width}}x{{video_element_height}}</p>
      <p style="position: fixed; bottom: 0px; left: 10px; z-index: 3" v-if="$store.getters.get_debug_mode">{{fps}}</p>
      <div
      style="z-index: 10; bottom: 10px; display: flex; flex-direction: column; align-items: center; position: fixed; height: 50px;"
      @click="cycle_zoom_factor"
      >
        <h4 style="margin: 0; width: 30px; height: 30px; line-height: 30px; padding: 10px; border-radius: 50%; text-align: center; color: white; background-color: rgba(0,0,0,0.4); border: 1px solid white">
          {{zoom_factor_list[zoom_factor_index]}}x
        </h4>
      </div>
      <canvas
      ref="canvas"
      style="z-index: 2"
      :style="`width: ${width_pixels_canvas}px; height: ${height_pixels_canvas}px; opacity: ${$store.getters.get_debug_mode ? '1' : '0'}`"
      />
      
      <!-- Increase video height to support a larger focus distance -->
      <video
      ref="video"
      style="position: fixed;"
      :style="`height: ${100 * zoom_factor_list[zoom_factor_index]}vh`"
      autoplay="true" muted="true" playsinline="true"
      />
  </div>
</template>

<script>
import { BrowserQRCodeReader, BrowserBarcodeReader, HTMLCanvasElementLuminanceSource, HybridBinarizer, BinaryBitmap, Exception } from "@zxing/library";
import { create_video_element_stream } from "./barcode_reader/src/misc/camera";

import {get_cookie} from "../cookie.js"
import {set_cookie} from "../cookie.js"

export default {
    name: "stream-barcode-reader",
    props: {
      format: {
        type: String,
        required: true
      },
      view_finder_border_color: {
        type: String,
        default: 'var(--highlight-color)'
      }
    },
    data() {
        return {
            zoom_factor_index: 1,
            zoom_factor_list: [1.0, 1.5, 2.0],
            view_finder_background_color: "rgba(255,255,255,0.8)",
            previous_result: "",
            previous_result_time: new Date(),
            interval: 8000,
            min_interval: 3000,
            isLoading: true,
            codeReader: null,
            result_blocked_set: new Set(),
            result_dict: {},
            worker_zxing_ready: true,
            decode_continuously: false,
            videoElement: null,
            resolution: "",
            fps: "",
            fps_timer: Date.now(),
            fps_counter: 0,
            bitmap_src: "",
            window_height: 0,
            width_pixels_canvas: 0,
            height_pixels_canvas: 0,
            width_video: 0,
            height_video: 0,
            video_element_height: 0,
            width_crop: 0,
            height_crop: 0,
            width_pixels: 0,
            height_pixels: 0,
            upscale_modifier: 1.5,
            is_paused: false,
            isMediaStreamAPISupported:
                navigator &&
                navigator.mediaDevices &&
                "enumerateDevices" in navigator.mediaDevices
        };
    },
    computed: {
        view_finder_style() {
            const view_finder_width = this.width_pixels_canvas / 2 * 0.9
            const view_finder_height = this.height_pixels_canvas / 2 * 0.9
            
            return {
              '--view-finder-width': (view_finder_width).toString() + 'px',
              '--view-finder-height': (view_finder_height).toString() + 'px',
              '--view-finder-background-color': this.view_finder_background_color,
              '--background-color-border': this.view_finder_border_color,
              'opacity': view_finder_width > 0 ? 1 : 0
            }
        }
    },
    created() {
      window.addEventListener('visibilitychange', this.handle_visibility_change)
      this.zoom_factor_index = this.init_zoom_factor()
      this.window_height = window.innerHeight
    },
    async mounted() {
        if (!this.$store.getters.getUser && this.$route.name != 'LoginQrCode') {
          return
        }

        if (!this.isMediaStreamAPISupported) {
            throw new Exception("Media Stream API is not supported");
        }

        if (this.format == 'ean13') {
          this.codeReader = new BrowserBarcodeReader(50)
          const ean13_reader = this.codeReader.reader.readers[1].readers[0]
          this.codeReader.reader.readers = [ean13_reader]

        }
        else if (this.format == 'qr_code') {
          this.codeReader = new BrowserQRCodeReader(50)
        }
        else {
          alert("Supported format specified. Format must be 'ean_13' or 'qr_code'.")
        }

        window.addEventListener('resize', this.on_resize);

        // Start
        this.start_camera();
    },
    beforeUnmount() {
        // This function won't be executed on refresh due to async behaviour
        this.$store.commit("stop_stream")
        this.decode_continuously = false
    },
    unmounted() {
      window.removeEventListener('resize', this.on_resize);
      window.removeEventListener('visibilitychange', this.handle_visibility_change)
    },
    methods: {
        handle_visibility_change() {
          if (document.visibilityState == 'hidden') {
            this.$store.commit("stop_stream")
          } else if (document.visibilityState == 'visible') {
            this.start_camera();
          } else {
            alert("Detected unknown visibilityState: " + document.visibilityState)
          }
        },
        init_zoom_factor() {
          // Pull from cookies
          const CAMERA_ZOOM_COOKIE = get_cookie('CAMERA_ZOOM_COOKIE')
          if (CAMERA_ZOOM_COOKIE) {
            return this.zoom_factor_list.indexOf(parseFloat(CAMERA_ZOOM_COOKIE))
          }

          // Return default default
          return 2
        },
        cycle_zoom_factor() {
          const zoom_factor_index = this.zoom_factor_index + 1
          if (zoom_factor_index > this.zoom_factor_list.length - 1) {
            this.zoom_factor_index = 0
          } else {
            this.zoom_factor_index = zoom_factor_index
          }

          set_cookie("CAMERA_ZOOM_COOKIE", this.zoom_factor_list[this.zoom_factor_index], 365)
          setTimeout(() => this.set_crop_resolution(), 100)
        },
        on_resize() {
          this.set_crop_resolution()
        },
        pause() {
          this.is_paused = true
        },
        continue() {
          this.is_paused = false
        },
        set_crop_resolution() {
          if (!this.$refs.video) {
            return
          }

          this.video_element_width = this.$refs.video.clientWidth
          this.video_element_height = this.$refs.video.clientHeight
          const aspect_ratio_height = this.height_video / this.video_element_height
          this.width_crop = this.width_pixels * this.upscale_modifier * aspect_ratio_height
          this.height_crop = this.height_pixels * this.upscale_modifier * aspect_ratio_height
        },
        async start_camera() {

            let video = this.$refs.video
            const canvas = this.$refs.canvas
            
            if (!video || !canvas) {
              return
            }

            // Create stream            
            const stream = await this.$store.getters.get_stream
            const video_element = await create_video_element_stream(video, stream)

            // Get resolution
            this.width_video = video.videoWidth
            this.height_video = video.videoHeight
            this.resolution = `${this.width_video}x${this.height_video}`

            // Prepare canvas
            const cap_val = 6 // Can divide by 1.5 and 2
            if (this.format == 'ean13') {
              const height_pixels_canvas = Math.ceil(((this.window_height / 3.5)) / cap_val) * cap_val
              this.width_pixels_canvas = height_pixels_canvas * 1.5
              this.height_pixels_canvas = height_pixels_canvas
            } else if (this.format == 'qr_code') {
              const height_pixels_canvas = Math.ceil(((this.window_height / 2.5)) / cap_val) * cap_val
              this.width_pixels_canvas = height_pixels_canvas
              this.height_pixels_canvas = height_pixels_canvas
            }

            // viewfinder 2 is too large
            this.width_pixels = this.width_pixels_canvas / this.upscale_modifier
            this.height_pixels = this.height_pixels_canvas / this.upscale_modifier
            this.set_crop_resolution()

            canvas.width = this.width_pixels
            canvas.height = this.height_pixels
            const ctx = canvas.getContext("2d");

            // Add event listeners
            const self = this
            video.addEventListener('play', () => {
              async function step() {

                if (!self.decode_continuously) {
                  return
                }

                // Wait while paused
                if (self.is_paused) {
                  setTimeout(requestAnimationFrame(step), 50)
                } else {
                  self.fps_counter += 1

                  ctx.drawImage(
                    video,
                    self.width_video / 2 - self.width_crop / 2,
                    self.height_video / 2 - self.height_crop / 2,
                    self.width_crop,
                    self.height_crop,
                    0,
                    0,
                    self.width_pixels,
                    self.height_pixels
                  )

                  const time_diff = Date.now() - self.fps_timer 
                  if (time_diff > 1000) {
                    self.fps_timer = Date.now()
                    self.fps = (self.fps_counter * (1000  / time_diff)).toFixed(2)
                    self.fps_counter = 0
                  }

                  const luminanceSource = new HTMLCanvasElementLuminanceSource(canvas);
                  const hybridBinarizer = new HybridBinarizer(luminanceSource);
                  const binaryBitmap = new BinaryBitmap(hybridBinarizer);

                  // BUG FIX: Frozen camera is solved by writing to the bitmap
                  //          In debug mode it writes to every indivual pixel.
                  self.write_binary_bitmap_to_canvas(ctx, binaryBitmap, self.width_pixels, self.height_pixels)

                  const x = await self.decode_binary_bitmap(binaryBitmap)
                  if (x) {
                    self.fps_counter += 1;
                    self.on_decode(x);
                  }

                  // Stop decoding if disabled
                  requestAnimationFrame(step);
                }
              }

              self.decode_continuously = true
              requestAnimationFrame(step);
            })

            // Connect stream to video element
            await this.attachStreamToVideo(stream, video_element.videoEl);
            this.$refs.video.oncanplay = () => {
                this.isLoading = false;
                this.$emit("loaded");
            };
        },
        async decode_binary_bitmap(binary_bitmap) {
          try {
            return await this.codeReader.decodeBitmap(binary_bitmap)
          } catch {
            return null
          }
        },
        async write_binary_bitmap_to_canvas(ctx, binaryBitmap, width, height) {
          const string = binaryBitmap.toString('base64')

          // Always fill canvas with white pixels
          // This fixes a rare bug where camera stream is frozen
          ctx.fillStyle = "#FFF";  // Fill with white first
          width = width + 1
          ctx.fillRect(0, 0, width, height);

          // Only fill the individual black pixels in debug mode
          if (this.$store.getters.get_debug_mode) {
            ctx.fillStyle = "#000";
            for (var i = 0; i < height; i++) {  // Loop through each character
                for (var j = 0; j < width; j++) {
                    if (string[i*width+j] == 'x') {     // If the character is one,
                        ctx.fillRect(j, i, 2, 2 );  // fill the pixel with black
                    }
                }
            }
          }
        },
        on_decode(result) {
            /*
            On decode, update a dictionary of results and track time and certainty that
            this barcode was actually scanned.

            The goal is to maintain a high scan speed, yet avoid scans on accident.
            */

            // Quit if no result
            if (!result) {
              return
            }

            // Compute whether the interval has passed
            const barcode = result.text
            const now = new Date()

            if (!(barcode in this.result_dict)) {
              this.result_dict[barcode] = {
                'last_time_scanned': new Date(1970, 1, 1),
                'certainty': 0
              }
            }

            this.result_dict[barcode]['certainty'] = this.result_dict[barcode]['certainty'] + 20
            
            // Decrease certainty for all other barcodes
            const barcode_list = Object.keys(this.result_dict)
            let remove_list = []
            barcode_list.forEach(x => {
              this.result_dict[x]['certainty'] = (this.result_dict[x]['certainty'] - 5) / 1.1
              if (this.result_dict[x]['certainty'] < 0) {
                remove_list.push(x)
              }
            })
            remove_list.forEach(x => delete this.result_dict[x])

            // Return if result is still blocked
            const min_interval_passed = now - this.previous_result_time > this.min_interval
            const interval_passed = now - this.result_dict[barcode]['last_time_scanned'] > this.interval
            const certainty_threshold_met = this.result_dict[barcode]['certainty'] > 30
            if (!min_interval_passed || !interval_passed || !certainty_threshold_met) {
              return
            }

            // Set timer
            this.result_dict[barcode]['last_time_scanned'] = now
            this.result_blocked_set.add(barcode)

            // Handle valid scan
            this.$emit("decode", {
              'barcode': barcode,
              'certainty': this.result_dict[barcode]['certainty']
            });
            this.previous_result = barcode
            this.previous_result_time = now
        },
        async attachStreamToVideo(stream, videoSource) {
          /**
           * Sets the new stream and request a new decoding-with-delay.
           *
           * @param stream The stream to be shown in the video element.
           * @param decodeFn A callback for the decode method.
           */

          const videoElement = this.prepareVideoElement(videoSource);

          this.addVideoSource(videoElement, stream);

          this.videoElement = videoElement;

          await this.playVideoOnLoadAsync(videoElement);

          return videoElement;
        },

        prepareVideoElement(videoSource) {
          /**
           * Sets a HTMLVideoElement for scanning or creates a new one.
           *
           * @param videoSource The HTMLVideoElement to be set.
           */

          // Needed for iOS 11
          videoSource.setAttribute('autoplay', 'true');
          videoSource.setAttribute('muted', 'true');
          videoSource.setAttribute('playsinline', 'true');

          return videoSource;
        },

        addVideoSource(videoElement, stream) {
          /**
           * Defines what the videoElement src will be.
           *
           * @param videoElement
           * @param stream
           */

          // Older browsers may not have `srcObject`
          try {
            // @note Throws Exception if interrupted by a new loaded request
            videoElement.srcObject = stream;
          } catch (err) {
            // @note Avoid using this in new browsers, as it is going away.
            videoElement.src = URL.createObjectURL(stream);
          }
        },

        playVideoOnLoadAsync(videoElement) {
          return new Promise((resolve) => this.playVideoOnLoad(videoElement, () => resolve()));
        },

        playVideoOnLoad(element, callbackFn) {
          /**
           * Binds listeners and callbacks to the videoElement.
           *
           * @param element
           * @param callbackFn
           */

          this.videoCanPlayListener = () => {
            this.tryPlayVideo(element);
          }

          // Required for screen refresh
          element.addEventListener('ended', () => {
            this.$store.commit("stop_stream")
          });
          element.addEventListener('canplay', this.videoCanPlayListener);
          element.addEventListener('playing', callbackFn);

          // if canplay was already fired, we won't know when to play, so just give it a try
          this.tryPlayVideo(element);
        },

        
        async tryPlayVideo(videoElement) {
          /**
           * Just tries to play the video and logs any errors.
           * The play call is only made is the video is not already playing.
           */

          if (this.isVideoPlaying(videoElement)) {
            console.warn('Trying to play video that is already playing.');
            return;
          }

          try {
            await videoElement.play();
          } catch {
            console.warn('It was not possible to play the video.');
          }
        },

        isVideoPlaying(video) {
          /**
           * Checks if the given video element is currently playing.
           */
          return video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2;
        }
    }
};
</script>

<style scoped>
.scanner-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    height: 100vh;
    width: 100vw;
}
.overlay{
  position:fixed;
  left: 0;
  top:0;
  width:100%;
  height:100%;
  background: var(--view-finder-background-color);
  z-index: 1;
  clip-path: polygon(
    0% 0%, 0% 100%, calc(50% - var(--view-finder-width)) 100%, calc(50% - var(--view-finder-width)) calc(50% - var(--view-finder-height)), calc(50% + var(--view-finder-width)) calc(50% - var(--view-finder-height)), calc(50% + var(--view-finder-width)) calc(50% + var(--view-finder-height)), calc(50% - var(--view-finder-width)) calc(50% + var(--view-finder-height)), calc(50% - var(--view-finder-width)) 100%, 100% 100%, 100% 0%);
}

.view-finder {
  position: fixed;
  z-index: 2;
  background-color: var(--background-color-border)
}

.ver {
  width: 5px;
  height: 40px;
  z-index: 3;
}

.hor {
  width: 40px;
  height: 5px;
  z-index: 3;
}

.top-left.ver {
  left: calc(50% - var(--view-finder-width));
  top: calc(50% - var(--view-finder-height));
}

.top-left.hor {
  left: calc(50% - var(--view-finder-width));
  top: calc(50% - var(--view-finder-height));
}

.top-right.ver {
  left: calc(50% + var(--view-finder-width) - 5px);
  top: calc(50% - var(--view-finder-height));
}

.top-right.hor {
  left: calc(50% + var(--view-finder-width) - 40px);
  top: calc(50% - var(--view-finder-height));
}

.bottom-left.ver {
  left: calc(50% + var(--view-finder-width) - 5px);
  top: calc(50% + var(--view-finder-height) - 40px);
}

.bottom-left.hor {
  left: calc(50% + var(--view-finder-width) - 40px);
  top: calc(50% + var(--view-finder-height) - 5px);
}

.bottom-right.ver {
  left: calc(50% - var(--view-finder-width));
  top: calc(50% + var(--view-finder-height) - 40px);
}

.bottom-right.hor {
  left: calc(50% - var(--view-finder-width));
  top: calc(50% + var(--view-finder-height) - 5px);
}
</style>