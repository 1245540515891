<template>
  <div class="oib-title">
    <div class="title">
      <h1 style="margin-bottom: -20px"> {{ title }} </h1>
      <p style="color: var(--contrast-color-p4)"> {{ subtitle }} </p>
    </div>
    <div class="content" :style="content_style">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "OibTitle",
  props: ["title", "subtitle", "no_overflow"],
  computed: {
    content_style() {
      if (this.no_overflow) return "overflow: hidden";
      return "";
    }
  }
}
</script>


<style scoped>
.oib-title {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
.title {
  padding: 50px;
}
.content {
  height: 100%;
}
</style>