<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Biebassistent toevoegen" @submit="submit">
    <div>
      <oib-input-text
      label="Voornaam"
      placeholder="Jan"
      style="margin-bottom: 20px;"
      :required="true"
      :error_msg="null"
      @input="set_nick_name($event.target.value)"
      />
      <oib-input-text
      label="Achternaam"
      placeholder="Janssen"
      :required="true"
      :error_msg="duplicate_warning"
      @input="set_last_name($event.target.value)"
      />
    </div>  
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputText from "../components/oib_form/oib_input_text.vue"

import {post_organisations_users} from "../store/api/user.js"

export default {
  name: "BiebhulpToevoegen",
  components: {
    OibForm,
    OibInputText
  },
  data() {
    return {
      form_step_validation_list: [false],
      nick_name: "",
      last_name: "",
      duplicate_warning: ""
    }
  },
  methods: {
    set_nick_name(value) {
      this.nick_name = value
      this.val_step_1()
    },
    set_last_name(value) {
      this.last_name = value
      this.val_step_1()
    },
    async val_step_1() {
      // Set validation
      this.form_step_validation_list[0] = this.nick_name != "" && this.last_name != ""
    },
    async submit() {

      // Split last_name
      const last_name_parts = this.last_name.split(' ')
      const insertion_name_slice = last_name_parts.slice(0, -1)
      const insertion_name = insertion_name_slice.length > 0 ? insertion_name_slice.join(' ') : null
      const last_name = last_name_parts[last_name_parts.length - 1]
      
      // Add user to organisation
      const response = await post_organisations_users(
        this.$store.getters.get_jwt, 
        this.$store.getters.get_organisation_id_active,
        ['LIBRARY_ASSISTENT'],
        this.nick_name,
        this.nick_name,
        insertion_name,
        last_name,
        null,
        null,
        null
      )

      if (response.status == 409) {
        this.duplicate_warning = this.nick_name + ' ' + this.last_name + ' is al toegevoegd.'
      } else {
        // Return to Account beheer
        await this.$store.dispatch("jwt_refresh_resources")
        this.$router.go(-2)
      }
    }
  }
};
</script>
