<template>
  <div
    :class="'fade oib-book-header ' + $mq"
    :style="`${opacity}; background-image: url(${cover_url_loaded})`"
  >
    <img
    :src="cover_url"
    style="opacity: 0; height: 0; width: 0; margin: 0; padding: 0"
    :onload="onload"
    />
    <div />
  </div>
</template>

<script>
import {get_book_cover} from "../store/api/library.js"

export default {
  name: "OibBookHeader",
  props: ["isbn13"],
  data() {
    return {
      cover_url: "",
      cover_url_loaded: "",
      opacity: "opacity: 0"
    }
  },
  mounted() {
    this.opacity = "opacity: 0"
    this.cover_url_loaded = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjYGBg+A8AAQQBAHAgZQsAAAAASUVORK5CYII="
    this.cover_url = get_book_cover(this.isbn13, 'l')
  },
  updated() {
    this.cover_url = get_book_cover(this.isbn13, 'l')
  },
  methods: {
    onload() {
      this.opacity = "opacity: 1"
      this.cover_url_loaded = this.cover_url
    }
  }
};
</script>

<style scoped>
.oib-book-header {
  position: fixed;
  background-image: var(--background-image);
  height: 80vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  z-index: -1;
  margin-top: -28vh;
}
.oib-book-header div {
  background: rgb(27, 23, 37);
  background: linear-gradient(
    0deg,
    rgba(27, 23, 37, 1) 0%,
    rgba(27, 23, 37, 1) 63%,
    rgba(9, 9, 121, 0) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  background-size: 100% 110vh;
  background-repeat: no-repeat;
  z-index: 1;
  margin-top: 30vh;
  padding-top: 30vh;
  height: 100%;
}

.context {
  color: var(--contrast-color-sub);
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 2px;
  margin: 0;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
</style>