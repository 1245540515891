<template>
  <oib-page>
    <oib-nav-student title="Bieb" :allowLogout="true" :show_back="false">
      <template v-slot:header><slot name="header">
        <oib-header-search
        :init_search_query="search_query"
        :transparent="$mq == 'mobile'"
        placeholder="Boeken zoeken"
        @click_searchbar="go_to_route('BibliotheekZoeken')"
        >
          <template v-slot:left>
            <oib-button-logout v-if="is_student"/>
            <oib-button-icon v-if="!is_student" icon="settings" @click="go_to_route('MenuBibliotheek')" style="font-size: 24px;"/>
          </template>
          <template v-slot:search_collection>
            <oib-library-picker-button-inline/>
          </template>
          <template v-slot:on_empty_search>
            <oib-button-book-scan style="padding-right: 20px;" />
          </template>
        </oib-header-search>
      </slot></template>
    </oib-nav-student>

    <oib-content padding_top='padding_top'>
      <oib-tab-bibliotheek tab_active="lezen" />
      <div class="wrapper column">
        <oib-hint
        v-if="show_request_library_permissions_hint"
        cookie_id="request_library_permissions"
        style="max-width: 770px;"
        title="Wil je boeken toevoegen aan de bieb?"
        >
          Vraag de beheerder op school voor biebbeheerder of -assistent rechten.
        </oib-hint>
        <!-- <oib-hint
        cookie_id="persoonlijke_voorleestips"
        style="max-width: 770px;"
        title="Persoonlijke voorleestips"
        >
          Binnenkort vind je in dit scherm voorleestips en informatieve video's van leesconsulenten voor jouw klas.
        </oib-hint> -->
        <oib-hint 
        v-if="is_adult && $store.getters.get_library_selector_list.length > 0"
        cookie_id="zoek_een_boek"
        style="max-width: 770px;"
        title="Hoe zoek je een boek?"
        href="https://leesapp.onderwijsinbeeld.nl/functies/boeken-zoeken"
        >
          Bekijk de instructievideo
        </oib-hint>
      </div>

      <!-- <div class="wrapper column" v-if="can_track_books && $store.getters.get_books_finished_list.length + $store.getters.get_books_reading_list.length == 0" style="margin-bottom: 30px">
        <div class="column" style="background-color: rgba(255,255,255, 0.03); padding: 20px 10px; max-width: 1000px; border-radius: 2px; justify-content: space-between; flex-wrap: wrap;">
          <h4 style="font-size: 0.9em; margin: 0 0 0 0; padding-right: 10px;">Hoe leen ikzelf een boek?</h4>
          <p style="font-size: 0.8em; margin-top: 10px; margin-bottom: 0">Zoek of scan een boek. Klik op "bieb kiezen" en leen het boek uit een bieb. Bekijk <a href="" target="_blank">de handleiding</a>.</p>
        </div>
      </div> -->
<!-- 
      <div v-if="tile_list_personal_recommendations.length > 0 && $mq == 'mobile'">
        <div class="wrapper column" style="align-items: center;">
          <book-tile-highlight
          :user_id="user_id"
          :isbn13="tile_list_personal_recommendations[0].isbn13"
          :book_edition_id="tile_list_personal_recommendations[0].book_edition_id"
          :book_title="tile_list_personal_recommendations[0].book_title"
          :color="tile_list_personal_recommendations[0].color"
          />
        </div>
      </div> -->

      <div>

        <!-- my books -->
        <div v-if="can_track_books">
          <div class="wrapper column">
            <div class="row section-header">
              <h2 style="margin-bottom: 0;">Aan het lezen</h2>
            </div>
          </div>
          <oib-book-swiper
          :user_id="user_id"
          :tile_list="$store.getters.get_books_reading_list"
          :show_availability="false"
          style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px"
          >
            <template v-slot:left>
              <div
              class="column"
              style="justify-content: center; align-items: center; width: 146px; height: 184px; border-radius: 4px; border: 2px solid var(--primary-color-p4); margin-right: 20px;"
              @click="go_to_route('BoekScan', {'user_id': user_id, 'fast_borrow': true})"
              >
                <p class="material-icons-outlined" style="font-size: 48px; margin-bottom: 0;">add</p>
                <p style="text-align: center;">Nieuw boek kiezen</p>
              </div>
            </template>
          </oib-book-swiper>
        </div>

        <div v-if="tile_list_personal_recommendations.length > 0">
          <div class="wrapper column">
            <div class="row section-header">
              <h2 style="margin-bottom: 0;">Voor jou</h2>
              <p @click="go_to_search_filter({'sort': 'Meest relevant', 'user_id_recommendation': user_id})">Bekijk alles ➜</p>
            </div>
          </div>
          <oib-book-swiper
          :user_id="user_id"
          :tile_list="tile_list_personal_recommendations"
          :show_availability="false"
          :marker_width="80"
          style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px"
          />
        </div>
      </div>

      <div v-if="can_track_books && $store.getters.get_books_finished_list.length > 0">
        <div class="wrapper column">
          <div class="row section-header">
            <h2 style="margin-bottom: 0;">Gelezen boeken</h2>
            <p @click="go_to_search_filter({'sort': 'Datum uitgelezen', 'user_id': user_id, 'progress_state': 2})">Bekijk alles ➜</p>
          </div>
        </div>
        <oib-book-swiper
        :user_id="user_id"
        :tile_list="$store.getters.get_books_finished_list"
        :show_availability="false"
        :marker_width="65"
        style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px"
        />
      </div>

      <div v-if="can_track_books" style="margin-bottom: 30px;">
        <div class="wrapper column">
          <div class="row section-header">
            <h2 style="margin-bottom: 16px">In het nieuws</h2>
          </div>
        </div>
        <oib-swiper
        v-if="$store.getters.get_tag_list_highlight.length > 0"
        style="padding-left: 5%; margin-bottom: 0;"
        >
          <div class="row">
            <oib-tag-tile
            v-for="obj in $store.getters.get_tag_list_highlight"
            :key="obj.tag_id"
            :tag_id="obj.tag_id"
            :tag_name="obj.tag_name"
            :tag_name_alias="obj.tag_name_alias"
            :color="obj.color"
            style="margin-right: 20px;"
            />
          </div>
        </oib-swiper>
      </div>

      <div v-if="can_track_books && $store.getters.get_books_favorite_list.length > 0">
        <div class="wrapper column">
          <div class="row section-header">
            <h2 style="margin-bottom: 0;">Je favoriete boeken</h2>
            <p @click="go_to_search_filter({'sort': 'Meest relevant', 'user_id': user_id, 'is_favorite': 1})">Bekijk alles ➜</p>
          </div>
        </div>
        <oib-book-swiper
        :user_id="user_id"
        :tile_list="$store.getters.get_books_favorite_list"
        :show_availability="false"
        style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px"
        />
      </div>

      <div v-if="Object.keys(tile_list_most_popular_classroom).length > 0">
        <div v-for="classroom in classroom_set" :key="classroom.classroom_id">
          <div v-if="tile_list_most_popular_classroom[classroom.classroom_id] && tile_list_most_popular_classroom[classroom.classroom_id].length > 0">
            <div class="wrapper">
              <div class="row section-header">
                <h2 class="h2">Klassentoppers {{ classroom.classroom_name }}</h2>
                <p @click="go_to_search_filter({'sort': 'Meest gelezen', 'classroom_id': classroom.classroom_id})">Bekijk alles ➜</p>
              </div>
            </div>
            <oib-book-swiper
            :user_id="user_id"
            :tile_list="tile_list_most_popular_classroom[classroom.classroom_id]"
            :show_view_more="false"
            style="padding-left: 5%; margin-top: 10px; margin-bottom: 40px"
            />
          </div>
        </div>
      </div>

      <!--- recommendations -->
      <div v-if="tile_list_author_recommendations.length > 0">
        <div class="wrapper column">
          <h2 class="h2" style="margin-bottom: 0px;">Meer van {{author_name}}</h2>
          <p>Omdat je {{book_title}} leest.</p>
        </div>
        <oib-book-swiper
        :user_id="user_id"
        :tile_list="tile_list_author_recommendations"
        :show_availability="true"
        style="padding-left: 5%"
        />
      </div>

      <div v-if="tile_list_series_recommendations.length > 0">
        <div class="wrapper column">
          <h2 class="h2" style="margin-bottom: 0px;">Meer van {{series_title}}</h2>
          <p>Omdat je {{book_title}} leest.</p>
        </div>
        <oib-book-swiper
        :user_id="user_id"
        :tile_list="tile_list_series_recommendations"
        :show_availability="true"
        style="padding-left: 5%"
        />
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue";
import OibBookSwiper from "../components/OibBookSwiper.vue";
import OibButtonIcon from '../components/OibButtonIcon.vue'
import OibButtonBookScan from '../components/oib_button_book_scan.vue'
import OibButtonLogout from "../components/OibButtonLogout.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibLibraryPickerButtonInline from '../components/oib_library_picker_button_inline.vue'
import OibTabBibliotheek from '../components/oib_tab_bibliotheek.vue'
import OibHint from '../components/oib_hint.vue'
import OibSwiper from '../components/oib_swiper.vue'
import OibTagTile from '../components/oib_tag_tile.vue'

import { parse_query } from '../store/request'
import { get_books } from "../store/api/library"
import {
  load_book_recommendations_author,
  load_book_recommendations_series,
  init_library_selectors,
  load_tag_highlights
} from "../store/library"

import { load_user_recommendations } from "../store/book_recommendation"

import { has_role } from "../store/utils";
import { go_to_route } from "../store/navigation";

export default {
  name: "BookProgress",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibBookSwiper,
    OibButtonIcon,
    OibButtonLogout,
    OibButtonBookScan,
    OibHeaderSearch,
    OibLibraryPickerButtonInline,
    OibTabBibliotheek,
    OibHint,
    OibSwiper,
    OibTagTile
  },
  computed: {
    show_request_library_permissions_hint() {
      return has_role(['TEACHER']) && !has_role(['LIBRARIAN', 'LIBRARY_ASSISTENT'])
    },
    active_library_selector() {
      return this.$store.getters.get_active_library_selector
    },
    active_book_edition_id() {
        const book = this.$store.getters.getBook(this.$store.getters.get_isbn13_active)
        return book ? book['book_edition_id'] : null
    },
    can_track_books() {
      return has_role(['STUDENT', 'TEACHER', 'LIBRARIAN', 'LIBRARY_ASSISTENT'])
    },
    is_adult() {
      return has_role(['TEACHER', 'LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])
    },
    can_manage_library() {
      return has_role(['LIBRARY_ASSISTENT', 'LIBRARIAN', 'SUPERVISOR', 'LIBRARY_PARTNER', 'SYSTEM_ADMIN'])
    },
    is_student_or_teacher() {
      return has_role(['STUDENT', 'TEACHER'])
    },
    is_student() {
      return has_role(['STUDENT'])
    },
    selector_name() {
      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    },
    tile_list_last_added() {
      const book_dict = this.$store.getters.get_books_last_added_dict
      if (this.active_library_selector in book_dict) {
        return book_dict[this.active_library_selector]
      }
      return []
    },
    tile_list_most_popular() {
      const book_dict = this.$store.getters.get_books_most_popular_library_dict
      if (this.active_library_selector in book_dict) {
        return book_dict[this.active_library_selector]
      }
      return []
    },
    tile_list_most_popular_classroom() {
      const book_dict = this.$store.getters.get_books_most_popular_library_classroom_dict
      if (this.active_library_selector in book_dict) {
        return book_dict[this.active_library_selector]
      }
      return []
    },
    total_book_count() {
      const book_count_dict = this.$store.getters.get_book_count_library_dict
      if (this.active_library_selector in book_count_dict) {
        return book_count_dict[this.active_library_selector]
      } else {
        return -1
      }
    },
    book_release_year_data() {
      const release_year_dict = this.$store.getters.get_book_release_year_library_dict
      if (this.active_library_selector in release_year_dict) {
        return release_year_dict[this.active_library_selector]
      }
      return []
    },
    book_type_data() {
      const book_type_dict = this.$store.getters.get_book_type_library_dict
      if (this.active_library_selector in book_type_dict) {
        return book_type_dict[this.active_library_selector]
      }
      return []
    },
    book_age_data() {
      const age_dict = this.$store.getters.get_book_age_library_dict
      if (this.active_library_selector in age_dict) {
        return age_dict[this.active_library_selector]
      }
      return []
    },
    book_color_style () {
      if (this.tile_list_personal_recommendations.length == 0) {
        return ""
      }
      const book_color = this.tile_list_personal_recommendations[0].color
      const color = book_color ? `#${book_color}` : 'var(--highlight-color)';
      return {
        '--book-color': color
      }
    }
  },
  data() {
    return {
      search_query: "",
      tile_list_author_recommendations: [],
      tile_list_series_recommendations: [],
      tile_list_personal_recommendations: [],      
      book_title: "",
      author_name: "",
      series_title: "",
      user_id: null,
      student_count: 0,
      classroom_set: null,
      classroom_name: null,
      book_limit: 8,
      book_investment_data: [
        {'label': 'Minimaal', 'count': 3},
        {'label': 'Voldoende', 'count': 5},
        {'label': 'Optimaal', 'count': 7}
      ],
      chart: {
        type: 'bar'
      }
    }
  },
  async created() {
    // Skip if logged out
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    this.user_id = user['user_id']
    this.student_count = this.$store.getters.get_school.student_count

    init_library_selectors()
    const library_selector_list = this.$store.getters.get_library_selector_list

    if (library_selector_list.length > 1) {
      if (this.is_student_or_teacher) {
        this.load_most_popular_classroom()
      }
    }

    if (this.can_track_books) {
      this.load_books_reading()
      this.load_books_finished()
      this.load_recommendations()
      this.load_books_favorite(),
      load_tag_highlights()
    }
  },
  async mounted() {

    document.documentElement.scrollTop = 0

    // Skip if logged out
    const user = this.$store.getters.getUser
    if (!user) {
      return
    }

    this.$store.commit("set_search_query", '')
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  watch: {
    async active_library_selector() {

      // Return if user is unknown
      const user = this.$store.getters.getUser
      if (!user) {
        return
      }

      if (this.is_student_or_teacher) {
        this.load_most_popular_classroom()
      }

      if (this.can_track_books) {
        this.load_books_reading()
        this.load_books_finished()
        this.load_recommendations()
        this.load_books_favorite()
      }
    }
  },
  methods: {
    // import
    has_role,
    go_to_route,

    // local
    go_to_search_filter(query) {
      // Parse query
      let query_parsed = parse_query(query)
      if (query_parsed == '') {
        query_parsed = null
      }

      this.$router.push({
        name: "BibliotheekZoekenFilter",
        params: {
          query: query_parsed
        }
      });
    },
    async load_books_favorite() {
      if (this.can_track_books) {
        const books = await this.get_books_favorite()
        let tile_list_favorite = []
        books.forEach(book => tile_list_favorite.push({
          'isbn13': book.isbn13,
          'book_edition_id': book.book_edition_id,
          'book_title': book.book_title,
          'sub_text': book.author_name,
        }))

        this.$store.commit("set_books_favorite", tile_list_favorite)
      }
    },
    async get_books_favorite() {
      const jwt = this.$store.getters.getJwt
      const json = await get_books(jwt, 'products', {
        'user_id': this.user_id,
        'select': 'isbn13,book_edition_id,book_title,author_name',
        'is_favorite': 1,
        'limit': 100,
      })
      return json['books']
    },
    async load_books_reading() {
      if (this.can_track_books) {
        const books = await this.get_books_reading()
        let tile_list_reading = []
        books.forEach(book => tile_list_reading.push({
          'isbn13': book.isbn13,
          'book_edition_id': book.book_edition_id,
          'book_title': book.book_title,
          'sub_text': book.author_name,
        }))

        this.$store.commit("set_books_reading", tile_list_reading)
      }
    },
    async get_books_reading() {
      const jwt = this.$store.getters.getJwt
      const json = await get_books(jwt, 'products', {
        'user_id': this.user_id,
        'select': 'isbn13,book_edition_id,book_title,author_name,progress_datetime,borrowed_datetime,library_id',
        'has_action': 1,
        'limit': 100,
      })
      return json['books']
    },
    async load_books_finished() {
      const books = await this.get_books_finished()
      let tile_list_finished = []
      
      books.forEach(book => tile_list_finished.push({
        'isbn13': book.isbn13,
        'book_edition_id': book.book_edition_id,
        'book_title': book.book_title,
        'sub_text': book.progress_datetime,
        'marker': book.progress_state == 3 ? 'Gestopt' : null
      }))

      this.$store.commit("set_books_finished", tile_list_finished)
    },
    async get_books_finished() {
      const jwt = this.$store.getters.getJwt
      const json = await get_books(jwt, 'products', {
        'user_id': this.user_id,
        'progress_state': '2,3',
        'select': 'isbn13,book_edition_id,book_title,author_id,author_name,series_id,series_title,release_number,progress_datetime,progress_state',
        'limit': 8,
        'sort': 'progress_datetime,DESC'
      })
      return json['books']
    },
    async load_recommendations() {

      // Do not load recommendations if no access to library
      const library_selector_list = this.$store.getters.get_library_selector_list
      const library_id_group = library_selector_list[this.active_library_selector]
      if (!library_id_group) {
        return
      }

      this.load_personal_recommendations()

      const isbn13_set_reading = this.$store.getters.get_progress_state_dict[this.user_id][1]
      
      // Do not load recommendations if not reading any book
      if (isbn13_set_reading.size == 0) {
        return
      }


      const isbn13 = [...isbn13_set_reading][0]
      const book = this.$store.getters.getBook(isbn13)
      if (!book) {
        return
      }
      this.book_title = book.book_title
      const author_id = book.author_id
      const series_id = book.series_id

      if (author_id) {
        this.tile_list_author_recommendations = await load_book_recommendations_author(author_id)
        this.author_name = book.author_name
      }

      if (series_id) {
        this.tile_list_series_recommendations = await load_book_recommendations_series(series_id)
        this.series_title = book.series_title
      }      
    },
    async load_personal_recommendations() {
      this.tile_list_personal_recommendations = await load_user_recommendations(this.user_id)
    },
    async load_most_popular_classroom() {
      // Get classroom set
      const classroom_dict = this.$store.getters.get_classroom_dict
      const group_dict = this.$store.getters.get_group_dict
      const group_user_dict = this.$store.getters.get_group_user_dict
      const group_id_list = group_user_dict[this.user_id]

      // Skip if user is not part of a group
      if (!group_id_list || group_id_list.length == 0) {
        return
      }

      let classroom_set = new Set()
      group_id_list.forEach(group_id => classroom_set.add(classroom_dict[group_dict[group_id].classroom_id]))
      this.classroom_set = classroom_set

      const library_selector_list = this.$store.getters.get_library_selector_list
      const library_id_group = library_selector_list[this.active_library_selector]

      for (let classroom of this.classroom_set) {
        const jwt = this.$store.getters.get_jwt
        const query = {
          'select': 'library_id,isbn13,book_edition_id,book_title,author_name,copies_in_use,copies_total,classroom_read_count',
          'library_id': library_id_group.query['library_id'],
          'classroom_id': classroom.classroom_id,
          'sort': 'classroom_read_count,DESC',
          'limit': this.book_limit
        }

        let tile_list = []
        const json = await get_books(jwt, 'editions', query)
        json['books'].forEach( x => {
          if (x['classroom_read_count'] > 0) {
            tile_list.push({
              'isbn13': x['isbn13'],
              'book_edition_id': x['book_edition_id'],
              'book_title': x['book_title'],
              'sub_text': x['author_name'],
              'marker': x['classroom_read_count'] + 'x',
              'copies_in_use': x['copies_in_use'],
              'copies_total': x['copies_total']
            })
          }          
        })

        this.$store.commit("set_books_most_popular_library_classroom", {
          library_id: this.active_library_selector,
          classroom_id: classroom.classroom_id,
          books: tile_list
        })
      }     
    }
  }
};
</script>

<style scoped>

.row {
  display: flex;
  flex-direction: row;
}

.section-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1342px;
}

.section-header h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.section-header p {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 0.7em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section-header p:hover {
  cursor: pointer;
}

.reset-margin {
  margin-left: 50px;
}

p {
  margin: 0;
  margin-bottom: 20px;
  font-size: 0.8em;
  color: #acaeb0;
}

.subtext {
  line-height: 0;
  font-size: 0.6em;
}

.styled-table {
    border-collapse: collapse;
    margin: 0 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    display: block;
    overflow-x: auto;
    margin-right: 40px;
}

.styled-table thead tr {
    background-color: var(--highlight-color);
    color: #ffffff;
    text-align: left;
}

.styled-table th,
.styled-table td {
    padding: 12px 15px;
}

.styled-table th {
  text-align: left;
}

.styled-table tbody tr {
    border-bottom: 1px solid #21222f;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #21222f;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--highlight-color);
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: var(--highlight-color);
}

.styled-table tr td:nth-child(2) { width:100% }


.tile-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 0px;
  max-width: 1200px;
}

.tile-grid div{
  width: 100%;
  height: 130px;
}

@media screen and (min-width:740px) {
  .tile-grid div{
    width: 300px;
    height: 150px;
  }
}

@media screen and (min-width:940px) {
  .tile-grid div{
    width: 350px;
    height: 130px;
  }
}


@media screen and (min-width:1250px) {
  .tile-grid div{
    width: 275px;
    height: 200px;
  }
}

@media screen and (min-width:1450px) {
  .tile-grid div{
    width: 350px;
    height: 180px;
  }
}

.book-background {
  /* padding-top: 40px; */
  background: rgb(27,23,37);
  background: linear-gradient(0deg, rgba(27,23,37,1) 0%, var(--book-color) 100%);
  background-size: 100% 950px;
  background-repeat: no-repeat;
}

.book-background-drop-shadow {
  margin-right: 5%;
  width: 35%;
  height: auto;
  border-radius: 10px;
  box-shadow: 15px 14px 31px -13px var(--book-color);
  -webkit-box-shadow: 15px 14px 31px -13px var(--book-color);
  -moz-box-shadow: 15px 14px 31px -13px var(--book-color);
}
</style>