<template>
  <div class="customBtn" id="googleSignInButton">
    <span class="icon"><img src="../assets/icon_google.svg" alt="Google logo"/></span>
    <span class="buttonText">{{ text }}</span>
  </div>
</template>

<script>

export default {
  name: "OibButtonGoogle",
  props: ["text"]
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap");

.row {
  display: flex;
  flex-direction: row;
}

.customBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 250px;

  background: white;
  color: #444;
  border-radius: 5px;
  white-space: nowrap;
}
.customBtn:hover {
  cursor: pointer;
}
span.label {
  font-family: serif;
  font-weight: normal;
}
span.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 42px;
}
span.icon img {
  width: 24px;
  height: 24px;
}
span.buttonText {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
</style>
