<template>
  <oib-button-google text="Log in met Google" id="googleSignInButton" />
</template>

<script>
import {get_cookie} from "../cookie.js"
import OibButtonGoogle from "./oib_button_google.vue"

export default {
  name: "Google",
  components: {
    OibButtonGoogle
  },
  mounted() {

    const self = this;

    // Add window error catcherz
    window.addEventListener("error", self.get_cookie_disabled_error)

    // Get client id depending on environment
    const url = new URL(window.location.href);
    const uri = `${url.hostname}`;
    const valid_uri_list = [
      'app.onderwijsinbeeld.nl',
      'www.app.onderwijsinbeeld.nl',
      'test.app.onderwijsinbeeld.nl',
      'www.test.app.onderwijsinbeeld.nl'
    ]
    const client_id_production = "352829114878-njektihjgerj7v6encae74l4kovtbb31.apps.googleusercontent.com"
    const client_id_localhost = "928184858003-aadaci4epppvivfm2al9oi5jadbsml7h.apps.googleusercontent.com"
    const client_id = valid_uri_list.includes(uri) ? client_id_production : client_id_localhost
    const redirect_uri = valid_uri_list.includes(uri) ? `https://${uri}/login/google` : `http://localhost/login/google`

    window.gapi.load("auth2", function () {

      const account_type = get_cookie('ACCOUNT_TYPE')
      const account_email = get_cookie('ACCOUNT_EMAIL')
      if (account_type == 'GOOGLE' && account_email) {
        // Sign in with previously selected GOOGLE ACCOUNT EMAIL
        window.gapi.auth2.init({
          client_id: client_id,
          cookiepolicy: "single_host_origin",
          ux_mode: 'redirect',
          redirect_uri: redirect_uri,
          login_hint: account_email
        }).then(auth2 => {
          auth2.signIn()
          auth2.attachClickHandler(document.getElementById("googleSignInButton"));
        })
      } else {
        // No Google account hint found, so we merely attach the clickhandler
        window.gapi.auth2.init({
          client_id: client_id,
          cookiepolicy: "single_host_origin",
          ux_mode: 'redirect',
          redirect_uri: redirect_uri,
        }).then(auth2 => {
          auth2.attachClickHandler(document.getElementById("googleSignInButton"));
        })
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener("error", this.get_cookie_disabled_error);
  },
  methods: {
    get_cookie_disabled_error(e) {
      if (e.message == "Script error.") {
        this.$store.commit("set_login_error_message", "Google login mislukt, want cookies zijn uitgeschakeld.");
        this.$store.commit("set_login_error_instructions", "");
      }
      return false;
    }
  },
};
</script>
