<template>
  <div>
    <book-search-filter
    v-if="show_filter"
    @submit="query => go_to_search(query)"
    @hide="show_filter = false"
    />
    <oib-page v-if="!show_filter">
      <oib-nav-student title="Zoeken" filling="">
        <template v-slot:header><slot name="header">
          <oib-header-search
          v-if="select_set_copy_id.size == 0"
          :init_search_query="search_query"
          :init_focus="show_keyboard"
          :search_frequency_ms="0"
          placeholder="Boeken zoeken"
          @search="query => set_search_query(query)"
          >
            <template v-slot:search_collection>
              <oib-library-picker-button-inline v-if="filter_library"/>  
            </template>
            <template v-slot:on_empty_search>
              <oib-button-book-scan style="padding-right: 20px;" :style="$mq == 'desktop'? 'color: var(--primary-color)': 'color: var(--contrast-color)'" />
            </template>
          </oib-header-search>
        </slot></template>
      </oib-nav-student>
      <oib-content padding_top='padding_top'>
        <oib-swiper
        class="fade"
        style="padding-left: 5%"
        :style="`${$mq == 'desktop' ? 'margin-top: -45px; margin-bottom: 5px;' : ''} ${select_set_copy_id.size == 0 ? 'opacity: 1' : 'opacity: 0.1'}`"
        @click="show_filter = true"
        >
          <div style="display: flex; flex-direction: row">
            <span class="material-icons-outlined filter-icon">tune</span>
            <oib-button-label
              class="clickable"
              :active="false"
              style="margin-right: 10px;"
              >
              <span>Meest relevant</span>
            </oib-button-label>
            <oib-button-label
              class="clickable"
              :active="age_range_text"
              style="margin-right: 10px;"
              >
              <span>{{age_range_text ? age_range_text : 'Leeftijd'}}</span>
            </oib-button-label>
            <oib-button-label
              class="clickable"
              :active="release_year_range[0] != min_year || release_year_range[1] != max_year"
              style="margin-right: 10px;"
              >
              <span>{{release_year_range[0] != min_year || release_year_range[1] != max_year ? release_year_range_text : 'Datum uitgifte'}}</span>
            </oib-button-label>
            <oib-button-label
              class="clickable"
              :active="book_type_index_set.size > 0"
              style="margin-right: 40px;"
              >
              <span v-if="book_type_index_set.size ==  0">Boek type</span>
              <span v-if="book_type_index_set.size ==  1">{{book_type_list[[...book_type_index_set][0]]}}</span>
              <span v-if="book_type_index_set.size >  1">Boek type ({{book_type_index_set.size}})</span>
            </oib-button-label>
          </div>
        </oib-swiper>
        <div
          class="wrapper row"
          style="align-items: center; margin-top: 0px; margin-bottom: 20px;"
          >
        </div>
        <div v-if="search_query && $store.getters.get_search_result" style="width: 100%; max-width: 1500px">
          <h2
          v-show="$store.getters.get_search_result.tag_list && $store.getters.get_search_result.tag_list.length > 0"
          class="wrapper column"
          style="margin-bottom: 10px;"
          >
            Thema's
          </h2>
          <oib-swiper
          v-if="$store.getters.get_search_result.tag_list && $store.getters.get_search_result.tag_list.length > 0"
          style="padding-left: 5%; margin-bottom: 0;"
          >
          <div class="row">
              <oib-tag-tile
              v-for="obj in $store.getters.get_search_result.tag_list"
              :key="obj.tag_id"
              :tag_id="obj.tag_id"
              :tag_name="obj.tag_name"
              :color="obj.color"
              style="margin-right: 20px;"
              />
          </div>
          </oib-swiper>
          <div
          v-show="$store.getters.get_search_result.series_list && $store.getters.get_search_result.series_list.length > 0"
          class="wrapper column"
          >
            <h2 style="margin-bottom: 10px">Series</h2>
          </div>
          <oib-swiper
          v-if="$store.getters.get_search_result.series_list && $store.getters.get_search_result.series_list.length > 0"
          style="padding-left: 5%; margin-bottom: 0"
          >
            <div class="row">
              <oib-tile-series
              v-for="obj in $store.getters.get_search_result.series_list"
              :key="obj.series_id"
              :name="obj.series_title"
              :series_id="obj.series_id"
              :series_title="obj.series_title"              
              :color="obj.color"
              :isbn13_cover_list="obj.isbn13_cover_list.split(',')"
              img="/assets/svg/magie.svg"
              style="margin-right: 20px;"
              />
            </div>
          </oib-swiper>
          <div v-show="$store.getters.get_search_result.book_list && $store.getters.get_search_result.book_list.length > 0"
            class="wrapper column"
          >
            <h2 style="margin-bottom: 10px">Boeken</h2>
            <oib-book-grid-static
            :item_list="$store.getters.get_search_result.book_list"
            />
          </div>
        </div>
        <div v-if="!search_query" class="wrapper column">
          <!-- <div class="row" style="justify-content: space-between; flex-wrap: wrap; max-width: 1500px">
            <div
              class="row tag-tile"
              style="width: 130px; height: 80px; margin-bottom: 20px; border-radius: 4px; padding: 10px; -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); margin-right: 1vw"
              :style="`background-color: gray`"
              @click="go_to_search({'sort': 'Meest gelezen'})"
              >
                <h4 style="margin: 0; min-width: 70px; max-width: 70px">Bekijk alles</h4>
                <img :src="stack_of_books_src" alt="" class="drop-shadow" style="width: 60px; height: 60px; transform: translate(20px, 20px) rotate(25deg); background-color: white; border-radius: 2px; padding: 5px;" />
            </div>
            <oib-tag-tile
              v-for="tile in tag_tile_dict"
              :key="tile.name"
              :color="tile.color"
              :name="tile.name"
              :tag_name="tile.tag_name"
              :img="tile.img"
            />
          </div> -->

          <div style="max-width: 1400px;">
            <oib-grid-static-tag
              :item_list="$store.getters.get_tag_list_quick_search"
            />

            <div class="row section-header">
              <h2 style="margin-bottom: 16px">In het nieuws</h2>
            </div>
            <oib-grid-static-tag
              v-if="$store.getters.get_tag_list_quick_search && $store.getters.get_tag_list_highlight"
              :item_list="$store.getters.get_tag_list_highlight"
            />
          </div>
        </div>
      </oib-content>
    </oib-page>
  </div>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibContent from "../components/OibContent.vue"
import OibHeaderSearch from "../components/oib_header_search.vue"
import OibButtonBookScan from '../components/oib_button_book_scan.vue'
import OibButtonLabel from '../components/OibButtonLabel.vue'
import OibSwiper from '../components/oib_swiper.vue'
import OibLibraryPickerButtonInline from '../components/oib_library_picker_button_inline.vue'
import OibTagTile from '../components/oib_tag_tile.vue'
import OibBookGridStatic from '../components/oib_book_grid_static.vue'
import OibTileSeries from '../components/oib_tile_series.vue'
import BookSearchFilter from '../components/book_search_filter.vue'
import OibGridStaticTag from '../components/oib_grid_static_tag.vue'

import { get_books_search } from "../store/api/book_search";
import { parse_query } from '../store/request'
import { load_tag_highlights } from '../store/library'
import { load_tag_quick_search } from '../store/library'

export default {
  name: "BibliotheekZoeken",
  components: {
    OibPage,
    OibNavStudent,
    OibContent,
    OibHeaderSearch,
    OibButtonBookScan,
    OibSwiper,
    OibButtonLabel,
    OibLibraryPickerButtonInline,
    OibTagTile,
    OibBookGridStatic,
    OibTileSeries,
    BookSearchFilter,
    OibGridStaticTag
  },
  data() {
    return {
      show_keyboard: false,
      show_filter: false,
      filter_library: true,
      stack_of_books_src: require('@/assets/svg/stack_of_books.png'),
      sub_text_key: 'author_name',
      query_obj: {},
      tag_name: null,
      classroom_id: null,
      user_id: null,
      user_id_recommendation: null,
      progress_state: null,
      is_favorite: null,
      age_known: null,
      age_range: [0, 18],
      min_year: 1970,
      max_year: 2023,
      release_year_range: [1970, 2023],
      year_list: [],
      row_count: null,
      column_count: null,
      search_query: '',
      book_tile_width: 160,
      search_limit: 30,
      query_running: false,
      padding: 0,
      grouping: null,
      select_set_copy_id: new Set(),
      book_type_list: [
        'Leesboek',
        'Informatief',
        'Prentenboek',
        'Aanwijsboek',
        'Stripboek',
        'Voorleesboek',
        'Dichtbundel',
        'Zoekboek',
        'Oriëntatie op lezen',
        'Samenleesboek',
        'Vakliteratuur'
      ],
      sort_mode: null,
      book_type_index_set: new Set()
    }
  },
  created() {
    this.show_keyboard = window.history.state.forward == null
    load_tag_quick_search()
    load_tag_highlights()
  },
  async mounted() {

    // Do not run if user is not defined - User did refresh or logout
    if (!this.$store.getters.getUser) {
      return
    }

    this.search_query = this.$store.getters.get_search_query

    // Start search
    // Since some mobile browsers resize on mounted, add timeout to wait for resize event
    // Without this timeout there will be a mismatch between the query_limit and the search_limit
    this.search();
  },
  computed: {
    active_library_selector() {
      return this.$store.getters.get_active_library_selector
    },
    selector_name() {
      if (!this.filter_library) {
        return 'Boeken die nog niet op school staan'
      }

      const selector_list = this.$store.getters.get_library_selector_list
      const selector_active_index = this.$store.getters.get_active_library_selector
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    },
    age_range_text() {
      if (this.age_known == 0) {
        return 'Onbekend'
      }

      if (this.age_range[0] == 0 && this.age_range[1] == 18) {
        return null
      }

      if (this.age_range[0] == this.age_range[1]) {
        return this.age_range[0] + ' jaar'
      } else {
        return this.age_range[0] + ' t/m ' + this.age_range[1] + ' jaar'
      }
    },
    release_year_range_text() {
      if (this.release_year_range[0] == this.release_year_range[1]) {
        return this.release_year_range[0]
      } else {
        return this.release_year_range[0] + ' t/m ' + this.release_year_range[1]
      }
    }
  },
  watch: {
    active_library_selector() {
      this.search()
    },
    age_range() {
      this.age_known = 1
    }
  },
  methods: {
    set_search_query(search_query) {
      this.search_query = search_query
      this.$store.commit("set_search_query", this.search_query)
      this.search()
    },
    async search() {

      // Quit if search_query is empty or other query is already running
      if (!this.search_query || this.query_running) {
        return
      }

      // Set search_query for this run
      const search_query = this.search_query

      let query = {
        'select': 'isbn13,item_id,item_name,item_type,details',
        'search': search_query
      }

      // Apply library filters
      if (this.filter_library && !this.user_id) {
        const library_selector = this.$store.getters.get_active_library_selector
        if (library_selector != null) { 
          if (this.user_id_recommendation) {
            const library_id_access = Array.from(this.$store.getters.get_user_library_dict[this.user_id_recommendation])
            query['library_id'] = library_id_access.join(',')
          } else {
            query['library_id'] = this.$store.getters.get_library_selector_list[library_selector].query['library_id']
          }          

          query['select'] += ',total_count,in_use_count'
        }
      }

      // Reset search if new search uses different parameters
      const query_prev = this.$store.getters.get_search_request
      let query_prev_ignore_pagination = {}
      Object.assign(query_prev_ignore_pagination, query_prev);
      delete query_prev_ignore_pagination['limit']
      delete query_prev_ignore_pagination['page']

      if (JSON.stringify(query) != JSON.stringify(query_prev_ignore_pagination)) {
        this.row_count = 0
        // this.$store.commit("set_search_result", [])
        this.$store.commit("set_search_page", 0)
      }

      // Set this search_query as search_query_prev
      this.$store.commit("set_search_request", query)

      // Add pagination
      query['limit'] = this.search_limit

      // Skip if this query was already executed
      if (JSON.stringify(query_prev) == JSON.stringify(query)) {
        return
      }

      // Run search_request - duplicate request protection is build in
      const jwt = this.$store.getters.get_jwt

      this.query_running = true
      const json = await get_books_search(jwt, query).finally(() => this.query_running = false)

      // Parse result
      let book_list = []
      let series_list = []
      let tag_list = []
      console.log(json)
      json['item_list'].forEach(x => {
        if (x['item_type'] == 'BOOK') {
          book_list.push({
            'isbn13': x['isbn13'],
            'book_edition_id': x['item_id'],
            'item_name': x['item_name'],
            'sub_text': x['details']['author_name'],
            'copies_in_use': x['in_use_count'],
            'copies_total': x['total_count'],
          })
        
        } else if (x['item_type'] == 'SERIES') {
          series_list.push({
            'series_id': x['item_id'],
            'series_title': x['item_name'],
            'color': x['details']['color'],
            'isbn13_cover_list': x['details']['isbn13_cover_list'],
          })
        } else if (x['item_type'] == 'TAG') {
          tag_list.push({
            'tag_id': x['item_id'],
            'tag_name': x['item_name'],
            'color': x['details']['color'] ? '#' + x['details']['color'] : null,
          })
        }
      })

      this.$store.commit("set_search_result", {
        'book_list': book_list,
        'series_list': series_list,
        'tag_list': tag_list
      })

      // Run search request if search_query changes during request
      if (this.search_query != search_query) {
        this.search()
      }
    },
    is_number(string) {
      return /^\d+$/.test(string)
    },
    pick_library() {
      this.$router.push({
        name: 'BibliotheekKiezenGroep',
        params: {
          'user_id': this.user_id,
          'library_route': 'back'
        }
      });
    },
    go_to_search(query) {
      // Parse query
      let query_parsed = parse_query(query)
      if (query_parsed == '') {
        query_parsed = null
      }

      this.$router.push({
        name: "BibliotheekZoekenFilter",
        params: {
          query: query_parsed
        }
      });
    },
    on_select(copy_id) {
      if (this.select_set_copy_id.has(copy_id)) {
        this.select_set_copy_id.delete(copy_id)
      } else {
        this.select_set_copy_id.add(copy_id)
      }
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.submit.mobile {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  padding: 10px 0;
  text-align: center;  
  background-color: var(--primary-color-sub);
}

.submit.mobile:hover {
  cursor: pointer;
}

.submit.mobile button {
  width: 100%;
  max-width: 400px;
  padding: 10px 30px;
}

.sort-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}

.filter-icon {
  margin-right: 20px;
  margin-top: 2px;
}

.sort-section-header {
  justify-content: space-between;
  align-items: center;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}

.tag-tile:hover {
  cursor: pointer;
}
</style>
