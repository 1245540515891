<template>
  <div class="rating">
    <span 
      v-for="star_id in [1, 2, 3, 4, 5]"
      :key="star_id"
      class="clickable"
      :class="rating>=star_id?'material-icons':'material-icons-outlined'"
      style="width: 24px; height: 24px;"> 
      star_rate 
    </span>
  </div>
</template>

<script>
export default {
  name: 'OibRating',
  props: ['rating']
}
</script>

<style scoped>
.rating {
  color: inherit;
}
.rating span {
  color: inherit;
}
</style>
