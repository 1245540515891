<template>
  <section style="z-index: 10">
    <div class="wrapper popup-wrapper">
      <div :class="'popup ' + show_class" :style="animation_style">
        <slot></slot>
        <p class="close-modal" @click="hide">SLUITEN</p>
      </div>
    </div>
    <div class="overlay-modal" :class="show_class" @click="hide"/>
  </section>
</template>

<script>
export default {
  name: "OibModal",
  data() {
    return {
      previous_show_time: new Date(),
      show_class: "",
      animation_style: 'animation-duration: 0'
    }
  },
  methods: {
    show() {
      this.animation_style = 'animation-duration: 0.8s'
      this.show_class = "show"      
    },
    hide() {
      this.animation_style = 'animation-duration: 0.8s'
      this.show_class = ""      
    },
    toggle() {
      this.animation_style = 'animation-duration: 0.8s'
      this.show_class = this.show_class == 'show' ? '' : 'show'
    }
  }
}
</script>


<style scoped>
.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
  cursor: pointer;
}

.close-modal:hover {
  color: var(--contrast-color);
}

.overlay-modal {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0);
  opacity: 0;
  z-index: 0;
}

.overlay-modal.show {
  opacity: 0.3;
  z-index: 2;
}

.popup-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;
}

@keyframes slide_up {
  0%   {display: flex; opacity: 1; max-height: 0;}
  100% {max-height: 400px;}
}


@keyframes slide_down {
  0%   {max-height: 400px; padding: 0 15px; opacity: 1}
  98%  {max-height: 0px;}
  99%  {opacity: 1; padding: 0 15px;}
  100% {opacity: 0; padding: 0px; display: none}
}


.popup {
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: 6px 6px 0 0;
  max-height: 0;
  transition: all 0.25s ease-out;
  overflow: hidden;
  background-color: var(--primary-color-p3);
  animation-name: slide_down;
  padding: 0px;
  opacity: 0;
}

.popup.show {
    max-height: 500px;
    opacity: 100;
    animation-name: slide_up;
    padding: 0 15px;
}


</style>