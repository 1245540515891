<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Leerkrachten toevoegen" ref="form">
    <div>
      <oib-input-multi-select-descriptive
        description="Hoe wil je leerkrachten toevoegen?"
        :allow_multi_select="false"
        :required="true"
        :sort_alphabetically="false"
        :options="main_character_initial_state_selector_list"
        @select="x => on_click(main_character_initial_state_selector_list[parseInt(x[0])])"
      />
    </div>
    <div></div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibInputMultiSelectDescriptive from "../components/oib_form/oib_input_multi_select_descriptive.vue"

import { go_to_route } from "../store/navigation";

export default {
  name: "MenuLeerkrachtenToevoegen",
  components: {
    OibForm,
    OibInputMultiSelectDescriptive
  },
  data() {
    return {
      form_step_validation_list: [false, false],
      main_character_final_state_index: null,
      main_character_initial_state_selector_list: [
        {'tag': 'Koppel leerlingvolgsysteem', 'details': 'Importeer leerkrachten via ParnasSys of ESIS en synchroniseer deze lijst dagelijks zodat nieuwe leerkrachten automatisch een account krijgen.'},
        {'tag': 'Handmatig', 'details': 'Voeg een collega of biebouder toe die niet in het leerlingvolgysteem staat.'},
      ],
    }
  },
  methods: {
    go_to_lvs_connect_form(){
      this.$router.push({
        name: 'LvsKoppelenAutorisatie',
        params: {
          'user_group': 'leerkrachten'
        }
      });
    },
    go_to_add_user_manual() {
      go_to_route('BiebhulpToevoegen')
    },
    on_click(option) {
      if (option.tag == 'Koppel leerlingvolgsysteem') {
        setTimeout(this.go_to_lvs_connect_form, 500)
      } else if (option.tag == 'Handmatig') {
        setTimeout(this.go_to_add_user_manual, 500)
      }
    }
  }
};
</script>
