import { request_post } from "../request.js"
import { request_put } from "../request.js"
import { request_delete } from "../request.js"
import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"
import { get_bucket } from "../bucket.js"

export async function post_organisations_libraries(jwt, organisation_id, organisation_id_owner, library_name, location_name) {
  /* Create a school library.

  :param int organisation_id: the id of the organisation to which the library should be added.
  :param str library_name: the name of the library that should be created.
  :param int building_id: the id of the building that houses this library.
  :param int classroom_id: the id of the classroom that houses this library.
  */

  const request_path = `/organisations/${organisation_id}/libraries`
  const body = {
    'organisation_id_owner': organisation_id_owner,
    'library_name': library_name,
    'location_name': location_name,
    'due_day_count': null
  }

  return await request_post(jwt, request_path, body)
}

export async function put_organisations_libraries(jwt, organisation_id_owner_old, library_id, organisation_id_owner, library_name, location_name, creation_datetime) {
  /* Update a school library.

  :param int organisation_id: the id of the organisation to which the library should be added.
  :param int library_id: the id of the library_id you wish to update.
  :param str library_name: the name of the library that should be updated.
  */

  const request_path = `/organisations/${organisation_id_owner_old}/libraries/${library_id}`
  const body = {
    'organisation_id_owner': organisation_id_owner,
    'library_name': library_name,
    'location_name': location_name,
    'due_day_count': null,
    'creation_datetime': creation_datetime
  }

  return await request_put(jwt, request_path, body, undefined)
}

export async function delete_libraries(jwt, library_id) {
  /* Delete a library.

  :param int library_id: the id of the library_id you wish to update.
  */

  const request_path = `/libraries/${library_id}`

  return await request_delete(jwt, request_path, undefined)
}

export async function get_classrooms_libraries(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/classrooms/libraries?${query_string}`, undefined)
}

export async function put_classrooms_id_libraries(jwt, classroom_id, allow_self_service) {
  /* Set classroom settings for all libraries

  :param int classroom_id: the id of the classroom.
  :param bool allow_self_service: whether student in the classroom can borrow books by themselves.
  */

  const request_path = `/classrooms/${classroom_id}/libraries`

  return await request_put(jwt, request_path, {'allow_self_service': allow_self_service}, undefined)
}

export async function put_classrooms_id_libraries_id(jwt, classroom_id, library_id, has_access) {
  /* Login with an identification token and return the user.

  :param int classroom_id: the id of the classroom that should be granted access to this library.
  :param int library_id: the id of the library.
  */

  const request_path = `/classrooms/${classroom_id}/libraries/${library_id}`

  return await request_put(jwt, request_path, {'has_access': has_access}, undefined)
}

export async function get_libraries_books_copies(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/books/copies?${query_string}`)
}

// TODO: Move to library_metrics API
export async function get_libraries_books_users_logs(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries/books/users/logs?${query_string}`)
}

export async function get_libraries_query(jwt, query) {
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/libraries?${query_string}`, undefined)
}

export async function post_libraries_authenticate(jwt) {
  return await request_post(jwt, '/libraries/authenticate', undefined)
}

export async function post_libraries_books_copies_batch(jwt, library_id, isbn13_list) {
  /* Add a copy of a book to a library.

  :param int library_id: the id of the library.
  :param list isbn13_list: the a list of isbn13 numbers of the copies.
  */

  const request_path = `/libraries/${library_id}/books/copies:batch`
  return await request_post(jwt, request_path, {
    'copy_list': isbn13_list
  })
}

export async function post_libraries_books_copies(jwt, library_id, isbn13) {
  /* Add a copy of a book to a library.

  :param int library_id: the id of the library.
  :param int isbn13: the isbn13 of the book.
  */

  const request_path = `/libraries/${library_id}/books/${isbn13}/copies`
  return await request_post(jwt, request_path, undefined)
}

export async function put_libraries_books_copies(jwt, library_id_old, isbn13_old, copy_id, library_id, isbn13, addition_datetime) {
  /* Update a copy.*/

  const request_path = `/libraries/${library_id_old}/books/${isbn13_old}/copies/${copy_id}`
  return await request_put(jwt, request_path, {
    'library_id': library_id,
    'isbn13': isbn13,
    'addition_datetime': addition_datetime
  })
}

export async function delete_libraries_books_copies(jwt, library_id, isbn13, copy_id) {
  /* Login with an identification token and return the user.

  :param int library_id: the id of the library.
  :param int isbn13: the isbn13 of the book.
  :param int copy_id: the id of the copy of the book.
  */

  const request_path = `/libraries/${library_id}/books/${isbn13}/copies/${copy_id}`

  return await request_delete(jwt, request_path)
}

export async function put_libraries_books_copies_users_borrow(jwt, library_id, isbn13, copy_id, user_id, organisation_id_user, datetime_logged) {
  /* Rent a book to a user

  :param int library_id: the id of the library.
  :param int isbn13: the isbn13 of the book.
  :param int copy_id: the id of the copy of the book.
  :param int user_id: the id of the user that will rent this copy.
  :param str datetime_logged: the datetime that this book was borrowed.
  */

  const request_path = `/libraries/${library_id}/books/${isbn13}/copies/${copy_id}/users/${user_id}/borrow`

  return await request_put(jwt, request_path, {
    'organisation_id_user': organisation_id_user,
    'datetime_logged': datetime_logged,
    'at_home': 0
  })
}

export async function put_libraries_books_copies_users_return(jwt, library_id, isbn13, copy_id, user_id, organisation_id_user, datetime_logged) {
  /* Return a book of a user

  :param int library_id: the id of the library.
  :param int isbn13: the isbn13 of the book.
  :param int copy_id: the id of the copy of the book.
  :param int user_id: the id of the user that will rent this copy.
  :param str datetime_logged: the datetime that this book was returned.
  */

  const request_path = `/libraries/${library_id}/books/${isbn13}/copies/${copy_id}/users/${user_id}/return`

  return await request_put(jwt, request_path, {
    'organisation_id_user': organisation_id_user,
    'datetime_logged': datetime_logged,
  })
}


export async function put_libraries_books_copies_users_return_all(jwt, library_id, datetime_logged) {
  /* Login with an identification token and return the user.

  Required:
  :param int library_id: the id of the library.

  Optional:
  :param int isbn13: the isbn13 of the book.
  :param int copy_id: the id of the copy of the book.
  :param int user_id: the id of the user that will rent this copy.
  */

  const request_path = `/libraries/${library_id}/books/copies/users/return`
  return await request_put(jwt, request_path, {
    'datetime_logged': datetime_logged
  })
}

export async function get_books(jwt, grouping, query) {
  /* Query the books collection in the book_query_ext service
  
  :param str jwt: the json web token
  :param str grouping: products | editions | stories
  :param dict query: the query to execute
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/books/${grouping}?${query_string}`, 20000)
}

export async function get_book_stories(jwt, query) {
  /* Query the book_story collection in the book_ext service
  
  :param str jwt: the json web token
  :param dict query: the query to execute
  */
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/book-stories?${query_string}`, undefined)
}

export async function get_book_editions(jwt, query) {
  /* Query the book_edition collection in the book_ext service
  
  :param str jwt: the json web token
  :param dict query: the query to execute
  */
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/book-editions?${query_string}`, undefined)
}

export async function get_authors(jwt, query) {
  /* Query the authors collection in the book_ext service
  
  :param str jwt: the json web token
  :param dict query: the query to execute
  */
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/authors?${query_string}`, undefined)
}

export async function get_series(jwt, query) {
  /* Query the series collection in the book_ext service
  
  :param str jwt: the json web token
  :param dict query: the query to execute
  */
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/series?${query_string}`, undefined)
}

export async function get_publishers(jwt, query) {
  /* Query the publishers collection in the book_ext service
  
  :param str jwt: the json web token
  :param dict query: the query to execute
  */
  const query_string = parse_query(query)
  return await request_get_json(jwt, `/publishers?${query_string}`, undefined)
}

export async function post_authors_batch(jwt, author_list) {
  /* Create a list of authors.

  :param str jwt: the json web token
  :param list author_list: a list of authors to create.
  */

  const request_path = `/authors:batch`
  return await request_post(jwt, request_path, {'author_list': author_list})
}

export async function post_series_batch(jwt, series_list) {
  /* Create a list of series.

  :param str jwt: the json web token
  :param list series_list: a list of series to create.
  */

  const request_path = `/series:batch`
  return await request_post(jwt, request_path, {'series_list': series_list})
}

export async function post_publishers_batch(jwt, publisher_list) {
  /* Create a list of publishers.

  :param str jwt: the json web token
  :param list publisher_list: a list of publishers to create.
  */

  const request_path = `/publishers:batch`
  return await request_post(jwt, request_path, {'publisher_list': publisher_list})
}

export async function post_book_stories_batch(jwt, book_story_list) {
  /* Create a list of publishers.

  :param str jwt: the json web token
  :param list book_story_list: a list of book stories.
  */

  const request_path = `/book-stories:batch`
  return await request_post(jwt, request_path, {'book_story_list': book_story_list})
}

export async function post_book_editions_batch(jwt, book_edition_list) {
  /* Create a list of publishers.

  :param str jwt: the json web token
  :param list book_edition_list: a list of book editions.
  */

  const request_path = `/book-editions:batch`
  return await request_post(jwt, request_path, {'book_edition_list': book_edition_list})
}

export async function put_book_editions_book_products(jwt, book_edition_id, isbn13, body) {
  /* Add a book product idempotently.

  :param int book_edition_id: the unique id of the book edition of this book product
  :param str isbn13: the unique isbn13 of this book product.
  :param dict body: the properties of this book product
  */

  const request_path = `/book-editions/${book_edition_id}/book-products/${isbn13}`
  return await request_put(jwt, request_path, body, undefined)
}

export function get_book_cover(isbn13, size) {
  return `${get_bucket('media')}/book_covers/front/${size}/${isbn13}.webp`
}

export function get_book_tag_img(tag_name) {
  const filename = tag_name.replaceAll(' & ', ' en ').replaceAll(' ', '_').replaceAll("'", '')
  return `${get_bucket('media')}/book_tags/${filename}.svg`
}
