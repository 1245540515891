<template>
  <oib-form :form_step_validation_list="form_step_validation_list" @submit="submit">
    <div>
      <oib-form-header header="Inlogmethode koppelen"/>
      <oib-input-text
      :label="capitalize_first_letter(account_type) + ' account email'"
      placeholder="naam@emaildomein.nl"
      :required="true"
      :error_msg="error_msg"
      :init_value="init_account_email.toString()"
      @input="set_account_email($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"

export default {
  name: "OibFormLibrary",
  props: {
    init_account_email: {
      default: ""
    },
    account_type: {
      default: "",
      required: true
    },
    error_msg: {
      default: "",
      required: false
    }
  },
  components: {
    OibForm,
    OibFormHeader,
    OibInputText
  },
  data() {
    return {
      form_step_validation_list: [false],
      account_email: ""
    }
  },
  async mounted() {
    this.account_email = this.init_account_email
    this.val_step_1()
  },
  methods: {
    capitalize_first_letter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    set_account_email(value) {
      this.account_email = value
      this.val_step_1()
    },
    async val_step_1() {
      this.form_step_validation_list[0] = this.account_email.includes('@')
    },
    async submit() {
      // Publish submit event
      this.$emit("submit", {
        'account_email': this.account_email
      })
    }
  }
};
</script>
