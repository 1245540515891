<template>
  <oib-input-file
  :label="label"
  :required="required"
  :error_msg="error_msg"
  :init_value="init_value"
  @input="on_input"
  />
</template>

<script>
import OibInputFile from "./oib_input_file.vue"
import { read, utils } from "xlsx";


export default {
  name: "OibInputXlsx",
  components: {
    OibInputFile
  },
  props: {
    init_value: {
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    error_msg: {
      type: String,
      default: ""
    },
  },
  methods: {
    on_input(event) {
      
      const file = event.target.files[0]
      const reader = new FileReader()

      const self = this
      reader.onload = function(e) {
          const data = e.target.result
          const workbook = read(data, { type: 'binary' })
          const worksheets = workbook.SheetNames
          const worksheet = utils.sheet_to_json(workbook.Sheets[worksheets[0]])
          self.$emit("oib_input", worksheet);
      }

      reader.readAsBinaryString(file)
    }
  }
}
</script>

<style scoped>
input, .custom-file-upload {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

input[type="file"] {
    display: none;
}

</style>