<template>
  <div>
    <div class="column">
      <label v-if="label">{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
      <p v-if="description" class="description">{{description}}</p>
      <div v-if="display_selected && indices.size > 0" style="margin-top: 10px;">
        <div
          v-for="option, i in option_list"
          :key="i"
          style="max-width: 500px; margin-bottom: 4px"
          @click="on_select(i)"
        >
          <div v-if="indices.has(i)" class="row">
            <oib-checkbox
            :set_checked="indices.has(i)"
            style="margin-top: 0px;"
            />
            <div style="margin-left: 20px;">
              <p style="margin: 0; color: white; font-size: 0.8em" :style="option.details ? 'margin-top: 1px' : 'margin-top: 4px'">{{option.tag}}</p>
              <p style="font-size: 0.8em; margin-top: 0; color: var(--contrast-color-p3)">{{option.details}}</p>
            </div>
          </div>
        </div>
        <div style="height: 1px; background-color: var(--primary-color-p4);"></div>
      </div>
      <div 
      ref="checklist"
      style="margin-top: 10px; overflow-y: hidden;"
      :style="checklist_style"
      >
        <div
          v-for="option, i in option_list"
          :key="i"
          class="clickable"
          style="max-width: 500px;"
          @click="on_select(i)"
        >
          <div v-if="indices_filtered.has(i)" class="row" style="margin-bottom: 4px">
            <oib-checkbox
            :set_checked="indices.has(i)"
            style="margin-top: 0px;"
            />
            <div style="margin-left: 20px;">
              <p style="margin: 0; color: white; font-size: 0.8em" :style="option.details ? 'margin-top: 1px' : 'margin-top: 4px'">{{option.tag}}</p>
              <p v-if="option.details" style="font-size: 0.8em; margin-top: 0; color: var(--contrast-color-p3)">{{option.details}}</p>
            </div>
          </div>
        </div>
      </div>
      <p
        v-if="error_msg"
        class="fade"
        style="color: var(--negative-color); font-size: 0.8em; margin: 5px 0 5px 0; min-height: 20px;"
        :style="error_msg && error_msg.length > 0 ? 'opacity: 1' : 'opacity: 0'">
        {{ error_msg }}
      </p>
    </div>
  </div>
</template>

<script>
import OibCheckbox from "../../components/OibCheckbox.vue"

export default {
  name: "OibInputText",
  props: {
    options: {
      type: Object || Map,
      required: true
    },
    label: {
      type: String,
    },
    description: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    allow_multi_select: {
      type: Boolean,
      default: false
    },
    keys_init: {
      default: null
    },
    sort_alphabetically: {
      default: true
    },
    display_selected: {
      default: false
    },
    filter_query: {
      default: ''
    },
    error_msg: {
      default: null
    }
  },  
  components: {
    OibCheckbox
  },
  data () {
    return {
      option_list: [],
      indices: new Set(),
      indices_filtered: new Set(),
      tile_width: null,
      keys: null,
      keys_selected: new Set(),
      checklist_style: ''
    }
  },
  watch: {
    keys_init() {
      this.keys_init.forEach((key) => {
        this.indices.add(this.get_index_by_key(key))
        this.keys_selected.add(key)
      })
    },
    filter_query() {
      this.filter_options()
    },
  },
  created() {
    this.keys = Array.from(Object.keys(this.options))
    if (this.sort_alphabetically) {
      this.keys.sort((a, b) => this.options[a].tag.localeCompare(this.options[b].tag))
    }    

    this.keys.forEach(x => {
      // object and map compatibility
      const value = this.options[x] ? this.options[x] : this.options.get(x)
      this.option_list.push(value)
    });

    const max_str_length = this.get_max_str_length(this.option_list)
    this.tile_width = max_str_length * 6 + (Math.max(30, max_str_length * 2));

    
    // Init tile if specified
    if (this.keys_init != null) {
      this.keys_init.forEach((key) => {
        this.indices.add(this.get_index_by_key(key))
        this.keys_selected.add(key)
      })

      this.publish_event()
    }
    this.filter_options()
  },
  methods: {
    filter_options() {
      this.indices_filtered = new Set()

      const filter_query_lower = this.filter_query.toLowerCase()

      if (this.filter_query.length < 2) {
        for (let i = 0; i < this.option_list.length; i++) {
          this.indices_filtered.add(i)
        }
      } else {
        for (let i = 0; i < this.option_list.length; i++) {
          if (this.option_list[i].tag.includes(filter_query_lower)) {
            this.indices_filtered.add(i)
          }
        }
      }
    },
    get_index_by_key(key) {
      for (let i = 0; i < this.keys.length; i++) {
        if (key == this.keys[i]) {
          return i
        }
      }
    },
    get_max_str_length(array) {
      let max_str_length = 0
      for(let i = 0; i < array.length; i++) {
        const value = array[i]
        max_str_length = Math.max(max_str_length, value.length)
      }
      return max_str_length
    },
    on_select(index) {
      console.log(this.option_list)
      console.log(this.indices)
      console.log(this.keys_selected)

      if (this.allow_multi_select) {
        if (this.indices.has(index)) {
          this.indices.delete(index)
          this.keys_selected.delete(parseInt(this.keys[index]))
        } else {
          this.indices.add(index)
          this.keys_selected.add(parseInt(this.keys[index]))
        }
      } else {
        this.indices.clear()
        this.keys_selected.clear()
        this.indices.add(index)
        this.keys_selected.add(parseInt(this.keys[index]))
      }
      this.publish_event()
    },
    on_select_all(is_checked) {
      if (is_checked) {
        for (let i = 0; i < this.option_list.length; i++)  {
          this.indices.add(i)
          this.keys_selected.add(parseInt(this.keys[i]))
        }
      } else {
        for (let i = 0; i < this.option_list.length; i++)  {
          this.indices.delete(i)
          this.keys_selected.delete(parseInt(this.keys[i]))
        }
      }
      this.publish_event()
    },
    publish_event() {
      this.$emit("select", Array.from(this.keys_selected))
    }
  }
}
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
  align-items: top;
}
.multi-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
  color: white;
}

.tile-btn {
  width: 100px;
  padding: 10px 5px;
  margin-bottom: 20px;
}

.select-all {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.select-all p {
  margin: 0 10px 5px 0;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}
</style>