<template>
  <div>
    <div class="wrapper log-book">
      <h3 class="h3">Logboek</h3>
      <div class="log-entry" v-for="log in progress" :key="log">
        <p>bladzijden: {{ log.pages_read }}</p>
        <p>datum: {{ log.datetime_logged }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OibSectionLogbookBook',
  props: ['user_id', 'isbn13'],
  computed: {
    progress() {
      const progress_dict = this.$store.getters.get_progress_dict
      if (this.user_id in progress_dict && this.isbn13 in progress_dict[this.user_id]) {
        const progress_list = progress_dict[this.user_id.toString()][this.isbn13.toString()];
        return progress_list.sort(this.compare_log)
      }
      return []
    },
  },
  methods: {
    compare_log(a, b) {
      if (a.datetime_logged < b.datetime_logged) {
        return 1
      }
      if (a.datetime_logged > b.datetime_logged) {
        return -1
      }
      return 0
    }
  }
}
</script>

<style scoped>
.log-book {
  padding: 80px auto 80px auto;
  flex-direction: column;
  text-align: center;
  color: var(--contrast-color-p2);
}

.log-entry {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  min-width: 200px;
  max-width: 250px;
  margin: auto;
  color: var(--contrast-color-p3);
  font-size: 12px;
}
</style>
