<template>
  <oib-page>
    <oib-nav-student title="Boeken verwijderen"/>
    <oib-content >
      <oib-menu-item
      description="Type 'Verwijderen' om te bevestigen dat je de boeken echt wilt verwijderen."
      style="padding-top: 20px;"
      >
        <template v-slot:title>
          <oib-icon-label
          style="color: white;"
          icon="delete"
          title="Bevestigen"
          direction="row"
          :active="false"
          />
        </template>
      </oib-menu-item>
      <div class="wrapper column">
        <input type="text" placeholder="Verwijderen" v-model="text_input"/>
        <oib-button
          shape="rounded"
          style="margin-top: 50px;"
          :disabled="!is_valid_form"
          @click="submit"
          >Verwijderen</oib-button>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"
import OibButton from "../components/OibButton.vue"

import {get_libraries_books_copies} from "../store/api/library.js"
import {delete_libraries_books_copies} from "../store/api/library.js"

export default {
  name: "BibliotheekBoekVerwijderen",
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel,
    OibButton
  },
  data() {
    return {
      library_name_start: "",
      text_input: "",
      error_msg: " ",
      building_access_set: new Set(),
      classroom_access_set: new Set()
    }
  },
  computed: {
    is_valid_form() {
      return this.text_input.toLowerCase() == 'verwijderen'
    },
  },
  methods: {
    async submit() {

      // Parse query params
      const library_id_list = Object.keys(this.$store.getters.get_library_dict)
      let query = {
        'library_id': library_id_list.join(','),
        'limit': 1000000,
        'page': 0
      }
      new URLSearchParams(window.location.search).forEach((v, k) => query[k] = v)

      // Get copy list
      const jwt = this.$store.getters.get_jwt
      const json = await get_libraries_books_copies(jwt, query)
      const copy_list = json['copy_list']

      // Delete copies
      for (let i = 0; i < copy_list.length; i++) {
        const copy = copy_list[i]
        await delete_libraries_books_copies(
          jwt,
          copy['library_id'],
          copy['isbn13'],
          copy['copy_id']
        )
      }

      this.$router.back()
    },
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  mounted() {
    const library_id = this.$store.getters.get_active_library_id
    if (library_id == 0) {
      return
    }
    const library = this.$store.getters.get_library_dict[library_id]
    this.library_name_start = library['library_name']
  }
};
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--primary-color-sub);
    color: var(--contrast-color);
    padding: 8px 16px;
    border: none;
    border-radius: 2px;
}

* {  
  box-sizing: border-box;
}

input:focus {
    outline-color: red;
    color: var(--contrast-color);
    outline-width: 0.2px;
    box-shadow: none;
    border-color: transparent;
    outline-style: none;
    outline-style: none;
    box-shadow: none;
    border-color: transparent;
    background-color: var(--primary-color-p3);
    color: white;
}

.location-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
}

.location-option .wrapper-checkbox {
  padding-right: 10px;
}

.sub-title {
  font-size: 0.9em;
  margin-top: 0;
  margin-bottom: 0;
  text-align: left;
}

.error {
  font-size: 0.8em;
  color: red;
  height: 12px;
}
</style>