<template>
  <oib-page>
    <oib-nav-student title="Instellingen"/>
    <oib-content>      
      <div class="wrapper column">
        <h2 class="h2">Account</h2>
      </div>
      <oib-menu-item
      description="Koppel de QR-code op een boekenlegger aan dit account. De vorige code wordt automatisch vergeten."
      @click="go_to_route('InstellingenQrCode', {'user_id': user_id})"
      hover="hover"
      >
        <template v-slot:title>
          <oib-icon-label
          style="color: white;"
          icon="qr_code_scanner"
          title="QR-code"
          direction="row"
          :active="false"
          />
        </template>
      </oib-menu-item>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibMenuItem from "../components/oib_menu_item.vue"
import OibIconLabel from "../components/oib_icon_label.vue"

import { go_to_route } from "../store/navigation";

export default {
  name: "Instellingen",
  props: ["user_id"],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibMenuItem,
    OibIconLabel
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  methods: {
    // Import
    go_to_route
  }
};
</script>
