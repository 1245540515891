<template>
  <oib-form-library-independence
  v-if="classroom_access_id_set_start"
  :classroom_access_id_set_init="classroom_access_id_set_start"
  @submit="x => submit(x)"
  />
</template>

<script>
import OibFormLibraryIndependence from "../components/oib_form_library_independence.vue";

import {put_classrooms_id_libraries} from "../store/api/library.js"

function difference(setA, setB) {
    let _difference = new Set(setA)
    for (let elem of setB) {
        _difference.delete(elem)
    }
    return _difference
}

export default {
  name: "BibliotheekAanpassenZelfstandigheid",
  components: {
    OibFormLibraryIndependence
  },
  data() {
    return {
      classroom_access_id_set_start: null
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  async created() {
    // Skip on refresh
    if (!this.$store.getters.getActiveSession) {
      return
    }

    // Load borrow independence to avoid desynchronisation after lvs was connected
    const jwt = this.$store.getters.get_jwt
    const classroom_id_list = Object.keys(this.$store.getters.get_classroom_dict)
    let classroom_settings_id_list_active = new Set()
    if (classroom_id_list.length > 0) {

      // Load current state
      const classroom_id_list_str = classroom_id_list.join(',')
      await this.$store.dispatch("load_classroom_borrow_independence", {
        jwt: jwt,
        classroom_id: classroom_id_list_str
      })

      // Parse data for form
      const classroom_settings_dict = this.$store.getters.get_classroom_borrow_independence
      for (let classroom_id_str of classroom_id_list) {
        if (classroom_id_str in classroom_settings_dict && classroom_settings_dict[classroom_id_str]) {
          classroom_settings_id_list_active.add(parseInt(classroom_id_str))  
        }
      }
    } 
    this.classroom_access_id_set_start = classroom_settings_id_list_active   
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }

      const jwt = this.$store.getters.get_jwt

      // Update access
      const classroom_id_create = difference(body.classroom_access_id_set, this.classroom_access_id_set_start);
      const classroom_id_delete = difference(this.classroom_access_id_set_start, body.classroom_access_id_set);

      classroom_id_create.forEach(async (classroom_id) => await put_classrooms_id_libraries(jwt, classroom_id, 1))
      classroom_id_delete.forEach(async (classroom_id) => await put_classrooms_id_libraries(jwt, classroom_id, 0))

      // Load borrow independence to avoid desynchronisation after lvs was connected
      const classroom_id_list = Object.keys(this.$store.getters.get_classroom_dict)
      if (classroom_id_list.length > 0) {
        const classroom_id_list_str = classroom_id_list.join(',')
        this.$store.dispatch("load_classroom_borrow_independence", {
          jwt: jwt,
          classroom_id: classroom_id_list_str
        })
      }

      // Route back
      this.$router.back()
    }
  }
};
</script>
