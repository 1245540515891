<template>
  <div class="column">
    <label>{{label}}<span v-if="required" style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
    <p v-if="description" class="description">{{description}}</p>
    <div class="row" style="justify-content: space-between; flex-wrap: wrap;">
      <img
      v-for="(src, index) in img_list_suggest"
      :key="src"
      class="option"
      :src="src"
      style="object-fit: cover; border-radius: 3px;"
      :style="img_index_active == index ? 'border: 0.3em solid var(--highlight-color)' : ''"
      @click="select_suggestion(src, index)"
      >
      <div
      ref="drop_area"
      class="drop-area option"
      :class="dimensions"
      @click="on_click()"
      >
        <div v-if="canvas_file == null" class="column" style="justify-content: center; align-items: center; pointer-events: none; text-align: center;">
          <p class="material-icons-outlined" style="font-size: 36px; margin-bottom: 0; margin-top: 0">photo</p>
          <p style="margin-bottom: 0;">Upload foto</p>
        </div>
        <canvas
        v-if="canvas_file != null"
        ref="canvas"
        width=100% height=280
        style="border-radius: 4px;"
        :style="img_index_active == img_list_suggest.length ? 'border: 0.3em solid var(--highlight-color)' : ''"></canvas>
      </div>
    </div>
    <!-- <canvas ref="canvas" width=100% height=280></canvas> -->
    <input type="file" style="visibility: hidden" ref="input_file" @change="on_file_change">
  </div>
</template>

<script>
export default {
  name: "OibInputText",
  props: {
    label: {
      type: String,
      required: true
    },
    init_value: {
      type: String
    },
    description: {
      type: String,
      default: null
    },
    required: {
      default: true
    },
    dimensions: {
      type: String,
      default: "vertical"
    },
    img_list_suggest: {
      type: Array
    }
  },
  data() {
    return {
      canvas_file: null,
      url: null,
      img_index_active: null
    }
  },
  watch: {
    init_value: {
      handler() {
        if (this.init_value != null) {        
          fetch(this.init_value).then(res => res.blob()).then(blob => this.set_file(blob))
        }
      },
      immediate: true
    }
  },
  mounted() {
    // Init image if specified
    if (this.init_value != null) {
      fetch(this.init_value).then(res => res.blob()).then(blob => this.set_file(blob))
    }    

    // Init drop_area
    const drop_area = this.$refs.drop_area
    drop_area.addEventListener('dragenter', this.on_drag_enter, false)
    drop_area.addEventListener('dragleave', this.on_drag_leave, false)
    window.addEventListener("dragover", e => e.preventDefault(), false)
    drop_area.addEventListener('drop', this.on_image_dropped, false)
  },
  methods: {
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    },
    async select_suggestion(src_file, index) {
      const res = await fetch(`${window.location.origin}/${src_file}`)
      let blob = await res.blob()
      blob = blob.slice(0, blob.size, "image/png")
      var reader = new FileReader();
      const self = this
      reader.onloadend = () => {
        var base64data = reader.result;                
        const file = self.dataURLtoFile(base64data, "label.png")
        
        self.img_index_active = index
        self.$emit("input", file);
      }
      reader.readAsDataURL(blob);
    },
    on_drag_enter(e) {
      e.preventDefault();
      this.$refs.drop_area.classList.add('highlight')
    },
    on_drag_leave(e) {
      e.preventDefault();
      this.$refs.drop_area.classList.remove('highlight')
    },
    on_image_dropped(e) {
      e.preventDefault();
      this.set_canvas_file(e.dataTransfer.files[0])
    },
    on_click() {
      this.$refs.input_file.click()
      this.img_index_active = this.img_list_suggest.length
    },
    on_file_change(e) {
      if (e.target.files.length > 0) {
        this.set_canvas_file(e.target.files[0])
      }      
    },
    set_canvas_file(canvas_file) {
      // Return if blob is not a canvas_file
      if (canvas_file && canvas_file.size < 250) {
        return
      }

      this.canvas_file = canvas_file;
      this.url = URL.createObjectURL(this.canvas_file);
      this.update_canvas()
      this.$emit("input", this.canvas_file);
    },
    update_canvas() {
      // This function renders the image to a canvas. This avoids rendering a 10MB background image.

      const drop_area_rect = this.$refs.drop_area.getBoundingClientRect()
      const preview_image = new Image()
      preview_image.src = this.url
      preview_image.decode().then(() => {

        const canvas = this.$refs.canvas
        const ctx = canvas.getContext('2d');
        canvas.width = drop_area_rect.width
        canvas.height = drop_area_rect.height;

        if (preview_image.naturalWidth > preview_image.naturalHeight) {
          // Draw image centered to canvas
          ctx.drawImage(
            preview_image,
            (preview_image.naturalWidth - (canvas.width * preview_image.naturalHeight / canvas.height)) / 2,
            0,
            canvas.width * preview_image.naturalHeight / canvas.height,
            preview_image.naturalHeight,
            0,
            0,
            canvas.width,
            canvas.height
          );
        } else {
          // Draw image centered to canvas
          ctx.drawImage(
            preview_image,
            0,
            (preview_image.naturalHeight - (canvas.height * preview_image.naturalWidth / canvas.width)) / 2,
            preview_image.naturalWidth,
            canvas.height * preview_image.naturalWidth / canvas.width,
            0,
            0,
            canvas.width,
            canvas.height
          );
        }
      })
    }
  }
}
</script>

<style scoped>
input {
    width: 100%;
    background-color: var(--contrast-color);
    color: var(--primary-color);
    padding: 10px 16px;
    border: none;
    border-radius: 10px;
}

* {  
  box-sizing: border-box;
}

label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.drop-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  background-color: transparent;
  background-size: cover;
  background-size: 100% auto;
  background-position: center;
}

.drop-area.horizontal {
  height: 280px;
  width: 100%;
}

.drop-area p {
  font-size: 0.8em;
}

.drop-area.highlight {
  border-color: var(--highlight-color)
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}

.option {
  width: 46%;
  aspect-ratio: 1 / 1;
  margin-bottom: 8%
}

.active {
  border: 2px solid var(--highlight-color);
}
</style>