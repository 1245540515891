<template>
  <oib-form-library @submit="x => submit(x)"/>
</template>

<script>
import OibFormLibrary from "../components/oib_form_library.vue";

import {post_organisations_libraries} from "../store/api/library.js"
import {put_classrooms_id_libraries_id} from "../store/api/library.js"
import {reload_libraries} from "../store/library.js"
import {login_library_module} from "../store/library.js"
import {put_upload_image} from "../store/api/media.js"

export default {
  name: "BibliotheekToevoegen",
  props: ['library_id'],
  components: {
    OibFormLibrary
  },
  methods: {
    async submit(body) {

      // Skip if body was undefined
      if (!body) {
        return
      }
      
      await this.add_school(body)

      // Route to bibliotheek beheer
      this.$router.push({
        name: 'BibliotheekCollectie',
        params: {
          user_id: this.$store.getters.getUser['user_id']
        }
      });
    },
    async add_school(body) {
        // Skip if body was undefined
        if (!body) {
          return
        }

        // Create library
        const response = await post_organisations_libraries(
            this.$store.getters.get_jwt,
            body.organisation_id,
            body.organisation_id_owner,
            body.library_name,
            body.location_name
        )

        if (response.status == 409) {
          this.error_msg = "Er bestaat al een bibliotheek met deze naam op deze school."
          return
        }
        
        this.error_msg = " "

        const json = await response.json();
        const library_id = json['library_id']

        // Log into library module to gain rights to access newly created library
        await login_library_module()
        const jwt = this.$store.getters.get_jwt

        // Grant library access to classrooms
        const classroom_itr = body.classroom_access_id_set.values()
        for (let i = 0; i < body.classroom_access_id_set.size; i++) {
          const classroom_id = classroom_itr.next().value
          await put_classrooms_id_libraries_id(jwt, parseInt(classroom_id), library_id, 1)
        }

        await reload_libraries()

        // Upload image      
        let formData = new FormData();
        formData.append("image", body.image_file);
        put_upload_image(jwt, 'libraries', library_id, formData)
      }
  }
};
</script>
