<template>
  <div v-if="row_count && column_count" >
    <div v-for="y in row_count" :key="y" class="row book-row">
      <div v-for="x in column_count" :key="x">
        <div :style="`width: ${tile_width}px;`">
          <slot v-if="get_tile(x, y)" :data="get_tile(x, y)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OibGrid",
  props: {
    elements: {
      type: Array,
      required: true
    },
    tile_width: {
      required: true
    }
  },
  data() {
    return {
      column_count: 0
    }
  },
  computed: {
    row_count() {
      const total_count = this.elements.length;
      const row_count_rest = total_count % this.column_count;
      let row_count_filled = Math.floor(total_count / this.column_count);

      // Add additional row if not all tiles fit in the grid aspect ratio
      if (row_count_rest != 0) {
        row_count_filled += 1;
      }

      return row_count_filled
    },
  },
  mounted() {
    window.addEventListener('resize', this.on_resize);
    this.on_resize()
  },
  unmounted() {
    window.removeEventListener('resize', this.on_resize);
  },
  watch: {
    tile_width() {
      this.on_resize()
    }
  },
  methods: {
    get_tile(x, y) {
      // Correct for 1 indexed loop
      x--; y--;

      const grid_coordinate_1d = y * this.column_count + x;
      if (grid_coordinate_1d > this.elements.length - 1) {
        return null
      }
      const index = y * this.column_count + x
      return {
        'index': index,
        'body': this.elements[index]
      }
    },
    on_resize() {
      // Compute column_count
      const min_spacing = 10
      const total_width = this.$store.getters.get_width;
      this.column_count = Math.floor((total_width) / (this.tile_width + min_spacing))
    },
  }
}
</script>

<style scoped>
.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
</style>