import { request_put } from "../request.js"

export async function put_upload_image(jwt, directory, filename, formdata) {
  /* Upload an image to a directory.

  :param str directory: the directory in which to file should be stored.
  :param int filename: the name of the file.
  :param object formdata: the image
  */

  const request_path = `/upload/image/${directory}/${filename}`

  return await request_put(jwt, request_path, formdata)
}

export async function put_upload_audio(jwt, directory, filename, formdata) {
  /* Upload an audio file to a directory.

  :param str directory: the directory in which to file should be stored.
  :param int filename: the name of the file.
  :param object formdata: the image
  */

  const request_path = `/upload/audio/${directory}/${filename}`

  return await request_put(jwt, request_path, formdata)
}
