import {get_classrooms_libraries} from '../api/library'

export default ({
  state: {
    search_page: 0,
    search_query: "",
    search_request: {},
    search_result: [],

    edition_isbn13_list_dict: {},
    classroom_dict_borrow_independence: {},

    books_reading_list: [],
    books_finished_list: [],
    books_favorite_list: [],

    books_last_added_dict: {},
    books_most_popular_library_dict: {},
    books_most_popular_library_classroom_dict: {},

    book_release_year_library_dict: {},
    book_type_library_dict: {},
    book_age_library_dict: {},
    book_count_library_dict: {},

    recommendation_book_dict: {},
    recommendation_user_dict: {},
    recommendation_author_dict: {},
    recommendation_series_dict: {},

    tag_list_highlight: [],
    tag_list_quick_search: []
  },
  mutations: {
    reset_library_cache(state) {
      state.search_page = 0
      state.search_query = ""
      state.search_request = {}
      state.search_result = []

      state.edition_isbn13_list_dict = {}
  
      state.books_reading_list = []
      state.books_finished_list = []
      state.books_favorite_list = []

      state.books_last_added_dict = {}
      state.books_most_popular_library_dict = {}
  
      state.book_release_year_library_dict = {}
      state.book_type_library_dict = {}
      state.book_age_library_dict = {}
      state.book_count_library_dict = {}
  
      state.recommendation_book_dict = {}
      state.recommendation_user_dict = {}
      state.recommendation_author_dict = {}
      state.recommendation_series_dict = {}
    },
    set_search_query(state, search_query) {
      state.search_query = search_query;
    },
    set_search_request(state, search_request) {
      state.search_request = search_request;
    },
    set_search_page(state, page) {
      state.search_page = page;
    },
    set_search_result(state, search_result) {
      state.search_result = search_result
    },
    set_books_reading(state, payload) {
      state.books_reading_list = payload
    },
    set_books_finished(state, payload) {
      state.books_finished_list = payload
    },
    set_books_favorite(state, payload) {
      state.books_favorite_list = payload
    },
    set_books_last_added(state, payload) {
      state.books_last_added_dict[payload.library_id] = payload.books
    },
    set_books_most_popular_library(state, payload) {
      state.books_most_popular_library_dict[payload.library_id] = payload.books
    },
    set_books_most_popular_library_classroom(state, payload) {
      const library_id = payload.library_id
      const classroom_id = payload.classroom_id

      if (!(library_id in state.books_most_popular_library_classroom_dict)) {
        state.books_most_popular_library_classroom_dict[library_id] = {}  
      }
      state.books_most_popular_library_classroom_dict[library_id][classroom_id] = payload.books
    },
    set_book_release_year_library(state, payload) {
      state.book_release_year_library_dict[payload.library_id] = payload.books
    },
    set_book_count_library(state, payload) {
      state.book_count_library_dict[payload.library_id] = payload.book_count
    },
    set_book_type_library(state, payload) {
      state.book_type_library_dict[payload.library_id] = payload.books
    },
    set_book_age_library(state, payload) {
      state.book_age_library_dict[payload.library_id] = payload.books
    },
    set_recommendation(state, payload) {
      state.recommendation_book_dict[payload['book_edition_id']] = payload['tile_list']
    },
    set_recommendation_user(state, payload) {
      const library_id = payload['library_id']
      const user_id = payload['user_id']
      if (!(library_id in state.recommendation_user_dict)) {
        state.recommendation_user_dict[library_id] = {}
      }
      state.recommendation_user_dict[library_id][user_id] = payload['tile_list']
    },
    set_recommendation_author(state, payload) {
      const library_id = payload['library_id']
      const author_id = payload['author_id']
      if (!(library_id in state.recommendation_author_dict)) {
        state.recommendation_author_dict[library_id] = {}
      }
      state.recommendation_author_dict[library_id][author_id] = payload['tile_list']
    },
    set_recommendation_series(state, payload) {
      const library_id = payload['library_id']
      const series_id = payload['series_id']
      if (!(library_id in state.recommendation_series_dict)) {
        state.recommendation_series_dict[library_id] = {}
      }
      state.recommendation_series_dict[library_id][series_id] = payload['tile_list']
    },
    add_edition_isbn13_list(state, payload) {
      state.edition_isbn13_list_dict[payload.book_edition_id] = payload.isbn13_list
    },
    set_classroom_borrow_independence(state, payload) {
      state.classroom_dict_borrow_independence[payload.classroom_id] = payload.allow_self_service
    },
    set_tag_list_highlight(state, tag_list) {
      state.tag_list_highlight = tag_list
    },
    set_tag_list_quick_search(state, tag_list) {
      state.tag_list_quick_search = tag_list
    }
  },
  actions: {
    async load_classroom_borrow_independence(state, payload) {
      const json = await get_classrooms_libraries(
        payload['jwt'],
        {'classroom_id': payload['classroom_id']}
      )
      json['classroom_settings_list'].forEach(x => {
        state.commit("set_classroom_borrow_independence", x)
      })
    },      
  },
  modules: {

  },
  getters: {
    get_search_page: state => state.search_page,
    get_search_query: state => state.search_query,
    get_search_result: state => state.search_result,
    get_search_request: state => state.search_request,
    
    get_edition_isbn13_list_dict: state => state.edition_isbn13_list_dict,

    get_books_reading_list: state => state.books_reading_list,
    get_books_finished_list: state => state.books_finished_list,
    get_books_favorite_list: state => state.books_favorite_list,

    get_books_last_added_dict: state => state.books_last_added_dict,
    get_books_most_popular_library_dict: state => state.books_most_popular_library_dict,
    get_books_most_popular_library_classroom_dict: state => state.books_most_popular_library_classroom_dict,

    get_book_release_year_library_dict: state => state.book_release_year_library_dict,
    get_book_type_library_dict: state => state.book_type_library_dict,
    get_book_age_library_dict: state => state.book_age_library_dict,
    get_book_count_library_dict: state => state.book_count_library_dict,

    get_recommendation_dict: state => state.recommendation_book_dict,
    get_recommendation_user_dict: state => state.recommendation_user_dict,
    get_recommendation_author_dict: state => state.recommendation_author_dict,
    get_recommendation_series_dict: state => state.recommendation_series_dict,

    get_classroom_borrow_independence: state => state.classroom_dict_borrow_independence,

    get_tag_list_highlight: state => state.tag_list_highlight,
    get_tag_list_quick_search: state => state.tag_list_quick_search,
  }
})
