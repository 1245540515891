<template>
  <div @click="pick_classroom" style="margin-right: 10px">
    <h3 style="margin: 0; background-color: var(--highlight-color); padding: 8px; font-size: 12px; border-radius: 2px; width: fit-content; white-space:nowrap; min-width: 30px; text-align: center;">{{selector_name}}</h3>
  </div>
</template>

<script>
export default {
  name: "OibButtonInlineClassroom",
  computed: {
    selector_name(){
      const selector_list = this.$store.getters.get_location_selector_list
      const selector_active_index = this.$store.getters.get_location_selector_active
      const selector_active = selector_list[selector_active_index]
      return selector_active != undefined ? selector_active.name : null
    },
  },
  mounted() {
    this.$emit("input", this.selected);
  },
  methods: {
    pick_classroom() {
      this.$router.push({
        name: 'GroepKiezen',
      });
    }
  }
};
</script>

<style scoped>
.custom-select {
  position: relative;
  text-align: left;
  outline: none;
  font-size: 0.8em;
  line-height: 35px;
  height: 35px;
  background-color: none;
  background-size: cover;
  border-radius: 10px;
}

.custom-select .selected {
  border-radius: 4px;
  color: white;
  padding: 0 1em;
  cursor: pointer;
  user-select: none;
  height: 29px;
  margin: 3px;
  display: flex;
  align-items: center;
  background-color: var(--highlight-color);
}

.custom-select .selected.open {
  border-bottom: 1px solid var(--primary-color);
  border-radius: 16px 16px 0px 0px;
}

.custom-select .items {
  color: var(--primary-color);
  border-radius: 0px 0px 16px 16px;
  overflow: hidden;
  position: absolute;
  background-color: var(--contrast-color);
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div p {
  color: var(--primary-color);
}

.custom-select .items div:hover {
  background-color: var(--contrast-color-p4);
}

.selectHide {
  display: none;
}
</style>
