<template>
  <oib-form
    title="Partner koppelen"
    submit_text="Bevestigen"
    :form_step_validation_list="form_step_validation_list"
    @submit="submit"
  >
    <div>
      <oib-form-header :header="`Wil je de collectie delen met ${organisation_name}?`"/>
      <p style="font-size: 0.9em;">Wat je deelt:</p>
      <ul style="font-size: 0.9em;">
        <li>Welke boeken er in de bieb staan.</li>
        <li>Hoe vaak deze boeken worden gelezen.</li>
      </ul>
      <p style="font-size: 0.9em;">Wat je niet deelt:</p>
      <ul style="font-size: 0.9em;">
        <li>Persoonlijke gegevens van gebruikers.</li>
        <li>Leerresultaten van leerlingen.</li>
      </ul>

      <h3 style="margin-top: 40px;">Voordelen</h3>
      <p style="font-size: 0.9em;">Deel de boekencollectie met {{organisation_name}} zodat zij op doordachte wijze kunnen helpen met het aanvullen van jullie collectie.</p>
      <p style="font-size: 0.9em;">Vroeger keken ze bijvoorbeeld op oude bonnetjes om in te schatten welke boeken al op school stonden, maar met deze nieuwe manier van werken kunnen scholen effectiever budget besteden en de kwaliteit van de bieb borgen.</p>
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"

export default {
  name: "OibFormConnectPartner",
  props: {
    organisation_id: {
      required: true
    },
    organisation_name: {
      type: String,
      required: true
    }
  },
  components: {
    OibForm,
    OibFormHeader,
  },
  data() {
    return {
      form_step_validation_list: [true],
      partner_name: ""
    }
  },
  created() {

  },
  methods: {
    async submit() {
      this.$emit("submit", {
        'organisation_id': this.organisation_id
      })
    }
  }
};
</script>
