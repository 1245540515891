<template>
  <div class="fade tile" v-on:click="goToBook" style="padding-bottom: 80px;">
    <div :style="`background-image: url(${get_organisation_image_url()}); background-size: cover; background-position: center; height: 400px; display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;`">
      <div style="padding: 10px;">
        <div style="background-color: rgba(33,34,47, 0.5); padding: 5px 15px; border-radius: 40px; height: object-fit">
          <p style="margin: 0; color: white; font-size: 0.8em; width: object-fit">{{distance}} km afstand</p>
        </div>
      </div>
    </div>
    <div class="wrapper column">
      <h2 style="text-align: left; color: white; margin-bottom: 10px">{{ title }}</h2>
      <p style="margin-top: 0; ">{{subtext}}</p>
      <div class="row">
        <oib-button
          style="padding: 10px 30px; margin-right: 20px;"
          priority="p1"
        >Lees meer</oib-button>
        <oib-button
          style="padding: 10px 30px;"
          priority="p2"
          @click="go_to_connect_partner()"
        >Collectie delen</oib-button>
      </div>  
    </div> 
  </div>
</template>

<script>
import OibButton from "./OibButton.vue"

import {get_bucket} from "../store/bucket.js"

export default {
  name: "OibListItem",
  props: {
    title: {
      type: String,
      required: true
    },
    subtext: {
      type: String
    },
    organisation_id: {
      type: Number,
      required: true
    },
    icon: {
      type: String,
      default: "chevron_right"
    },
    distance: {
      required: true
    }
  },
  components: {
    OibButton
  },
  methods: {
    get_organisation_image_url() {
      return `${get_bucket('media')}/organisations/l/${this.organisation_id}.webp`
    },
    go_to_connect_partner() {
      this.$router.push({
        name: 'PartnerKoppelen',
        params: {
          organisation_id: this.organisation_id,
          organisation_name: this.title
        }
      });
    },
  }
};
</script>

<style scoped>

.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 10px;
  padding: 0px;
}

.book-row:hover {
  background-color: rgba(255, 255, 255, 0.03);
  -webkit-box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05); 
  box-shadow: 0px 14px 15px -4px rgba(0,0,0,0.05);
}

.book-row-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.book-row-details {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.h2 {
  font-size: 1.5em;
  margin: 20px 0 10px 0;
}

.h3 {
  font-size: 1.2em;
  margin: 20px 0 10px 0;
}

.h4 {
  font-size: 0.9em;
  margin: 0;
  padding-bottom: 3px;
}

.book-row-content-main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.subh4 {
  font-size: 0.7em;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #acaeb0;
}

.subh4 span {
  font-size: 1.3em;
  padding-right: 4px;
}

.fade {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.fade:hover {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.background-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(33,34,47, 0) 0%,  rgba(33,34,47,0) 10%, rgba(33,34,47,0) 50%, rgba(33,34,47,0) 100%);
}

.tile {
  width: 100%;
}

@media screen and (min-width:1050px) {
  .tile {
    width: 400px;
    margin-right: 40px;
  }
}

</style>