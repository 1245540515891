import { request_get_json } from "../request.js"
import { parse_query } from "../request.js"

export async function get_books_recommendations(jwt, query) {
  /* Query the book recommendations.

  :param dict query: the query to execute.
  */

  const query_string = parse_query(query)
  return await request_get_json(jwt, `/books/recommendations?${query_string}`)
}
