<template>
  <button :class="'button-icon clickable ' + border" :style="has_backdrop ? 'background-color: rgba(0, 0, 0, 0.3); border-radius: 100px; margin: 0; padding: 4px' : ''">
    <span class="center-text" :class="icon_class_computed" style="margin-bottom: 1px">{{
      icon
    }}</span>
  </button>
</template>

<script>
export default {
  name: "OibButtonIcon",
  props: {
    icon: {
      type: String,
      required: true
    },
    border: {
      type: String
    },
    active: {
      type: Boolean,
      default: false
    },
    has_backdrop: {
      type: Boolean,
      default: false
    },
    icon_class: {
      default: 'material-symbols'
    }
  },
  computed: {
    icon_class_computed() {
      return this.active ? this.icon_class : this.icon_class + '-outlined';
    }
  }
};
</script>

<style scoped>
.button-icon {
  display: flex;
  align-items: center;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}

.button-icon.column {
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

.button-icon.row {
  flex-direction: row;
}

.button-icon .title {
  width: 100%;
  text-align: center;
}

.button-icon.column .title {
  font-size: 8px;
}

.button-icon.row .title{
  font-size: 12px;
  margin-left: 15px;
}

.center-text {
  width: 100%;
  text-align: center;
}

button span {
  font-size: inherit;
}
</style>
