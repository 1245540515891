<template>
  <div>
    <oib-page>
      <oib-nav-student title="" :show_backdrop="true">
        <template v-slot:right>
            <oib-button-icon
            v-if="has_role(['SYSTEM_ADMIN', 'SYSTEM_SUPPORT'])"
            icon="edit"
            style="font-size: 24px;"
            :has_backdrop="true"
            @click="go_to_thema_aanpassen"
            />
          </template>
      </oib-nav-student>
      <oib-content padding_top='padding_top' class="backdrop" style="min-height: 600px" :style="backdrop_style">
        <div class="column wrapper" style="margin-top: -30px;">
          <div class="row" style="max-width: 1500px; justify-content: space-between; height: 80px;">
            <h1 class="truncate" style="text-transform: capitalize; margin-bottom: 10px;">{{title}}</h1>
            <div
            :style="`opacity: ${opacity}`"
            class="drop-shadow fade"
            style="width: 110px; height: 110px;transform: translate(20px, -40px) rotate(25deg); background-color: white; border-radius: 2px; padding: 5px;"
            >
              <img
              :src="img_src"
              :alt="tag_name + ' icoon'"              
              style="width: 110px; height: 110px;"
              :onload="show_image"
              />
            </div>
          </div>
        </div>
        <oib-book-grid
        :query="query_obj"
        :sub_text_key="sub_text_key"
        @select="on_select"
        class="fade"
        :style="`opacity: ${opacity}`"
        ref="book_grid"
        />
      </oib-content>
    </oib-page>
  </div>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibContent from "../components/OibContent.vue"
import OibBookGrid from '../components/oib_book_grid.vue'
import OibButtonIcon from "../components/OibButtonIcon.vue"

import {get_book_tag_img} from "../store/api/library.js"
import { has_role } from "../store/utils.js";

export default {
  name: "BibliotheekZoekenThema",
  props: {
    tag_id: {
      required: true
    },
    tag_name: {
      required: true,
      type: String
    },
    tag_name_alias: {
      required: true,
      type: String
    },
    color: {
      required: true,
      type: String
    }
  },
  components: {
    OibPage,
    OibNavStudent,
    OibContent,
    OibBookGrid,
    OibButtonIcon
  },
  data() {
    return {
      filter_library: true,
      user_id: null,
      sub_text_key: 'author_name',
      query_obj: null,
      img_src: null,
      title: '',
      opacity: 0
    }
  },
  created() {
    this.img_src = get_book_tag_img(this.tag_name)
    this.title = this.parse_title()
  },
  async mounted() {

    // Do not run if user is not defined - User did refresh or logout
    if (!this.$store.getters.getUser) {
      return
    }

    // Start search
    // Since some mobile browsers resize on mounted, add timeout to wait for resize event
    // Without this timeout there will be a mismatch between the query_limit and the search_limit
    this.search();
    setTimeout(() => this.opacity = 1, 300)
  },
  updated() {
    this.search()
  },
  computed: {
    active_library_selector() {
      return this.$store.getters.get_active_library_selector
    },
    backdrop_style() {
      return {'--backdrop-color': this.color}
    }
  },
  watch: {
    active_library_selector() {
      this.search()
    }
  },
  methods: {
    // Imported
    has_role,

    // Local
    show_image() {
      this.opacity = 1
    },
    parse_title() {
      return this.tag_name_alias.replaceAll(' en ', ' & ')
    },
    async search() {

      // Build query
      let query = {
        'tag_id': this.tag_id,
        'min_tag_relevance': 0.2
      }

      // Set default settings
      query['select'] = 'isbn13,book_edition_id,book_title,author_name'
      query['sort'] = 'tag_relevance,DESC'

      // Apply library filters
      if (this.filter_library && !this.user_id) {
        const library_selector = this.$store.getters.get_active_library_selector
        if (library_selector != null) { 
          if (this.user_id_recommendation) {
            const library_id_access = Array.from(this.$store.getters.get_user_library_dict[this.user_id_recommendation])
            query['library_id'] = library_id_access.join(',')
          } else {
            query['library_id'] = this.$store.getters.get_library_selector_list[library_selector].query['library_id']
          }          

          query['select'] += ',copies_in_use,copies_total,borrow_count_sum,age_min,age_max'
        }
      }

      this.query_obj = {
        'grouping': 'editions',
        'query': query
      }
    },
    is_number(string) {
      return /^\d+$/.test(string)
    },
    pick_library() {
      this.$router.push({
        name: 'BibliotheekKiezenGroep',
        params: {
          'user_id': this.user_id,
          'library_route': 'back'
        }
      });
    },
    go_to_thema_aanpassen() {
      this.$router.push({
        name: 'ThemaAanpassen',
        params: {
          'tag_id': this.tag_id,
        }
      });
    },
    on_select(copy_id) {
      if (this.select_set_copy_id.has(copy_id)) {
        this.select_set_copy_id.delete(copy_id)
      } else {
        this.select_set_copy_id.add(copy_id)
      }
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.book-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.close-modal {
  width: 100%;
  font-size: 0.9em;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 50px;
  font-weight: bold;
}

.clickable:hover {
  cursor: pointer;
}

.submit.mobile {
  position: fixed;
  bottom: 0px;
  z-index: 5;
  width: 100%;
  padding: 10px 0;
  text-align: center;  
  background-color: var(--primary-color-sub);
}

.submit.mobile:hover {
  cursor: pointer;
}

.submit.mobile button {
  width: 100%;
  max-width: 400px;
  padding: 10px 30px;
}

.sort-selector {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  cursor: pointer;
}

.filter-icon {
  margin-right: 20px;
  margin-top: 2px;
}

.sort-section-header {
  justify-content: space-between;
  align-items: center;
}

.user-feedback {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;  
}

.user-feedback div {
  justify-content: center;
  text-align: center;
  max-width: 380px;
  height: 100%;
}

@media screen and (min-width:992px) {
  .user-feedback {
    width: calc(100% - 200px)
  }
}
.drop-shadow {
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  -o-transition: box-shadow 0.2s ease-in;
  -ms-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
  -webkit-box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40); 
  box-shadow: 5px 5px 20px 5px rgba(0,0,0,0.40);
}

.tag-tile:hover {
  cursor: pointer;
}

.backdrop {
  background: rgb(27,23,37);
  background: linear-gradient(0deg, rgba(27,23,37,1) 0%, var(--backdrop-color) 100%);
  background-size: 100% 550px;
  background-repeat: no-repeat;
}

.truncate{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.fade {
  -webkit-transition: all 0.15s ease-in;
  -moz-transition: all 0.15s ease-in;
  -o-transition: all 0.15s ease-in;
  -ms-transition: all 0.15s ease-in;
  transition: all 0.15s ease-in;
}

</style>
