<template>
  <oib-form :form_step_validation_list="form_step_validation_list" title="Bieb aanpassen" @submit="submit">
    <div v-for="(building, index) in building_list" :key="building.building_id">
      <oib-form-header :header="building.building_name"/>
      <oib-input-multi-select
      v-if="Object.keys(get_building_classrooms_names(building.building_id)).length > 0"
      label="Toegang"
      :description="`Selecteer de klaslokalen op de ${building.building_name} die toegang hebben tot deze bibliotheek.`"
      :allow_multi_select="true"
      :required="true"
      :options="get_building_classrooms_names(building.building_id)"
      :keys_init="classroom_access_index_set_dict[building.building_id]"
      @select="x => set_classroom_access(index, x)"/>
      <div
      v-if="Object.keys(get_building_classrooms_names(building.building_id)).length == 0"
      >
        <label>Toegang<span style="margin-left: 4px; color: var(--highlight-color)">*</span></label>
        <p class="description">Selecteer de klaslokalen op de {{building.building_name}} die toegang hebben tot deze bibliotheek.</p>
        <p class="description">Deze functie wordt geactiveerd zodra de De Leesapp is gekoppeld aan het leerlingvolgsysteem.</p>
      </div>
    </div>    
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputMultiSelect from "../components/oib_form/oib_input_multi_select.vue"

export default {
  name: "OibFormLibrary",
  props: {
    library_id: {
      default: null
    },
    library_name_init: {
      type: String,
      default: ""
    },
    building_id_init: {
      default: null
    },
    classroom_id_init: {
      default: null
    },
    classroom_access_id_set_init: {
      default: null
    }
  },
  components: {
    OibForm,
    OibFormHeader,
    OibInputMultiSelect
  },
  data() {
    return {
      form_step_validation_list: [true],
      building_list: [],
      building_dict: {},
      classroom_list: [],
      classroom_dict: {},
      classroom_access_index_set_dict: {},
      classroom_access_set_dict: {},
    }
  },
  created() {

    this.building_list = this.$store.getters.get_building_list
    this.$store.getters.get_building_list.forEach(x => {
      this.building_dict[x['building_id']] = x['building_name']
    })
    this.classroom_list = this.$store.getters.get_classroom_list

    this.classroom_list.forEach(x => {
      this.classroom_dict[x['classroom_id']] = x['classroom_name']
    })

    // Init validation list
    this.form_step_validation_list = []
    for (let i = 0; i < this.building_list.length; i++) {
      this.form_step_validation_list.push(true)
    }

    // Init classroom access with empty sets
    for (let i = 0; i < this.building_list.length; i++) {
      this.set_classroom_access(i, new Set())
    }    

    // Init form
    if (this.library_id != null)  {

      // Access
      if (this.classroom_access_id_set_init != null) {

        // Init classroom set for each building
        for (let i = 0; i < this.building_list.length; i++) {
          const building_id = this.building_list[i]['building_id']
          this.classroom_access_index_set_dict[building_id] = new Set()
        }

        // Group classroom indices by building
        for (let i = 0; i < this.classroom_list.length; i++) {
          const classroom = this.classroom_list[i]
          const classroom_id = classroom['classroom_id']
          if (this.classroom_access_id_set_init.has(classroom_id)) {
            this.classroom_access_index_set_dict[classroom['building_id']].add(classroom_id)
          }
        }
      }      
    }    
  },
  methods: {
    get_building_classrooms(building_id) {
      let classroom_list = []
      this.classroom_list.forEach((x) => {
        if (x['building_id'] == building_id) {
          classroom_list.push(x)
        }
      })
      return classroom_list
    },
    get_building_classrooms_names(building_id) {
      let name_dict = {}
      this.get_building_classrooms(building_id).forEach(x => {
        name_dict[x['classroom_id']] = x['classroom_name']
      })
      return name_dict
    },
    set_classroom_access(step_index, indices) {
      const building_id = this.building_list[step_index]['building_id']
      this.classroom_access_set_dict[building_id] = new Set(indices)
    },
    async submit() {

      // Get classrooms from all buildings that have access to this library
      let classroom_access_set = new Set()
      for (let building of this.building_list) {
        const building_id = building['building_id'].toString()
        const access_set = this.classroom_access_set_dict[building_id]
        classroom_access_set = new Set([...classroom_access_set, ...access_set]);
      }

      // Publish submit event
      this.$emit("submit", {
        'classroom_access_id_set': classroom_access_set,
      })
    }
  }
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 10px;
}

.description {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 0.9em;
}
</style>