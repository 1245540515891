import { createStore } from 'vuex'

import user from "./modules/user"
import library from "./modules/library"
import library_v2 from "./modules/library_v2"
import lvs from "./modules/lvs"
import exercise from "./modules/exercise"
import bookUser from "./modules/bookUser"
import style from "./modules/style"
import test from "./modules/test"
import task from "./modules/task"
import ux from "./modules/ux"
import book from "./modules/book"
import book_recommendation from "./modules/book_recommendation"

import { get_stream } from "../components/barcode_reader/src/misc/camera.js"

export default createStore({
  state: {
    userDataLoaded: false,
    debug_mode: false,
    stream: null,
    schoolyear: '2024-2025'
  },
  mutations: {
    set_debug_mode(state, value) {
      state.debug_mode = value
    },
    stop_stream(state) {
      if (!state.stream) {
        return
      }

      state.stream.getTracks().forEach(track => {
        state.stream.removeTrack(track)
        track.stop()
      });
    },
    setUserDataLoaded(state, value) {
      state.userDataLoaded = value;
    }
  },
  modules: {
    user,
    library,
    exercise,
    bookUser,
    style,
    library_v2,
    lvs,
    test,
    task,
    ux,
    book,
    book_recommendation
  },
  getters: {
    get_debug_mode: state => state.debug_mode,
    get_schoolyear: state => state.schoolyear,
    getUserDataLoaded: state => state.userDataLoaded,
    getLocalChangesCount: state => {
        var count = 0;
        for (const changes in state.localChanges) count += Object.keys(state.localChanges[changes]).length;
        return count;
    },
    async get_stream(state) {
      if(!state.stream || !state.stream.active) {
        state.stream = await get_stream('auto', state.debug_mode)
        if (state.debug_mode) {
          alert(state.stream.active)
        }
      }
      return state.stream
    }
  }
})
