import {get_schools_lvs_query} from "../api/lvs.js"
import { get_schools_lvs_sync_query } from "../api/lvs.js"
import {get_schools_lvs_download_results_query} from "../api/lvs.js"

export default {
  state: {
    lvs_link: null,
    sync_request: null,
    download_result: null,
  },
  actions: {
    async load_lvs(state, payload) {
      const jwt = payload.jwt
      const branch_number = payload.branch_number

      // Load lvs_link
      console.log(branch_number)
      let json = await get_schools_lvs_query(jwt, {'branch_number': branch_number})
      const lvs_link_list = json['lvs_list']
      if (lvs_link_list.length != 1) {
        return
      }

      const lvs_link = lvs_link_list[0]
      state.commit('set_lvs_link', lvs_link)

      // Load sync request
      json = await get_schools_lvs_sync_query(jwt, {
        'branch_number': branch_number,
        'lvs_type': lvs_link.lvs_type
      })
      const sync_request_list = json['sync_request_list']
      if (sync_request_list.length > 0) {
        state.commit('set_lvs_sync_request', sync_request_list[0])
      }

      // Load download result
      json = await get_schools_lvs_download_results_query(jwt, {
          branch_number: branch_number,
          lvs_type: lvs_link.lvs_type
      })

      const download_result_list = json['download_result_list']
      if (download_result_list.length > 0) {
        state.commit('set_lvs_download_result', download_result_list[0])
      }     

      state.dispatch("sync_task_lvs_sync_request", {
        'jwt': jwt,
        'organisation_id': state.getters.get_organisation_id_active,
        'lvs_sync_request': state.state.sync_request
      })
    },
  },
  mutations: {
    set_lvs_link(state, lvs_link) {
      state.lvs_link = lvs_link
    },
    set_lvs_sync_request(state, sync_request) {
      state.sync_request = sync_request
    },
    set_lvs_download_result(state, download_result) {
      state.download_result = download_result
    },
    reset_lvs(state) {
      state.lvs_link = null,
      state.sync_request = null
      state.download_result = null
    }
  },
  getters: {
    get_lvs_link: state => state.lvs_link,
    get_lvs_sync_request: state => state.sync_request,
    get_lvs_download_result: state => state.download_result
  }
}
