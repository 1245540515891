<template>
  <oib-button-qr-code @click="go_to_route('LoginQrCode')" text="Log in met boekenlegger" />
</template>

<script>
import OibButtonQrCode from "./oib_button_qr_code.vue"

import { go_to_route } from "../store/navigation";

export default {
  name: "OibButtonLoginQrCode",
  components: {
    OibButtonQrCode
  },
  methods: {
    // Import
    go_to_route
  }
}
</script>
