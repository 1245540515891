<template>
  <div class="avatar icon">
    <span class="material-icons">{{ icon }}</span>
  </div>
</template>


<script>
export default {
  name: "OibAvatarIcon",
  props: ["icon"],
};
</script>

<style scoped>
.avatar.icon {
  background-color: #21222f;
  color: #00d8ff;
  display: flex;
  align-items: center;
}

.avatar.icon span {
  width: 100%;
}
</style>