<template>
  <div>
    <audio ref="bell">
      <source src="../assets/bell.mp3" />
    </audio>
  </div>
</template>

<script>
import Recorder from "../recorder.js";

export default {
  name: "OibAudioRecorderV2",
  data() {
    return {
      audioContext: null,
      recorder: null,
    };
  },
  beforeUnmount() {
    this.recorder && this.recorder.destroy();
  },
  methods: {
    async startRecording() {
      // initialise recorder if required
      if (!this.recorder) {
        await this.initRecorder();
      }

      this.recorder.clear()
      this.recorder.record();
    },
    stopRecording() {
      this.recorder && this.recorder.stop();

      // Play finished sound
      this.$refs.bell.play();
    },
    async initRecorder() {
      try {
        // webkit shim
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        navigator.getUserMedia =
          navigator.getUserMedia || navigator.webkitGetUserMedia;
        window.URL = window.URL || window.webkitURL;

        this.audioContext = new AudioContext();
      } catch (e) {
        alert("No web audio support in this browser!");
      }

      // config, callbackSuccess, callbackError
      const self = this;
      await navigator.mediaDevices
        .getUserMedia({
          audio: {
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false,
            googAutoGainControl: false,
            channelCount: 1,
          },
        })
        .then(function (stream) {
          /* use the stream */
          const input = self.audioContext.createMediaStreamSource(stream);
          self.recorder = new Recorder(input);
        })
        .catch(function (err) {
          console.log("No live audio input: " + err);
        });
    },
    createDownloadLink() {
      this.recorder &&
        this.recorder.exportWAV(function (blob) {
          const url = URL.createObjectURL(blob);
          const au = document.createElement("audio");
          const hf = document.createElement("a");

          au.controls = true;
          au.src = url;
          hf.href = url;
          hf.download = new Date().toISOString() + ".wav";
          hf.innerHTML = hf.download;
        });
    },
    exportWAV(func) {
      this.recorder &&
        this.recorder.exportWAV((blob) => func(blob))
    }
  }
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.bottom-card {
  border-top-left-radius: 40px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
