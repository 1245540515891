<template>
  <canvas :id="id"></canvas>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "OibChart",
  props: ["id", "data", "options", "text"],
  mounted() {
    this.renderChart();
  },
  watch: {
    text() {
      this.chart.data.text = this.text 
      this.chart.update()
    },
    data() {
      this.chart.data = this.data
      this.chart.options = this.options;
      this.chart.data.text = this.text
      this.chart.update();
    },
  },
  methods: {
    renderChart() {
      let data = this.data

      data['text'] = this.text

      const config = {
        type: "line",
        data,
        options: this.options,
      };

      const media_query = this.$mq;

      CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
        if (width < 2 * radius) radius = width / 2;
        if (height < 2 * radius) radius = height / 2;
        this.beginPath();
        this.moveTo(x + radius, y);
        this.arcTo(x + width, y, x + width, y + height, radius);
        this.arcTo(x + width, y + height, x, y + height, radius);
        this.arcTo(x, y + height, x, y, radius);
        this.arcTo(x, y, x + width, y, radius);
        this.closePath();
        return this;
      }

      var originalDoughnutDraw = Chart.controllers.line.prototype.draw;
      Chart.helpers.extend(Chart.controllers.line.prototype, {
        draw: function() {
          originalDoughnutDraw.apply(this, arguments);

          // Compute chart and text dimensions
          var chart = this.chart;
          var width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx;
          
          const text = chart.config.data.text;
          if (text !== undefined) {
            ctx.clearRect(0, 0, width, height)
            var textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;

            // Create rectangular contrasting background
            const padding = media_query == "desktop" ? 50 : 45;
            ctx.filter = 'none';

            ctx.roundRect(textX - (padding / 2), textY - (padding / 2), ctx.measureText(text).width + padding, padding, 8);
            ctx.fillStyle = "rgb(33, 34, 47)";
            ctx.fill();

            // Print text on top of contrasting background
            var fontSize = media_query == "desktop" ? "16px" : "14px";
            ctx.font = `bold ${fontSize} Open Sans`;
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.fillText(text, textX, textY);

            
            // ctx.shadowBlur = 1.2;
            // // ctx.stroke();
            // ctx.filter = media_query == "desktop" ? 'blur(10px)' : 'blur(20px)';
          }
        }
      });

      Chart.helpers.extend(Chart.controllers.bar.prototype, {
        draw: function() {
          originalDoughnutDraw.apply(this, arguments);

          // Compute chart and text dimensions
          var chart = this.chart;
          var width = chart.chart.width,
              height = chart.chart.height,
              ctx = chart.chart.ctx;

          const text = chart.config.data.text;
          if (text !== undefined) {
            ctx.clearRect(0, 0, width, height)

            var textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;

            // Create rectangular contrasting background
            const padding = media_query == "desktop" ? 50 : 45;
            ctx.filter = 'none';

            ctx.roundRect(textX - (padding / 2), textY - (padding / 2), ctx.measureText(text).width + padding, padding, 8);
            ctx.fillStyle = "rgb(33, 34, 47)";
            ctx.fill();

            // Print text on top of contrasting background
            var fontSize = media_query == "desktop" ? "16px" : "14px";
            ctx.font = `bold ${fontSize} Open Sans`;
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.fillText(text, textX, textY);

            
            // ctx.shadowBlur = 0.1;
            // // ctx.stroke();
            // ctx.filter = media_query == "desktop" ? 'blur(10px)' : 'blur(20px)';
          }
        }
      });


      this.chart = new Chart(document.getElementById(this.id), config);
    },
  },
};
</script>
