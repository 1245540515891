export default ({
    state: {
      screen_height: null,
    },
    mutations: {
      set_screen_height(state, screen_height) {
        state.screen_height = screen_height
      }
    },
    getters: {
      get_screen_height: state => state.screen_height
    }
  })
  