<template>
  <oib-page>
    <oib-nav-student title="Thema's beheren"/>
    <oib-content padding_top='padding_top' style="padding-bottom: 100px;">
      <section class="wrapper column">
        <oib-grid-static-tag
          :item_list="tag_list"
        />
      </section>
    </oib-content>
  </oib-page>
</template>

<script>
import {get_tags_query} from "../store/api/book.js"

import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibGridStaticTag from "../components/oib_grid_static_tag.vue"

export default {
  name: "ThemaBeheren",
  props: ['isbn13'],
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibGridStaticTag
  },
  data() {
    return {
      tag_list: []  
    }
  },
  async mounted() {
    const jwt = this.$store.getters.get_jwt
    const json = await get_tags_query(jwt, {'limit': 100000})
    this.tag_list = json['tag_list']
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
};
</script>
