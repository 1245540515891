import { StreamApiNotSupportedError, InsecureContextError } from "./errors.js";
import { eventOn, timeout } from "callforth";
// import { eventOn } from "callforth";
import shimGetUserMedia from "./shimGetUserMedia";
import Swal from 'sweetalert2'

class VideoElement {
  constructor(videoEl) {
    this.videoEl = videoEl
  }

  stop() {
    this.videoEl.srcObject = null;
  }
}

function get_ideal_resolution() {
  // Get camera resolution based on device orientation

  const portrait = {
    // 'aspectRatio': 9 / 16,
    'width': { min: 360, ideal: 1920, max: 1920 },
    'height': { min: 360, ideal: 1920, max: 1920 }
  }
  const landscape = {
    // 'aspectRatio': 16 / 9,
    'width': { min: 360, ideal: 1920, max: 1920 },
    'height': { min: 720, ideal: 1080, max: 1080 }
  }

  return window.innerHeight > window.innerWidth ? portrait : landscape
}

async function getMediaDevices() {
  // Filter some devices, known to be bad choices.
  const deviceBlackList = ["OBS Virtual Camera", "OBS-Camera"];

  return (await navigator.mediaDevices.enumerateDevices())
    .filter(({ kind }) => kind === "videoinput")
    .filter(({ label }) => !deviceBlackList.includes(label))
    .filter(({ label }) => !label.includes("infrared"));
}

// Modern phones often have multipe front/rear cameras.
// Sometimes special purpose cameras like the wide-angle camera are picked
// by default. Those are not optimal for scanning QR codes but standard
// media constraints don't allow us to specify which camera we want exactly.
const narrowDownFacingMode = async (camera, debug_mode) => {
  
  const devices = await getMediaDevices()

  let constraint = undefined
  let devices_custom = []
  if (devices.length > 1) {
    // Explicitly picking the first entry in the list of all videoinput
    // devices for as the default front camera and the last entry as the default
    // rear camera seems to be a good heuristic on some devices.
    let front_camera = devices[0];
    let rear_camera = devices[devices.length - 1];

    // Rare devices with complex camera setups do not follow this practice
    // Filter camera by custom label
    const filter_list = ['camera aan achterzijde', 'camera back', 'back camera']
    devices_custom = devices.filter(({ label }) => filter_list.includes(label.toLowerCase()))
    if (devices_custom.length > 0) {
      rear_camera = devices_custom[0]
    }

    switch (camera) {
      case "auto":
        constraint = { deviceId: { exact: rear_camera.deviceId } };
        break
      case "rear":
        constraint = { deviceId: { exact: rear_camera.deviceId } };
        break
      case "front":
        constraint = { deviceId: { exact: front_camera.deviceId } };
        break
      default:
        constraint = undefined;
    }
  } else {
    switch (camera) {
      case "auto":
        constraint = { facingMode: { ideal: "environment" } };
        break
      case "rear":
        constraint = { facingMode: { exact: "environment" } };
        break
      case "front":
        constraint = { facingMode: { exact: "user" } };
        break
      default:
        constraint = undefined;
    }
  }

  if (debug_mode) {
    Swal.fire(navigator.userAgent + '\n\n' + `${devices.length} camera's gevonden` + '\n\n' + JSON.stringify(devices) + '\n\n' + JSON.stringify(devices_custom) + '\n\n' + JSON.stringify(constraint) + '\n\n' + `${window.innerWidth} - ${window.innerHeight} - ${JSON.stringify(get_ideal_resolution())}`)
  }

  return constraint
};

export async function get_stream(camera, debug_mode) {

  if (window.isSecureContext !== true) {
    throw new InsecureContextError();
  }

  if (navigator?.mediaDevices?.getUserMedia === undefined) {
    throw new StreamApiNotSupportedError();
  }

  // This is a browser API only shim. It patches the global window object which
  // is not available during SSR. So we lazily apply this shim at runtime.
  await shimGetUserMedia();

  // Get camera resolution based on device orientation
  const resolution = get_ideal_resolution()

  // navigator.mediaDevices.enumerateDevices() will return an empty label attribute value
  // if the permission for accessing the mediadevice is not given.
  // Call getUserMedia to request permissions
  const devices = await getMediaDevices()
  if (devices.length == 0 || (devices.length == 1 && devices[0].deviceId == '')) {

    if (debug_mode) {
      Swal.fire('No devices found - requesting camera permissions')
    }

    await navigator.mediaDevices.getUserMedia({
      audio: false,
      video: {
        width: resolution.width,
        height: resolution.height,
        ...(await narrowDownFacingMode(camera, debug_mode))
      }
    });
  }

  // Set constraints
  const constraints = {
    audio: false,
    video: {
      width: resolution.width,
      height: resolution.height,
      ...(await narrowDownFacingMode(camera, debug_mode))
    }
  };

  // Return stream
  const stream = await navigator.mediaDevices.getUserMedia(constraints);

  if (debug_mode && !stream) {
    alert("No stream created")
  }

  return stream
}

export async function create_video_element_stream(videoEl, stream) {
  // At least in Chrome `navigator.mediaDevices` is undefined when the page is
  // loaded using HTTP rather than HTTPS. Thus `STREAM_API_NOT_SUPPORTED` is
  // initialized with `false` although the API might actually be supported.
  // So although `getUserMedia` already should have a built-in mechanism to
  // detect insecure context (by throwing `NotAllowedError`), we have to do a
  // manual check before even calling `getUserMedia`.

  if (videoEl.srcObject !== undefined) {
    videoEl.srcObject = stream;
  } else if (videoEl.mozSrcObject !== undefined) {
    videoEl.mozSrcObject = stream;
  } else if (window.URL.createObjectURL) {
    videoEl.src = window.URL.createObjectURL(stream);
  } else if (window.webkitURL) {
    videoEl.src = window.webkitURL.createObjectURL(stream);
  } else {
    videoEl.src = stream;
  }

  await eventOn(videoEl, "loadeddata");

  // According to: https://oberhofer.co/mediastreamtrack-and-its-capabilities/#queryingcapabilities
  // On some devices, getCapabilities only returns a non-empty object after
  // some delay. There is no appropriate event so we have to add a constant timeout
  // await timeout(500);
  await timeout(100);

  return new VideoElement(videoEl);
}
