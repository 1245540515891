<template>
  <oib-page>
    <oib-header title="Inlog instructies"/>
    <div class="wrapper column average">
      <h2 class="h2" style="margin-top: 100px;">{{$store.getters.get_login_error_message}}</h2>
      <div v-html="$store.getters.get_login_error_instructions"/>
    </div>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibHeader from "../components/OibHeader.vue"

export default {
  name: "Opdracht",
  components: {
    OibPage,
    OibHeader,
  },
};
</script>
