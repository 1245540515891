<template>
  <oib-form :form_step_validation_list="form_step_validation_list" @submit="submit">
    <div>
      <oib-form-header header="Stem op boek informatie"/>
      <oib-input-text
      label="Paginanummers"
      placeholder="256"
      :required="true"
      :error_msg="page_count_error"
      :init_value="init_page_count.toString()"
      @input="set_page_count($event.target.value)"
      />
    </div>
  </oib-form>
</template>

<script>
import OibForm from "../components/oib_form/oib_form.vue";
import OibFormHeader from "../components/oib_form/oib_form_header.vue"
import OibInputText from "../components/oib_form/oib_input_text.vue"

export default {
  name: "OibFormLibrary",
  props: {
    init_page_count: {
      default: ""
    }
  },
  components: {
    OibForm,
    OibFormHeader,
    OibInputText
  },
  data() {
    return {
      form_step_validation_list: [false],
      page_count: "",
      page_count_error: "",
    }
  },
  async mounted() {
    this.val_step_1()
  },
  methods: {
    set_page_count(value) {
      this.page_count = value
      this.val_step_1()
    },
    async val_step_1() {
      this.form_step_validation_list[0] = this.page_count != "" && !isNaN(this.page_count)
    },
    async submit() {
      // Publish submit event
      this.$emit("submit", {
        'page_count': this.page_count
      })
    }
  }
};
</script>
