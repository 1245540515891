<template>
  <oib-page>
    <oib-nav-student v-if="user" :title="user_name" :allowLogout="has_role(['STUDENT'])"/>
    <oib-content padding_top='padding_top'>
      <div style="display: flex; flex-direction: column;">
        <div class="wrapper column">
          <h2 class="h2" style="margin-top: 0">
            {{ charts[activeChartIndex].title }}
            <span
              style="font-size: 12px; letter-spacing: 1.5px; color: #666666"
              >{{ charts[activeChartIndex].subtitle }}</span
            >
          </h2>
          <div 
          v-for="(avi, index) in avi_dict"
          :key="avi"
          :style="`height: 0; transform: translate(0, calc(30vh / 11 * ${11 - index / 5} - 8px));`">
            <span class="y-axis-label" :style="(charts[activeChartIndex].last_score == index) ? 'color: white; font-weight: bold': ''">{{avi_dict[index]}}</span>
          </div>
        </div> 
        <oib-chart
          id="sdf"
          :data="charts[activeChartIndex].data"
          :options="charts[activeChartIndex].options"
          :text="has_graph_access ? (avi_data_known ? undefined : 'Geen leestoetsen ingeladen') : 'Niet weergeven'"
          style="max-height: 30vh"
        />
        <div class="graph-controls">
          <div class="graph-selectors">
            <div class="button-label-group">
              <oib-button-label
                v-for="(label, index) in graphSelectors"
                :key="label"
                :active="active[index]"
                v-on:click="toggleGraph(index)"
                style="margin: 0 6px"
              >
                {{ label }}
              </oib-button-label>
            </div>
          </div>
          <div class="wrapper row" style="justify-content: space-between; align-items: end">
            <h2>Aan het lezen</h2>
          </div>
        </div>
        <div class="fade" :style="`opacity: ${loaded_has_action ? 100 : 0}`">
          <div v-if="books_reading" class="wrapper column">
            <oib-list-item-books-reading
              v-for="log in books_reading"
              :key="log.isbn13"
              :book="log"
              :user_id="user_id"
              style="max-width: 1342px;"
            />
            <div style="width: 200px;">
              <oib-button
                style="width: 160px; padding: 5px; height: 30px; margin-top: 20px; margin-bottom: 10px;"
                class="next"
                @click="go_to_route('BoekScan', {'user_id': user_id, 'fast_borrow': true})"
              >Nieuw boek lenen</oib-button>
            </div>
          </div>
        </div>
        <div class="fade" :style="`opacity: ${loaded_book_slides ? 100 : 0}`">
          <div v-if="tile_list_recommendations.length > 0" style="margin-top: 30px">
            <div class="wrapper">
              <div class="row section-header">
                <h2 style="margin-top: 0">Boektips</h2>
                <p @click="go_to_search({'sort': 'Meest relevant', 'user_id_recommendation': user_id})">Bekijk alles ➜</p>
              </div>
            </div>
            <oib-book-swiper
            :user_id="user_id"
            :tile_list="tile_list_recommendations"
            :show_availability="false"
            :marker_width="80"
            style="padding-left: 5%; margin-top: 10px;"
            />
          </div>
          <div v-if="tile_list_latest.length > 0" style="margin-top: 30px">
            <div class="wrapper">
              <div class="row section-header">
                <h2 style="margin-top: 0">Recente boeken</h2>
                <p @click="go_to_search({'sort': 'Datum uitgelezen', 'user_id': user_id, 'progress_state': '2,3'})">Bekijk alles ➜</p>
              </div>
            </div>
            <oib-book-swiper
            :user_id="user_id"
            :tile_list="tile_list_latest"
            :show_availability="false"
            :marker_width="65"
            style="padding-left: 5%; margin-top: 10px;"
            />
          </div>
          <div class="wrapper" style="margin-top: 20px"><h2>Logboek</h2></div>
          <log-book :user_id="user_id" />
        </div>
      </div>
    </oib-content>
  </oib-page>
</template>

<script>
import OibPage from "../components/OibPage.vue";
import OibContent from "../components/OibContent.vue";
import OibNavStudent from "../components/OibNavStudent.vue"
import OibButton from "../components/OibButton.vue"
import OibChart from "../components/OibChart.vue";
import OibButtonLabel from "../components/OibButtonLabel.vue";
import LogBook from "../components/LogBook.vue";
import OibListItemBooksReading from "../components/oib_list_item_books_reading.vue"
import OibBookSwiper from "../components/OibBookSwiper.vue";
import {get_books} from "../store/api/library"

import { has_role } from "../store/utils";
import {load_user} from "../store/user.js"
import {load_student} from "../store/user.js"
import {get_user_test_list} from '../store/test'
import { parse_query } from '../store/request'
import {load_user_recommendations} from '../store/book_recommendation'
import { go_to_route } from "../store/navigation";
import { get_books_users_query } from '../store/api/book_user_query'

export default {
  name: "OverzichtLeerling",
  props: {
    user_id: {
      required: true
    }
  },
  components: {
    OibPage,
    OibContent,
    OibNavStudent,
    OibButton,
    OibChart,
    OibButtonLabel,
    LogBook,
    OibListItemBooksReading,
    OibBookSwiper
  },
  computed: {
    contentPosition() {
      if (window.screen.width > 768) {
        return "desktop"
      }
      return "mobile"
    },
    idScoreChart() {
      return this.user_id + "-" + "scoreChart";
    },
    idReadingTimeChart() {
      return this.user_id + "-" + "readingTimeChart";
    }
  },
  beforeCreate() {
    if (!this.$store.getters.getActiveSession) {
      this.$router.push({
        name: "Login",
      });
    }
  },
  data() {
    return {
      has_graph_access: true,
      user: undefined,
      user_name: "",
      books_reading: [],
      tile_list_latest: [],
      tile_list_recommendations: [],
      schoolday_count_median_read: null,
      loaded_has_action: false,
      loaded_book_slides: false,
      graphSelectors: ["Leestoets-score"],
      active: [],
      activeChartIndex: 0,
      avi_data_known: true,
      avi_score_last: -1,
      dmt_score_last: -1,
      iep_score_last: -1,
      avi_datetime_last: -1,
      dmt_datetime_last: -1,
      iep_datetime_last: -1,
      avi_dict: {
        0: 'START',
        5: 'M3',
        10: 'E3',
        15: 'M4',
        20: 'E4',
        25: 'M5',
        30: 'E5',
        35: 'M6',
        40: 'E6',
        45: 'M7',
        50: 'E7',
        55: 'PLUS'
      },
      charts: [
        {
          title: "AVI-score",
          subtitle: "",
          data: {
            datasets: [{
            label: 'DLE',
            backgroundColor: "rgba(0, 174, 239, 0.1)",
            borderColor: "rgba(0, 255, 239, 1)",
            pointBackgroundColor: "rgba(0, 255, 239, 1)",
            lineTension: 0,
            data: [{
              x: new Date(2018, 10, 29),
              y: 0,
            },
            {
              x: new Date(2019, 1, 20),
              y: 0,
            },
            {
              x: new Date(2019, 6, 20),
              y: 0,
            },
            {
              x: new Date(2020, 1, 31),
              y: 0,
            },
            {
              x: new Date(2020, 6, 19),
              y: 0,
            },
            {
              x: new Date(2021, 3, 10),
              y: 0,
            },
            {
              x: new Date(2021, 6, 18),
              y: 0,
            }],
            // this dataset is drawn below
            type: 'line',
            order: 1
          }, {
            label: 'DL',
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            borderColor: "rgba(0, 174, 239, 1)",
            pointBackgroundColor: "rgba(0, 174, 239, 1)",
            lineTension: 0,
            borderDash: [10,10],
            data: [{
              x: new Date(2018, 10, 29),
              y: 0,
            },
            {
              x: new Date(2019, 1, 20),
              y: 0,
            },
            {
              x: new Date(2019, 6, 20),
              y: 0,
            },
            {
              x: new Date(2020, 1, 31),
              y: 0,
            },
            {
              x: new Date(2020, 6, 19),
              y: 0,
            },
            {
              x: new Date(2021, 3, 10),
              y: 0,
            },
            {
              x: new Date(2021, 6, 18),
              y: 0,
            }],
            // this dataset is drawn below
            type: 'line',
            order: 1
          }]
        },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            layout: {
              padding: {
                // Any unspecified dimensions are assumed to be 0
                right: 5,
              },
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  display: false,
                  time: {
                    unit: "month",
                  },
                },
              ],
              yAxes: [
                {
                  display: false,
                  ticks: {
                    min: 0,
                    max: 55,
                    maxTicksLimit: 10,
                  },
                  gridLines: {
                    zeroLineColor: "transparent",
                    drawOnChartArea: true,
                    drawTicks: true,
                    display: true,
                    drawBorder: true,
                  },
                },
              ],
            },
          },
        },
        {
          title: "DMT-score",
          subtitle: "",
          data: {
            datasets: [{
            label: 'DLE',
            backgroundColor: "rgba(0, 174, 239, 0.1)",
            borderColor: "rgba(0, 255, 239, 1)",
            pointBackgroundColor: "rgba(0, 255, 239, 1)",
            lineTension: 0,
            data: [{
              x: new Date(2018, 10, 29),
              y: 0,
            },
            {
              x: new Date(2019, 1, 20),
              y: 0,
            },
            {
              x: new Date(2019, 6, 20),
              y: 0,
            },
            {
              x: new Date(2020, 1, 31),
              y: 0,
            },
            {
              x: new Date(2020, 6, 19),
              y: 0,
            },
            {
              x: new Date(2021, 3, 10),
              y: 0,
            },
            {
              x: new Date(2021, 6, 18),
              y: 0,
            }],
            // this dataset is drawn below
            type: 'line',
            order: 1
          }, {
            label: 'DL',
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            borderColor: "rgba(0, 174, 239, 1)",
            pointBackgroundColor: "rgba(0, 174, 239, 1)",
            lineTension: 0,
            borderDash: [10,10],
            data: [{
              x: new Date(2018, 10, 29),
              y: 0,
            },
            {
              x: new Date(2019, 1, 20),
              y: 0,
            },
            {
              x: new Date(2019, 6, 20),
              y: 0,
            },
            {
              x: new Date(2020, 1, 31),
              y: 0,
            },
            {
              x: new Date(2020, 6, 19),
              y: 0,
            },
            {
              x: new Date(2021, 3, 10),
              y: 0,
            },
            {
              x: new Date(2021, 6, 18),
              y: 0,
            }],
            // this dataset is drawn below
            type: 'line',
            order: 1
          }]
        },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            layout: {
              padding: {
                // Any unspecified dimensions are assumed to be 0
                right: 5,
              },
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  display: false,
                  time: {
                    unit: "month",
                  },
                },
              ],
              yAxes: [
                {
                  display: false,
                  ticks: {
                    min: 0,
                    max: 55,
                    maxTicksLimit: 10,
                  },
                  gridLines: {
                    zeroLineColor: "transparent",
                    drawOnChartArea: true,
                    drawTicks: true,
                    display: true,
                    drawBorder: true,
                  },
                },
              ],
            },
          },
        },
        {
          title: "IEP-score",
          subtitle: "",
          data: {
            datasets: [{
            label: 'DLE',
            backgroundColor: "rgba(0, 174, 239, 0.1)",
            borderColor: "rgba(0, 255, 239, 1)",
            pointBackgroundColor: "rgba(0, 255, 239, 1)",
            lineTension: 0,
            data: [{
              x: new Date(2018, 10, 29),
              y: 0,
            },
            {
              x: new Date(2019, 1, 20),
              y: 0,
            },
            {
              x: new Date(2019, 6, 20),
              y: 0,
            },
            {
              x: new Date(2020, 1, 31),
              y: 0,
            },
            {
              x: new Date(2020, 6, 19),
              y: 0,
            },
            {
              x: new Date(2021, 3, 10),
              y: 0,
            },
            {
              x: new Date(2021, 6, 18),
              y: 0,
            }],
            // this dataset is drawn below
            type: 'line',
            order: 1
          }, {
            label: 'DL',
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            borderColor: "rgba(0, 174, 239, 1)",
            pointBackgroundColor: "rgba(0, 174, 239, 1)",
            lineTension: 0,
            borderDash: [10,10],
            data: [{
              x: new Date(2018, 10, 29),
              y: 0,
            },
            {
              x: new Date(2019, 1, 20),
              y: 0,
            },
            {
              x: new Date(2019, 6, 20),
              y: 0,
            },
            {
              x: new Date(2020, 1, 31),
              y: 0,
            },
            {
              x: new Date(2020, 6, 19),
              y: 0,
            },
            {
              x: new Date(2021, 3, 10),
              y: 0,
            },
            {
              x: new Date(2021, 6, 18),
              y: 0,
            }],
            // this dataset is drawn below
            type: 'line',
            order: 1
          }]
        },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            layout: {
              padding: {
                // Any unspecified dimensions are assumed to be 0
                right: 5,
              },
            },
            scales: {
              xAxes: [
                {
                  type: "time",
                  display: false,
                  time: {
                    unit: "month",
                  },
                },
              ],
              yAxes: [
                {
                  display: false,
                  ticks: {
                    min: 0,
                    max: 55,
                    maxTicksLimit: 10,
                  },
                  gridLines: {
                    zeroLineColor: "transparent",
                    drawOnChartArea: true,
                    drawTicks: true,
                    display: true,
                    drawBorder: true,
                  },
                },
              ],
            },
          },
        },
      ],
      name: "Tim Janssen",
      lastActive: new Date(2021, 1, 5),
      readingTime: 226,
      score: 417,
    };
  },
  async mounted() {
    // Skip if page was refreshed
    if (!this.$store.getters.getUser) {
      return
    }

    // set scroll to top
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // Load student info
    this.user = await load_user(this.user_id)
    this.user_name = this.user.user_name
    this.books_reading = await this.get_books_reading()
    this.loaded_has_action = true
    // this.load_book_sliders()
    this.load_graph()

    // Always show book slides after 200ms, in case one of the api's is having (performance) issues
    const self = this
    setTimeout(() => self.loaded_book_slides = true, 200)

    // TODO: implement if enough data was gathered
    // const jwt = this.$store.getters.get_jwt
    // const json = await get_books(jwt, 'editions', {
    //   'user_id': this.user_id,
    //   'select': 'isbn13,book_edition_id,progress_datetime,schoolday_count_median',
    //   'progress_state': 2,
    //   'sort': 'progress_datetime,ASC',
    //   'limit': 100
    // })
    // console.log(json)
  },
  methods: {
    // Import
    has_role,
    go_to_route,

    // Local
    async load_book_sliders() {
      
      await Promise.all([
        this.load_books_latest(),
        load_student(this.user_id)
      ])
      this.tile_list_recommendations = await load_user_recommendations(this.user_id)
      this.loaded_book_slides = true
    },
    async load_graph() {
      // Do not query test results if unauthorized
      if (!has_role(['TEACHER', 'LIBRARIAN', 'SUPERVISOR', 'SCHOOL_ADMIN', 'SYSTEM_ADMIN'])) {
        this.has_graph_access = false
        return
      }

      // Initialize charts
      for (var i = 0; i < this.active.length; i++) {
        this.active.push(false);
      }
      this.activeChartIndex = 0;
      this.active[this.activeChartIndex] = true;

      const test_result_list = await get_user_test_list(this.user_id)
      if (test_result_list.length == 0) {
        this.avi_data_known = false
      }

      let data_0 = []
      let data_1 = []
      let data_2 = []
      let data_3 = []
      let data_4 = []
      let data_5 = []
      test_result_list.forEach(x => {
        if (x['test_part_type'].startsWith('AVI')) {
          this.avi_score_last = x['didactic_age_equivalent']
          this.avi_datetime_last = x['test_datetime']

          data_0.push({
            x: new Date(x['test_datetime']),
            y: x['didactic_age_equivalent']
          })
          
          data_1.push({
            x: new Date(x['test_datetime']),
            y: x['didactic_age']
          })
        } else if (x['test_part_type'].startsWith('DMT')) {
          this.dmt_score_last = x['didactic_age_equivalent']
          this.dmt_datetime_last = x['test_datetime']

          data_2.push({
            x: new Date(x['test_datetime']),
            y: x['didactic_age_equivalent']
          })
          
          data_3.push({
            x: new Date(x['test_datetime']),
            y: x['didactic_age']
          })
        } else if (x['test_part_type'].startsWith('IEP')) {
          this.iep_score_last = x['didactic_age_equivalent']
          this.iep_datetime_last = x['test_datetime']

          data_4.push({
            x: new Date(x['test_datetime']),
            y: x['didactic_age_equivalent']
          })
          
          data_5.push({
            x: new Date(x['test_datetime']),
            y: x['didactic_age']
          })
        }
      })

      let last_datetime_list = [
        this.iep_datetime_last,
        this.dmt_datetime_last,
        this.avi_datetime_last
      ]

      last_datetime_list = last_datetime_list.filter(x => x != -1)
      last_datetime_list.sort().reverse()

      this.graphSelectors = []
      if (last_datetime_list.length == 0) {
        this.graphSelectors.push("Leestoets-score")
      } else {
        for (let i = 0; i < last_datetime_list.length; i++) {
          const dt = last_datetime_list[i]
          if (dt == this.iep_datetime_last && !this.graphSelectors.includes("IEP-score")) {
            this.graphSelectors.push("IEP-score")
          }
          if (dt == this.dmt_datetime_last && !this.graphSelectors.includes("DMT-score")) {
            this.graphSelectors.push("DMT-score")
          }
          if (dt == this.avi_datetime_last && !this.graphSelectors.includes("AVI-score")) {
            this.graphSelectors.push("AVI-score")
          }
        }
      }

      for (let i = 0; i < this.graphSelectors.length; i++) {
        const selector = this.graphSelectors[i]
        if (selector == "IEP-score") {
          this.addGraphData(i, data_4, data_5)
          this.charts[i].title = "IEP-score"
          this.charts[i].last_score = this.iep_score_last
        }
        if (selector == "DMT-score") {
          this.addGraphData(i, data_2, data_3)
          this.charts[i].title = "DMT-score"
          this.charts[i].last_score = this.dmt_score_last
        }
        if (selector == "AVI-score") {
          this.addGraphData(i, data_0, data_1)
          this.charts[i].title = "AVI-score"
          this.charts[i].last_score = this.avi_score_last
        }
      }
    },
    addGraphData(index, dle_data, dl_data) {
      if (dle_data.length > 0) {

        if (dle_data.length == 1) {
          dle_data.push({
            x: new Date(dle_data[0].x.getDate() + 1),
            y: dle_data[0].y
          })
          dl_data.push({
            x: new Date(dl_data[0].x.getDate() + 1),
            y: dl_data[0].y
          })
        }

        this.charts[index].data = {
          datasets: [{
            label: 'DLE',
            backgroundColor: "rgba(0, 174, 239, 0.1)",
            borderColor: "rgba(0, 255, 239, 1)",
            pointBackgroundColor: "rgba(0, 255, 239, 1)",
            lineTension: 0,
            data: dle_data
          }, {
            label: 'DL',
            backgroundColor: "rgba(0, 0, 0, 0.0)",
            borderColor: "rgba(0, 174, 239, 1)",
            pointBackgroundColor: "rgba(0, 174, 239, 1)",
            data: dl_data,
            lineTension: 0,
            borderDash: [10,10]
          }]
        }
      }
    },
    toggleGraph(index) {
      if (!this.active[index]) {
        for (var i = 0; i < this.active.length; i++) {
          this.active[i] = false;
        }
        this.activeChartIndex = index;
        this.active[index] = true;
      }
    },
    async get_books_reading() {
      const jwt = this.$store.getters.get_jwt
      console.log('xxxxx')
      console.log('xxxxx')
      console.log('xxxxx')
      const json = await get_books_users_query(jwt, this.user_id, {
        // 'is_active': true,
        'limit': 100
      })

      console.log(json)
      // const json = await get_books(jwt, 'products', {
      //   'user_id': this.user_id,
      //   'select': 'isbn13,book_title,progress_datetime,borrowed_datetime,library_id',
      //   'has_action': 1,
      //   'limit': 100,
      // })
      // return json['books']
      return json['book_user_list']
    },
    async load_books_latest() {
      const json = await this.get_books_latest()
      this.tile_list_latest = []

      let count = 0
      let avi_schoolday_count = 0

      json['books'].forEach(book => {
        if (book.schoolday_count_median != null) {
          avi_schoolday_count += book.schoolday_count_median
          count += 1
        }
        
        this.tile_list_latest.push({
          'isbn13': book.isbn13,
          'book_edition_id': book.book_edition_id,
          'book_title': book.book_title,
          'sub_text': book.progress_datetime,
          'marker': book.progress_state == 3 ? '✕ Gestopt' : null
        })
      })

      if (count > 4) {
        this.schoolday_count_median_read = avi_schoolday_count / count
      }
    },
    async get_books_latest() {
      const jwt = this.$store.getters.get_jwt
      return await get_books(jwt, 'products', {
        'user_id': this.user_id,
        'progress_state': '2,3',
        'select': 'isbn13,book_edition_id,book_title,progress_datetime,progress_state',
        'sort': 'progress_datetime,DESC',
        'limit': 8
      })
    },
    go_to_search(query) {
      // Parse query
      let query_parsed = parse_query(query)
      if (query_parsed == '') {
        query_parsed = null
      }

      this.$router.push({
        name: "BibliotheekZoekenFilter",
        params: {
          query: query_parsed
        }
      });
    }
  }
};
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hide {
  display: none;
}

.wrapper {
  display: flex;
  max-width: 90%;
  margin: auto;
}

.graph-controls {
  background-image: linear-gradient(
    rgba(0, 174, 239, 0.1),
    var(--primary-color)
  );
  height: 175px;
}

.graph-controls h2 {
  margin-top: 80px;
}

.graph-selectors {
  display: flex;
  margin-top: 20px;
  width: 100%;
  justify-content: space-around;
}

.button-label-group {
  display: flex;
  flex-direction: row;
}

.button-label-group div {
  padding: 0 5px;
}

.y-axis-label {
  font-size: 12px;
  letter-spacing: 1.5px;
  color: rgba(255, 255, 255, 0.3);
}

* {
  overflow-anchor: none;
}

.section-header {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1342px;
}

.section-header h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.section-header p {
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 0.7em;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.section-header p:hover {
  cursor: pointer;
}

</style>